/**
 * https://material-ui.com/customization/breakpoints/
 * xs, extra-small: 0px
 * sm, small: 600px
 * md, medium: 960px
 * lg, large: 1280px
 * xl, extra-large: 1920px
 */
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

const Breakpoints: { [key: string]: Breakpoint } = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
};

export default Breakpoints;
