import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './authSlice';
import { usersReducer } from './usersSlice';
import { contentReducer } from './contentSlice';
import { calculatorReducer } from './calculatorSlice';
import { snackbarReducer } from './snackbarSlice';
import { dashboardReducer } from './dashboardSlice';
import { adminReportsReducer } from './adminReportsSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    content: contentReducer,
    calculator: calculatorReducer,
    snackbar: snackbarReducer,
    dashboard: dashboardReducer,
    adminReports: adminReportsReducer,
  },
});
