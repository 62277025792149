import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { PageLayout, Spinner } from '../../components';
import {
  calculatorActions,
  selectIsLoading,
  selectIsReportSubmitted
} from '../../redux/calculatorSlice';
import useOnMount from '../../hooks/useOnMount';
import { Constants } from '../../utils';
import paths from '../paths';

const Calculator: FunctionComponent = ({ children }) => {
  const location = useLocation();
  const { state } = location;
  const reportUsername: string = (state as any)?.reportUsername;
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();
  const isSubmitted = useSelector(selectIsReportSubmitted);
  const params: { reportName: string } = useParams();

  const pagesWithFooter = [
    paths.calculatorServiceData.replace(
      Constants.REPORT.URL_PARAM,
      params.reportName
    ),
    paths.calculatorInterventionsChecklist.replace(
      Constants.REPORT.URL_PARAM,
      params.reportName
    ),
  ];

  if (!isLoading && !isSubmitted) {
    pagesWithFooter.push(
      paths.calculatorReport.replace(
        Constants.REPORT.URL_PARAM,
        params.reportName
      )
    );
  }

  useOnMount(() => {
    dispatch(calculatorActions.clearAllData());
    if (params.reportName && params.reportName !== Constants.REPORT.NEW) {
      dispatch(calculatorActions.getReport(params.reportName, reportUsername));
    }
  });

  useEffect(() => 
    () => { dispatch(calculatorActions.clearAllData()); }, [dispatch]
  );

  return (
    <>
      <Spinner show={isLoading} />
      <PageLayout footer={pagesWithFooter.includes(location.pathname)}>
        {children}
      </PageLayout>
    </>
  );
};

export default Calculator;
