import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import {
  IconButton,
  Menu,
  TextField,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core';
import { Check, Clear } from '@material-ui/icons';
import styles from './FilterMenu.styles';

type Props = WithStylesType<typeof styles> & {
  anchorEl: Element | null;
  filterName: string;
  currentFilterValue: string | null;
  label?: string | null;
  onClose: () => void;
  onApplyFilter: (filterName: string, filterValue: string | null) => void;
  onClearFilter: (filterName: string) => void;
};

const FilterMenu: FunctionComponent<Props> = ({
  anchorEl,
  filterName,
  currentFilterValue,
  label,
  onClose,
  onApplyFilter,
  onClearFilter,
  classes,
}) => {
  const [filterValue, setFilterValue] = useState<string | null>(currentFilterValue);

  useEffect(() => {
    setFilterValue(currentFilterValue);
  }, [currentFilterValue]);

  const onClearFilterButtonClick = () => {
    setFilterValue('');
    onClearFilter(filterName);
  };

  const onSubmit = (e: React.FormEvent) => {
    onApplyFilter(filterName, filterValue);
    e.preventDefault();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      className={classes.root}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
      <Card>
        <CardContent>
          <form noValidate autoComplete="off" onSubmit={onSubmit}>
            <TextField
              value={filterValue}
              className={classes.input}
              label={label || 'Filter'}
              onChange={(e) => setFilterValue(e.currentTarget.value)}
            />
          </form>
        </CardContent>
        <CardActions>
          <IconButton
            size="small"
            className={classes.applyFilterBtn}
            onClick={() => onApplyFilter(filterName, filterValue)}>
            <Check />
          </IconButton>
          <IconButton size="small" onClick={onClearFilterButtonClick}>
            <Clear />
          </IconButton>
        </CardActions>
      </Card>
    </Menu>
  );
};

export default withStyles(styles)(FilterMenu);
