import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Information from './Information/Information';
import HelpfulTips from './HelpfulTips/HelpfulTips';
import ReportsList from './ReportsList/ReportsList';
import {
  contentActions,
  selectIsCalculatorDashboardLoading,
  selectCalculatorDashboardContent,
} from '../../../redux/contentSlice';
import useOnMount from '../../../hooks/useOnMount';
import { Spinner } from '../../../components';
import styles from './CalculatorDashboard.styles';

const CalculatorDashboard: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const testid = 'cd';
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsCalculatorDashboardLoading);
  const content = useSelector(selectCalculatorDashboardContent);

  useOnMount(() => {
    if (!content) dispatch(contentActions.fetchCalculatorDashboardContent());
  });

  return (
    <Grid container spacing={4}>
      {isLoading ? (
        <div className={classes.informationSpinnerContainer}>
          <Spinner show={isLoading} inline />
        </div>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography
              variant="h1"
              classes={{ root: classes.title }}
              data-testid={`${testid}__title`}
              dangerouslySetInnerHTML={{ __html: content?.title || '' }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Information
              title={content?.main.title}
              description={content?.main.text}
              testid={testid}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <HelpfulTips
              title={content?.tips.title}
              tips={content?.tips.items}
              testid={testid}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <ReportsList />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(CalculatorDashboard);
