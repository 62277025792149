export enum SnackbarOptions {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Success = 'success',
}

export interface ISnackbarState {
  severity: string;
  message: string;
  open: boolean;
}
