import React, { FunctionComponent } from 'react';

import { Button, ButtonProps } from '@material-ui/core';
import cx from 'classnames';
import useStyles from './CircularButton.styles';

interface IProps extends ButtonProps {
  customClass?: string;
}

const CircularButton: FunctionComponent<IProps> = ({
  customClass = '',
  ...props
}) => {
  const styles = useStyles();

  return (
    <Button
      className={cx(styles.container, {
        [customClass]: customClass,
      })}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default CircularButton;
