import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors, FontWeight } from '../../../assets/styles';

export default (): StyleRules =>
  createStyles({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    label: {
      color: Colors.Blue,
      fontWeight: FontWeight.Bold,
    },
    labelError: {
      color: Colors.Red,
    },
    error: {
      fontWeight: FontWeight.Regular,
      fontSize: 14,
    },
  });
