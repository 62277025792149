import React, { FunctionComponent, MouseEvent, useState } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import MoreVert from '@material-ui/icons/MoreVert';
import { usersActions } from '../../../../../redux/usersSlice';
import { Constants } from '../../../../../utils';
import { IUser } from '../../../../../interfaces/users.interface';
import Menu from '../../../../../components/Menu/Menu';
import styles from './OptionsMenu.styles';

interface IMenuOption {
  text: string;
  action: (event: MouseEvent) => void;
  hidden?: boolean;
  testid: string;
}

interface IProps extends WithStylesType<typeof styles> {
  userStatus: string;
  user: IUser;
  onChangeRole: (user: IUser) => void;
  onDelete: (user: IUser) => void;
}

const OptionsMenu: FunctionComponent<IProps> = ({
  classes,
  userStatus = '',
  user,
  onChangeRole,
  onDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const dispatch = useDispatch();
  const needsPasswordChange =
    user.cognitoStatus === Constants.COGNITO_STATUS.FORCE_CHANGE_PASSWORD;
  const isConfirmedAccount =
    user.cognitoStatus === Constants.COGNITO_STATUS.CONFIRMED;

  const onMenuOpen = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
    dispatch(usersActions.setSelectedUser(user));
  };

  const activeOptions: IMenuOption[] = [
    {
      text: 'Activate',
      action: () => {
        dispatch(usersActions.enableUser(user.email));
      },
      hidden: userStatus === Constants.ACCOUNT_STATUS.active,
      testid: `menu-${user.email}__activate`,
    },
    {
      text: 'Inactivate',
      action: () => {
        dispatch(usersActions.disableUser(user.email));
      },
      hidden: userStatus === Constants.ACCOUNT_STATUS.inactive,
      testid: `menu-${user.email}__inactivate`,
    },
    {
      text: 'Edit User Email',
      action: () => dispatch(usersActions.setIsEmailEditDialogOpen(true)),
      hidden: (
        userStatus === Constants.ACCOUNT_STATUS.inactive || !isConfirmedAccount
      ),
      testid: `menu-${user.email}__edit`,
    },
    {
      text: 'Resend Welcome Email',
      action: () => dispatch(usersActions.setIsResendEmailDialogOpen(true)),
      hidden: !needsPasswordChange,
      testid: `menu-${user.email}__resend-email`,
    },
    {
      text: 'Change Role',
      action: () => onChangeRole(user),
      hidden: false,
      testid: `menu-${user.email}__change-role`,
    },
    {
      text: 'Delete',
      action: () => onDelete(user),
      hidden: false,
      testid: `menu-${user.email}__delete`,
    },
  ];
  const pendingOptions: IMenuOption[] = [
    {
      text: 'Approve',
      action: () => {
        dispatch(usersActions.approveUser(user.email));
      },
      testid: `menu-${user.email}__approve`,
    },
    {
      text: 'Reject',
      action: () => {
        dispatch(usersActions.deleteUser(user.email));
      },
      testid: `menu-${user.email}__reject`,
    },
    {
      text: 'Delete',
      action: () => onDelete(user),
      hidden: false,
      testid: `menu-${user.email}__delete`,
    },
  ];

  const options = {
    [Constants.ACCOUNT_STATUS.active]: activeOptions,
    [Constants.ACCOUNT_STATUS.inactive]: activeOptions,
    [Constants.ACCOUNT_STATUS.pending]: pendingOptions,
  };

  return (
    <>
      <IconButton
        onClick={onMenuOpen}
        className={classes.button}
        data-testid={`user-${user.email}_more-options-btn`}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        options={options[userStatus]}
      />
    </>
  );
};

export default withStyles(styles)(OptionsMenu);
