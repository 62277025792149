import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import cx from 'classnames';
import styles from './Copyright.styles';

interface IProps extends WithStylesType<typeof styles> {
  color?: string;
  customClass?: string;
}

const Copyright: FunctionComponent<IProps> = ({
  classes,
  color = '',
  customClass = '',
  children,
}) => (
  <Typography
    classes={{
      root: cx(classes.text, {
        [classes[color]]: color,
        [customClass]: customClass,
      }),
    }}
  >
    {children}
  </Typography>
);

export default withStyles(styles)(Copyright);
