import { AppThunk } from '../../interfaces/redux.interface';
import contactusService from '../../services/contactus.service';
import { snackbarActions } from '../snackbarSlice';


const savingsIdeaEmail = (name: string, text: string): AppThunk => (dispatch) => {
  const email = {
    name,
    text,
  };
  contactusService
    .savingsIdeaEmail(JSON.stringify({ ...email }))
    .then(()=> {
      dispatch(snackbarActions.success('Idea sent successfully!'));
    })
    .catch((error) => {
      dispatch(snackbarActions.error('Something went wrong'));
    });
};

export const contactusActions = {
  savingsIdeaEmail,
};
