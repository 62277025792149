import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { HSLogoColorIcon } from '../../../assets/icons';
import useQuery from '../../../hooks/useQuery';
import RequestAccountForm from '../RequestAccountForm/RequestAccountForm';
import SignInForm from '../SignInForm/SignInForm';
import styles from './FormContainer.styles';

const FormContainer: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const testid = 'form';
  const queryParams = useQuery();
  const requestAccess = queryParams.has('request-access');

  return (
    <div className={classes.container}>
      <HSLogoColorIcon className={classes.hsLogo} />
      <Typography
        variant="h1"
        classes={{ root: classes.title }}
        data-testid={`${testid}__title`}
      >
        {requestAccess
          ? 'Request an Account'
          : 'Welcome to the HealthySteps - Medicaid Return on Investment Calculator'}
      </Typography>
      {!requestAccess && (
        <Typography
          classes={{ root: classes.subtitle }}
          data-testid={`${testid}__subtitle`}
        >
          Please enter your details below
        </Typography>
      )}
      {requestAccess ? <RequestAccountForm /> : <SignInForm />}
      <Typography className={classes.version}>
        {process.env.REACT_APP_VERSION}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(FormContainer);
