import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, IRootState } from '../../interfaces/redux.interface';
import { IUserCredentials, IAuthState } from '../../interfaces/auth.interface';
import { paths, history } from '../../routes';
import { Constants } from '../../utils';
import authService from '../../services/auth.service';

export const selectIsLoading = (state: IRootState) => state.auth.isLoading;
export const selectUser = (state: IRootState) => state.auth.user;
export const selectError = (state: IRootState) => state.auth.error;
export const selectIsAuthorized = (state: IRootState) =>
  state.auth.isAuthorized;

const initialState: IAuthState = {
  isAuthorized: false,
  isLoading: false,
  user: null,
  error: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    authSuccess: (state, action) => {
      state.isAuthorized = true;
      state.isLoading = false;
      state.user = action.payload;
    },
    authRemove: () => {
      return { ...initialState };
    },
    authError: (state, action) => {
      state.isAuthorized = false;
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

const { setLoading, authSuccess, authError, authRemove } = authSlice.actions;

const signIn = ({
  username,
  password,
}: IUserCredentials): AppThunk<Promise<object>> => (dispatch) => {
  dispatch(setLoading(true));

  return authService
    .signIn({ username, password })
    .then((user) => {
      if (
        user.challengeName === Constants.COGNITO_CHALLENGE.newPasswordRequired
      ) {
        authService.setCognitoUser(user);

        dispatch(
          authSuccess({
            username: user.username,
            email: user.challengeParam.userAttributes.email,
          })
        );
        history.push(paths.newPasswordChallenge);
        return user;
      }
      if (user.attributes && user.attributes.email_verified === false) {
        authService.setCognitoUser(user);

        dispatch(
          authSuccess({
            username: user.username,
            ...user.attributes
          })
        );
        history.push(paths.verifyEmail);
        return user;
      } 
      dispatch(
        authSuccess({
          username: user.username,
          initials: user.attributes.name ? user.attributes.name
            .split(' ')
            .map((i: string) => i.charAt(0))
            .join('') : '',
          ...user.attributes,
        })
      );
      history.push(paths.calculatorDashboard);
      return user;
    })
    .catch((err) => {
      dispatch(authError(err));
      throw new Error(err);
    });
};

const verifyEmail = (
  code: string,
): AppThunk<Promise<object | string>> => (dispatch) => {
  dispatch(setLoading(true));

  return authService
    .verifyEmail(authService.getCognitoUser(), code)
    .then((res) => {
      dispatch(setLoading(false));

      return res;
    })
    .catch((error) => {
      dispatch(setLoading(false));
      throw new Error(error);
    });
};

const resendCode = (): AppThunk<Promise<void>> => (dispatch) => {
  dispatch(setLoading(true));

  return authService
    .resendCode(authService.getCognitoUser())
    .then((res) => {
      dispatch(setLoading(false));

      return res;
    })
    .catch((error) => {
      dispatch(authError(error));
      throw new Error(error);
    });
};

const forgotPassword = (username: string): AppThunk<Promise<object>> => (
  dispatch
) => {
  dispatch(setLoading(true));

  return authService
    .forgotPassword(username)
    .then((res) => {
      dispatch(setLoading(false));

      return res;
    })
    .catch((error) => {
      dispatch(authError(error));
      throw new Error(error);
    });
};

const forgotPasswordSubmit = (
  username: string,
  code: string,
  password: string
): AppThunk<Promise<void>> => (dispatch) => {
  dispatch(setLoading(true));

  return authService
    .forgotPasswordSubmit(username, code, password)
    .then(() => {
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(authError(error));
      throw new Error(error);
    });
};

const signOut = (): AppThunk => (dispatch) => {
  authService.signOut().then(() => dispatch(authRemove()));
};

const currentAuthenticatedUser = (): AppThunk<Promise<object>> => (
  dispatch
) => {
  dispatch(setLoading(true));

  return authService
    .currentAuthenticatedUser()
    .then((user) => {
      dispatch(
        authSuccess({
          username: user.username,
          initials: user.attributes.name ? user.attributes.name
          .split(' ')
          .map((i: string) => i.charAt(0))
          .join('') : '',
          ...user.attributes,
        })
      );
      return user;
    })
    .catch(() => dispatch(authError(null)));
};

const newPasswordChallenge = (email: string, password: string): AppThunk => (
  dispatch
) => {
  dispatch(setLoading(true));

  authService
    .completeNewPassword(authService.getCognitoUser(), password)
    .then(() => {
      dispatch(
        authActions.signIn({
          username: email,
          password,
        })
      );
    })
    .catch(() => dispatch(authError(null)));
};

export const authReducer = authSlice.reducer;
export const authActions = {
  signIn,
  signOut,
  currentAuthenticatedUser,
  newPasswordChallenge,
  forgotPassword,
  forgotPasswordSubmit,
  verifyEmail,
  resendCode,
};
