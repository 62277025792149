import { AppThunk, IRootState } from '../../interfaces/redux.interface';
import { createSlice } from '@reduxjs/toolkit';
import { snackbarActions } from '../snackbarSlice';
import {
  adminReportsState,
  defaultFilter
} from '../../interfaces/adminReports.interface';
import reportsService from '../../services/reports.service';
import { IFilter } from '../../interfaces/filter.interface';

export const selectReports = (state: IRootState) => state.adminReports.reports;
export const selectIsLoading = (state: IRootState) => state.adminReports.isLoading;
export const selectLastEvaluatedKey = (state: IRootState) => state.adminReports.lastEvaluatedKey;
export const selectFilters = (state: IRootState) => state.adminReports.filters;
export const selectLastPageReportsLoaded = (state: IRootState) => state.adminReports.lastPageReportsLoaded;

export const adminReportsSlice = createSlice({
  name: 'adminReports',
  initialState: adminReportsState,
  reducers: {
    clearReports: (state) => ({
      ...state,
      reports: [],
      filters: [defaultFilter],
      lastEvaluatedKey: undefined
    }),
    setReports: (state, action) => {
      const { reports, lastEvaluatedKey } = action.payload;
      state.reports = [...state.reports, ...reports];
      state.lastPageReportsLoaded = reports.length > 0;
      state.lastEvaluatedKey = lastEvaluatedKey;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setFilter: (state, action) => {
      const filter: IFilter = action.payload;
      state.filters = [...state.filters.filter(f => f.property !== filter.property), filter]
    },
    deleteFilter: (state, action) => {
      const filter: IFilter = action.payload;
      state.filters = [...state.filters.filter(f => f.property !== filter.property)]
    },
  }
});

const {
  setLoading,
  setReports,
  setFilter,
  clearReports,
  deleteFilter,
} = adminReportsSlice.actions;

const getReports = (): AppThunk => async (dispatch, getState) => {
  const { adminReports: { filters, lastEvaluatedKey, pageSize } } = getState();
  try {
    dispatch(setLoading(true));
    const { Items, LastEvaluatedKey } = await reportsService.getReportsFiltered(filters, pageSize, lastEvaluatedKey, [{ name: 'role', value: 'user' }]);
    const parsedReports = Items.map((item: any) => ({
      ...item,
      summary: JSON.parse(item.summary),
      interventionsChecklist: JSON.parse(item.interventionsChecklist),
    })
    );
    dispatch(setReports({ reports: parsedReports, lastEvaluatedKey: LastEvaluatedKey }));
  } catch (error: any) {
    dispatch(snackbarActions.error(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

const updateFilter = (filter: IFilter): AppThunk => (dispatch, getState) => {
  dispatch(clearReports());
  dispatch(setFilter(filter));
  dispatch(getReports());
};

const removeFilter = (filter: IFilter): AppThunk => (dispatch, getState) => {
  const { adminReports: { filters } } = getState();
  const currentFilter = filters.find(f => f.property === filter.property);
  if (currentFilter) {
    dispatch(clearReports());
    dispatch(deleteFilter(filter));
    dispatch(getReports());
  }
};

const exportFinishedCalculations = (state: string = ''): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await reportsService.finishedCalculationsDownloadRequest(state);
    const csvBlob = await res.blob();
    saveAs(csvBlob, 'ROI_FinishedCalculations.csv');
  } catch (error: any) {
    dispatch(snackbarActions.error(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

const updateIsTestReport = (reportName: string, userName: string, isTestReport: boolean): AppThunk => async (dispatch, getState) => {
  
  try {
    dispatch(setLoading(true));
    const report = await reportsService.getReport(reportName, userName);
    const { serviceData: _serviceData } = report;
    const serviceData = JSON.parse(_serviceData);
    serviceData.is_test_report = isTestReport;
    report.serviceData = JSON.stringify(serviceData);
    report.update = true;
    report.username = userName;
    delete report.updatedISO;
    delete report.updated;
    await reportsService.saveReport(JSON.stringify(report));
    dispatch(clearReports());
    dispatch(getReports());
  } catch (error: any) {
    dispatch(snackbarActions.error(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const adminReportsReducer = adminReportsSlice.reducer;
export const adminReportsActions = {
  getReports,
  updateFilter,
  removeFilter,
  updateIsTestReport,
  ...adminReportsSlice.actions,
  exportFinishedCalculations,
};