import ServiceData from './serviceData';
import Calculator from './shared/base';

export default class EmergencyDepartment extends Calculator {
  public serviceData: ServiceData;

  constructor({
    state,
    serviceData,
    edInputNumber1,
    edInputNumber2,
    edInputNumber3,
    sliderLowEstimate,
    sliderHighEstimate,
  }: {
    state: string;
    serviceData: ServiceData;
    edInputNumber1: number;
    edInputNumber2: number;
    edInputNumber3: number;
    sliderLowEstimate?: number;
    sliderHighEstimate?: number;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.edInputNumber1 = edInputNumber1;
    this.edInputNumber2 = edInputNumber2 / 100;
    this.edInputNumber3 = edInputNumber3 / 100;
    // this.sliderLowEstimate = sliderLowEstimate / 100;
    this.sliderLowEstimate = 0.95;
    // this.sliderHighEstimate = sliderHighEstimate / 100;
    this.sliderHighEstimate = 1.05;
  }

  /**
   * @name ed_tab_average_medicaid_expense_per_person_with_emergency_room_medicaild_expense
   * @constant 451.35
   * @excel Excel ED Tab B3
   */
  public readonly edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense = this
    .constants.emergencyDepartment
    .edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense;

  /**
   * @name ed_tab_massachusetts_medicaid_payment_rate_office_visit_that_may_involve_family_counseling_as_well
   * @constant 84.21
   * @excel Excel ED Tab B4
   */
  public readonly edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell = this
    .constants.emergencyDepartment
    .edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell;

  /**
   * @name ed_tab_medicaid_price_inflaction_factor
   * @constant 1.05
   * @excel Excel ED Tab B5
   */
  public readonly edTabMedicaidPriceInflactionFactor = this.constants
    .emergencyDepartment.edTabMedicaidPriceInflactionFactor;

  /**
   * @name ed_tab_statewide_fqhc_rate_ceilings_for_medical_care_2020
   * @constant 166.88
   * @excel Excel ED Tab B6
   */
  public readonly edTabStatewideFqhcRateCeilingsForMedicalCare2020 = this
    .constants.emergencyDepartment
    .edTabStatewideFqhcRateCeilingsForMedicalCare2020;

  /**
   * (App) Over the past year, approximately how many parents/caregivers contacted you or another HS Specialist at your site via phone or text seeking advice about whether to bring a sick child in for a visit.
   * @name ed_input_number_1
   */
  public edInputNumber1;

  /**
   * @name number_of_hs_parents_who_contacted_hss_seeking_advice
   * @excel Excel C24
   * @reference ed_input_number_1
   */
  public numberOfHsParentsWhoContactedHssSeekingAdvice = () => {
    return this.isFiniteNumber(this.edInputNumber1);
  };

  /**
   * (App) Of the parents/caregivers seeking advice about a sick child, approximately what number ended up treating the child at home for a minor illness but would have brought the child in for a visit (for example, at a doctor’s office or hospital emergency department) if they had not talked with the HS Specialist?
   * @name ed_input_number_2
   * @excel Excel C25
   */
  public edInputNumber2;

  /**
   * (App)
   * @name number_of_hss_contacts_that_led_to_averted_visit
   */
  public numberOfHssContactsThatLedToAvertedVisit = () => {
    return this.isFiniteNumber(this.edInputNumber2);
  };

  /**
   * Of the parents/caregivers seeking advice about a sick child, approximately what number ended up treating the child at home for a minor illness but would have brought the child in for a visit (for example, at a doctor’s office or hospital emergency department) if they had not talked with the HS Specialist?
   * @name percentage_that_led_to_averted_visit
   * @excel Excel C25
   * @reference percentage_that_led_to_averted_visit
   */
  public percentageThatLedToAvertedVisit = () => {
    return this.isFiniteNumber(this.edInputNumber2);
  };

  /**
   * (App) Of the parents/caregivers seeking advice about a sick child, approximately what number ended up bringing the child in for a clinic or other office-based visit (for example, at your site) but would have brought the child in for a hospital emergency department visit if they had not talked with the HS Specialist?
   * @name ed_input_number_3
   * @excel Excel C26
   */
  public edInputNumber3;

  /**
   * (App)
   * @name percentage_that_led_to_conversion_of_visit
   * @excel Excel C26
   * @formula number_of_hss_contacts_that_led_to_conversion_of_visit / number_of_hs_parents_who_contacted_hss_seeking_advice
   */
  public percentageThatLedToConversionOfVisit = () => {
    return this.isFiniteNumber(this.edInputNumber3);
  };

  /**
   * (App) What percentage of children age three or younger avoided visit(s) to an ER or a doctor's office due to HealthySteps Specialist(s) support - Low Estimate
   * @name slider_low_estimate
   * @excel Excel C27
   */
  public sliderLowEstimate;

  /**
   * @name slider_high_estimate
   * @excel Excel C28
   */
  public sliderHighEstimate;

  /**
   * @name estimated_number_of_hss_contacts_that_led_to_averted_visit_low
   * @excel Excel C29
   * @formula number_of_hs_parents_who_contacted_hss_seeking_advice * percentage_that_led_to_averted_visit * slider_low_estimate
   */
  public estimatedNumberOfHssContactsThatLedToAvertedVisitLow = () => {
    return this.isFiniteNumber(
      this.numberOfHsParentsWhoContactedHssSeekingAdvice() *
        this.percentageThatLedToAvertedVisit() *
        this.sliderLowEstimate
    );
  };

  /**
   * @name estimated_number_of_hss_contacts_that_led_to_averted_visit_high
   * @excel Excel C30
   * @formula number_of_hs_parents_who_contacted_hss_seeking_advice * percentage_that_led_to_averted_visit * slider_high_estimate
   */
  public estimatedNumberOfHssContactsThatLedToAvertedVisitHigh = () => {
    return this.isFiniteNumber(
      this.numberOfHsParentsWhoContactedHssSeekingAdvice() *
        this.percentageThatLedToAvertedVisit() *
        this.sliderHighEstimate
    );
  };

  /**
   * @name estimated_number_of_hss_contacts_that_led_to_conversion_of_visit_from_ed_to_office_based_low
   * @excel Excel C31
   * @formula number_of_hs_parents_who_contacted_hss_seeking_advice * percentage_that_led_to_conversion_of_visit * slider_low_estimate
   */
  public estimatedNumberOfHssContactsThatLedToConversionOfVisitFromEdToOfficeBasedLow = () => {
    return this.isFiniteNumber(
      this.numberOfHsParentsWhoContactedHssSeekingAdvice() *
        this.percentageThatLedToConversionOfVisit() *
        this.sliderLowEstimate
    );
  };

  /**
   * @name estimated_number_of_hss_contacts_that_led_to_conversion_of_visit_from_ed_to_office_based_high
   * @excel Excel C32
   * @formula number_of_hs_parents_who_contacted_hss_seeking_advice * percentage_that_led_to_conversion_of_visit * slider_high_estimate
   */
  public estimatedNumberOfHssContactsThatLedToConversionOfVisitFromEdToOfficeBasedHigh = () => {
    return this.isFiniteNumber(
      this.numberOfHsParentsWhoContactedHssSeekingAdvice() *
        this.percentageThatLedToConversionOfVisit() *
        this.sliderHighEstimate
    );
  };

  /**
   * @name savings_per_averted_ed_visit_that_is_not_replaced_with_an_office_visit
   * @excel Excel C33
   * @formula ed_tab_average_medicaid_expense_per_person_with_emergency_room_medicaild_expense * ed_tab_medicaid_price_inflaction_factor
   */
  public savingsPerAvertedEdVisitThatIsNotReplacedWithAnOfficeVisit = () => {
    return this.isFiniteNumber(
      this
        .edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense *
        this.edTabMedicaidPriceInflactionFactor
    );
  };

  /**
   * @name savings_per_averted_office_visit
   * @excel Excel C34
   * @reference ed_tab_massachusetts_medicaid_payment_rate_office_visit_that_may_involve_family_counseling_as_well
   */
  public savingsPerAvertedOfficeVisit = () => {
    return this.isFiniteNumber(
      this
        .edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell
    );
  };

  /**
   * @name savings_per_averted_fqhc_visit
   * @excel Excel C35
   * @reference ed_tab_statewide_fqhc_rate_ceilings_for_medical_care_2020
   */
  public savingsPerAvertedFqhcVisit = () => {
    return this.isFiniteNumber(
      this.edTabStatewideFqhcRateCeilingsForMedicalCare2020
    );
  };

  /**
   * @name savings_per_averted_office_FQHC_ED_visit
   * @excel Excel C36
   * @formula ( savings_per_averted_ed_visit_that_is_not_replaced_with_an_office_visit * 0.5) + ( savings_per_averted_office_visit * 0.5)
   */
  public savingsPerAvertedOfficeFQHCEDVisit = () => {
    return this.isFiniteNumber(
      this.savingsPerAvertedEdVisitThatIsNotReplacedWithAnOfficeVisit() * 0.5 +
        this.savingsPerAvertedOfficeVisit() * 0.5
    );
  };

  /**
   * @name savings_per_visit_converted_from_ED_to_office_FQHC_based
   * @excel Excel C37
   * @formula savings_per_averted_ed_visit_that_is_not_replaced_with_an_office_visit - average( savings_per_averted_office_visit, savings_per_averted_fqhc_visit )
   */
  public savingsPerVisitConvertedFromEDToOfficeFQHCBased = () => {
    return this.isFiniteNumber(
      this.savingsPerAvertedEdVisitThatIsNotReplacedWithAnOfficeVisit() -
        (this.savingsPerAvertedOfficeVisit() +
          this.savingsPerAvertedFqhcVisit()) /
          2
    );
  };

  /**
   * @name emergency_department_estimated_saving_low
   * @excel Excel E22
   * @formula (estimated_number_of_hss_contacts_that_led_to_averted_visit_low * savings_per_averted_office_FQHC_ED_visit)+( estimated_number_of_hss_contacts_that_led_to_conversion_of_visit_from_ed_to_office_based_low * savings_per_visit_converted_from_ED_to_office_FQHC_based)
   */
  public emergencyDepartmentEstimatedSavingLow = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfHssContactsThatLedToAvertedVisitLow() *
        this.savingsPerAvertedOfficeFQHCEDVisit() +
        this.estimatedNumberOfHssContactsThatLedToConversionOfVisitFromEdToOfficeBasedLow() *
          this.savingsPerVisitConvertedFromEDToOfficeFQHCBased()
    );
  };

  /**
   * @name emergency_department_estimated_saving_high
   * @excel Excel F22
   * @formula (estimated_number_of_hss_contacts_that_led_to_averted_visit_high * savings_per_averted_office_FQHC_ED_visit)+(estimated_number_of_hss_contacts_that_led_to_conversion_of_visit_from_ed_to_office_based_high * savings_per_visit_converted_from_ED_to_office_FQHC_based)
   */
  public emergencyDepartmentEstimatedSavingHigh = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfHssContactsThatLedToAvertedVisitHigh() *
        this.savingsPerAvertedOfficeFQHCEDVisit() +
        this.estimatedNumberOfHssContactsThatLedToConversionOfVisitFromEdToOfficeBasedHigh() *
          this.savingsPerVisitConvertedFromEDToOfficeFQHCBased()
    );
  };
}
