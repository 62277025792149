import { Constants } from '../utils';

const signIn = '/';
const newPasswordChallenge = '/new-password-challenge';
const verifyEmail = '/verify-email';
const forgotPassword = '/forgot-password';
const resetPassword = '/reset-password';
const dashboard = '/dashboard';
const calculatorDashboard = `${dashboard}/calculator`;
const resourcesDashboard = `${dashboard}/resources`;
const contactusDashboard = `${dashboard}/contactus`;
const reviewsDashboard = `${dashboard}/reviews`;
const usersDashboard = `${dashboard}/users`;
const adminCalculationsDashboard = `${dashboard}/calculations`;
const calculator = `/calculator/${Constants.REPORT.URL_PARAM}`;
const calculatorServiceData = `${calculator}/service-data`;
const calculatorInterventionsChecklist = `${calculator}/interventions-checklist`;
const calculatorInterventions = `${calculator}/interventions`;
const calculatorReport = `${calculator}/report`;
const interventionsOralHealth = `${calculatorInterventions}/oral-health`;
const interventionsEmergencyDepartmentUse = `${calculatorInterventions}/emergency-department-use`;
const interventionsImmunizations = `${calculatorInterventions}/immunizations`;
const interventionsAsthma = `${calculatorInterventions}/asthma`;
const interventionsPostpartumMaternalDepression = `${calculatorInterventions}/postpartum-maternal-depression`;
const interventionsHealthyBirthSpacing = `${calculatorInterventions}/healthy-birth-spacing`;
const interventionsIntimatePartnerViolence = `${calculatorInterventions}/intimate-partner-violence`;
const interventionsBreastfeeding = `${calculatorInterventions}/breastfeeding`;
const interventionsSmokingCessation = `${calculatorInterventions}/smoking-cessation`;

export default {
  signIn,
  newPasswordChallenge,
  verifyEmail,
  forgotPassword,
  resetPassword,
  dashboard,
  calculatorDashboard,
  usersDashboard,
  calculator,
  calculatorServiceData,
  calculatorInterventionsChecklist,
  calculatorInterventions,
  calculatorReport,
  interventionsOralHealth,
  interventionsEmergencyDepartmentUse,
  interventionsImmunizations,
  interventionsAsthma,
  interventionsPostpartumMaternalDepression,
  interventionsHealthyBirthSpacing,
  interventionsIntimatePartnerViolence,
  interventionsBreastfeeding,
  interventionsSmokingCessation,
  resourcesDashboard,
  contactusDashboard,
  reviewsDashboard,
  adminCalculationsDashboard,
};
