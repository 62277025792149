import React, { FunctionComponent, useState } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { CircularButton, InputText, Spinner } from '../../components';
import { HSLogoColorIcon } from '../../assets/icons';
import { AppThunkDispatch } from '../../interfaces/redux.interface';
import { FormValidations } from '../../utils';
import { authActions, selectIsLoading } from '../../redux/authSlice';
import { paths } from '..';
import styles from './ForgotPassword.styles';

interface FormValues {
  email: string;
}

const ForgotPassword: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const dispatch: AppThunkDispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);

  const initialValues: FormValues = {
    email: '',
  };

  const onSubmit = (values: FormValues) => {
    dispatch(authActions.forgotPassword(values.email)).then(() =>
      setSubmitted(true)
    );
  };

  const validationSchema = Yup.object().shape({
    email: FormValidations.email,
  });

  const form = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const resetForm = (
    <>
      <h1 className={classes.title}>Forgot Your Password?</h1>
      <p className={classes.note}>
        We’ll email you instructions to reset your password.
      </p>
      <form className={classes.form} onSubmit={form.handleSubmit}>
        <InputText
          customClass={classes.inputMail}
          labelText="Email Address"
          id="forgot-password-form-email-input"
          name="email"
          onChange={form.handleChange}
          value={form.values.email}
          errorMessage={form.errors.email}
          placeholder="Eg: julia@healthysteps.org"
        />
        <CircularButton
          color="primary"
          type="submit"
          variant="contained"
          customClass={classes.submitButtonStyles}
        >
          Reset Password
        </CircularButton>
      </form>
      <div className={classes.loginLinkContainer}>
        <Link to={paths.signIn} className={classes.loginLink}>
          Return to Login
        </Link>
      </div>
    </>
  );
  const formSuccess = (
    <>
      <h1 className={classes.title}>Success! An email was sent.</h1>
      <p className={classes.note}>
        We've sent an email to {form.values.email} with password reset
        instructions. If the email doesn't show up soon, check your spam folder.
      </p>
      <Link to={paths.signIn}>
        <CircularButton
          type="button"
          color="primary"
          variant="contained"
          customClass={classes.submitButtonStyles}
        >
          Return To Login
        </CircularButton>
      </Link>
    </>
  );

  return (
    <>
      <div className={classes.forgotRoot}>
        <div className={classes.containerColumn}>
          <div className={classes.main}>
            <HSLogoColorIcon className={classes.zeroLogo} />
            {submitted ? formSuccess : resetForm}
          </div>
        </div>
      </div>
      <Spinner show={isLoading} />
    </>
  );
};

export default withStyles(styles)(ForgotPassword);
