import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { FormikProps } from 'formik';
import { InputText, Dialog } from '../../../../../components';
import { Constants } from '../../../../../utils';
import styles from './InterventionFieldNoteDialog.styles';

const { DIALOG_MODES, NOTE_OPTIONS } = Constants;

type Props = WithStylesType<typeof styles> & {
  id: string;
  name: string;
  value: string;
  mode: 'add' | 'edit' | 'delete';
  open: boolean;
  onConfirm?: (note: string) => void;
  onClose: () => void;
  form: FormikProps<any>;
};

const InterventionFieldNoteDialog: FunctionComponent<Props> = ({
  id,
  open,
  name,
  value,
  mode,
  form,
  onConfirm,
  onClose,
  classes,
}) => {

  const [note, setNote] = useState<string>(value);

  useEffect(() => {
    setNote(value);
  }, [value]);

  const onNoteDialogConfirm = () => {
    switch (mode) {
      case DIALOG_MODES.ADD:
      case DIALOG_MODES.EDIT:
        form.setValues({
          ...form.values,
          [name]: note,
        });
        onConfirm && onConfirm(note);
        break;
      case DIALOG_MODES.DELETE:
        setNote('');
        form.setValues({
          ...form.values,
          [name]: '',
        });
        onConfirm && onConfirm('');
        break;
    }
  };

  return (
    <Dialog
      id={id}
      title={NOTE_OPTIONS[mode].title}
      open={open}
      onClose={onClose}
      customClass={classes.noteDialog}
      onSubmitBtnClick={onNoteDialogConfirm}
      submitBtnText={NOTE_OPTIONS[mode].submitBtnText}
    >
      {
        mode === DIALOG_MODES.ADD || mode === DIALOG_MODES.EDIT ?
          <InputText
            id={id}
            name={name}
            labelText="Description"
            value={note}
            onChange={(e) => {
              setNote(e.currentTarget.value);
            }}
            placeholder="If useful, add comments here…"
            type={Constants.INPUT_TYPES.TEXTAREA}
            onEnterPress={onNoteDialogConfirm}
            customClass={classes.noteDialogInput}
            maxLength={500}
            autoFocus
          />
          :
          'Are you sure you want to delete the note?'
      }
    </Dialog>
  );
};

export default withStyles(styles)(InterventionFieldNoteDialog);

