import React, { FunctionComponent } from 'react';
import { Tooltip as MuiTooltip, TooltipProps, Avatar } from '@material-ui/core';
import cx from 'classnames';
import { LightbulbIcon } from '../../assets/icons';
import useStyles from './Tooltip.styles';

interface IProps extends TooltipProps {
  id: string;
  customClass?: string;
  variant?: string;
}

const Tooltip: FunctionComponent<IProps> = ({
  customClass = '',
  variant = '',
  id,
  ...props
}) => {
  const styles = useStyles();

  return (
    <MuiTooltip
      id={id}
      data-testid={id}
      classes={{
        tooltip: cx(styles.tooltip, {
          [customClass]: customClass,
        }),
      }}
      {...props}
    >
      {props.children ? (
        props.children
      ) : (
        <Avatar className={styles.avatar}>
          <LightbulbIcon />
        </Avatar>
      )}
    </MuiTooltip>
  );
};

export default Tooltip;
