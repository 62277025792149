import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { Colors, FontWeight, Breakpoints } from '../../assets/styles';

export default (theme: Theme): StyleRules =>
  createStyles({
    containerColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      margin: '0 16px',
      maxWidth: '100vw',
      minHeight: '100vh',
      overflow: 'hidden',
      padding: 0,
    },
    forgotRoot: {
      minHeight: '100vh',
      backgroundColor: Colors.White,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    main: {
      maxWidth: 500,
      margin: 'auto',
      paddingTop: 10,
    },
    zeroLogo: {
      display: 'block',
      margin: '0 auto',
      marginBottom: 32,
      height: 'auto',
      maxWidth: 300,
    },
    title: {
      textAlign: 'center',
      fontSize: 36,
      fontWeight: FontWeight.Bold,
      color: Colors.Blue,
      marginBottom: 24,
      [theme.breakpoints.down(Breakpoints.XS)]: {
        fontSize: 36 * 0.7,
      },
    },
    note: {
      fontSize: 18,
      textAlign: 'center',
      marginBottom: 40,
      fontWeight: FontWeight.Light,
      color: Colors.Gray4,
    },
    inputMail: {
      marginBottom: 50,
    },
    loginLinkContainer: {
      marginTop: 50,
      textAlign: 'center',
    },
    loginLink: {
      fontWeight: FontWeight.Light,
      fontSize: 15,
      color: Colors.Blue1,
      textDecoration: 'none',
    },
    submitButtonStyles: {
      width: 280,
      display: 'block',
      margin: '0 auto',
    },
  });
