import { createStyles, Theme, StyleRules } from '@material-ui/core/styles';
import { Breakpoints, FontWeight, Colors } from './assets/styles';

export default (theme: Theme): StyleRules =>
  createStyles({
    '@global html': {
      boxSizing: 'border-box',
      fontSize: 16,
      margin: 0,
      padding: 0,
    },
    [theme.breakpoints.down(Breakpoints.MD)]: {
      '@global html': {
        fontSize: 15,
      },
    },
    [theme.breakpoints.down(Breakpoints.SM)]: {
      '@global html': {
        fontSize: 14,
      },
    },
    [theme.breakpoints.down(Breakpoints.XS)]: {
      '@global html': {
        fontSize: 13,
      },
    },
    '@global *, *:before, *:after': {
      boxSizing: 'inherit',
    },
    '@global body': {
      fontFamily: ['Museo Sans', 'sans-serif'].join(','),
      margin: 0,
    },
    '@global ul': {
      listStyleType: 'none',
      padding: 0,
    },
    '@global button': {
      fontFamily: 'inherit',
      boxShadow: 'none',
      border: 'none',
      padding: 0,
      overflow: 'visible',
      background: 'transparent',
      cursor: 'pointer',
      outline: 'none',
      textDecoration: 'none',
    },
    '@global a': {
      fontSize: 15,
      fontWeight: FontWeight.Light,
      color: Colors.Blue1,
      textDecoration: 'none',
    },
  });
