import React, { FunctionComponent } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import cx from 'classnames';
import styles from './Spinner.styles';

interface IProps extends WithStylesType<typeof styles> {
  show: boolean;
  size?: number;
  inline?: boolean;
}

const Spinner: FunctionComponent<IProps> = ({
  classes,
  show = false,
  size = 80,
  inline,
}) => {
  if (!show) {
    return null;
  }

  return (
    <div
      className={cx(classes.spinner, {
        [classes.spinnerInline]: inline,
      })}
    >
      <CircularProgress size={size} />
    </div>
  );
};

export default withStyles(styles)(Spinner);
