import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors } from '../../../../../assets/styles';

export default (): StyleRules =>
  createStyles({
    name: {
      color: Colors.Blue1,
      display: 'block',
    },
    email: {
      fontSize: 13,
    },
    lastCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    date: {
      fontSize: 13,
      width: '100%',
    },
    status: {
      borderRadius: 14.5,
      backgroundColor: Colors.Green3,
      width: 123,
      padding: '5px 0',
    },
    pendingStatus: {
      backgroundColor: Colors.Orange,
      '& $statusText': {
        color: Colors.White,
      },
    },
    submittedStatus: {
      backgroundColor: Colors.Red,
      '& $statusText': {
        color: Colors.White,
      },
      marginRight: '15.9%'
    },
    finishedStatus: {
      backgroundColor: Colors.Green3,
      '& $statusText': {
        color: Colors.White,
      },
    },
    statusText: {
      fontSize: 13,
      color: Colors.White,
      textAlign: 'center',
    },
  });
