import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import {
  Colors,
  Cards,
  FontWeight,
} from '../../../../assets/styles';

export default ({ typography, breakpoints }: Theme): StyleRules => {
  const { pxToRem } = typography;

  return createStyles({
    container: {
      ...Cards.CardShadow1,
      padding: pxToRem(40),
      height: '100%',
      position: 'relative',
    },
    title: {
      fontSize: pxToRem(28),
      color: Colors.Blue,
      fontWeight: FontWeight.Bold,
      marginBottom: pxToRem(20),
    },
    description: {
      fontSize: 14,
      lineHeight: '24px',
      marginBottom: pxToRem(36),
      '& a': {
        fontSize: 14,
        fontWeight: FontWeight.Regular,
      },
    },
    button: {
      maxWidth: 240,
    },
  });
};
