import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';

export default ({ typography }: Theme): StyleRules => {
  const { pxToRem } = typography;

  return createStyles({
    title: {
      maxWidth: 636,
      textAlign: 'center',
      margin: `0 auto ${pxToRem(22)}`,
    },
    informationSpinnerContainer: {
      position: 'relative',
      width: '100%',
      height: pxToRem(500),
    },
  });
};
