export interface StateConstants {
  asthma: {
    asthmaTabPercentChildrenDiagnosedWithAsthma: number;
    asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: number;
    asthmaTabMedicalPriceInflationFactor: number;
  };
  breastfeeding: {
    breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: number;
    breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: number;
    breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: number;
    breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: number;
    breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: number;
    breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: number;
    breastfeedingTabMedicalPriceInflationFactor20172019: number;
  };
  emergencyDepartment: {
    edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: number;
    edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: number;
    edTabMedicaidPriceInflactionFactor: number;
    edTabStatewideFqhcRateCeilingsForMedicalCare2020: number;
  };
  healthBirthSpacing: {
    birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: number;
    birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: number;
    birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: number;
    birthsTabCostPerPubliclyFundedBirthInMA: number;
    birthsTabMedicalPriceInflationFactor: number;
  };
  immunizations: {
    immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: number;
    immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: number;
    immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: number;
    immunizationsTabMedicaidVisitPercentageForChildren: number;
  };
  intimatePartner: {
    ipvTabPercentageOfWomanInSampleWithHigherIpvScore: number;
    ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: number;
    ipvTabMedicalPriceInflactionFactor: number;
  };
  oralHealth: {
    dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: number;
    dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: number;
    dentalTabMedicalPriceInflaction: number;
  };
  postpartumMaternalDepression: {
    maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: number;
    maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: number;
    maternalDepressionTabMedicaidPriceInflationFactor: number;
  };
  smokingCessation: {
    smokingTabCombinedChildAndAdultsSavingsOver3Years: number;
    smokingTabMedicaidPriceInflactionFactor: number;
  };
}

interface StatesConstants {
  [state: string]: StateConstants;
}

export const States: StatesConstants = {
  DEFAULT: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 0,
      asthmaTabMedicalPriceInflationFactor: 0,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 0,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 0,
      breastfeedingTabMedicalPriceInflationFactor20172019: 0,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 0,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 0,
      edTabMedicaidPriceInflactionFactor: 0,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 0,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0,
      birthsTabCostPerPubliclyFundedBirthInMA: 0,
      birthsTabMedicalPriceInflationFactor: 0,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0,
      immunizationsTabMedicaidVisitPercentageForChildren: 0,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 0,
      ipvTabMedicalPriceInflactionFactor: 0,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 0,
      dentalTabMedicalPriceInflaction: 0,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 0,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0,
      maternalDepressionTabMedicaidPriceInflationFactor: 0,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 0,
      smokingTabMedicaidPriceInflactionFactor: 0,
    },
  },
  AL: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 67,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 176.45,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.179,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.62,
      birthsTabCostPerPubliclyFundedBirthInMA: 17541,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.724,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  AR: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 75.76,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 58.32,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.058,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.67,
      birthsTabCostPerPubliclyFundedBirthInMA: 23579,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.541,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  AZ: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 89.38,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 290.85,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.072,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.57,
      birthsTabCostPerPubliclyFundedBirthInMA: 27268,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.609,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  CA: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.024,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 37.50,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 71.5,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.062,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.37,
      birthsTabCostPerPubliclyFundedBirthInMA: 16064,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.673,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  CO: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.038,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 121.41,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 176.45,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.056,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.51,
      birthsTabCostPerPubliclyFundedBirthInMA: 15433,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.737,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  CT: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.056,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 83.87,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 164.94,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.079,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.34,
      birthsTabCostPerPubliclyFundedBirthInMA: 25862,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.813,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  DC: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.047,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 118.84,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 176.45,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.072,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.37,
      birthsTabCostPerPubliclyFundedBirthInMA: 17326,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.796,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  FL: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.046,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 48.27,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 140.72,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.08,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.44,
      birthsTabCostPerPubliclyFundedBirthInMA: 18206,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.567,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  GA: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.032,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 62.71,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 122.97,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.087,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.48,
      birthsTabCostPerPubliclyFundedBirthInMA: 16282,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.598,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  HI: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.056,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 56.46,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 233.15,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.153,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.49,
      birthsTabCostPerPubliclyFundedBirthInMA: 25835,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.67,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  IL: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.039,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 71.82,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 163.35,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.075,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.46,
      birthsTabCostPerPubliclyFundedBirthInMA: 16518,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.699,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  KS: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.039,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 66.79,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 176.45,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.214,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.63,
      birthsTabCostPerPubliclyFundedBirthInMA: 21230,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.669,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  KY: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.018,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 67.1,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 176.45,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.072,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.65,
      birthsTabCostPerPubliclyFundedBirthInMA: 24588,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.605,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  MD: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.035,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.04056595807562,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 130.48,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 176.45,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.107,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.39,
      birthsTabCostPerPubliclyFundedBirthInMA: 24006,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.795,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  MA: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.031,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 96.84,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 185.87,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.05,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.38,
      birthsTabCostPerPubliclyFundedBirthInMA: 26779,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.87,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  MN: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.028,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 93.43,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 176.45,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.071,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.55,
      birthsTabCostPerPubliclyFundedBirthInMA: 22284,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.686,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  MS: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.023,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 74.71,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 138.29,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.072,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.62,
      birthsTabCostPerPubliclyFundedBirthInMA: 14201,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.543,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  MO: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.078,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 58.57,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 176.45,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.059,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.61,
      birthsTabCostPerPubliclyFundedBirthInMA: 20468,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.692,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  NJ: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.031,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 71.20,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 190.87,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.116,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.35,
      birthsTabCostPerPubliclyFundedBirthInMA: 22882,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.833,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  NY: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.019,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 56.18,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 147.43,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.094,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.3,
      birthsTabCostPerPubliclyFundedBirthInMA: 25281,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.775,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  NC: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 81.76,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 80.33,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.107,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.52,
      birthsTabCostPerPubliclyFundedBirthInMA: 20425,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.622,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  OH: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.052,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 66.14,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 176.45,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.088,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.59,
      birthsTabCostPerPubliclyFundedBirthInMA: 18144,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.694,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  OK: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 115.23,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 175.45,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.062,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.66,
      birthsTabCostPerPubliclyFundedBirthInMA: 16681,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.611,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  OR: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 100.83,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 135,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.149,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.5,
      birthsTabCostPerPubliclyFundedBirthInMA: 14270,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.631,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  PA: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.033,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 54.42,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 217,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.082,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.47,
      birthsTabCostPerPubliclyFundedBirthInMA: 22596,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.75,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  PR: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.133,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 110.43,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 176.45,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.072,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.57,
      birthsTabCostPerPubliclyFundedBirthInMA: 20716,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.31,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  SC: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.034,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 81.15,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 70.98,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.14,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.57,
      birthsTabCostPerPubliclyFundedBirthInMA: 21422,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.601,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  TX: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.054,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 52.86,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 218.85,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.054,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.61,
      birthsTabCostPerPubliclyFundedBirthInMA: 21498,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.649,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
  WA: {
    asthma: {
      asthmaTabPercentChildrenDiagnosedWithAsthma: 0.039,
      asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation: 543,
      asthmaTabMedicalPriceInflationFactor: 2.382204082,
    },
    breastfeeding: {
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding: 0.437,
      breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths: 0.95,
      breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife: 443,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding: 0.04,
      breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding: 0.12,
      breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife: 16543,
      breastfeedingTabMedicalPriceInflationFactor20172019: 1.10509507239303,
    },
    emergencyDepartment: {
      edTabAverageMedicaidExpensePerPersonWithEmergencyRoomMedicaildExpense: 451.35,
      edTabMassachusettsMedicaidPaymentRateOfficeVisitThatMayInvolveFamilyCounselingAsWell: 91.35,
      edTabMedicaidPriceInflactionFactor: 1.10509507239303,
      edTabStatewideFqhcRateCeilingsForMedicalCare2020: 285.15,
    },
    healthBirthSpacing: {
      birthsTabPercentageOfWomenNotUsingContraceptionPostpartum: 0.226,
      birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception: 0.85,
      birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth: 0.44,
      birthsTabCostPerPubliclyFundedBirthInMA: 22815,
      birthsTabMedicalPriceInflationFactor: 1.352284546,
    },
    immunizations: {
      immunizationTabFluVaccinationRateForMaPopulationsDuringFluSeasonChildren: 0.696,
      immunizationsTabPercentageOfUnvaccinatedWhoContractFluChildren: 0.152,
      immunizationsTabEfficacyOfFluVaccinationForChildrenPercentageReductionChildren: 0.75,
      immunizationsTabMedicaidVisitPercentageForChildren: 0.67,
    },
    intimatePartner: {
      ipvTabPercentageOfWomanInSampleWithHigherIpvScore: 0.12280701754386,
      ipvTabMeanDifferenceInTotalMedicaidExpendituresForWomenWithHigherIPVComparedToWithThoseWithNoIPV: 1064,
      ipvTabMedicalPriceInflactionFactor: 2.169665428,
    },
    oralHealth: {
      dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3: 0.6,
      dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp: 360,
      dentalTabMedicalPriceInflaction: 1.26595713913873,
    },
    postpartumMaternalDepression: {
      maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear: 1805,
      maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms: 0.12,
      maternalDepressionTabMedicaidPriceInflationFactor: 1.053897069,
    },
    smokingCessation: {
      smokingTabCombinedChildAndAdultsSavingsOver3Years: 1433.97307336376,
      smokingTabMedicaidPriceInflactionFactor: 1.235553967,
    },
  },
};
