import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { TableRow, TableCell as TC, TableBody as TB, Typography } from '@material-ui/core';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Constants } from '../../../../../utils';
import { tableCellStyles } from '../ReviewsTable.styles';
import styles from './ReviewsTableBody.styles';
import ReviewsOptionsMenu from '../ReviewsOptionMenu/ReviewsOptionMenu';
import { IReviewReport } from '../../../../../interfaces/report.interface';

export const TableCell = withStyles(tableCellStyles)(TC);

interface IProps extends WithStylesType<typeof styles> {
  reports: IReviewReport[] | null;
}

const ReviewsTableBody: FunctionComponent<IProps> = ({ classes, reports = [] }) => {
  return (
    <TB>
      {reports?.map((report) => {
        const testid = `report-${report.name}`;
        return (
          <TableRow key={report.name} data-testid={`user-${report.name}`}>
            <TableCell data-testid={report.name}>
              <span className={classes.name}>{report.name}</span>
            </TableCell>
            <TableCell data-testid={report.updated}>
              <Typography className={classes.date} data-testid={`${testid}__date`}>
                {`${Constants.REPORT_STATUSES[report.status]?.dateText} ${dayjs(
                  report.updated
                ).format('MM/DD/YYYY | h:mm a')}`}
              </Typography>
            </TableCell> 
            <TableCell data-testid={`user-${report.name}__name`}>
              <span className={classes.name}>{report.user?.name}</span>
              <span className={classes.email}>{report.user?.email}</span>
            </TableCell>
            <TableCell data-testid={`user-${report.name}__name-of-site`}>
              {report.siteName}
            </TableCell>
            <TableCell data-testid={`user-${report.name}__status`}>
              <div
                className={cx(classes.status, {
                  [classes.submittedStatus]:report.status === Constants.REPORT_STATUS.SUBMITTED,
                  [classes.finishedStatus]:report.status === Constants.REPORT_STATUS.FINISHED,
                  [classes.pendingStatus]:report.status === Constants.REPORT_STATUS.PENDING,
                })}
              >
                <Typography
                  className={classes.statusText}
                  data-testid={`${testid}__status`}
                >
                  {Constants.REPORT_STATUSES[report.status].status}
                </Typography>
              </div>
            </TableCell>
            <TableCell data-testid={`user-${report.name}__status`}>
              <ReviewsOptionsMenu reviewStatus={report} />
            </TableCell>
          </TableRow>
        );
      })}
    </TB>
  );
};

export default withStyles(styles)(ReviewsTableBody);
