import React, { FunctionComponent, MouseEvent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Menu as MUiMenu, MenuItem } from '@material-ui/core';
import styles from './Menu.styles';
import { IMenuOption } from '../../interfaces/menuOption.interface';

interface IProps extends WithStylesType<typeof styles> {
  anchorEl: Element | null;
  options: IMenuOption[];
  setAnchorEl: Function;
}

const Menu: FunctionComponent<IProps> = ({
  classes,
  anchorEl,
  setAnchorEl,
  options,
}) => {
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onOptionClick = (action: (event: MouseEvent) => void) => (
    e: MouseEvent
  ) => {
    action(e);
    handleClose();
  };

  return (
    <MUiMenu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      className={classes.menu}
    >
      {options
        .filter((option) => !option.hidden)
        .map((option) => (
          <MenuItem
            key={option.text}
            onClick={onOptionClick(option.action)}
            className={classes.menuItem}
            data-testid={option.testid}
          >
            {option.text}
          </MenuItem>
        ))}
    </MUiMenu>
  );
};

export default withStyles(styles)(Menu);
