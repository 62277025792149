import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors, FontWeight } from '../../../assets/styles';

export default (): StyleRules =>
  createStyles({
    label: {
      fontWeight: FontWeight.Bold,
      fontSize: 16,
      color: Colors.Blue,
      marginLeft: 23,
      width: 'max-content',
    },
    labelRoot: {
      margin: 0,
    },
    uncheckedLabel: {
      color: Colors.Gray6,
    },
  });
