import React, { FunctionComponent, useState } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import {
  IconButton,
  Menu,
  Card,
  CardContent,
  CardActions,
  TextField,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import styles from './ReportUserFilterMenu.styles';
import cognitoService from '../../../../services/cognito.service';
import { useDispatch } from 'react-redux';
import { snackbarActions } from '../../../../redux/snackbarSlice';

type Props = WithStylesType<typeof styles> & {
  anchorEl: Element | null;
  filterName: string;
  currentFilterValue: string | undefined;
  onClose: () => void;
  onApplyFilter: (filterName: string, filterValue: string | undefined) => void;
  onClearFilter: (filterName: string) => void;
};

const ReportUserFilterMenu: FunctionComponent<Props> = ({
  anchorEl,
  filterName,
  currentFilterValue,
  onClose,
  onApplyFilter,
  onClearFilter,
  classes,
}) => {
  const [userName, setUserName] = useState<string | undefined>(currentFilterValue);
  const [searchValue, setSearchValue] = useState<string | undefined>(currentFilterValue);
  const [users, setUsers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onClearFilterButtonClick = () => {
    setUserName('');
    setSearchValue('');
    setUsers([]);
    onClearFilter(filterName);
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (searchValue) {
      try {
        setIsLoading(true);
        const { Users } = await cognitoService.getUsers({ searchText: searchValue });
        const users = Users || [];
        setUsers(users);
      } catch (error: any) {
        dispatch(snackbarActions.error(error.message));
      } finally {
        setIsLoading(false);
      }
    } else {
      setUsers([]);
    }
  };

  const onUserSelected = (user: any) => {
    setUserName(user.Username);
    onApplyFilter(filterName, user.Username);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      className={classes.root}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
      <Card>
        <CardContent>
          <form noValidate autoComplete="off" onSubmit={onSubmit}>
            <TextField
              value={searchValue}
              className={classes.input}
              placeholder='Search by user name (hit enter to search)'
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {
              isLoading && <LinearProgress className={classes.linearProgress} />
            }
          </form>
          <List className={classes.usersList} component="nav">
            {
              users.map(u => (
                <ListItem key={u.Username} button onClick={() => onUserSelected(u)} selected={u.Username === userName}>
                  <ListItemText primary={u.Attributes?.find((a: any) => a.Name === 'name')?.Value || ''} />
                </ListItem>
              ))
            }
          </List>
        </CardContent>
        <CardActions>
          <IconButton size="small" onClick={onClearFilterButtonClick}>
            <Clear />
          </IconButton>
        </CardActions>
      </Card>
    </Menu>
  );
};

export default withStyles(styles)(ReportUserFilterMenu);