import React, { FunctionComponent } from 'react';
import InputText from '../Form/InputText/InputText';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Dialog from '../Dialog/Dialog';
import { FormValidations } from '../../utils';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  onSave: (reportName: string) => void;
  value?: string;
  inputCustomClass?: string;
  closeOnSubmit?: boolean;
};

const ReportNameDialog: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  onSave,
  value,
  inputCustomClass,
  closeOnSubmit
}) => {
  const onSubmit = (values: { reportName: string }) => {};
  const form = useFormik({
    initialValues: {
      reportName: value || '',
    },
    onSubmit,
    validationSchema: Yup.object().shape({
      reportName: FormValidations.reportNameValidation(''),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Dialog
      id="report__finish-report"
      title="Save ROI Calculations"
      open={isOpen}
      onClose={() => {
        form.resetForm();
        onClose && onClose();
      }}
      onSubmitBtnClick={async () => {
        const errors = await form.validateForm();
        if (!errors.reportName) {
          onSave(form.values.reportName);
        }
      }}
      submitBtnText="Save"
      closeOnSubmit={closeOnSubmit || false}
    >
      <form onSubmit={form.handleSubmit}>
        <InputText
          id="reportName"
          name="reportName"
          onChange={form.handleChange}
          value={form.values.reportName}
          labelText="Name Your ROI Calculations"
          errorMessage={form.errors.reportName}
          customClass={inputCustomClass}
        />
      </form>
    </Dialog>
  );
};

export default ReportNameDialog;
