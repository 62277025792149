import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Table, TableRow, TableBody, TableCell as TC } from '@material-ui/core';
import { StringFormatter } from '../../../../utils';
import { ISummaryReport } from '../../../../interfaces/calculator.interface';
import { IInterventionsChecklist, InterventionsChecklistForm } from '../../../../interfaces/interventions.interface';
import styles, { tableCellStyles } from './ReportBody.styles';

export const TableCell = withStyles(tableCellStyles)(TC);

interface IProps extends WithStylesType<typeof styles> {
  report: ISummaryReport;
  interventionsChecklist: IInterventionsChecklist;
}

const ReportBody: FunctionComponent<IProps> = ({ classes, report, interventionsChecklist }) => {
  const tableRows = [
    {
      titleClass: classes.columnTitle,
      title: 'Annual Cost Savings to Medicaid',
      firstAmountClass: classes.columnTitle,
      firstAmount: 'Low Estimate',
      secondAmountClass: classes.columnTitle,
      secondAmount: 'High Estimate',
      visible: true,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Interventions for Children',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.money(report.childrenLowSavings.toString()),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.money(
        report.childrenHighSavings.toString()
      ),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Oral Health',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.money(
        report.dentalEstimatedSavingLow.toString()
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.money(
        report.dentalEstimatedSavingHigh.toString()
      ),
      visible: interventionsChecklist[InterventionsChecklistForm.OralHealth],
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Asthma',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.money(
        report.asthmaEstimatedSavingsLow.toString()
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.money(
        report.asthmaEstimatedSavingsHigh.toString()
      ),
      visible: interventionsChecklist.asthma,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Appropriate Use of Care',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.money(
        report.emergencyDepartmentEstimatedSavingLow.toString()
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.money(
        report.emergencyDepartmentEstimatedSavingHigh.toString()
      ),
      visible: interventionsChecklist.emergencyDepartmentUse,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Flu Vaccine',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.money(
        report.immunizationsEstimatedSavingsLow.toString()
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.money(
        report.immunizationsEstimatedSavingsHigh.toString()
      ),
      visible: interventionsChecklist.immunizations,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Interventions for Caregivers',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.money(report.parentLowSavings.toString()),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.money(report.parentHighSavings.toString()),
      visible: true,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Postpartum Maternal Depression',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.money(
        report.maternalDepressionEstimatedSavingsLow.toString()
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.money(
        report.maternalDepressionEstimatedSavingsHigh.toString()
      ),
      visible: interventionsChecklist.postpartumMaternalDepression,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Healthy Birth Spacing',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.money(
        report.healthBirthSpacingEstimatedSavingsLow.toString()
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.money(
        report.healthBirthSpacingEstimatedSavingsHigh.toString()
      ),
      visible: interventionsChecklist.healthyBirthSpacing,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Intimate Partner Violence',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.money(
        report.intimatePartnerViolenceEstimatedSavingsLow.toString()
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.money(
        report.intimatePartnerViolenceEstimatedSavingsHigh.toString()
      ),
      visible: interventionsChecklist.intimidatePartnerViolence,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Breastfeeding',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.money(
        report.breastfeedingEstimatedSavingsLow.toString()
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.money(
        report.breastfeedingEstimatedSavingsHigh.toString()
      ),
      visible: interventionsChecklist.breastfeeding,
    },
    {
      titleClass: classes.subsectionTitle,
      title: 'Smoking Cessation',
      firstAmountClass: classes.subsectionAmount,
      firstAmount: StringFormatter.money(
        report.smokingCessationEstimatedSavingsLow.toString()
      ),
      secondAmountClass: classes.subsectionAmount,
      secondAmount: StringFormatter.money(
        report.smokingCessationEstimatedSavingsHigh.toString()
      ),
      visible: interventionsChecklist.smokeCessation,
    },
    {
      titleClass: classes.sectionTitle,
      title: 'Estimated Annual Short-Term Cost Savings',
      firstAmountClass: classes.sectionAmount,
      firstAmount: StringFormatter.money(
        report.globalEstimateSavingsLow.toString()
      ),
      secondAmountClass: classes.sectionAmount,
      secondAmount: StringFormatter.money(
        report.globalEstimateSavingsHigh.toString()
      ),
      visible: true,
    },
  ];
  return (
    <Table className={classes.table}>
      <TableBody>
        {tableRows.map((row, idx) => (
          row.visible ?
          <TableRow key={idx}>
            <TableCell className={row.titleClass} component="th" scope="row">
              {row.title}
            </TableCell>
            <TableCell className={row.firstAmountClass} align="right">
              {row.firstAmount}
            </TableCell>
            <TableCell className={row.secondAmountClass} align="right">
              {row.secondAmount}
            </TableCell>
          </TableRow>
          : <></>
        ))}
      </TableBody>
    </Table>
  );
};

export default withStyles(styles)(ReportBody);
