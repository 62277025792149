import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { Colors, Breakpoints } from '../../../../assets/styles';

export default ({breakpoints}: Theme): StyleRules =>
  createStyles({
    input: {
      padding: 0,
      height: '100%',
      '&::placeholder': {
        color: Colors.Gray,
      },
    },
    container: {
      width: '500px',
      [breakpoints.down(Breakpoints.XS)]: {
        width: 'auto',
      },
    },
  });
