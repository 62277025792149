import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Cards, Colors, FontWeight } from '../../../../assets/styles';

export default (): StyleRules =>
  createStyles({
    container: {
      ...Cards.CardShadow1,
      padding: 20,
      height: '100%',
    },
    title: {
      fontSize: 15,
      color: Colors.Blue,
      fontWeight: FontWeight.Bold,
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      backgroundColor: Colors.Orange1,
      marginRight: 11,
      width: 44,
      height: 44,
    },
    avatarSmall: {
      backgroundColor: Colors.Orange1,
      display: 'inline-flex',
      verticalAlign: 'sub',
      width: '20px',
      height: '20px',
      margin: '0 4px',
      '& > svg': {
        transform: 'scale(.6)',
      },
    },
    paragraph: {
      borderBottom: `1px solid ${Colors.Gray5}`,
      fontSize: 13,
      lineHeight: '26px',
      margin: '0 -20px',
      padding: '15px 20px',
      '&$paragraph:last-child': {
        border: 0,
        paddingBottom: 0,
      },
    },
  });
