import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { Colors, FontWeight, Breakpoints } from '../../../../../assets/styles';

export default ({ breakpoints }: Theme): StyleRules => {
  return createStyles({
    content: {
      width: '50%',
      [breakpoints.down(Breakpoints.MD)]: {
        width: '100%',
      },
    },
    input: {
      marginBottom: 25,
    },
    select: {
      marginBottom: 25,
      '& label': {
        color: Colors.Blue,
      }
    },
    datePickerWrapper: {
      marginBottom: 25,
    },
    datePickerText: {
      fontSize: 16,
      color: Colors.Blue,
      fontWeight: FontWeight.Bold,
      margin: 0,
      marginBottom: 16,
    },
    datePickerContainer: {
      display: 'flex',
      alignItems: 'center',
      '& $datePickerLabel:first-child': {
        marginLeft: 0,
      },
      paddingBottom: 7,
    },
    datePickerLabel: {
      fontSize: 16,
      fontWeight: FontWeight.Bold,
      color: Colors.Gray,
      margin: '0 11px',
    },
    readOnlyPicker: {
      border: 'none',
      '& button': {
        display: 'none',
      },
      '& input':{
        color: Colors.Black,
        paddingLeft: 10,
        fontWeight: FontWeight.Bold,
      },
    },
  });
};
