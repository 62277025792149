import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import cx from 'classnames';
import styles from './ServiceSectionLayout.styles';

interface IProps extends WithStylesType<typeof styles> {
  title: string | React.ReactElement;
  testId?: string;
  customClass?: string;
  required?: boolean;
}

const ServiceSectionLayout: FunctionComponent<IProps> = ({
  classes,
  children,
  title,
  testId,
  customClass = '',
  required = false,
}) => {
  return (
    <>
      <Typography variant="h2" data-testid={`${testId}__title`}>
        {title}
        {required && <span className={classes.required}>*</span>}
      </Typography>
      <div
        className={cx(classes.container, {
          [customClass]: customClass,
        })}
      >
        {children}
      </div>
    </>
  );
};

export default withStyles(styles)(ServiceSectionLayout);
