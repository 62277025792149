import { IFilter } from '../interfaces/filter.interface';
import apiService from './api.service';

const getReports = (moreKey: object | null = null) => {
  const stringKey =
    moreKey ? encodeURIComponent(JSON.stringify(moreKey)) : null;

  let url = 'reports';
  if (stringKey) {
    url += `?key=${stringKey}`;
  }
  return apiService.authRequest(url);
};

const getReport = (reportName: string, username?: string) => {
  return apiService.authRequest(`reports/${reportName}${username ? `/?username=${  username}` : ''}`);
};

const saveReport = (report: {}) =>
  apiService.authRequest('reports', {
    method: 'POST',
    body: report,
  });

const deleteReport = (reportName: string) =>
  apiService.authRequest(`reports/${reportName}`, {
    method: 'DELETE',
  });

const newReportNotification = (reportNotification: {}) =>
  apiService.authRequest('notifications/report/post', {
    method: 'POST',
    body: reportNotification
  });

const reportActionNotification = (action: string, params: {}) =>
  apiService.authSendEmail(`notifications/report/action/${action}`, {
    method: 'POST',
    mode: 'no-cors',
    body: params,
  });

const getReviewReports = (moreKey: object | null = null) => {
  const stringKey =
    moreKey ? encodeURIComponent(JSON.stringify(moreKey)) : null;

  let url = 'review/reports/?status=submitted&limit=10';
  if (stringKey) {
    url += `&startKey=${stringKey}`;
  }
  return apiService.authRequest(url);
};

const getReportsFiltered = (filters: IFilter[], limit: number | undefined = 10, startKey: object | undefined = undefined, queryParameters: { name: string, value: string | string[] }[] = []) => {
  const parameters: { name: string, value: string | string[] }[] = [
    {
      name: 'limit',
      value: limit.toString()
    },
    {
      name: 'filter',
      value: encodeURIComponent(JSON.stringify(filters))
    },
    ...queryParameters
  ];
  if (startKey) {
    parameters.push({
      name: 'startKey',
      value: encodeURIComponent(JSON.stringify(startKey))
    });
  }
  const url = `reports/filter?${parameters.map(p => `${p.name}=${p.value}`).join('&')}`;
  return apiService.authRequest(url);
};

const copyReport = (referenceName: string, copyName: string) => {
  return apiService.authRequest(`reports/${referenceName}/copy`, {
    method: 'POST',
    body: JSON.stringify({
      name: copyName,
    }),
  });
};

const finishedCalculationsDownloadRequest = (state: string) => {
  const parameters = [
    {
      property: 'state',
      value: state
    },
  ];
  const filter =  state ? `?filter=${encodeURIComponent(JSON.stringify(parameters))}` : '';
  return apiService.authRequest(`reports/export${filter}`, {  responseType: 'text'  }, true);
};

export default {
  getReports,
  getReport,
  saveReport,
  deleteReport,
  newReportNotification,
  getReviewReports,
  copyReport,
  getReportsFiltered,
  finishedCalculationsDownloadRequest,
  reportActionNotification,
};