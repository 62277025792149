import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../assets/styles';

export default ({ breakpoints }: Theme): StyleRules => {
  return createStyles({
    root: {
      marginBottom: '20px'
    },
    resourceGroup: {
      '& ul': {
        borderRight: '3px solid',
        borderRightColor: Colors.Blue,
      },
      '&:last-of-type': {
        '& ul': {
          borderRight: 'none'
        }
      },
      [breakpoints.down('xs')]: {
        '& ul': {
          borderRight: 'none'
        }
      },
    },
  });
};
