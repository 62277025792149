import { createStyles, StyleRules } from '@material-ui/core/styles';
import { FontWeight, Colors } from '../../../../../assets/styles';

export default (): StyleRules => {
  return createStyles({
    container: {
      position: 'relative',
    },
    disabled: {
      pointerEvents: 'none',
      opacity: '0.5',
    },
    toggleOffTextWrapper: {
      position: 'absolute',
      padding: '48px 45px',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, 0)',
      width: 638,
      border: '1px solid rgba(148,148,148,0.28)',
      backgroundColor: Colors.White,
    },
    toggleOffText: {
      fontSize: 25,
      color: Colors.Blue,
      fontWeight: FontWeight.Bold,
      textAlign: 'center',
    },
    buttonWrapper: {
      textAlign: 'end',
      marginTop: 50,
    },
    button: {
      width: 180,
      maxWidth: 180,
      marginLeft: 10,
    },
    secondaryButton: {
      textTransform: 'none',
    },
    homeBtn: {
      padding: '8px 12px',
      color: Colors.Blue1,
      fontSize: '15px',
      lineHeight: '15px',
      borderRadius: '4px',
      float: 'left',
      '& svg': {
        transform: 'scale(-1, 1)',
        marginRight: '6px',
      },
    },
  });
};
