import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors } from '../../../../../assets/styles';

export default (): StyleRules => {
  return createStyles({
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    titleUnchecked: {
      color: Colors.Gray,
    },
    toggleLabel: {
      fontSize: 13,
      color: Colors.Yellow,
    }
  });
};
