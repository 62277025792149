import 'date-fns';
import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import cx from 'classnames';
import DateFnsUtils from '@date-io/date-fns';
import styles, { datePickerTheme } from './DatePicker.styles';

interface IProps extends WithStylesType<typeof styles> {
  id: string;
  name: string;
  value: Date | null;
  customClass?: string;
  placeholder?: string;
  onChange?: (date: MaterialUiPickersDate) => void;
  disabled?: boolean;
  errorMessage?: string;
  showIcon?: boolean;
}

const DatePicker: FunctionComponent<IProps> = ({
  classes,
  id,
  name,
  value,
  onChange = () => {},
  placeholder = 'MM/DD/YYYY',
  customClass = '',
  disabled = false,
  errorMessage,
  showIcon = true,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={datePickerTheme}>
        <KeyboardDatePicker
          id={id}
          name={name}
          format="MM/dd/yyyy"
          value={value}
          onChange={onChange}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: cx(classes.input, {
                [customClass]: customClass,
                [classes.error]: errorMessage,
              }),
              error: classes.inputError,
            },
            autoComplete: 'off',
          }}
          placeholder={placeholder}
          fullWidth
          disabled={disabled}
          maxDateMessage="Max. valid date 01/01/2100"
          minDateMessage="Min. valid date 01/01/1900"
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default withStyles(styles)(DatePicker);
