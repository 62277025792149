import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors, FontWeight } from '../../assets/styles';

export default (): StyleRules =>
  createStyles({
    header: {
      backgroundColor: Colors.White,
      borderBottom: `1px solid ${Colors.Gray3}`,
      height: 71,
      display: 'flex',
      alignItems: 'center',
      padding: '7px 29px 7px 0px',
    },
    dashboardHeader: {
      justifyContent: 'flex-end',
      padding: '7px 29px',
    },
    content: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    navigation: {
      display: 'flex',
      alignItems: 'center',
      '& $navBtn:not(:last-child):after': {
        content: '""',
        position: 'absolute',
        backgroundColor: Colors.Gray5,
        width: 2,
        height: 38,
        right: '-7px',
      },
    },
    homeBtnWrapper: {
      margin: '-7px 0',
      padding: '7px 8px',
      borderRight: `2px solid ${Colors.Gray5}`,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: '#008DCD',
      fontWeight: 'normal',
    },
    homeBtn: {
      padding: '8px 12px',
      color: Colors.Blue1,
      fontSize: '15px',
      lineHeight: '15px',
      borderRadius: '4px',
      '& svg': {
        transform: 'scale(-1, 1)',
        marginRight: '6px',
      },
    },
    saveAndExitTitle: {
      paddingBottom: 10,
    },
    navBtn: {
      fontSize: 15,
      fontWeight: FontWeight.Regular,
      color: Colors.Blue1,
      textTransform: 'none',
      margin: '0 7px',
    },
    backBtn: {
      paddingLeft: 0,
    },
    amountWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    totalSavingsText: {
      fontSize: 13,
      fontWeight: FontWeight.Bold,
      color: Colors.Gray,
      textTransform: 'uppercase',
      paddingRight: 10,
    },
    reportName: {
      fontSize: 20,
      fontWeight: FontWeight.Bold,
      marginRight: 30,
    },
    totalSavingsAmount: {
      fontSize: 28,
      fontWeight: FontWeight.Bold,
    },
    totalSavingsAmountPositive: {
      color: Colors.Green2,
    },
    calculationsNameInput: {
      width: '500px !important',
    },
  });
