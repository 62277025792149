import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors } from '../../../assets/styles';

export default (): StyleRules =>
  createStyles({
    container: {
      borderRadius: 4,
      backgroundColor: Colors.White,
      marginTop: 21,
      padding: '24px 36px',
    },
    required: {
      color: Colors.Red,
      marginLeft: 10,
    },
  });
