import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../assets/styles';

export default (theme: Theme): StyleRules => {
  return createStyles({
    root: {
        padding: theme.spacing(2),
        color: Colors.Blue2
    },
    icon: {
        fontSize: 50,
        color: Colors.Blue2,
    },
    label: {
        fontSize: 10,
        fontWeight: 400,
        '& span': {
          color: Colors.Blue2,
          marginLeft: 20
        }
    }
  });
};
