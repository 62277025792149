/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  selectInterventionsChecklistValue,
  selectInterventionsValue,
  calculatorActions,
  selectServiceDataForm,
  selectReportName,
  selectState,
  selectTouched,
} from '../../../../redux/calculatorSlice';
import { CalculatorEnum } from '../../../../interfaces/calculator.interface';
import {
  InterventionsChecklistForm,
  EmergencyDepartmentUseForm,
  IEmergencyDepartmentUse,
  InterventionsCommonForm,
} from '../../../../interfaces/interventions.interface';
import { FormValidations, StringFormatter } from '../../../../utils';
import {
  InterventionHeader,
  InterventionBody,
  InterventionFooter,
  InterventionContainer,
  InterventionFieldNoteDialog,
} from '../Intervention';
import { EmergencyDepartment } from '../../../../calculations';
import useDebounceEffect from '../../../../hooks/useDebounceEffect';
import { paths } from '../../..';
import { Constants } from '../../../../utils';

const { DIALOG_MODES } = Constants;

const EmergencyDepartmentUse: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const switchValue = useSelector(
    selectInterventionsChecklistValue(
      InterventionsChecklistForm.EmergencyDepartmentUse
    )
  );
  const emergencyDepartmentUseData = useSelector(
    selectInterventionsValue(CalculatorEnum.EmergencyDepartmentUse)
  );

  const serviceData = useSelector(selectServiceDataForm);
  const selectedState = useSelector(selectState);

  const reportName = useSelector(selectReportName);
  const touched = useSelector(selectTouched);

  const [reinitialize, setReinitialize] = useState(true);
  const [noteName, setNoteName] = useState<string>('');
  const [noteAction, setNoteAction] = useState<'add' | 'edit' | 'delete'>(DIALOG_MODES.ADD);
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);

  const fields = [
    {
      text: `Number of caregivers who contacted HS Specialist(s) seeking advice about whether to bring a sick child in for a visit
      `,
      id: EmergencyDepartmentUseForm.ContactedByPhone,
      showError: true,
    },
    {
      text: `Percentage of caregivers seeking advice about a sick child who ended up treating the child at home for a minor illness but would have brought the child in for a visit (for example, at a doctor’s office, urgent care, or hospital emergency department) if they had not talked with a HS Specialist
      `,
      id: EmergencyDepartmentUseForm.TreatedAtHome,
      percentage: true,
      showError: true,
      showSlider: true,
    },
    {
      text: 'Percentage of caregivers seeking advice about a sick child who ended up bringing the child in for a clinic or other office-based visit (including urgent care) but would have brought the child in for a hospital emergency department visit if they had not talked with a HS Specialist',
      id: EmergencyDepartmentUseForm.ClinicVisit,
      percentage: true,
      showError: true,
      showSlider: true,
    },
  ];

  const onSubmit = (values: IEmergencyDepartmentUse) => {
    if (touched) {
      dispatch(
        calculatorActions.saveReport({
          reportName,
          update: true,
        }));
    }

    history.push(
      StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsImmunizations
      )
    );
  };

  useEffect(() => {
    if (switchValue) {
      const timeout = setTimeout(() => {
        setReinitialize(false);
      }, 250);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [switchValue]);

  const form = useFormik({
    initialValues: emergencyDepartmentUseData,
    onSubmit,
    validationSchema:
      switchValue &&
      Yup.object({
        [EmergencyDepartmentUseForm.ContactedByPhone]: FormValidations.required(
          ''
        ).test(FormValidations.getInterventionNumberOfChildsConfig(EmergencyDepartmentUseForm.ContactedByPhone, serviceData.csInput3)),
        [EmergencyDepartmentUseForm.TreatedAtHome]: FormValidations.maxPercentageRequired(
          ''
        ).test(
          'When added cannot be greater than 100%',
          'Total of percent estimates when added cannot be greater than 100%',
          function test(value) {
            if (!value) return false;

            const numClinicVisit = this.parent[
              EmergencyDepartmentUseForm.ClinicVisit
            ];

            if (+value + +numClinicVisit > 100) {
              return false;
            }

            return true;
          }
        ),
        [EmergencyDepartmentUseForm.ClinicVisit]: FormValidations.maxPercentageRequired(
          ''
        ).test(
          'When added cannot be greater than 100%',
          'Total of percent estimates when added cannot be greater than 100%',
          function test(value) {
            if (!value) return false;

            const numClinicVisit = this.parent[
              EmergencyDepartmentUseForm.TreatedAtHome
            ];

            if (+value + +numClinicVisit > 100) {
              return false;
            }

            return true;
          }
        ),
      }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: reinitialize || !switchValue,
  });

  useEffect(() => {
    if (!switchValue) {
      form.resetForm();
      dispatch(
        calculatorActions.clearInterventionData(
          CalculatorEnum.EmergencyDepartmentUse
        )
      );
    }
  }, [switchValue]);

  useDebounceEffect(() => {
    const hasBeenEdited = Object.keys(form.values).some(
      (key) => !!form.values[key]
    );
    if (!form.dirty && !hasBeenEdited) return;
    /** Calculations happen */
    const emergencyDepartment = new EmergencyDepartment({
      state: selectedState,
      serviceData,
      edInputNumber1: +form.values[EmergencyDepartmentUseForm.ContactedByPhone],
      edInputNumber2: +form.values[EmergencyDepartmentUseForm.TreatedAtHome],
      edInputNumber3: +form.values[EmergencyDepartmentUseForm.ClinicVisit],
      // sliderLowEstimate: +form.values[InterventionsCommonForm.LowEstimate],
      // sliderHighEstimate: +form.values[InterventionsCommonForm.HighEstimate],
    });

    const savingsLow = emergencyDepartment.emergencyDepartmentEstimatedSavingLow();
    const savingsHigh = emergencyDepartment.emergencyDepartmentEstimatedSavingHigh();

    if (
      +form.values[InterventionsCommonForm.SavingsLow] !== savingsLow ||
      +form.values[InterventionsCommonForm.SavingsHigh] !== savingsHigh
    ) {
      form.setValues({
        ...form.values,
        [InterventionsCommonForm.SavingsLow]: savingsLow.toString(),
        [InterventionsCommonForm.SavingsHigh]: savingsHigh.toString(),
      });
    }

    form.validateForm().then((errors) => {
      const isValid = !Object.keys(errors).length;

      if (form.dirty) {
        dispatch(
          calculatorActions.setInterventionData({
            intervention: CalculatorEnum.EmergencyDepartmentUse,
            value: { ...form.values, isValid: !!isValid },
          })
        );
        dispatch(calculatorActions.setSummaryData());
      }
    });
  }, [form.values]);

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      calculatorActions.toggleInterventionsChecklistValue({
        key: InterventionsChecklistForm.EmergencyDepartmentUse,
        value: e.target.checked,
      })
    );
  };

  const onNote = (noteName: string, noteAction: 'add' | 'edit' | 'delete') => {
    setNoteName(noteName);
    setNoteAction(noteAction);
    setIsNoteDialogOpen(true);
  };

  const onDialogNoteClose = () => {
    setIsNoteDialogOpen(false);
  };

  return (
    <>
      <InterventionContainer
        disabled={!switchValue}
        onSubmit={form.handleSubmit}
        header={
          <InterventionHeader
            title="Appropriate Use of Care"
            checked={switchValue}
            onChange={onSwitchChange}
          />
        }
        body={
          <InterventionBody
            description={
              <>
                While most HS Specialists are not licensed to give medical guidance, they often act as the point of contact for families, connecting them to nurse hotlines or other practice-specific medical resources if families ask for help.<br /> <br />
                Conversations with HealthySteps sites indicate that HS Specialists sometimes field phone calls, text messages, and online portal requests from caregivers seeking advice about whether or not to bring a sick child in for a visit. The following questions ask about the actions taken by caregivers in these situations during the 12-month date range.<br /> <br />
                Exclude phone or text advice provided for asthma exacerbations as they are captured on the asthma intervention page.
              </>
            }
            form={form}
            fields={fields}
            onNote={onNote}
          />
        }
        footer={
          <InterventionFooter
            form={form}
            savingsText="Appropriate Use of Care"
            hideSliders={true}
            onNote={onNote}
          // description="What percentage of children age three or younger avoided visit(s) to an ER or a doctor's office due to HealthySteps Specialist(s) support"
          />
        }
      />
      <InterventionFieldNoteDialog
        id={`${noteName}__${noteAction}-dialog`}
        name={noteName}
        value={form.values[noteName]}
        mode={noteAction}
        open={isNoteDialogOpen}
        onClose={onDialogNoteClose}
        form={form} />
    </>
  );
};

export default EmergencyDepartmentUse;
