import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { Colors } from '../../assets/styles';

export default ({ typography }: Theme): StyleRules => {
  const { pxToRem } = typography;

  return createStyles({
    container: {
      minWidth: 74,
      backgroundImage: `linear-gradient(${Colors.Blue2}, ${Colors.Green1})`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '100vh',
    },
    containerExpanded: {
      width: 220,
      paddingTop: 25,
      '& $listItem': {
        padding: `${pxToRem(37)} 0`,
      },
      '& $logo': {
        paddingBottom: 20,
        width: '100%',
      },
    },
    logo: {
      width: 58,
    },
    list: {
      width: '100%',
    },
    listItem: {
      flexDirection: 'column',
      padding: `${pxToRem(20)} 0`,
    },
    listItemSelected: {
      backgroundColor: 'rgba(0,0,0,0.25)',
    },
    listItemText: {
      color: Colors.White,
      marginTop: 14,
    },
    listItemIcon: {
      width: 24,
      height: 24
    }
  });
};
