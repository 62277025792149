/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export default function useDebounceEffect(
  effectFn: () => void,
  dependencies: unknown[],
  delay = 250
) {
  return useEffect(() => {
    const handler = setTimeout(() => {
      effectFn();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, dependencies);
}
