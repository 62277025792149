import React, { FunctionComponent, useState } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../../redux/authSlice';
import { CircularButton } from '../../../components';
import { contactusActions } from '../../../redux/contactusSlice';
import styles from './ContactUs.styles';

const ContactUsPage: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const [emailText, setEmailText] = useState('');
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userName = user?.name || 'N/A';
  const email = 'healthysteps@zerotothree.org';

  const sendEmail = () => {
    setIsSendingEmail(true);
    dispatch(contactusActions.savingsIdeaEmail(userName, emailText));
    setEmailText('');
    setIsSendingEmail(false);
  };

  return (
    <div>
      <div className={classes.container}>
        <Typography variant="h6" gutterBottom className={classes.text}>
          For support and questions, please email us at <a className={classes.textLink} href={`mailto:${email}`}>healthysteps@zerotothree.org</a>
        </Typography>
        <section className={classes.contactEmail}>
          <p>Do you think there are additional HealthySteps services provided, not included in this ROI Calculator, that could drive short-term cost savings (12 months) to your state Medicaid agency? If so, please share your ideas below with the HealthySteps National Office.</p>
          <textarea
            id="contactForm"
            name="w3review"
            className={classes.textArea}
            value={emailText}
            onChange={({ target }) => setEmailText(target.value)}
          />
          <div className={classes.buttonContainer}>
            <CircularButton
              type="button"
              color="primary"
              variant="contained"
              customClass={classes.button}
              onClick={sendEmail}
              disabled={!emailText || isSendingEmail}
            >
              Send for Review
            </CircularButton>
          </div>
        </section>
      </div>
    </div>
  );
};

export default withStyles(styles)(ContactUsPage);
