import ServiceData from './serviceData';
import Calculator from './shared/base';

export default class HealthBirthSpacing extends Calculator {
  public serviceData: ServiceData;

  constructor({
    state,
    serviceData,
    lowEstimatedSlider,
    highEstimatedSlider,
  }: {
    state: string;
    serviceData: ServiceData;
    lowEstimatedSlider: number;
    highEstimatedSlider: number;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.lowEstimatedSlider = lowEstimatedSlider / 100;
    this.highEstimatedSlider = highEstimatedSlider / 100;
  }

  /**
   * @name births_tab_percentage_of_women_not_using_contraception_postpartum
   * @excel Excel Births Tab B2
   * @constant 5% (0.05)
   */
  public readonly birthsTabPercentageOfWomenNotUsingContraceptionPostpartum = this
    .constants.healthBirthSpacing
    .birthsTabPercentageOfWomenNotUsingContraceptionPostpartum;

  /**
   * @name births_tab_percentage_of_women_who_become_pregnant_over_one_year_of_not_using_contraception
   * @excel Excel Births Tab B3
   * @constant 85% (0.85)
   */
  public readonly birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception = this
    .constants.healthBirthSpacing
    .birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception;

  /**
   * @name births_tab_percentage_of_unintended_pregnancies_that_result_in_birth
   * @excel Excel Births Tab B6
   * @constant 38% (0.38)
   */
  public readonly birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth = this
    .constants.healthBirthSpacing
    .birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth;

  /**
   * @name births_tab_cost_per_publicly_funded_birth_in_MA
   * @excel Excel Births Tab B7
   * @constant 26779
   */
  public readonly birthsTabCostPerPubliclyFundedBirthInMA = this.constants
    .healthBirthSpacing.birthsTabCostPerPubliclyFundedBirthInMA;

  /**
   * @name births_tab_medical_price_inflation_factor
   * @excel Excel Births Tab B10
   * @constant 1.28
   */
  public readonly birthsTabMedicalPriceInflationFactor = this.constants
    .healthBirthSpacing.birthsTabMedicalPriceInflationFactor;

  /**
   * @name percentage_of_women_not_using_contraception_postpartum
   * @excel Excel C92
   * @reference births_tab_percentage_of_women_not_using_contraception_postpartum
   */
  public percentageOfWomenNotUsingContraceptionPostpartum = () => {
    return this.isFiniteNumber(
      this.birthsTabPercentageOfWomenNotUsingContraceptionPostpartum
    );
  };

  /**
   * @name estimated_number_of_HS_Medicaid_mothers_not_using_contraception_postpartum
   * @excel Excel C93
   * @formula percentage_of_women_not_using_contraception_postpartum * cs_input_4 (from Service Data Screen)
   */
  public estimatedNumberOfHSMedicaidMothersNotUsingContraceptionPostpartum = () => {
    return this.isFiniteNumber(
      this.percentageOfWomenNotUsingContraceptionPostpartum() *
        this.serviceData.csInput4
    );
  };

  /**
   * @name percentage_of_women_becoming_pregnant_during_one_year_of_not_using_contraception
   * @excel Excel C94
   * @reference births_tab_percentage_of_women_who_become_pregnant_over_one_year_of_not_using_contraception
   */
  public percentageOfWomenBecomingPregnantDuringOneYearOfNotUsingContraception = () => {
    return this.isFiniteNumber(
      this
        .birthsTabPercentageOfWomenWhoBecomePregnantOverOneYearOfNotUsingContraception
    );
  };

  /**
   * @name estimated_number_of_HS_Medicaid_mothers_not_using_contraception_and_becoming_pregnant
   * @excel Excel C95
   * @formula percentage_of_women_becoming_pregnant_during_one_year_of_not_using_contraception * estimated_number_of_HS_Medicaid_mothers_not_using_contraception_postpartum
   */
  public estimatedNumberOfHSMedicaidMothersNotUsingContraceptionAndBecomingPregnant = () => {
    return this.isFiniteNumber(
      this.percentageOfWomenBecomingPregnantDuringOneYearOfNotUsingContraception() *
        this.estimatedNumberOfHSMedicaidMothersNotUsingContraceptionPostpartum()
    );
  };

  /**
   * @name percentage_of_unintended_pregnancies_that_result_in_birth
   * @excel Excel C96
   * @reference births_tab_percentage_of_unintended_pregnancies_that_result_in_birth
   */
  public percentageOfUnintendedPregnanciesThatResultInBirth = () => {
    return this.isFiniteNumber(
      this.birthsTabPercentageOfUnintendedPregnanciesThatResultInBirth
    );
  };

  /**
   * @name estimated_number_of_HS_Medicaid_mothers_not_using_contraception_becoming_pregnant_and_giving_birth
   * @excel Excel C97
   * @formula estimated_number_of_HS_Medicaid_mothers_not_using_contraception_and_becoming_pregnant * percentage_of_unintended_pregnancies_that_result_in_birth
   */
  public estimatedNumberOfHSMedicaidMothersNotUsingContraceptionBecomingPregnantAndGivingBirth = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfHSMedicaidMothersNotUsingContraceptionAndBecomingPregnant() *
        this.percentageOfUnintendedPregnanciesThatResultInBirth()
    );
  };

  /**
   * (App) Estimated percentage of unintended pregnancies/birth averted due to healthySteps - Low Estimate
   * @name low_estimated_slider
   * @excel Excel C98
   */
  public lowEstimatedSlider;

  /**
   * (App) Estimated percentage of unintended pregnancies/birth averted due to healthySteps - High Estimate
   * @name high_estimated_slider
   * @excel Excel C99
   */
  public highEstimatedSlider;

  /**
   * @name estimated_number_of_unintended_pregnancies_births_averted_due_to_HS_low
   * @excel Excel C100
   * @formula low_estimated_slider * estimated_number_of_HS_Medicaid_mothers_not_using_contraception_becoming_pregnant_and_giving_birth
   */
  public estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSLow = () => {
    return this.isFiniteNumber(
      this.lowEstimatedSlider *
        this.estimatedNumberOfHSMedicaidMothersNotUsingContraceptionBecomingPregnantAndGivingBirth()
    );
  };

  /**
   * @name estimated_number_of_unintended_pregnancies_births_averted_due_to_HS_high
   * @excel Excel C101
   * @formula high_estimated_slider * estimated_number_of_HS_Medicaid_mothers_not_using_contraception_becoming_pregnant_and_giving_birth
   */
  public estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh = () => {
    return this.isFiniteNumber(
      this.highEstimatedSlider *
        this.estimatedNumberOfHSMedicaidMothersNotUsingContraceptionBecomingPregnantAndGivingBirth()
    );
  };

  /**
   * @name savings_per_unintended_pregnancy_birth_averted_maternity_through_month_60
   * @excel Excel C102
   * @formula births_tab_cost_per_publicly_funded_birth_in_MA * births_tab_medical_price_inflation_factor
   */
  public savingsPerUnintendedPregnancyBirthAvertedMaternityThroughMonth60 = () => {
    return this.isFiniteNumber(
      this.birthsTabCostPerPubliclyFundedBirthInMA *
        this.birthsTabMedicalPriceInflationFactor
    );
  };

  /**
   * @name health_birth_spacing_estimated_savings_low
   * @excel Excel E89
   * @formula estimated_number_of_unintended_pregnancies_births_averted_due_to_HS_low * savings_per_unintended_pregnancy_birth_averted_maternity_through_month_60
   */
  public healthBirthSpacingEstimatedSavingsLow = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSLow() *
        this.savingsPerUnintendedPregnancyBirthAvertedMaternityThroughMonth60()
    );
  };

  /**
   * @name health_birth_spacing_estimated_savings_high
   * @excel Excel F89
   * @formula estimated_number_of_unintended_pregnancies_births_averted_due_to_HS_high * savings_per_unintended_pregnancy_birth_averted_maternity_through_month_60
   */
  public healthBirthSpacingEstimatedSavingsHigh = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfUnintendedPregnanciesBirthsAvertedDueToHSHigh() *
        this.savingsPerUnintendedPregnancyBirthAvertedMaternityThroughMonth60()
    );
  };
}
