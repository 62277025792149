import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import FormContainer from './FormContainer/FormContainer';
import { Copyright } from '../../components';
import styles from './SignIn.styles';

const SignIn: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  return (
    <div className={classes.container}>
      <main className={classes.content}>
        <FormContainer />
      </main>
      <footer className={classes.footer}>
        <Copyright>
          Copyright 2020 ZERO TO THREE. All Rights Reserved. Use of these
          materials is expressly limited to Programs approved by the
          HealthySteps™ National Office (Program) and which are a part of the
          HealthySteps™ National Network for the sole purpose of implementation
          and delivery of the Program. Adaptations, reproductions, revisions,
          excerpts, derivatives including but not limited to translations, and
          other exploitation of the materials are expressly forbidden without
          the prior written authorization of ZERO TO THREE.
        </Copyright>
      </footer>
    </div>
  );
};

export default withStyles(styles)(SignIn);
