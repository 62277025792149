/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import { InterventionsCommonForm } from '../../../../../interfaces/interventions.interface';
import { Slider, Tooltip } from '../../../../../components';
import InterventionItemNote from '../InterventionItemNote/InterventionItemNote';
import { Constants, StringFormatter } from '../../../../../utils';
import styles from './InterventionFooter.styles';

interface IProps extends WithStylesType<typeof styles> {
  description?: string;
  savingsText: string;
  form: FormikProps<any>;
  hideSliders?: boolean;
  exclusiveDescription?: string;
  onNote: (fieldName: string, noteAction: 'add' | 'edit' | 'delete') => void;
}
const testid = 'intervention-section';

const InterventionFooter: FunctionComponent<IProps> = ({
  classes,
  description,
  savingsText,
  form,
  hideSliders,
  exclusiveDescription,
  onNote
}) => {
  const {
    HEALTHY_BIRTH_SPACING_TOOLTIP,
    ROI_POSTPARTUM_TOOLTIP,
    INTIMATE_PARTNER_VIOLENCE_TOOLTIP,
    HIGH_LOW_ESTIMATES_TOOLTIP,
    HEALTHY_BIRTH_SPACING_HIGH_LOW_TOOLTIP,
  } = Constants;

  const isHealthyBirth = (description === HEALTHY_BIRTH_SPACING_TOOLTIP.unintended);

  const getTooltipText = () => {
    let specificTooltip = '';

    switch (description) {
      case ROI_POSTPARTUM_TOOLTIP.postpartum:
        specificTooltip = 'Persistent depression = positive screening results at 3 time points, including during prenatal time period if known.';
        break;
      case INTIMATE_PARTNER_VIOLENCE_TOOLTIP.intimatePartner:
        specificTooltip = 'Improvement = the person has reached stability and the need for services has diminished.';
        break;
      default:
        break;
    }

    return (
      <>
        {isHealthyBirth ? 
          HEALTHY_BIRTH_SPACING_HIGH_LOW_TOOLTIP : HIGH_LOW_ESTIMATES_TOOLTIP}
        {specificTooltip && <p>{specificTooltip}</p>}
      </>
    );
  };

  return (
    <div> 
      <Typography className={classes.description}>
        {description}
        {!hideSliders &&
          <Tooltip
            id={`${testid}__intervention-section`}
            title={getTooltipText()}
            placement="right"
          >
            {}
          </Tooltip>}
      </Typography>
      {!hideSliders && (
        <div className={classes.sliderContainer}>
          <Slider
            id={InterventionsCommonForm.LowEstimate}
            label="Low estimate"
            value={form.values.low_estimate}
            onInputChange={form.handleChange}
            errorMessage={
              typeof form.errors.low_estimate === 'string'
                ? form.errors.low_estimate
                : ''
            }
            onSliderChange={(sliderValue) => {
              form.setFieldValue(
                InterventionsCommonForm.LowEstimate,
                sliderValue
              );
            }}
          />
          <Slider
            id={InterventionsCommonForm.HighEstimate}
            label="High estimate"
            value={form.values.high_estimate}
            onInputChange={form.handleChange}
            errorMessage={
              typeof form.errors.high_estimate === 'string'
                ? form.errors.high_estimate
                : ''
            }
            onSliderChange={(sliderValue) => {
              form.setFieldValue(
                InterventionsCommonForm.HighEstimate,
                sliderValue
              );
            }}
          />
          <InterventionItemNote note={form.values['estimates_note']} noteName={'estimates_note'} onNote={onNote} />
        </div>
      )}
      {exclusiveDescription && (
        <>
          <Typography className={classes.description}>
            {ReactHtmlParser(exclusiveDescription)}
            <Tooltip
              id={`${testid}__intervention-exclusive-section`}
              title={getTooltipText()}
              placement="right"
            >
              {}
            </Tooltip>
          </Typography>
          <div className={classes.sliderContainer}>
            <Slider
              id={InterventionsCommonForm.LowEstimateExclusive}
              label="Low estimate"
              value={form.values.low_estimate_exclusive}
              onInputChange={form.handleChange}
              errorMessage={
                typeof form.errors.low_estimate_exclusive === 'string'
                  ? form.errors.low_estimate_exclusive
                  : ''
              }
              onSliderChange={(sliderValue) => {
                form.setFieldValue(
                  InterventionsCommonForm.LowEstimateExclusive,
                  sliderValue
                );
              }}
            />
            <Slider
              id={InterventionsCommonForm.HighEstimateExclusive}
              label="High estimate"
              value={form.values.high_estimate_exclusive}
              onInputChange={form.handleChange}
              errorMessage={
                typeof form.errors.high_estimate_exclusive === 'string'
                  ? form.errors.high_estimate_exclusive
                  : ''
              }
              onSliderChange={(sliderValue) => {
                form.setFieldValue(
                  InterventionsCommonForm.HighEstimateExclusive,
                  sliderValue
                );
              }}
            />
            <InterventionItemNote note={form.values['estimates_exclusive_note']} noteName={'estimates_exclusive_note'} onNote={onNote} />
          </div>
        </>
      )}
      <Typography className={classes.calculationsTitle}>
        Calculations
      </Typography>
      <Typography className={classes.savings}>
        <span>{`${savingsText} estimated savings (Low)`}</span>
        <span>{StringFormatter.money(form.values.savings_low)}</span>
      </Typography>
      <Typography className={classes.savings}>
        <span>{`${savingsText} estimated savings (High)`}</span>
        <span>{StringFormatter.money(form.values.savings_high)}</span>
      </Typography>
    </div>
  );
};

export default withStyles(styles)(InterventionFooter);
