import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { Breakpoints, Cards, FontWeight, Colors } from '../../../assets/styles';

export default ({ typography, breakpoints }: Theme): StyleRules => {
  const { pxToRem } = typography;

  return createStyles({
    container: {
      ...Cards.CardShadow1,
      padding: `${pxToRem(24)} ${pxToRem(45)}`,
      width: '100%',
      maxWidth: 540,
      marginTop: 32,
      [breakpoints.down(Breakpoints.SM)]: {
        padding: 24,
      },
    },
    hsLogo: {
      margin: '0 auto',
      display: 'block',
      width: '100%',
      height: pxToRem(103),
    },
    title: {
      paddingTop: pxToRem(26),
      fontSize: pxToRem(25),
    },
    subtitle: {
      paddingTop: pxToRem(26),
      fontSize: pxToRem(18),
      fontWeight: FontWeight.Light,
      color: Colors.Gray4,
    },
    version: {
      textAlign: 'center',
      marginTop: 20,
    },
  });
};
