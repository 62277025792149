import React, { FunctionComponent, useState } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import {
  IconButton,
  Menu,
  Card,
  CardContent,
  CardActions,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup
} from '@material-ui/core';
import { Check, Clear } from '@material-ui/icons';
import styles from './ReportIsTestReportFilterMenu.styles';

type Props = WithStylesType<typeof styles> & {
  anchorEl: Element | null;
  filterName: string;
  currentFilterValue: boolean | undefined;
  onClose: () => void;
  onApplyFilter: (filterName: string, filterValue: boolean | undefined) => void;
  onClearFilter: (filterName: string) => void;
};

const ReportIsTestReportFilterMenu: FunctionComponent<Props> = ({
  anchorEl,
  filterName,
  currentFilterValue,
  onClose,
  onApplyFilter,
  onClearFilter,
  classes,
}) => {
  const [filterValue, setFilterValue] = useState<boolean | undefined>(currentFilterValue);

  const onClearFilterButtonClick = () => {
    setFilterValue(undefined);
    onClearFilter(filterName);
  };

  const onSubmit = (e: React.FormEvent) => {
    onApplyFilter(filterName, filterValue);
    e.preventDefault();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      className={classes.root}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
      <Card>
        <CardContent>
          <form noValidate autoComplete="off" onSubmit={onSubmit}>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
              <FormControlLabel
                value="isTestReport"
                control={
                  <Checkbox
                    id="is_test_report"
                    name="is_test_report"
                    placeholder="Is Test"
                    checked={filterValue}
                    onChange={(e) => { setFilterValue(e.target.checked) }}
                  />
                }
                label="Is Test"
              />
              </FormGroup>
            </FormControl>
          </form>
        </CardContent>
        <CardActions>
          <IconButton
            size='small'
            className={classes.applyFilterBtn}
            onClick={() => onApplyFilter(filterName, filterValue)}>
            <Check />
          </IconButton>
          <IconButton size="small" onClick={onClearFilterButtonClick}>
            <Clear />
          </IconButton>
        </CardActions>
      </Card>
    </Menu>
  );
}

export default withStyles(styles)(ReportIsTestReportFilterMenu);