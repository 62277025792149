import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { FontWeight, Colors } from '../../../../assets/styles';

export default ({ typography, breakpoints }: Theme): StyleRules => {
  const { pxToRem } = typography;

  return createStyles({
    container: {
      position: 'relative',
      width: '100%',
      minHeight: pxToRem(500),
    },
    controlsContainer: {
      display: 'flex',
      justifyContent: 'end',
    },
    exportButton: {
      padding: 0,
      height: '38px',
      width: '170px',
      border: `1px solid ${Colors.Gray2}`,
      alignContent: 'end',
      backgroundColor: Colors.White,
      color: Colors.Gray10,
      marginBottom: 20,
      '&:hover': {
        color: Colors.Gray4,
      }
    },
    table: {
      width: '100%',
      backgroundColor: Colors.White,
      border: `1px solid ${Colors.Gray1}`,
      marginBottom: 24,
    },
    moreBtn: {
      fontSize: 14,
      fontWeight: FontWeight.Bold,
      color: Colors.Blue1,
      marginBottom: 30,
    },
    noResults: {
      textAlign: 'center',
      fontSize: pxToRem(22),
      paddingTop: pxToRem(130),
    },
    filterButton: {
      marginLeft: pxToRem(-15),
      marginRight: pxToRem(5)
    },
    activeFilter: {
      color: Colors.Blue1
    },
    headersMenuOptionsButton: {
      color: Colors.Blue1,
    },
  });
};

export const tableCellStyles = ({
  typography,
  breakpoints,
}: Theme): StyleRules => {
  return createStyles({
    root: {
      paddingLeft: 24,
    },
    head: {
      fontWeight: FontWeight.Bold,
      color: Colors.Gray,
      fontSize: 15,
      paddingTop: 8,
      paddingBottom: 8,
    },
    body: {
      color: Colors.Gray,
      fontSize: 15,
    },
  });
};
