import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth as AwsAuth } from 'aws-amplify';

const apiUrl = process.env.REACT_APP_CONTENT_API_URL;

const nonAuthRequest = async (path: string, customConfig?: {}, isRaw: boolean = false) =>
  {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      ...customConfig,
    };

    const res = await fetch(`${apiUrl}/${path}`, config);
    const data = isRaw ? res : await res.json();

    if (!res.ok) {
      throw new Error(data.message);
    }

    return data;
  };

const authRequest = async (path: string, customConfig?: {}, isRaw: boolean = false) =>
  AwsAuth.currentSession().then(async (session: CognitoUserSession) => {
    const jwtToken = session.getIdToken().getJwtToken();

    const config = {
      method: 'GET',
      headers: {
        Authorization: jwtToken,
      },
      ...customConfig,
    };

    const res = await fetch(`${apiUrl}/${path}`, config);
    const data = isRaw ? res : await res.json();

    if (!res.ok) {
      throw new Error(data.message);
    }

    return data;
  });

const authSendEmail = async (path: string, customConfig?: {}) =>
  AwsAuth.currentSession().then(async (session: CognitoUserSession) => {
    const jwtToken = session.getIdToken().getJwtToken();

    const config = {
      method: 'POST',
      headers: {
        Authorization: jwtToken,
      },
      ...customConfig,
    };

    await fetch(`${apiUrl}/${path}`, config);
  });

export default {
  authRequest,
  authSendEmail,
  nonAuthRequest
};
