import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import InterventionsSideNav from './InterventionsSideNav/InterventionsSideNav';
import styles from './CalculatorInterventions.styles';

const CalculatorInterventions: FunctionComponent<WithStylesType<
  typeof styles
>> = ({ classes, children }) => {
  const testid = 'interventions';

  return (
    <div className={classes.container}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            classes={{ root: classes.title }}
            data-testid={`${testid}__title`}
          >
            HealthySteps Cost Savings Interventions
          </Typography>
        </Grid>
        <Grid className={classes.sideNav} item xs={4}>
          <InterventionsSideNav />
        </Grid>
        <Grid className={classes.main} item xs={8}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(CalculatorInterventions);
