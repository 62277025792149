import React, { FunctionComponent, useEffect } from 'react';
import { withStyles, WithStyles as WithStylesType } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik, FormikErrors } from 'formik';
import { InputText, Dialog } from '../../../../components';
import { AppThunkDispatch } from '../../../../interfaces/redux.interface';
import {
  usersActions,
  selectIsEmailEditDialogOpen,
  selectIsResendEmailDialogOpen,
  selectCurrentUser,
} from '../../../../redux/usersSlice';
import { Constants, FormValidations } from '../../../../utils';
import styles from './EmailDialogs.styles';

const awsChangeEmailError = 'An account with the given email already exists.';

interface FormValues {
  email: string;
  aws: string;
}

const EmailDialogs: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const selectedUser = useSelector(selectCurrentUser);
  const selectedUserEmail = selectedUser ? selectedUser.email : '';
  const isEmailEditDialogOpen = useSelector(selectIsEmailEditDialogOpen);
  const isResendEmailDialogOpen = useSelector(selectIsResendEmailDialogOpen);
  const dispatch: AppThunkDispatch = useDispatch();

  const initialValues: FormValues = {
    email: '',
    aws: '',
  };

  const onSubmit = (
    values: FormValues,
    { setErrors }: { setErrors: (errors: FormikErrors<FormValues>) => void },
  ) => {
    setErrors({ aws: '' });

    form.validateForm().then((errors) => {
      if (!errors.email) {
        dispatch(
          usersActions.changeUserEmail(selectedUserEmail, form.values.email)
        ).then(() => {
          dispatch(usersActions.setIsEmailEditDialogOpen(false));
        }).catch(() => {
          setErrors({ aws: awsChangeEmailError });
        });
      }
    });
  };

  const onResendEmail = () => {
    dispatch(usersActions.resendWelcomeEmail(selectedUserEmail));
    dispatch(usersActions.setIsResendEmailDialogOpen(false));
  };

  const form = useFormik({
    initialValues,
    onSubmit,
    validationSchema: Yup.object().shape({
      email: FormValidations.email,
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  useEffect(() => {
    form.setValues({ email: selectedUserEmail, aws: '' });
    // eslint-disable-next-line
  }, [selectedUser, isEmailEditDialogOpen]);

  return (
    <>
      <Dialog
        id="user__edit-user-email"
        title="Edit User Email"
        open={isEmailEditDialogOpen}
        onClose={() => {
          dispatch(usersActions.setIsEmailEditDialogOpen(false));
          form.resetForm();
        }}
        onSubmitBtnClick={form.handleSubmit}
        submitBtnText="Send"
        isSubmitBtnDisabled={selectedUserEmail === form.values.email}
        closeOnSubmit={false}
      >
        <form className={classes.container} onSubmit={form.handleSubmit}>
          <InputText
            id="email"
            name="email"
            onChange={form.handleChange}
            value={form.values.email}
            labelText="User email"
            type={Constants.INPUT_TYPES.TEXT}
            errorMessage={form.errors.email || form.errors.aws}
            customClass={classes.input}
          />
        </form>
      </Dialog>
      <Dialog
        id="user__resend-user-email"
        open={isResendEmailDialogOpen}
        title="Resend Welcome Email"
        onClose={() => dispatch(usersActions.setIsResendEmailDialogOpen(false))}
        onSubmitBtnClick={onResendEmail}
        submitBtnText="Send Email"
      >
        <div className={classes.container}>
          <p className={classes.confirmationMessage}>
            A new welcome email will be sent to the user.
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(EmailDialogs);