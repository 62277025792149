import React, { FunctionComponent, ReactNode } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  FormControl,
  FormHelperText
} from '@material-ui/core';
import cx from 'classnames';
import styles from './Checkbox.styles';

interface IProps extends WithStylesType<typeof styles> {
  id: string;
  name: string;
  checked: boolean;
  customClass?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  errorMessage?: string;
  content: ReactNode;
  hideErrorMessage?: boolean;
};

const Checkbox: FunctionComponent<IProps> = ({
  classes,
  id,
  name,
  customClass = '',
  checked,
  onChange,
  label,
  errorMessage,
  hideErrorMessage,
  content
}) => {
  return (
    <FormControl fullWidth className={cx({ [customClass]: customClass } )}>
      <div className={classes.header}>
        <label
          className={cx(classes.label, {
            [classes.labelError]: errorMessage,
          })}
          htmlFor={id}
          data-testid={`test-${id}__checkbox__label`}
        >
          {label}
        </label>
      </div>
      <FormControlLabel
        control={
          <MuiCheckbox name={name} checked={checked} onChange={onChange} color="primary" />
        }
        label={content}
      />
      {!hideErrorMessage && errorMessage && (
        <FormHelperText
          classes={{ root: classes.error }}
          error
          data-testid={`test-${id}__checkbox__error-message`}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default withStyles(styles)(Checkbox);
