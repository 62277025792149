/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
  selectInterventionsChecklistValue,
  calculatorActions,
  selectInterventionsValue,
  selectServiceDataForm,
  selectReportName,
  selectState,
  selectTouched,
} from '../../../../redux/calculatorSlice';
import {
  InterventionsChecklistForm,
  IntimatePartnerViolenceForm,
  IIntimatePartnerViolence,
  InterventionsCommonForm,
} from '../../../../interfaces/interventions.interface';
import { CalculatorEnum } from '../../../../interfaces/calculator.interface';
import {
  InterventionHeader,
  InterventionBody,
  InterventionFooter,
  InterventionContainer,
  InterventionFieldNoteDialog,
} from '../Intervention';
import { FormValidations, StringFormatter } from '../../../../utils';
import { IntimatePartner } from '../../../../calculations';
import useDebounceEffect from '../../../../hooks/useDebounceEffect';
import { paths } from '../../..';
import { Constants } from '../../../../utils';

const { DIALOG_MODES } = Constants;

const IntimatePartnerViolence: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const switchValue = useSelector(
    selectInterventionsChecklistValue(
      InterventionsChecklistForm.IntimatePartnerViolence
    )
  );
  const intmatePartnerViolenceData = useSelector(
    selectInterventionsValue(CalculatorEnum.IntimatePartnerViolence)
  );

  const serviceData = useSelector(selectServiceDataForm);
  const selectedState = useSelector(selectState);

  const reportName = useSelector(selectReportName);
  const touched = useSelector(selectTouched);

  const [reinitialize, setReinitialize] = useState(true);
  const [noteName, setNoteName] = useState<string>('');
  const [noteAction, setNoteAction] = useState<'add' | 'edit' | 'delete'>(DIALOG_MODES.ADD);
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);

  const fields = [
    {
      text:
        'Number of caregivers who discussed a concern related to IPV with a HS Specialist',
      id: IntimatePartnerViolenceForm.DiscussedIPV,
      showError: true,
    },
    {
      text: 'Number of caregivers who received counseling or related services from a HS Specialist',
      id: IntimatePartnerViolenceForm.ReceivedCounseling,
      showError: true,
    },
    {
      text: 'Number of caregivers who were referred for IPV related services by a HS Specialist',
      id: IntimatePartnerViolenceForm.ReferredForServices,
      showError: true,
    },
  ];

  const onSubmit = (values: IIntimatePartnerViolence) => {
    if (touched) {
      dispatch(
        calculatorActions.saveReport({
          reportName,
          update: true,
        }));
    }

    history.push(
      StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsBreastfeeding
      )
    );
  };

  useEffect(() => {
    if (switchValue) {
      const timeout = setTimeout(() => {
        setReinitialize(false);
      }, 250);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [switchValue]);

  const form = useFormik({
    initialValues: intmatePartnerViolenceData,
    onSubmit,
    validationSchema: switchValue && FormValidations.intervention({
      [IntimatePartnerViolenceForm.DiscussedIPV]:
        FormValidations.interventionNumberOfChilds(IntimatePartnerViolenceForm.DiscussedIPV, serviceData.csInput3),
      [IntimatePartnerViolenceForm.ReceivedCounseling]:
        FormValidations.interventionNumberOfChilds(IntimatePartnerViolenceForm.ReceivedCounseling, serviceData.csInput3),
      [IntimatePartnerViolenceForm.ReferredForServices]:
        FormValidations.interventionNumberOfChilds(IntimatePartnerViolenceForm.ReferredForServices, serviceData.csInput3),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: reinitialize || !switchValue,
  });

  useEffect(() => {
    if (!switchValue) {
      form.resetForm();
      dispatch(
        calculatorActions.clearInterventionData(
          CalculatorEnum.IntimatePartnerViolence
        )
      );
    }
  }, [switchValue]);

  useDebounceEffect(() => {
    const hasBeenEdited = Object.keys(form.values).some(
      (key) => !!form.values[key]
    );
    if (!form.dirty && !hasBeenEdited) return;
    /** Calculations happen */
    const intimatePartnerViolence = new IntimatePartner({
      state: selectedState,
      serviceData,
      lowEstimatedSlider: +form.values[InterventionsCommonForm.LowEstimate],
      highEstimatedSlider: +form.values[InterventionsCommonForm.HighEstimate],
    });

    const savingsLow = intimatePartnerViolence.intimatePartnerViolenceEstimatedSavingsLow();
    const savingsHigh = intimatePartnerViolence.intimatePartnerViolenceEstimatedSavingsHigh();

    if (
      +form.values[InterventionsCommonForm.SavingsLow] !== savingsLow ||
      +form.values[InterventionsCommonForm.SavingsHigh] !== savingsHigh
    ) {
      form.setValues({
        ...form.values,
        [InterventionsCommonForm.SavingsLow]: savingsLow.toString(),
        [InterventionsCommonForm.SavingsHigh]: savingsHigh.toString(),
      });
    }

    form.validateForm().then((errors) => {
      const isValid = !Object.keys(errors).length;

      if (form.dirty) {
        dispatch(
          calculatorActions.setInterventionData({
            intervention: CalculatorEnum.IntimatePartnerViolence,
            value: { ...form.values, isValid: !!isValid },
          })
        );
        dispatch(calculatorActions.setSummaryData());
      }
    });
  }, [form.values]);

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      calculatorActions.toggleInterventionsChecklistValue({
        key: InterventionsChecklistForm.IntimatePartnerViolence,
        value: e.target.checked,
      })
    );
  };

  const onNote = (noteName: string, noteAction: 'add' | 'edit' | 'delete') => {
    setNoteName(noteName);
    setNoteAction(noteAction);
    setIsNoteDialogOpen(true);
  };

  const onDialogNoteClose = () => {
    setIsNoteDialogOpen(false);
  };

  return (
    <>
      <InterventionContainer
        disabled={!switchValue}
        onSubmit={form.handleSubmit}
        header={
          <InterventionHeader
            title="Intimate Partner Violence"
            checked={switchValue}
            onChange={onSwitchChange}
          />
        }
        body={
          <InterventionBody
            description={
              <>
                Some HS Specialists talk with caregivers about intimate partner
                violence (IPV). The following questions ask about the actions
                taken by caregivers in these situations during the 12-month date
                range.
              </>
            }
            form={form}
            fields={fields}
            onNote={onNote}
          />
        }
        footer={
          <InterventionFooter
            form={form}
            savingsText="IPV"
            description="Percentage of severe IPV cases with improvement due to HealthySteps"
            onNote={onNote}
          />
        }
      />
      <InterventionFieldNoteDialog
        id={`${noteName}__${noteAction}-dialog`}
        name={noteName}
        value={form.values[noteName]}
        mode={noteAction}
        open={isNoteDialogOpen}
        onClose={onDialogNoteClose}
        form={form} />
    </>
  );
};

export default IntimatePartnerViolence;
