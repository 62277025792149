import React, { FunctionComponent, MouseEvent, useState } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import MoreVert from '@material-ui/icons/MoreVert';
import { Constants, Pdf } from '../../../../../utils';
import Menu from '../../../../../components/Menu/Menu';
import styles from './ReviewsOptionMenu.styles';
import { IReviewReport } from '../../../../../interfaces/report.interface';
import { IMenuOption } from '../../../../../interfaces/menuOption.interface';
import { calculatorActions } from '../../../../../redux/calculatorSlice/index';

interface IProps extends WithStylesType<typeof styles> {
  reviewStatus: IReviewReport;
}

const ReviewsOptionsMenu: FunctionComponent<IProps> = ({
  classes,
  reviewStatus,
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const dispatch = useDispatch();

  const onMenuOpen = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
    dispatch(calculatorActions.setName(reviewStatus.name));
  };

  const updateReportStatus = async (status: string) => {
    await dispatch(calculatorActions.updateReportStatus(
      reviewStatus.name,
      reviewStatus.username,
      reviewStatus.user?.email,
      status
    ));
    dispatch(calculatorActions.clearReports());
    dispatch(calculatorActions.getReviewReports());
  };
  
  const activeOptions: IMenuOption[] = [
    {
      text: 'Finished',
      action: async () => updateReportStatus(Constants.REPORT_STATUS.FINISHED),
      hidden: reviewStatus.status === Constants.REPORT_STATUS.FINISHED,
      testid: `menu-${ reviewStatus.user?.email || '' }__finished`,
    },
    {
      text: 'In progress',
      action: async () => updateReportStatus(Constants.REPORT_STATUS.PENDING),
      hidden: reviewStatus.status === Constants.REPORT_STATUS.PENDING,
      testid: `menu-${reviewStatus.user?.email}__inprogress`,
    },
    {
      text: 'Download',
      action: () => {
        Pdf.download(reviewStatus.name, reviewStatus.name);
      },
      testid: `menu-${reviewStatus.user?.email}__download`,
    },
  ];

  return (
    <>
      <IconButton
        onClick={onMenuOpen}
        className={classes.button}
        data-testid={`user-${reviewStatus.user?.email}_more-options-btn`}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        options={activeOptions}
      />
    </>
  );
};

export default withStyles(styles)(ReviewsOptionsMenu);
