import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors, FontWeight } from '../../assets/styles';

export default (): StyleRules =>
  createStyles({
    text: {
      color: Colors.White,
      fontSize: 12,
      fontWeight: FontWeight.Light,
      textAlign: 'center',
    },
    blue: {
      color: Colors.Blue,
    },
  });
