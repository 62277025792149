import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import NPCForm from './NPCForm/NPCForm';
import styles from './NewPasswordChallenge.styles';

const NewPasswordChallenge: FunctionComponent<WithStylesType<
  typeof styles
>> = ({ classes }) => {
  return (
    <div className={classes.container}>
      <NPCForm />
    </div>
  );
};

export default withStyles(styles)(NewPasswordChallenge);
