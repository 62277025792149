import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Dialog as MuiDialog, Typography } from '@material-ui/core';
import cx from 'classnames';
import CircularButton from '../CircularButton/CircularButton';
import styles from './Dialog.styles';

interface IProps extends WithStylesType<typeof styles> {
  id: string;
  title: string;
  open: boolean;
  onClose?: () => void;
  submitBtnText?: string;
  cancelBtnText?: string;
  onSubmitBtnClick?: () => void;
  onCancelBtnClick?: () => void;
  customClass?: string;
  closeOnSubmit?: boolean;
  isSubmitBtnDisabled?: boolean;
}

const Dialog: FunctionComponent<IProps> = ({
  classes,
  children,
  id,
  title,
  open,
  onClose,
  submitBtnText = 'Save',
  cancelBtnText = 'Cancel',
  onSubmitBtnClick,
  onCancelBtnClick,
  closeOnSubmit = true,
  customClass = '',
  isSubmitBtnDisabled = false,
}) => {
  const onCancelClick = () => {
    if (onClose) onClose();
    if (onCancelBtnClick) onCancelBtnClick();
  };

  const onSubmitClick = () => {
    if (closeOnSubmit && onClose) onClose();
    if (onSubmitBtnClick) onSubmitBtnClick();
  };

  return (
    <div>
      <MuiDialog
        open={open}
        PaperProps={{ id }}
        classes={{
          paper: cx(classes.container, { [customClass]: customClass }),
        }}
        onClose={onClose}
      >
        <Typography
          variant="h2"
          className={classes.title}
          data-testid={`${id}__title`}
        >
          {title}
        </Typography>
        <div className={classes.content} data-testid={`${id}__body`}>
          {children}
        </div>
        <div className={classes.actions} data-testid={`${id}__actions`}>
          <CircularButton
            type="button"
            color="primary"
            customClass={classes.cancelBtn}
            onClick={onCancelClick}
            data-testid={`${id}__cancel`}
          >
            {cancelBtnText}
          </CircularButton>
          <CircularButton
            type="button"
            color="primary"
            variant="contained"
            customClass={classes.acceptBtn}
            onClick={onSubmitClick}
            data-testid={`${id}__accept`}
            disabled={isSubmitBtnDisabled}
          >
            {submitBtnText}
          </CircularButton>
        </div>
      </MuiDialog>
    </div>
  );
};

export default withStyles(styles)(Dialog);
