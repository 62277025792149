import ServiceData from './serviceData';
import Calculator from './shared/base';

export default class Asthma extends Calculator {
  public serviceData: ServiceData;

  constructor({
    state,
    serviceData,
    lowEstimatedSlider,
    highEstimatedSlider,
  }: {
    state: string;
    serviceData: ServiceData;
    lowEstimatedSlider: number;
    highEstimatedSlider: number;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.lowEstimatedSlider = lowEstimatedSlider / 100;
    this.highEstimatedSlider = highEstimatedSlider / 100;
  }

  /**
   * @name asthma_tab_percent_children_diagnosed_with_asthma
   * @excel Excel Asthma Tab B2
   * @constant 3.1% (0.031)
   */
  public readonly asthmaTabPercentChildrenDiagnosedWithAsthma = this.constants
    .asthma.asthmaTabPercentChildrenDiagnosedWithAsthma;

  /**
   * @name asthma_tab_savings_associated_with_holistic_asthma_intervention_in_pediatric_medicaid_population
   * @excel Excel Asthma Tab B3
   * @constant 543
   */
  public readonly asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation = this
    .constants.asthma
    .asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation;

  /**
   * @name asthma_tab_medical_price_inflation_factor
   * @excel Excel Asthma Tab B4
   * @constant 2.26
   */
  public readonly asthmaTabMedicalPriceInflationFactor = this.constants.asthma
    .asthmaTabMedicalPriceInflationFactor;

  /**
   * @name number_of_tier2_and_tier3_hs_medicaid_children_who_have_asthma
   * @excel Excel E62
   * @formula asthma_tab_percent_children_diagnosed_with_asthma * cs_input_4 (from Service Data Screen)
   */
  public numberOfTier2AndTier3HsMedicaidChildrenWhoHaveAsthma = () => {
    return this.isFiniteNumber(
      this.asthmaTabPercentChildrenDiagnosedWithAsthma *
        this.serviceData.csInput4
    );
  };

  /**
   * low_estimated_slider (from app)
   * @name low_estimated_slider
   */
  public lowEstimatedSlider;

  /**
   * high_estimated_slider (from app)
   * @name high_estimated_slider
   */
  public highEstimatedSlider;

  /**
   * @name estimated_percentage_of_tiers_hs_medicaid_children_who_are_able_to_avoid_ED_visits_due_to_HS_low
   * @excel Excel E63
   * @reference low_estimated_slider (from app)
   */
  public estimatedPercentageOfTiersHsMedicaidChildrenWhoAreAbleToAvoidEDVisitsDueToHSLow = () => {
    return this.isFiniteNumber(this.lowEstimatedSlider);
  };

  /**
   * @name estimated_percentage_of_tiers_hs_medicaid_children_who_are_able_to_avoid_ED_visits_due_to_HS_high
   * @excel Excel E64
   * @reference high_estimated_slider (from app)
   */
  public estimatedPercentageOfTiersHsMedicaidChildrenWhoAreAbleToAvoidEDVisitsDueToHSHigh = () => {
    return this.isFiniteNumber(this.highEstimatedSlider);
  };

  /**
   * @name estimated_number_of_tiers_hs_medicaid_children_who_are_able_to_avoid_ED_visits_due_to_HS_low
   * @excel Excel E65
   * @formula number_of_tier2_and_tier3_hs_medicaid_children_who_have_asthma * estimated_percentage_of_tiers_hs_medicaid_children_who_are_able_to_avoid_ED_visits_due_to_HS_low
   */
  public estimatedNumberOfTiersHsMedicaidChildrenWhoAreAbleToAvoidEDVisitsDueToHSLow = () => {
    return this.isFiniteNumber(
      this.numberOfTier2AndTier3HsMedicaidChildrenWhoHaveAsthma() *
        this.estimatedPercentageOfTiersHsMedicaidChildrenWhoAreAbleToAvoidEDVisitsDueToHSLow()
    );
  };

  /**
   * @name estimated_number_of_tiers_hs_medicaid_children_who_are_able_to_avoid_ED_visits_due_to_HS_high
   * @excel Excel E66
   * @formula number_of_tier2_and_tier3_hs_medicaid_children_who_have_asthma * estimated_percentage_of_tiers_hs_medicaid_children_who_are_able_to_avoid_ED_visits_due_to_HS_high
   */
  public estimatedNumberOfTiersHsMedicaidChildrenWhoAreAbleToAvoidEDVisitsDueToHSHigh = () => {
    return this.isFiniteNumber(
      this.numberOfTier2AndTier3HsMedicaidChildrenWhoHaveAsthma() *
        this.estimatedPercentageOfTiersHsMedicaidChildrenWhoAreAbleToAvoidEDVisitsDueToHSHigh()
    );
  };

  /**
   * @name estimated_number_of_tiers_hs_medicaid_children_who_are_able_to_avoid_ED_visits_due_to_HS_high
   * @excel Excel E67
   * @formula asthma_tab_savings_associated_with_holistic_asthma_intervention_in_pediatric_medicaid_population * asthma_tab_medical_price_inflation_factor
   */
  public savingsPerChildExposedToHolisticAsthmaIntervention = () => {
    return this.isFiniteNumber(
      this
        .asthmaTabSavingsAssociatedWithHolisticAsthmaInterventionInPediatricMedicaidPopulation *
        this.asthmaTabMedicalPriceInflationFactor
    );
  };

  /**
   * @name asthma_estimated_savings_low
   * @excel Excel E61
   * @formula estimated_number_of_tiers_hs_medicaid_children_who_are_able_to_avoid_ED_visits_due_to_HS_low * savings_per_child_exposed_to_holistic_asthma_intervention
   */
  public asthmaEstimatedSavingsLow = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfTiersHsMedicaidChildrenWhoAreAbleToAvoidEDVisitsDueToHSLow() *
        this.savingsPerChildExposedToHolisticAsthmaIntervention()
    );
  };

  /**
   * @name asthma_estimated_savings_high
   * @excel Excel F61
   * @formula estimated_number_of_tiers_hs_medicaid_children_who_are_able_to_avoid_ED_visits_due_to_HS_high * savings_per_child_exposed_to_holistic_asthma_intervention
   */
  public asthmaEstimatedSavingsHigh = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfTiersHsMedicaidChildrenWhoAreAbleToAvoidEDVisitsDueToHSHigh() *
        this.savingsPerChildExposedToHolisticAsthmaIntervention()
    );
  };
}
