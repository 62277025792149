import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { ListItem, List, ListItemText, Typography } from '@material-ui/core';
import { Link, NavLink, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { Constants } from '../../utils';
import { selectUser } from '../../redux/authSlice';
import { paths } from '../../routes';
import {
  CalculatorIcon,
  ContactUsIcon,
  ResourcesIcon,
  HSLogoWhiteIcon,
  UserIcon,
  ReviewIcon,
  CalculationsIcon,
} from '../../assets/icons';
import styles from './MainNavigation.styles';

const MainNavigation: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const testid = 'nav';
  const location = useLocation();
  const user = useSelector(selectUser);
  const expandedSideBar = location.pathname.includes(paths.dashboard);
  const healthtStepsOrgUrl = 'https://www.healthysteps.org/';

  const menuOptions = [
    {
      path: paths.calculatorDashboard,
      icon: CalculatorIcon,
      text: 'Calculator',
      testid: `${testid}__calculator`,
      visible: true,
    },
    {
      path: '/dashboard/resources',
      icon: ResourcesIcon,
      text: 'Resources',
      testid: `${testid}__resources`,
      visible: true,
    },
    {
      path: '/dashboard/contactus',
      icon: ContactUsIcon,
      text: 'Contact Us',
      testid: `${testid}__contact-us`,
      visible: true,
    },
    {
      path: paths.usersDashboard,
      icon: UserIcon,
      text: 'Users',
      testid: `${testid}__users`,
      visible: user && user['custom:role'] === Constants.USER_ROLES.admin,
    },
    {
      path: paths.reviewsDashboard,
      icon: ReviewIcon,
      text: 'Reviews',
      testid: `${testid}__reviews`,
      visible: user && user['custom:role'] === Constants.USER_ROLES.admin,
    },
    {
      path: paths.adminCalculationsDashboard,
      icon: CalculationsIcon,
      text: 'Calculations',
      testid: `${testid}__calculations`,
      visible: user && user['custom:role'] === Constants.USER_ROLES.admin,
      classname: classes.listItemIcon
    },
  ];

  return (
    <div
      className={cx(classes.container, {
        [classes.containerExpanded]: expandedSideBar,
      })}
      data-testid={testid}
    >
      <Link to={{ pathname: healthtStepsOrgUrl }} target="_parent">
        <HSLogoWhiteIcon
          className={classes.logo}
          data-testid={`${testid}__home-link`}
        />
      </Link>
      <List classes={{ root: classes.list }} disablePadding>
        {menuOptions
          .filter((option) => option.visible)
          .map((option) => (
            <ListItem
              button
              key={option.path}
              component={NavLink}
              to={option.path}
              className={classes.listItem}
              activeClassName={classes.listItemSelected}
            >
              <option.icon data-testid={`${option.testid}_icon`} className={option.classname} />
              {expandedSideBar && (
                <ListItemText
                  primary={
                    <Typography
                      data-testid={`${option.testid}__text`}
                      classes={{ root: classes.listItemText }}
                    >
                      {option.text}
                    </Typography>
                  }
                />
              )}
            </ListItem>
          ))}
      </List>
    </div>
  );
};

export default withStyles(styles)(MainNavigation);
