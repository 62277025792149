import React, { FunctionComponent, MouseEvent, useState } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Avatar } from '@material-ui/core';
import {
  authActions,
  selectUser,
  selectIsLoading,
} from '../../../redux/authSlice';
import { CaretDownIcon } from '../../../assets/icons';
import Menu from '../Menu';
import styles from './AccountMenu.styles';

const AccountMenu: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const user = useSelector(selectUser);
  const isLoading = useSelector(selectIsLoading);

  const dispatch = useDispatch();

  const onMenuOpen = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(authActions.signOut());
  };

  const handleTermsOfService = () => {
    window.open('../assets/resources/HealthySteps_ROI_Calculator_Terms_of_Service-8-10-2021.pdf', '_blank');
  };

  const options = [
    {
      text: 'Log out',
      action: handleLogout,
      testid: 'account-menu__logout',
    },
    {
      text: 'Terms of Service',
      action: handleTermsOfService,
      testid: 'account-menu__terms-of-service',
    },
  ];

  return (
    <>
      <Button onClick={onMenuOpen} className={classes.button}>
        <Avatar className={classes.avatarInitials}>{user?.initials}</Avatar>
        {isLoading || (
          <>
            {user?.name}
            <CaretDownIcon className={classes.icon} />
          </>
        )}
      </Button>
      <Menu anchorEl={anchorEl} setAnchorEl={setAnchorEl} options={options} />
    </>
  );
};

export default withStyles(styles)(AccountMenu);
