import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';

export default ({ typography }: Theme): StyleRules => {
  const { pxToRem } = typography;

  return createStyles({
    container: {
      padding: `${pxToRem(54)} ${pxToRem(65)} ${pxToRem(38)}`,
    },
  });
};
