import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { TableRow, TableCell as TC, TableBody as TB, Checkbox } from '@material-ui/core';
import cx from 'classnames';
import OptionsMenu from '../OptionsMenu/OptionsMenu';
import { IUser } from '../../../../../interfaces/users.interface';
import { StringFormatter, Constants } from '../../../../../utils';
import { tableCellStyles } from '../UsersTable.styles';
import styles from './TableBody.styles';

export const TableCell = withStyles(tableCellStyles)(TC);

interface IProps extends WithStylesType<typeof styles> {
  users: IUser[] | null;
  onUserChangeRole: (user: IUser) => void;
  onDeleteUser: (user: IUser) => void;
  onToggleNotSendEmail: (user: IUser, value: boolean) => void;
}

const TableBody: FunctionComponent<IProps> = ({
  classes,
  users = [],
  onUserChangeRole,
  onDeleteUser,
  onToggleNotSendEmail
}) => {
  return (
    <TB>
      {users?.map((user) => (
        <TableRow key={user.username} data-testid={`user-${user.email}`}>
          <TableCell data-testid={`user-${user.email}__name`}>
            <span className={classes.name}>{user.name}</span>
            <span className={classes.email}>{user.email}</span>
          </TableCell>
          <TableCell data-testid={`user-${user.email}__name-of-site`}>
            {user.site}
          </TableCell>
          <TableCell data-testid={`user-${user.email}__role`}>
            {StringFormatter.capitalize(user.role)}
          </TableCell>
          <TableCell data-testid={`user-${user.email}__status`}>
            <div className={classes.lastCell}>
              <span
                className={cx({
                  [classes.activeStatus]:
                    user.accountStatus === Constants.ACCOUNT_STATUS.active,
                  [classes.inactiveStatus]:
                    user.accountStatus === Constants.ACCOUNT_STATUS.inactive,
                  [classes.pendingStatus]:
                    user.accountStatus === Constants.ACCOUNT_STATUS.pending,
                })}
              >
                {user.accountStatus}
              </span>
            </div>
          </TableCell>
          <TableCell>
            <div className={classes.centeredContent}>
              <Checkbox
                name={`${user.username}-send-email`}
                checked={!user.sendEmail}
                className={!user.sendEmail ? classes.checked : ''}
                onChange={() => onToggleNotSendEmail(user, !user.sendEmail)} 
              />
            </div>
          </TableCell>
          <TableCell>
            <OptionsMenu
              userStatus={user.accountStatus}
              user={user}
              onChangeRole={onUserChangeRole}
              onDelete={onDeleteUser}
            />
          </TableCell>
        </TableRow>
      ))}
    </TB>
  );
};

export default withStyles(styles)(TableBody);
