/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
  selectInterventionsChecklistValue,
  calculatorActions,
  selectInterventionsValue,
  selectServiceDataForm,
  selectReportName,
  selectState,
  selectTouched,
} from '../../../../redux/calculatorSlice';
import {
  InterventionsChecklistForm,
  PostpartumMaternalDepressionForm,
  IPostpartumMaternalDepression,
  InterventionsCommonForm,
} from '../../../../interfaces/interventions.interface';
import { CalculatorEnum } from '../../../../interfaces/calculator.interface';
import {
  InterventionHeader,
  InterventionBody,
  InterventionFooter,
  InterventionContainer,
  InterventionFieldNoteDialog,
} from '../Intervention';
import { FormValidations, StringFormatter } from '../../../../utils';
import useDebounceEffect from '../../../../hooks/useDebounceEffect';
import { PostpartumMaternalDepression as PostpartumMaternalDepressionClass } from '../../../../calculations';
import { paths } from '../../..';
import { Constants } from '../../../../utils';

const { DIALOG_MODES } = Constants;

const PostpartumMaternalDepression: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const switchValue = useSelector(
    selectInterventionsChecklistValue(
      InterventionsChecklistForm.PostpartumMaternalDepression
    )
  );
  const postpartumData = useSelector(
    selectInterventionsValue(CalculatorEnum.PostpartumMaternalDepression)
  );

  const serviceData = useSelector(selectServiceDataForm);
  const selectedState = useSelector(selectState);

  const reportName = useSelector(selectReportName);

  const [reinitialize, setReinitialize] = useState(true);
  const touched = useSelector(selectTouched);
  const [noteName, setNoteName] = useState<string>('');
  const [noteAction, setNoteAction] = useState<'add' | 'edit' | 'delete'>(DIALOG_MODES.ADD);
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);

  const fields = [
    {
      text: `Number of caregivers who discussed postpartum maternal depression with a HS Specialist (either in-person or telephonically)
      `,
      id: PostpartumMaternalDepressionForm.DiscussedProblem,
      showError: true,
    },
    {
      text: `Number of mothers who received counseling or related services for postpartum maternal depression from a HS Specialist
      `,
      id: PostpartumMaternalDepressionForm.ReceivedCounseling,
      showError: true,
    },
    {
      text: `Number of mothers that the HS Specialist(s) referred for counseling or other services for postpartum maternal depression
      `,
      id: PostpartumMaternalDepressionForm.ReferredForServices,
      showError: true,
    },
  ];

  const onSubmit = (values: IPostpartumMaternalDepression) => {
    if (touched) {
      dispatch(
        calculatorActions.saveReport({
          reportName,
          update: true,
        }));
    }
    history.push(
      StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsHealthyBirthSpacing
      )
    );

  };

  useEffect(() => {
    if (switchValue) {
      const timeout = setTimeout(() => {
        setReinitialize(false);
      }, 250);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [switchValue]);

  const form = useFormik({
    initialValues: postpartumData,
    onSubmit,
    validationSchema: switchValue && FormValidations.intervention({
      [PostpartumMaternalDepressionForm.DiscussedProblem]:
        FormValidations.interventionNumberOfChilds(PostpartumMaternalDepressionForm.DiscussedProblem, serviceData.csInput3),
      [PostpartumMaternalDepressionForm.ReceivedCounseling]:
        FormValidations.interventionNumberOfChilds(PostpartumMaternalDepressionForm.ReceivedCounseling, serviceData.csInput3),
      [PostpartumMaternalDepressionForm.ReferredForServices]:
        FormValidations.interventionNumberOfChilds(PostpartumMaternalDepressionForm.ReferredForServices, serviceData.csInput3),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: reinitialize || !switchValue,
  });

  useEffect(() => {
    if (!switchValue) {
      form.resetForm();
      dispatch(
        calculatorActions.clearInterventionData(
          CalculatorEnum.PostpartumMaternalDepression
        )
      );
    }
  }, [switchValue]);

  useDebounceEffect(() => {
    const hasBeenEdited = Object.keys(form.values).some(
      (key) => !!form.values[key]
    );
    if (!form.dirty && !hasBeenEdited) return;
    /** Calculations happen */
    const maternalDepression = new PostpartumMaternalDepressionClass({
      state: selectedState,
      serviceData,
      lowEstimatedSlider: +form.values[InterventionsCommonForm.LowEstimate],
      highEstimatedSlider: +form.values[InterventionsCommonForm.HighEstimate],
    });

    const savingsLow = maternalDepression.maternalDepressionEstimatedSavingsLow();
    const savingsHigh = maternalDepression.maternalDepressionEstimatedSavingsHigh();

    if (
      +form.values[InterventionsCommonForm.SavingsLow] !== savingsLow ||
      +form.values[InterventionsCommonForm.SavingsHigh] !== savingsHigh
    ) {
      form.setValues({
        ...form.values,
        [InterventionsCommonForm.SavingsLow]: savingsLow.toString(),
        [InterventionsCommonForm.SavingsHigh]: savingsHigh.toString(),
      });
    }

    form.validateForm().then((errors) => {
      const isValid = !Object.keys(errors).length;

      if (form.dirty) {
        dispatch(
          calculatorActions.setInterventionData({
            intervention: CalculatorEnum.PostpartumMaternalDepression,
            value: { ...form.values, isValid: !!isValid },
          })
        );
        dispatch(calculatorActions.setSummaryData());
      }
    });
  }, [form.values]);

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      calculatorActions.toggleInterventionsChecklistValue({
        key: InterventionsChecklistForm.PostpartumMaternalDepression,
        value: e.target.checked,
      })
    );
  };

  const onNote = (noteName: string, noteAction: 'add' | 'edit' | 'delete') => {
    setNoteName(noteName);
    setNoteAction(noteAction);
    setIsNoteDialogOpen(true);
  };

  const onDialogNoteClose = () => {
    setIsNoteDialogOpen(false);
  };

  return (
    <>
      <InterventionContainer
        disabled={!switchValue}
        onSubmit={form.handleSubmit}
        header={
          <InterventionHeader
            title="Postpartum Maternal Depression"
            checked={switchValue}
            onChange={onSwitchChange}
          />
        }
        body={
          <InterventionBody
            description={
              <>
                Some HS Specialists talk with caregivers about postpartum maternal depression. The following questions ask about the actions taken by caregivers in these situations during the 12-month date range.
              </>
            }
            form={form}
            fields={fields}
            onNote={onNote}
          />
        }
        footer={
          <InterventionFooter
            form={form}
            savingsText="Postpartum maternal depression"
            description="Percentage reduction in number of mothers with persistent depression due to HealthySteps"
            onNote={onNote}
          />
        }
      />
      <InterventionFieldNoteDialog
        id={`${noteName}__${noteAction}-dialog`}
        name={noteName}
        value={form.values[noteName]}
        mode={noteAction}
        open={isNoteDialogOpen}
        onClose={onDialogNoteClose}
        form={form} />
    </>
  );
};

export default PostpartumMaternalDepression;
