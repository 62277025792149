import React, { FunctionComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Slider as MuiSlider,
  ValueLabelProps,
} from '@material-ui/core';
import { Constants } from '../../../utils';
import Tooltip from '../../Tooltip/Tooltip';
import InputText from '../InputText/InputText';
import useStyles, { sliderStyles } from './Slider.styles';

const CustomSlider = withStyles(sliderStyles)(MuiSlider);

const ValueLabelComponent = (props: ValueLabelProps) => {
  const { children, open, value } = props;
  const styles = useStyles();

  return (
    <Tooltip
      // id={`${id}__tooltip`}
      id="tooltipxxx"
      open={open}
      placement="top"
      title={`${value}%`}
      customClass={styles.tooltip}
    >
      {children}
    </Tooltip>
  );
};

interface IProps {
  id: string;
  label: string;
  value: string;
  errorMessage?: string;
  hideInput?: boolean;
  onInputChange?: (
    e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onSliderChange?: (value: number | number[]) => void;
}

const Slider: FunctionComponent<IProps> = ({
  id,
  label,
  value,
  errorMessage,
  onInputChange,
  onSliderChange,
  hideInput = false,
}) => {
  const styles = useStyles();

  const preOnSliderChange = (
    event: React.ChangeEvent<{}>,
    sliderValue: number | number[]
  ) => {
    if (onSliderChange) onSliderChange(sliderValue);
  };

  return (
    <div className={styles.container}>
      <Typography className={styles.label}>{label}</Typography>
      <div className={styles.sliderWrapper}>
        <CustomSlider
          value={+value}
          onChange={preOnSliderChange}
          valueLabelDisplay="auto"
          defaultValue={0}
          ValueLabelComponent={ValueLabelComponent}
        />
        {!hideInput &&
          <InputText
            id={id}
            name={id}
            value={value}
            onChange={onInputChange}
            type={Constants.INPUT_TYPES.PERCENTAGE}
            errorMessage={errorMessage}
            hideErrorMessage={true}
            customClass={styles.input}
          />}
      </div>
      {errorMessage &&
        errorMessage !== Constants.FORM_ERRORS.REQUIRED_FIELD_ERROR && (
          <Typography className={styles.errorMessage}>
            {errorMessage}
          </Typography>
      )}
    </div>
  );
};

export default Slider;
