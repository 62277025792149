import { States, StateConstants } from './constants';

export default class Calculator {
  public constants: StateConstants;

  constructor({ state }: { state: string }) {
    const stateData = States[state];
    if (stateData) {
      this.constants = stateData;
    } else {
      this.constants = States.DEFAULT;
    }
  }

  protected isFiniteNumber = (num: number) => {
    if (isFinite(num)) {
      return num;
    }

    return 0;
  };
}
