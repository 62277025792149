/* eslint-disable @typescript-eslint/no-explicit-any */
import { IServiceData, serviceDataState } from './serviceData.interface';
import { Constants } from '../utils';
import {
  IInterventionsChecklist,
  IOralHealth,
  IEmergencyDepartmentUse,
  IImmunizations,
  IAsthma,
  IPostpartumMaternalDepression,
  IIntimatePartnerViolence,
  IHealthyBirthSpacing,
  IBreastfeeding,
  ISmokingCessation,
  interventionsChecklistState,
  oralHealthState,
  emergencyDepartmentUseState,
  immunizationsState,
  asthmaState,
  postpartumMaternalDepressionState,
  intimatePartnerViolenceState,
  healthyBirthSpacingState,
  breastfeedingState,
  smokingCessationState,
} from './interventions.interface';

interface IIndex {
  [key: string]: any;
}

export enum CalculatorEnum {
  ServiceData = 'serviceData',
  InterventionsChecklist = 'interventionsChecklist',
  OralHealth = 'oralHealth',
  EmergencyDepartmentUse = 'emergencyDepartmentUse',
  Immunizations = 'immunizations',
  Asthma = 'asthma',
  PostpartumMaternalDepression = 'postpartumMaternalDepression',
  HealthyBirthSpacing = 'healthyBirthSpacing',
  IntimatePartnerViolence = 'intimidatePartnerViolence',
  Breastfeeding = 'breastfeeding',
  SmokingCessation = 'smokeCessation',
}

export interface IBreakdownReport {
  siteName: string;
  siteLocation: string;
  dateFrom: string;
  dateTo: string;
  calculationsApplyTo: string;
  numberOfSitesCalculationsApplyTo: string;
  numberOfSpecialistsDeliveringServices: string;
  numberOfSpecialistsDeliveringServicesNote: string;
  specialistSalaryCost: string;
  specialistSalaryCostNote: string;
  directCost: string;
  directCostNote: string;
  otherCost: string;
  otherCostNote: string;
  intermediaryCost: string;
  intermediaryCostNote: string;
  siteServiceCost: string;
  siteServiceCostNote: string;
  children0To3: string;
  children0To3Note: string;
  medicaidChildren0To3: string;
  medicaidChildren0To3Note: string;
  children0To3Tier2And3: string;
  children0To3Tier2And3Note: string;
  medicaidChildren0To3Tier2And3: string;
  medicaidChildren0To3Tier2And3Note: string;
  medicaidChildrenUnder1Tier2And3: string;
  medicaidChildrenUnder1Tier2And3Note: string;
  // Oral health
  oralHealth_needForOralHealth: string;
  oralHealth_needForOralHealthNote: string;
  oralHealth_lowEstimate: string;
  oralHealth_highEstimate: string;
  oralHealth_estimatesNote: string;
  // Emergengy department
  emergencyDepartment_contactedByPhone: string;
  emergencyDepartment_contactedByPhoneNote: string;
  emergencyDepartment_treatedAtHome: string;
  emergencyDepartment_treatedAtHomeNote: string;
  emergencyDepartment_clinicVisit: string;
  emergencyDepartment_clinicVisitNote: string;
  // Immunizations
  immunizations_needForFluVaccinations: string;
  immunizations_needForFluVaccinationsNote: string;
  immunizations_numberOfEncourageVisits: string;
  immunizations_numberOfEncourageVisitsNote: string;
  immunizations_lowEstimate: string;
  immunizations_highEstimate: string;
  immunizations_estimatesNote: string;
  // Asthma
  asthma_numberHaveAsthma: string;
  asthma_numberHaveAsthmaNote: string;
  asthma_lowEstimate: string;
  asthma_highEstimate: string;
  asthma_estimatesNote: string;
  // Postpartum Maternal Depression
  postpartumMaternalDepression_discussedProblem: string;
  postpartumMaternalDepression_discussedProblemNote: string;
  postpartumMaternalDepression_receivedCounseling: string;
  postpartumMaternalDepression_receivedCounselingNote: string;
  postpartumMaternalDepression_referredForServices: string;
  postpartumMaternalDepression_referredForServicesNote: string;
  postpartumMaternalDepression_lowEstimate: string;
  postpartumMaternalDepression_highEstimate: string;
  postpartumMaternalDepression_estimatesNote: string;
  // Healthy Birth Spacing
  healthyBirthSpacing_needForContraception: string;
  healthyBirthSpacing_needForContraceptionNote: string;
  healthyBirthSpacing_lowEstimate: string;
  healthyBirthSpacing_highEstimate: string;
  healthyBirthSpacing_estimatesNote: string;
  healthyBirthSpacing_followedUpOnReferals: string;
  healthyBirthSpacing_followedUpOnReferalsNote: string;
  // Intimate Partner Violence
  intimatePartnerViolence_discussedProblem: string;
  intimatePartnerViolence_discussedProblemNote: string;
  intimatePartnerViolence_receivedCounseling: string;
  intimatePartnerViolence_receivedCounselingNote: string;
  intimatePartnerViolence_referredForServices: string;
  intimatePartnerViolence_referredForServicesNote: string;
  intimatePartnerViolence_lowEstimate: string;
  intimatePartnerViolence_highEstimate: string;
  intimatePartnerViolence_estimatesNote: string;
  // Breastfeeding
  breastfeeding_discussedProblem: string;
  breastfeeding_discussedProblemNote: string;
  breastfeeding_receivedCounseling: string;
  breastfeeding_receivedCounselingNote: string;
  breastfeeding_referredForServices: string;
  breastfeeding_referredForServicesNote: string;
  breastfeeding_lowEstimate: string;
  breastfeeding_highEstimate: string;
  breastfeeding_estimatesNote: string;
  breastfeeding_lowEstimateExclusive: string;
  breastfeeding_highEstimateExclusive: string;
  breastfeeding_estimatesExclusiveNote: string;
  // Smoke Cessation
  smokeCessation_discussedProblem: string;
  smokeCessation_discussedProblemNote: string;
  smokeCessation_lowEstimate: string;
  smokeCessation_highEstimate: string;
  smokeCessation_estimatesNote: string;
}

export const breakdownReportState: IBreakdownReport = {
  siteName: Constants.STRING_TYPES.EMPTY,
  siteLocation: Constants.STRING_TYPES.EMPTY,
  dateFrom: Constants.STRING_TYPES.EMPTY,
  dateTo: Constants.STRING_TYPES.EMPTY,
  calculationsApplyTo: Constants.STRING_TYPES.EMPTY,
  numberOfSitesCalculationsApplyTo: Constants.STRING_TYPES.EMPTY,
  numberOfSpecialistsDeliveringServices: Constants.STRING_TYPES.EMPTY,
  numberOfSpecialistsDeliveringServicesNote: '',
  specialistSalaryCost: Constants.STRING_TYPES.EMPTY,
  specialistSalaryCostNote: '',
  directCost: Constants.STRING_TYPES.EMPTY,
  directCostNote: '',
  otherCost: Constants.STRING_TYPES.EMPTY,
  otherCostNote: '',
  intermediaryCost: Constants.STRING_TYPES.EMPTY,
  intermediaryCostNote: '',
  siteServiceCost: Constants.STRING_TYPES.EMPTY,
  siteServiceCostNote: '',
  children0To3: Constants.STRING_TYPES.EMPTY,
  children0To3Note: '',
  medicaidChildren0To3: Constants.STRING_TYPES.EMPTY,
  medicaidChildren0To3Note: '',
  children0To3Tier2And3: Constants.STRING_TYPES.EMPTY,
  children0To3Tier2And3Note: '',
  medicaidChildren0To3Tier2And3: Constants.STRING_TYPES.EMPTY,
  medicaidChildren0To3Tier2And3Note: '',
  medicaidChildrenUnder1Tier2And3: Constants.STRING_TYPES.EMPTY,
  medicaidChildrenUnder1Tier2And3Note: '',
  //
  oralHealth_needForOralHealth: Constants.STRING_TYPES.EMPTY,
  oralHealth_needForOralHealthNote: '',
  oralHealth_lowEstimate: Constants.STRING_TYPES.EMPTY,
  oralHealth_highEstimate: Constants.STRING_TYPES.EMPTY,
  oralHealth_estimatesNote: '',
  //
  emergencyDepartment_contactedByPhone: Constants.STRING_TYPES.EMPTY,
  emergencyDepartment_contactedByPhoneNote: '',
  emergencyDepartment_treatedAtHome: Constants.STRING_TYPES.EMPTY,
  emergencyDepartment_treatedAtHomeNote: '',
  emergencyDepartment_clinicVisit: Constants.STRING_TYPES.EMPTY,
  emergencyDepartment_clinicVisitNote: '',
  //
  immunizations_needForFluVaccinations: Constants.STRING_TYPES.EMPTY,
  immunizations_needForFluVaccinationsNote: '',
  immunizations_numberOfEncourageVisits: Constants.STRING_TYPES.EMPTY,
  immunizations_numberOfEncourageVisitsNote: '',
  immunizations_lowEstimate: Constants.STRING_TYPES.EMPTY,
  immunizations_highEstimate: Constants.STRING_TYPES.EMPTY,
  immunizations_estimatesNote: '',
  //
  asthma_numberHaveAsthma: Constants.STRING_TYPES.EMPTY,
  asthma_numberHaveAsthmaNote: '',
  asthma_lowEstimate: Constants.STRING_TYPES.EMPTY,
  asthma_highEstimate: Constants.STRING_TYPES.EMPTY,
  asthma_estimatesNote: '',
  //
  postpartumMaternalDepression_discussedProblem: Constants.STRING_TYPES.EMPTY,
  postpartumMaternalDepression_discussedProblemNote: '',
  postpartumMaternalDepression_receivedCounseling: Constants.STRING_TYPES.EMPTY,
  postpartumMaternalDepression_receivedCounselingNote: '',
  postpartumMaternalDepression_referredForServices: Constants.STRING_TYPES.EMPTY,
  postpartumMaternalDepression_referredForServicesNote: '',
  postpartumMaternalDepression_lowEstimate: Constants.STRING_TYPES.EMPTY,
  postpartumMaternalDepression_highEstimate: Constants.STRING_TYPES.EMPTY,
  postpartumMaternalDepression_estimatesNote: '',
  //
  healthyBirthSpacing_needForContraception: Constants.STRING_TYPES.EMPTY,
  healthyBirthSpacing_needForContraceptionNote: '',
  healthyBirthSpacing_lowEstimate: Constants.STRING_TYPES.EMPTY,
  healthyBirthSpacing_highEstimate: Constants.STRING_TYPES.EMPTY,
  healthyBirthSpacing_estimatesNote: '',
  healthyBirthSpacing_followedUpOnReferals: Constants.STRING_TYPES.EMPTY,
  healthyBirthSpacing_followedUpOnReferalsNote: '',
  //
  intimatePartnerViolence_discussedProblem: Constants.STRING_TYPES.EMPTY,
  intimatePartnerViolence_discussedProblemNote: '',
  intimatePartnerViolence_receivedCounseling: Constants.STRING_TYPES.EMPTY,
  intimatePartnerViolence_receivedCounselingNote: '',
  intimatePartnerViolence_referredForServices: Constants.STRING_TYPES.EMPTY,
  intimatePartnerViolence_referredForServicesNote: '',
  intimatePartnerViolence_lowEstimate: Constants.STRING_TYPES.EMPTY,
  intimatePartnerViolence_highEstimate: Constants.STRING_TYPES.EMPTY,
  intimatePartnerViolence_estimatesNote: '',
  //
  breastfeeding_discussedProblem: Constants.STRING_TYPES.EMPTY,
  breastfeeding_discussedProblemNote: '',
  breastfeeding_receivedCounseling: Constants.STRING_TYPES.EMPTY,
  breastfeeding_receivedCounselingNote: '',
  breastfeeding_referredForServices: Constants.STRING_TYPES.EMPTY,
  breastfeeding_referredForServicesNote: '',
  breastfeeding_lowEstimate: Constants.STRING_TYPES.EMPTY,
  breastfeeding_highEstimate: Constants.STRING_TYPES.EMPTY,
  breastfeeding_estimatesNote: '',
  breastfeeding_lowEstimateExclusive: Constants.STRING_TYPES.EMPTY,
  breastfeeding_highEstimateExclusive: Constants.STRING_TYPES.EMPTY,
  breastfeeding_estimatesExclusiveNote: '',
  //
  smokeCessation_discussedProblem: Constants.STRING_TYPES.EMPTY,
  smokeCessation_discussedProblemNote: '',
  smokeCessation_lowEstimate: Constants.STRING_TYPES.EMPTY,
  smokeCessation_highEstimate: Constants.STRING_TYPES.EMPTY,
  smokeCessation_estimatesNote: '',
};
export interface ISummaryReport {
  totalChildren0to3: number;
  totalChildrenMedicaid0to3: number;
  medicaidPercentageOfTotals: number;
  totalAnnualProgramCost: number;
  annualCostPerChild: number;
  totalSavingsAvg: number;
  annualReturnOnInvestment: number;
  returnPerMedicaidChild: number;
  totalAnnualMedicaidProgramCost: number;
  annualCostPerMedicaidChild: number;
  costSavingsPerMedicaidFamily: number;
  annualMedicaidReturnOnInvestment: number;
  childrenLowSavings: number;
  childrenHighSavings: number;
  dentalEstimatedSavingLow: number;
  dentalEstimatedSavingHigh: number;
  emergencyDepartmentEstimatedSavingLow: number;
  emergencyDepartmentEstimatedSavingHigh: number;
  immunizationsEstimatedSavingsLow: number;
  immunizationsEstimatedSavingsHigh: number;
  asthmaEstimatedSavingsLow: number;
  asthmaEstimatedSavingsHigh: number;
  parentLowSavings: number;
  parentHighSavings: number;
  maternalDepressionEstimatedSavingsLow: number;
  maternalDepressionEstimatedSavingsHigh: number;
  healthBirthSpacingEstimatedSavingsLow: number;
  healthBirthSpacingEstimatedSavingsHigh: number;
  intimatePartnerViolenceEstimatedSavingsLow: number;
  intimatePartnerViolenceEstimatedSavingsHigh: number;
  breastfeedingEstimatedSavingsLow: number;
  breastfeedingEstimatedSavingsHigh: number;
  smokingCessationEstimatedSavingsLow: number;
  smokingCessationEstimatedSavingsHigh: number;
  globalEstimateSavingsLow: number;
  globalEstimateSavingsHigh: number;
  nationalOfficeDemostratedAnnualizedSavingsToMedicaidUpToDollarsPerFamily: number;
  annualReturnOnInvestmentPerc: number;
  anEstimatedDollarsInSavings: number;
  breakdownData: IBreakdownReport;
}

export const summaryReportState: ISummaryReport = {
  totalChildren0to3: 0,
  totalChildrenMedicaid0to3: 0,
  medicaidPercentageOfTotals: 0,
  totalAnnualProgramCost: 0,
  annualCostPerChild: 0,
  totalSavingsAvg: 0,
  annualReturnOnInvestment: 0,
  returnPerMedicaidChild: 0,
  totalAnnualMedicaidProgramCost: 0,
  annualCostPerMedicaidChild: 0,
  costSavingsPerMedicaidFamily: 0,
  annualMedicaidReturnOnInvestment: 0,
  childrenLowSavings: 0,
  childrenHighSavings: 0,
  dentalEstimatedSavingLow: 0,
  dentalEstimatedSavingHigh: 0,
  emergencyDepartmentEstimatedSavingLow: 0,
  emergencyDepartmentEstimatedSavingHigh: 0,
  immunizationsEstimatedSavingsLow: 0,
  immunizationsEstimatedSavingsHigh: 0,
  asthmaEstimatedSavingsLow: 0,
  asthmaEstimatedSavingsHigh: 0,
  parentLowSavings: 0,
  parentHighSavings: 0,
  maternalDepressionEstimatedSavingsLow: 0,
  maternalDepressionEstimatedSavingsHigh: 0,
  healthBirthSpacingEstimatedSavingsLow: 0,
  healthBirthSpacingEstimatedSavingsHigh: 0,
  intimatePartnerViolenceEstimatedSavingsLow: 0,
  intimatePartnerViolenceEstimatedSavingsHigh: 0,
  breastfeedingEstimatedSavingsLow: 0,
  breastfeedingEstimatedSavingsHigh: 0,
  smokingCessationEstimatedSavingsLow: 0,
  smokingCessationEstimatedSavingsHigh: 0,
  globalEstimateSavingsLow: 0,
  globalEstimateSavingsHigh: 0,
  nationalOfficeDemostratedAnnualizedSavingsToMedicaidUpToDollarsPerFamily: 0,
  annualReturnOnInvestmentPerc: 0,
  anEstimatedDollarsInSavings: 0,
  breakdownData: breakdownReportState,
};

export interface ICalculator extends IIndex {
  name: string;
  serviceData: IServiceData;
  interventionsChecklist: IInterventionsChecklist;
  oralHealth: IOralHealth;
  emergencyDepartmentUse: IEmergencyDepartmentUse;
  immunizations: IImmunizations;
  asthma: IAsthma;
  postpartumMaternalDepression: IPostpartumMaternalDepression;
  healthyBirthSpacing: IHealthyBirthSpacing;
  intimidatePartnerViolence: IIntimatePartnerViolence;
  breastfeeding: IBreastfeeding;
  smokeCessation: ISmokingCessation;
  summary: {
    totalSavingsLow: string;
    totalSavingsHigh: string;
    report: ISummaryReport;
  };
  status: string;
  isLoading: boolean;
  touched: boolean;
  reportsList: {
    reports: any[],
    LastEvaluatedKey: object | null,
  };
  updatedISO: string;
}

export const calculatorState: ICalculator = {
  name: '',
  serviceData: serviceDataState,
  interventionsChecklist: interventionsChecklistState,
  oralHealth: oralHealthState,
  emergencyDepartmentUse: emergencyDepartmentUseState,
  immunizations: immunizationsState,
  asthma: asthmaState,
  postpartumMaternalDepression: postpartumMaternalDepressionState,
  healthyBirthSpacing: healthyBirthSpacingState,
  intimidatePartnerViolence: intimatePartnerViolenceState,
  breastfeeding: breastfeedingState,
  smokeCessation: smokingCessationState,
  summary: {
    totalSavingsLow: '',
    totalSavingsHigh: '',
    report: summaryReportState,
  },
  status: 'pending',
  isLoading: false,
  touched: false,
  reportsList: {
    reports: [],
    LastEvaluatedKey: null,
  },
  updatedISO: '',
};
