import { makeStyles, StyleRules } from '@material-ui/core/styles';
import { FontWeight, Colors } from '../../../assets/styles';

export default makeStyles(
  (): StyleRules => {
    return {
      container: {
        marginBottom: 20,
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        fontWeight: 700,
        '& $arrow': {
          color: 'rgba(0,0,0,0.8)',
        },
      },
      label: {
        fontWeight: FontWeight.Bold,
        color: Colors.Blue,
        marginBottom: 10,
      },
      sliderWrapper: {
        display: 'flex',
        alignItems: 'center',
      },
      input: {
        maxWidth: 85,
        marginLeft: 35,
      },
      errorMessage: {
        color: Colors.Red,
        textAlign: 'right',
        fontSize: 14,
        marginTop: 10,
      },
    };
  }
);

export const sliderStyles = (): StyleRules => ({
  root: {
    color: Colors.Gray2,
    height: 8,
  },
  thumb: {
    height: 16,
    width: 16,
    marginLeft: -12,
    backgroundColor: Colors.Blue1,
  },
  active: {},
  valueLabel: {
    left: '-50%',
  },
  track: {
    height: 6,
    borderRadius: 4,
    opacity: 0.4,
  },
  rail: {
    height: 6,
    borderRadius: 4,
    opacity: 0.4,
  },
});
