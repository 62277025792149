import React, { FunctionComponent, useState, MouseEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import cx from 'classnames';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import {
  Typography,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  Button,
  Checkbox
} from '@material-ui/core';
import { FilterList, MoreVert } from '@material-ui/icons';
import useOnMount from '../../../../hooks/useOnMount';
import { paths } from '../../../';
import {
  adminReportsActions,
  selectIsLoading,
  selectReports,
  selectLastEvaluatedKey,
  selectFilters,
  selectLastPageReportsLoaded,
} from '../../../../redux/adminReportsSlice';
import { Menu, Spinner } from '../../../../components';
import { Constants, Pdf, StringFormatter } from '../../../../utils';
import styles from './CalculationsComponent.styles';
import { IMenuOption } from '../../../../interfaces/menuOption.interface';
import { ITableHeaderOption } from '../../../../interfaces/tableHeaderOption.interface';
import Report from '../../../Calculator/CalculatorReport/Report/Report';
import ReportStatusFilterMenu from '../ReportStatusFilterMenu/ReportStatusFilterMenu';
import FilterMenu from '../../../../components/FilterMenu/FilterMenu';
import ReportStateFilterMenu from '../ReportStateFilterMenu/ReportStateFilterMenu';
import ReportUserFilterMenu from '../ReportUserFilterMenu/ReportUserFilterMenu';
import ReportIsTestReportFilterMenu from '../ReportIsTestReportFilterMenu/ReportIsTestReportFilterMenu';
import ExportFilter from './ExportFilterComponent';

const {
  REPORT_STATUSES,
  REPORT_STATUS,
  US_STATES
} = Constants;

type Props = WithStylesType<typeof styles> & {};

const CalculationsComponent: FunctionComponent<Props> = ({ classes }) => {
  const testid = 'admin-reports-table';
  const isLoading = useSelector(selectIsLoading);
  const reports = useSelector(selectReports);
  const currentFilters = useSelector(selectFilters);
  const lastEvaluatedKey = useSelector(selectLastEvaluatedKey);
  const lastPageReportsLoaded = useSelector(selectLastPageReportsLoaded);
  const [reportMenuAnchorEl, setReportMenuAnchorEl] = useState<Element | null>(null);
  const [reportMenuOptions, setReportMenuOptions] = useState<IMenuOption[] | undefined>(undefined);
  const [reportStatusFilterMenuAnchorEl, setReportStatusFilterMenuAnchorEl] = useState<Element | null>(null);
  const [textFilterMenuAnchorEl, setTextFilterMenuAnchorEl] = useState<Element | null>(null);
  const [selectedTextFilterOption, setSelectedTextFilterOption] = useState<ITableHeaderOption | null>(null);
  const [reportStateFilterMenuAnchorEl, setReportStateFilterMenuAnchorEl] = useState<Element | null>(null);
  const [reportUserFilterMenuAnchorEl, setReportUserFilterMenuAnchorEl] = useState<Element | null>(null);
  const [reportIsTestReportFilterMenuAnchorEl, setReportIsTestReportFilterMenuAnchorEl] = useState<Element | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const headerOptions: ITableHeaderOption[] = [
    {
      title: 'Name of Report',
      testid: `${testid}__name-of-report-header-cell`,
      filter: {
        name: 'name'
      }
    },
    {
      title: 'Last Edited / Completed',
      testid: `${testid}__submitted-header-cell`,
    },
    {
      title: 'Specialist',
      testid: `${testid}__specialist-cell`,
      filter: {
        name: 'username'
      }
    },
    {
      title: 'Name of Site',
      testid: `${testid}__name-of-site-cell`,
      filter: {
        name: 'site'
      }
    },
    {
      title: 'Site State',
      testid: `${testid}__site-state-cell`,
      filter: {
        name: 'state'
      }
    },
    {
      title: 'Is Test',
      testid: `${testid}__site-is-test-report-cell`,
      filter: {
        name: 'istest'
      }
    },
    {
      title: 'Status',
      testid: `${testid}__status-cell`,
      filter: {
        name: 'status'
      }
    },
    {
      title: '',
      testid: `${testid}__null-cell`,
    },
  ];

  useOnMount(() => {
    dispatch(adminReportsActions.clearReports());
    dispatch(adminReportsActions.getReports());
  });

  useEffect(() => {
    if (lastEvaluatedKey && !lastPageReportsLoaded) {
      dispatch(adminReportsActions.getReports());
    }
  }, [lastPageReportsLoaded, lastEvaluatedKey, dispatch]);

  const onMenuOpen = (e: React.MouseEvent, report: any) => {
    const { status, name } = report;
    const menuOptions: IMenuOption[] = [];
    switch (status) {
      case REPORT_STATUS.FINISHED:
        menuOptions.push({
          text: 'Download',
          action: () => {
            Pdf.download(name, name);
          },
          testid: `admin-reports-menu-${name}__download`,
        });
        break;
      case REPORT_STATUS.PENDING:
        menuOptions.push({
          text: 'Edit',
          action: () => {
            history.push(
              paths.calculatorServiceData.replace(
                Constants.REPORT.URL_PARAM,
                report.name
              ), { reportUsername: report.username }
            );
          },
          testid: `admin-reports-menu-${name}__edit`,
        });
        break;
    }
    setReportMenuAnchorEl(e.currentTarget);
    setReportMenuOptions(menuOptions);
  };

  const onFilterButtonClick = (event: MouseEvent, selectedFilterOption: ITableHeaderOption) => {
    const { filter } = selectedFilterOption;
    if (filter) {
      switch (filter.name) {
        case 'status':
          setReportStatusFilterMenuAnchorEl(event.currentTarget);
          break;
        case 'name':
        case 'site':
          setSelectedTextFilterOption(selectedFilterOption);
          setTextFilterMenuAnchorEl(event.currentTarget);
          break;
        case 'state':
          setReportStateFilterMenuAnchorEl(event.currentTarget);
          break;
        case 'username':
          setReportUserFilterMenuAnchorEl(event.currentTarget);
          break;
        case 'istest':
          setReportIsTestReportFilterMenuAnchorEl(event.currentTarget);
          break;
      }
    }
  };

  const onReportStatusFilterMenuClose = () => {
    setReportStatusFilterMenuAnchorEl(null);
  };

  const onReportStatusFilterMenuApplyFilter = (filterName: string, filterValues: string[] | undefined) => {
    setReportStatusFilterMenuAnchorEl(null);
    dispatch(adminReportsActions.updateFilter({ property: filterName, value: filterValues as string[] }));
  };

  const onTextFilterMenuClose = () => {
    setTextFilterMenuAnchorEl(null);
  };

  const onApplyTextFilter = (filterName: string, filterValue: string | null) => {
    if (!filterValue) {
      onClearTextFilter(filterName);
      return;
    }
    onTextFilterMenuClose();
    dispatch(adminReportsActions.updateFilter({ property: filterName, value: filterValue }));
  };

  const onClearTextFilter = (filterName: string) => {
    dispatch(adminReportsActions.removeFilter({ property: filterName, value: '' }));
    onTextFilterMenuClose();
  }

  const onReportStateFilterMenuClose = () => {
    setReportStateFilterMenuAnchorEl(null);
  };

  const onReportStateFilterMenuApplyFilter = (filterName: string, filterValue: string | undefined) => {
    setReportStateFilterMenuAnchorEl(null);
    filterValue && dispatch(adminReportsActions.updateFilter({ property: filterName, value: filterValue as string }));
  };

  const onReportStateFilterMenuClearFilter = (filterName: string) => {
    setReportStateFilterMenuAnchorEl(null);
    dispatch(adminReportsActions.removeFilter({ property: filterName, value: '' }));
  };

  const onReportUserFilterMenuClose = () => {
    setReportUserFilterMenuAnchorEl(null);
  };

  const onReportUserFilterMenuApplyFilter = (filterName: string, filterValue: string | undefined) => {
    setReportUserFilterMenuAnchorEl(null);
    filterValue && dispatch(adminReportsActions.updateFilter({ property: filterName, value: filterValue as string }));
  };

  const onReportUserFilterMenuClearFilter = (filterName: string) => {
    setReportUserFilterMenuAnchorEl(null);
    dispatch(adminReportsActions.removeFilter({ property: filterName, value: '' }));
  };

  const onIsTestReportChange = (reportName: string, userName: string, isTestReport: boolean) => {
    dispatch(adminReportsActions.updateIsTestReport(reportName, userName, isTestReport));
  };

  const onReportIsTestReportFilterMenuApplyFilter = (filterName: string, filterValue: boolean | undefined) => {
    setReportIsTestReportFilterMenuAnchorEl(null);
    filterValue !== undefined && dispatch(adminReportsActions.updateFilter({ property: filterName, value: filterValue as boolean }));
  };

  const onReportIsTestReportFilterMenuClearFilter = (filterName: string) => {
    setReportIsTestReportFilterMenuAnchorEl(null);
    dispatch(adminReportsActions.removeFilter({ property: filterName, value: '' }));
  };

  const onReportIsTestReportFilterMenuClose = () => {
    setReportIsTestReportFilterMenuAnchorEl(null);
  };

  return (
    <div className={classes.container}>
      <Spinner show={isLoading} inline />
      <ExportFilter />
      <ReportStatusFilterMenu
        anchorEl={reportStatusFilterMenuAnchorEl}
        filterName={'status'}
        currentFilterValues={currentFilters.find(f => f.property === 'status')?.value as string[] || []}
        onClose={onReportStatusFilterMenuClose}
        onApplyFilter={onReportStatusFilterMenuApplyFilter} />
      <FilterMenu
        anchorEl={textFilterMenuAnchorEl}
        filterName={selectedTextFilterOption?.filter?.name || ''}
        currentFilterValue={''}
        label={`Filter by ${selectedTextFilterOption?.title}`}
        onClose={onTextFilterMenuClose}
        onApplyFilter={onApplyTextFilter}
        onClearFilter={onClearTextFilter} />
      <ReportStateFilterMenu
        anchorEl={reportStateFilterMenuAnchorEl}
        filterName={'state'}
        currentFilterValue={currentFilters.find(f => f.property === 'state')?.value as string || ''}
        onClose={onReportStateFilterMenuClose}
        onApplyFilter={onReportStateFilterMenuApplyFilter}
        onClearFilter={onReportStateFilterMenuClearFilter} />
      <ReportUserFilterMenu
        anchorEl={reportUserFilterMenuAnchorEl}
        filterName={'username'}
        currentFilterValue={currentFilters.find(f => f.property === 'username')?.value as string || ''}
        onClose={onReportUserFilterMenuClose}
        onApplyFilter={onReportUserFilterMenuApplyFilter}
        onClearFilter={onReportUserFilterMenuClearFilter} />
      <ReportIsTestReportFilterMenu
        anchorEl={reportIsTestReportFilterMenuAnchorEl}
        filterName={'istest'}
        currentFilterValue={currentFilters.find(f => f.property === 'istest')?.value as boolean || undefined}
        onClose={onReportIsTestReportFilterMenuClose}
        onApplyFilter={onReportIsTestReportFilterMenuApplyFilter}
        onClearFilter={onReportIsTestReportFilterMenuClearFilter}
      />
      <Table className={classes.table} data-testid={`${testid}__table`}>
        <TableHead data-testid={`${testid}__table-header`}>
          <TableRow>
            {headerOptions.map((option) => (
              <TableCell key={option.title} data-testid={option.testid}>
                {
                  option.filter &&
                  <IconButton
                    className={cx(classes.filterButton, {
                      [classes.activeFilter]:
                        currentFilters.find(f => f.property === option.filter.name)
                    })}
                    onClick={(event) => onFilterButtonClick(event, option)}
                    size='small'
                  >
                    <FilterList />
                  </IconButton>
                }
                {option.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            reports.map((report, index) => (
              <TableRow key={`${report.name}-${index}`}>
                <TableCell data-testid={report.name}>
                  <span className={classes.reportName}>{report.name}</span>
                </TableCell>
                <TableCell data-testid={report.updated}>
                  <Typography className={classes.basicCellText} data-testid={`${testid}__date`}>
                    {`${REPORT_STATUSES[report.status]?.dateText} ${dayjs(
                      report.updated
                    ).format('MM/DD/YYYY | h:mm a')}`}
                  </Typography>
                </TableCell>
                <TableCell data-testid={`admin-report-${report.name}__name`}>
                  <span className={classes.specialistName}>{report.user?.name}</span>
                  <span className={classes.specialistEmail}>{report.user?.email}</span>
                </TableCell>
                <TableCell data-testid={`admin-report-${report.name}__name-of-site`}>
                  <Typography className={classes.basicCellText} data-testid={`${testid}__date`}>
                    {report.siteName}
                  </Typography>
                </TableCell>
                <TableCell data-testid={`admin-report-${report.name}__site-state`}>
                  <Typography className={classes.basicCellText} data-testid={`${testid}__date`}>
                    {US_STATES.find(st => st.id === report.state)?.value || ''}
                  </Typography>
                </TableCell>
                <TableCell data-testid={`admin-report-${report.name}__site-is-test-report`}>
                  <Typography className={classes.basicCellText} data-testid={`${testid}__date`}>
                    <Checkbox
                      color='primary'
                      checked={report.isTestReport}
                      onChange={() => onIsTestReportChange(report.name, report.username, !report.isTestReport)}
                    />
                  </Typography>
                </TableCell>
                <TableCell data-testid={`admin-report-${report.name}__status`}>
                  <div
                    className={cx(classes.status, {
                      [classes.submittedStatus]: report.status === REPORT_STATUS.SUBMITTED,
                      [classes.finishedStatus]: report.status === REPORT_STATUS.FINISHED,
                      [classes.pendingStatus]: report.status === REPORT_STATUS.PENDING,
                    })}
                  >
                    <Typography
                      className={classes.statusText}
                      data-testid={`${testid}__status`}
                    >
                      {REPORT_STATUSES[report.status].status}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell data-testid={`admin-report-${report.name}__options`}>
                  <IconButton
                    onClick={e => onMenuOpen(e, report)}
                    className={classes.optionsButton}
                    data-testid={`admin-report-${report.name}_more-options-btn`}
                  >
                    <MoreVert />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      {
        reports?.map((report, idx) => (
          <div key={idx} className={classes.reportWrapper}>
            <Report
              id={report.name}
              report={report.summary.report}
              interventionsChecklist={report.interventionsChecklist}
              isFinished={report.status === REPORT_STATUS.FINISHED}
              lastDateUpdated={report.updatedISO}
              titleText={report.siteName || StringFormatter.getStateName(report.state)}
            />
          </div>
        ))
      }
      {!isLoading && !reports.length && (
        <Typography
          className={classes.noResults}
          data-testid={`${testid}__no-results-found`}
        >
          We're sorry, no results found.
        </Typography>
      )}
      {lastEvaluatedKey && !isLoading && (
        <Button
          fullWidth
          className={classes.moreBtn}
          onClick={() => dispatch(adminReportsActions.getReports())}
          data-testid={`${testid}__load-more`}
        >
          Load more
        </Button>
      )}
      <Menu
        anchorEl={reportMenuAnchorEl}
        setAnchorEl={setReportMenuAnchorEl}
        options={reportMenuOptions || []}
      />
    </div>
  );
};

export default withStyles(styles)(CalculationsComponent);