import {
  createStyles,
  StyleRules,
  ThemeOptions,
} from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';
import { Colors, FontWeight } from '../../../assets/styles';

export default (): StyleRules =>
  createStyles({
    content: {},
    input: {
      border: `solid 1px ${Colors.Gray2}`,
      backgroundColor: Colors.White,
      borderRadius: 4,
      height: 42,
      paddingLeft: 17,
    },
    inputError: {
      borderColor: Colors.Red,
    },
    helperText: {
      fontWeight: FontWeight.Regular,
      fontSize: 14,
    },
    error: {
      borderColor: Colors.Red,
    },
  });

const theme = {
  typography: {
    fontFamily: ['Museo Sans', 'sans-serif'].join(','),
    fontWeightLight: FontWeight.Light,
    fontWeightRegular: FontWeight.Regular,
    fontWeightBold: FontWeight.Bold,
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: Colors.Blue1,
      },
    },
    MuiDialogActions: {
      root: {
        '& button': {
          color: Colors.Blue1,
        },
        '& button:last-child': {
          borderRadius: '22.5px',
          backgroundColor: Colors.Blue1,
          color: Colors.White,
          fontWeight: FontWeight.Bold,
          '&:hover': {
            backgroundColor: Colors.Blue,
          },
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 14,
        position: 'absolute',
        bottom: -26,
      },
    },
    MuiFormControl: {
      root: {
        margin: '0 !important',
      },
    },
    MuiInputAdornment: {
      root: {
        margin: '0 !important',
      },
    },
  },
};

export const datePickerTheme = createMuiTheme(theme as ThemeOptions);
