import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../assets/styles';

export default ({ typography, breakpoints }: Theme): StyleRules => {
  const { pxToRem } = typography;

  return createStyles({
    container: {},
    title: {
      textAlign: 'center',
      margin: `0 auto ${pxToRem(22)}`,
    },
    table: {
      width: '100%',
      backgroundColor: Colors.White,
      border: `1px solid ${Colors.Gray1}`,
    },
    name: {
      color: Colors.Blue1,
      display: 'block',
    },
    email: {
      fontSize: 13,
    },
  });
};
