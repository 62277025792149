import { IFilter } from "./filter.interface";
import { Constants } from '../utils';

const { REPORT_STATUS } = Constants;

export const defaultFilter = {
  property: 'status',
  value: [REPORT_STATUS.PENDING, REPORT_STATUS.FINISHED]
};

export interface IAdminReports {
  reports: any[];
  lastPageReportsLoaded: boolean;
  lastEvaluatedKey: object | undefined;
  filters: IFilter[],
  isLoading: boolean;
  pageSize: number;
}

export const adminReportsState: IAdminReports = {
  reports: [],
  lastPageReportsLoaded: false,
  lastEvaluatedKey: undefined,
  filters: [defaultFilter],
  isLoading: false,
  pageSize: 20,
};