import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import {
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import styles from './ResourceItem.styles';
import resourceDownloadRequest from '../../../services/resources.service';
import { IResource } from '../../../interfaces/resource.interface';

type props = WithStylesType<typeof styles> & {
  resource: IResource;
};

const ResourceItem: FunctionComponent<props> = ({ classes, resource }) => {
  const { directDownload, path, label } = resource;
  const handleClick = async () => {
    if (directDownload) {
      await resourceDownloadRequest(path);
    } else {
      window.open(path, '_blank');
    }
  };
  return (
    <div className={classes.root} onClick={handleClick}>
      <ListItem button>
        <ListItemIcon>
          <AssignmentReturnedIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary={label} className={classes.label} />
      </ListItem>
    </div>
  );
};

export default withStyles(styles)(ResourceItem);