import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { adminReportsActions } from '../../../../redux/adminReportsSlice';
import { Dialog, Select } from '../../../../components';
import { Constants } from '../../../../utils';
import styles from './CalculationsComponent.styles';

const { US_STATES } = Constants;
const SELECTED_STATES = {
  ONE: 'selected_state',
  ALL: 'all_states',
};

type Props = WithStylesType<typeof styles> & {};

const ExportFilterComponent: FunctionComponent<Props> = ({ classes }) => {
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const [filterStateValue, setFilterStateValue] = useState('');
  const [filterOption, setFilterOptionValue] = useState(SELECTED_STATES.ALL);
  const dispatch = useDispatch();

  useEffect(() => () => {
    setFilterOptionValue(SELECTED_STATES.ALL);
    setFilterStateValue('');
  }, [isExportDialogOpen]);

  const onChangeOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value }} = e;
    if (value === SELECTED_STATES.ALL) {
      setFilterStateValue('');
    }
    setFilterOptionValue(value);
  };

  const onCloseDialog =() => {
    setIsExportDialogOpen(false);
  };

  const exportFisnishedCalculations = () => {
    dispatch(adminReportsActions.exportFinishedCalculations(filterStateValue));
  };

  return (
    <>
      <div className={classes.controlsContainer}>
        <Button
          onClick={() => setIsExportDialogOpen(true)}
          className={classes.exportButton}
        >
          <GetApp />Export Finished Calculations
        </Button>
      </div>
      
      <Dialog
        id="calculations__filter-export"
        title="Export Finished Calculations"
        open={isExportDialogOpen}
        onClose={onCloseDialog}
        onSubmitBtnClick={exportFisnishedCalculations}
        submitBtnText="Export"
        isSubmitBtnDisabled={filterOption === SELECTED_STATES.ONE && filterStateValue === ''}
        customClass={classes.filterContent}
      >
        <Typography>Export data from:</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="role" 
            name="role"
            value={filterOption}
            onChange={onChangeOption}
          >
            <FormControlLabel
              key={SELECTED_STATES.ALL}
              value={SELECTED_STATES.ALL}
              label="All States"
              control={<Radio color="primary" />}
              className={classes.radioOption}
            />
            <FormControlLabel
              key={SELECTED_STATES.ONE}
              value={SELECTED_STATES.ONE}
              label={(
                <div className={classes.composedSelect}>
                  An Specific State
                  <Select
                    id="state_export"
                    name="state_export"
                    placeholder="- Select State -"
                    options={US_STATES}
                    customClass={classes.select}
                    value={filterStateValue}
                    onChange={(e) => setFilterStateValue(e.target.value as string)}
                    disabled={filterOption === SELECTED_STATES.ALL}
                  />
                </div>)}
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </FormControl>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(ExportFilterComponent);