import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors } from '../../assets/styles';

export default (): StyleRules =>
  createStyles({
    root: {
      '& ul': {
        padding: 0,
      },
    },
    input: {
      width: 200,
    },
    applyFilterBtn: {
      color: Colors.Green,
    },
  });
