import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors } from '../../../../assets/styles';

export default (): StyleRules => {
  return createStyles({
    container: {
      paddingBottom: 15,
      paddingTop: 20,
      '@media print': {
        paddingTop: '40px',
      },
    },
    alignRight: {
      textAlign: 'right',
    },
    contentTitle: {
      fontSize: 15,
      marginBottom: 24,
    },
    contentSubTitle: {
      fontSize: 14,
      color: Colors.Blue,
      display: 'block',
      marginBottom: 10,
    },
    calculatedItems: {
      fontSize: 14,
      color: Colors.Gray,
      paddingLeft: '5%',
      marginBottom: '16px',
      lineHeight: '20px',
    },
    leftHalf: {
      paddingLeft: '10%',
    },
    rightHalf: {
      textAlign: 'center',
      marginTop: '20px',
      padding: '0 20px',
    },
    headerBanner: {
      fontSize: 14,
      color: Colors.Blue,
      padding: '0 10%',
      textAlign: 'left',
      marginTop: '40px',
    },
    divider: {
      background: Colors.Red,
      marginBottom: '15px',
      marginTop: '15px',
    },
    hsLogo: {
      height: 140,
      width: 'auto',
      margin: '0 auto',
    },
    headerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    leftPadding: {
      paddingLeft: '35px',
    },
  });
};
