import React, {
  FunctionComponent,
  ComponentType,
  useLayoutEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { selectIsAuthorized } from '../../../redux/authSlice';
import paths from '../../paths';

interface IProps {
  component: ComponentType;
}

const PublicRoute: FunctionComponent<IProps> = ({
  component: Component,
  ...props
}) => {
  const isAuthorized = useSelector(selectIsAuthorized);
  const history = useHistory();

  useLayoutEffect(() => {
    if (isAuthorized) {
      history.push(paths.calculatorDashboard);
    }
  }, [isAuthorized, history]);

  return <Route render={() => <Component />} {...props} />;
};

export default PublicRoute;
