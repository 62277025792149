import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import {
  Select as MUiSelect,
  FormControl,
  MenuItem,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import cx from 'classnames';
import styles from './Select.styles';

interface IProps extends WithStylesType<typeof styles> {
  id: string;
  name: string;
  placeholder: string;
  options: { id: string; value: string }[];
  labelText?: string;
  value?: string;
  onChange?: (
    event: React.ChangeEvent<{
      id?: string | undefined;
      value: unknown;
    }>
  ) => void;
  customClass?: string;
  errorMessage?: string;
  editable?: boolean;
  disabled?: boolean;
}

const Select: FunctionComponent<IProps> = ({
  classes,
  id,
  name,
  placeholder,
  options,
  labelText,
  value,
  onChange,
  customClass = '',
  errorMessage,
  editable = true,
  disabled = false,
}) => {
  const displayedValue = options.find((option) => option.id === value)?.value;

  return (
    <FormControl fullWidth className={cx({ [customClass]: customClass })}>
      <label
        className={cx(classes.label, {
          [classes.labelError]: errorMessage,
        })}
        htmlFor={id}
      >
        {labelText}
      </label>
      {editable ? (
        <>
          <MUiSelect
            disableUnderline
            name={name}
            IconComponent={UnfoldMore}
            value={value}
            onChange={onChange}
            displayEmpty
            renderValue={() => {
              if (displayedValue) {
                return <>{displayedValue}</>;
              }
              return <span className={classes.placeholder}>{placeholder}</span>;
            }}
            classes={{
              root: cx(classes.selectWrapper, {
                [classes.inputError]: errorMessage,
              }),
              icon: classes.selectIcon,
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            disabled={disabled}
          >
            {options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </MUiSelect>
          {errorMessage && (
            <FormHelperText classes={{ root: classes.error }} error>
              {errorMessage}
            </FormHelperText>
          )}
        </>
      ) : (
        <Typography className={classes.text}>{displayedValue}</Typography>
      )}
    </FormControl>
  );
};

export default withStyles(styles)(Select);
