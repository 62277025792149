import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';

export default ({ typography }: Theme): StyleRules => {
  const { pxToRem } = typography;
  return createStyles({
    title: {
      textAlign: 'center',
      margin: `0 auto ${pxToRem(22)}`,
    },
  });
};
