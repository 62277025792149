import ServiceData from './serviceData';
import Calculator from './shared/base';

export default class OralHealth extends Calculator {
  public serviceData: ServiceData;

  constructor({
    state,
    serviceData,
    lowEstimatedSlider,
    highEstimatedSlider,
  }: {
    state: string;
    serviceData: ServiceData;
    lowEstimatedSlider: number;
    highEstimatedSlider: number;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.lowEstimatedSlider = lowEstimatedSlider / 100;
    this.highEstimatedSlider = highEstimatedSlider / 100;
  }

  /**
   * @name dental_tab_percentage_of_children_with_first_dental_visit_after_age_3
   * @excel Excel Dental Tab B4
   * @constant 60% (0.6)
   */
  public readonly dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3 = this
    .constants.oralHealth
    .dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3;

  /**
   * @name dental_tab_late_starters_spent_more_over_eight_years_of_follow_up
   * @constant 360
   * @excel Excel Dental Tab B5
   */
  public readonly dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp = this
    .constants.oralHealth
    .dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp;

  /**
   * @name dental_tab_medical_price_inflaction
   * @constant 1.20
   * @excel Excel Dental Tab B6
   */
  public readonly dentalTabMedicalPriceInflaction = this.constants.oralHealth
    .dentalTabMedicalPriceInflaction;

  /**
   * @name percentage_of_children_with_first_dental_visit_after_age_3
   * @excel Excel C14
   * @reference dental_tab_percentage_of_children_with_first_dental_visit_after_age_3
   */
  public percentageOfChildrenWithFirstDentalVisitAfterAge3 = () => {
    return this.isFiniteNumber(
      this.dentalTabPercentageOfChildrenWithFirstDentalVisitAfterAge3
    );
  };

  /**
   * @name number_of_hs_medicaid_children_who_would_have_first_dental_visit_after_age_3
   * @excel Excel C15
   * @formula percentage_of_children_with_first_dental_visit_after_age_3 * cs_input_4 (from Service Data Screen)
   */
  public numberOfHsMedicaidChildrenWhoWouldHaveFirstDentalVisitAfterAge3 = () => {
    return this.isFiniteNumber(
      this.percentageOfChildrenWithFirstDentalVisitAfterAge3() *
        this.serviceData.csInput4
    );
  };

  /**
   * low_estimated_slider (from app)
   * @name low_estimated_slider
   * @excel Excel C16
   */
  public lowEstimatedSlider;

  /**
   * high_estimated_slider (from app)
   * @name high_estimated_slider
   * @excel Excel C17
   */
  public highEstimatedSlider;

  /**
   * @name estimated_number_of_late_starters_who_convert_to_early_starters_due_to_hs_low
   * @excel Excel C18
   * @formula low_estimated_slider * number_of_hs_medicaid_children_who_would_have_first_dental_visit_after_age_3
   */
  public estimatedNumberOfLateStartersWhoConvertToEarlyStartersDueToHsLow = () => {
    return this.isFiniteNumber(
      this.lowEstimatedSlider *
        this.numberOfHsMedicaidChildrenWhoWouldHaveFirstDentalVisitAfterAge3()
    );
  };

  /**
   * @name estimated_number_of_late_starters_who_convert_to_early_starters_due_to_hs_high
   * @excel Excel C19
   * @formula high_estimated_slider * number_of_hs_medicaid_children_who_would_have_first_dental_visit_after_age_3
   */
  public estimatedNumberOfLateStartersWhoConvertToEarlyStartersDueToHsHigh = () => {
    return this.isFiniteNumber(
      this.highEstimatedSlider *
        this.numberOfHsMedicaidChildrenWhoWouldHaveFirstDentalVisitAfterAge3()
    );
  };

  /**
   * @name savings_per_child_via_lower_dental_spending_from_being_an_early_starter
   * @excel Excel C20
   * @formula dental_tab_late_starters_spent_more_over_eight_years_of_follow_up * dental_tab_medical_price_inflaction
   */
  public savingsPerChildViaLowerDentalSpendingFromBeingAnEarlyStarter = () => {
    return this.isFiniteNumber(
      this.dentalTabLateStartersSpentMoreOverEightYearsOfFollowUp *
        this.dentalTabMedicalPriceInflaction
    );
  };

  /**
   * @name dental_estimated_saving_low
   * @excel Excel E13
   * @formula savings_per_child_via_lower_dental_spending_from_being_an_early_starter * estimated_number_of_late_starters_who_convert_to_early_starters_due_to_hs_low
   */
  public dentalEstimatedSavingLow = () => {
    return this.isFiniteNumber(
      this.savingsPerChildViaLowerDentalSpendingFromBeingAnEarlyStarter() *
        this.estimatedNumberOfLateStartersWhoConvertToEarlyStartersDueToHsLow()
    );
  };

  /**
   * @name dental_estimated_saving_high
   * @excel Excel F13
   * @formula savings_per_child_via_lower_dental_spending_from_being_an_early_starter * estimated_number_of_late_starters_who_convert_to_early_starters_due_to_hs_high
   */
  public dentalEstimatedSavingHigh = () => {
    return this.isFiniteNumber(
      this.savingsPerChildViaLowerDentalSpendingFromBeingAnEarlyStarter() *
        this.estimatedNumberOfLateStartersWhoConvertToEarlyStartersDueToHsHigh()
    );
  };
}
