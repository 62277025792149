import React, { FunctionComponent, ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Constants } from '../../../utils';
import { IRoute } from '../../../interfaces/router.interface';
import { selectIsAuthorized, selectUser } from '../../../redux/authSlice';
import paths from '../../paths';

interface IProps extends IRoute {
  component: ComponentType;
}

const PrivateRoute: FunctionComponent<IProps> = ({
  component: Component,
  path,
  childRoutes,
  childRoutesElements,
  admin,
}) => {
  const isAuthorized = useSelector(selectIsAuthorized);
  const user = useSelector(selectUser);

  const renderRoute = () => (
    <Component>
      {childRoutesElements && (
        <Switch>
          {/* <Route
            exact
            path={path}
            render={() => (
              <Redirect to={childRoutes ? childRoutes[0].path : path} />
            )}
          /> */}
          {childRoutesElements.map((element) => element)}
        </Switch>
      )}
    </Component>
  );

  return (
    <Route
      path={path}
      render={(hola) => {
        if (isAuthorized) {
          if (admin) {
            if (user && user['custom:role'] === Constants.USER_ROLES.admin) {
              return renderRoute();
            }

            return <Redirect to={paths.signIn} />;
          }

          return renderRoute();
        }

        return <Redirect to={paths.signIn} />;
      }}
    />
  );
};

export default PrivateRoute;
