import { createStyles, StyleRules } from '@material-ui/core/styles';
import { FontWeight, Colors } from '../../../../../assets/styles';

export default (): StyleRules =>
  createStyles({
    name: {
      color: Colors.Blue1,
      display: 'block',
    },
    email: {
      fontSize: 13,
    },
    lastCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    centeredContent: {
      textAlign: 'center',
    },
    checked: {
      '&&': {
        color: Colors.Blue1,
      },
    },
    activeStatus: {
      fontWeight: FontWeight.Bold,
      color: Colors.Green1,
    },
    inactiveStatus: {
      fontWeight: FontWeight.Bold,
      color: Colors.Gray6,
    },
    pendingStatus: {
      fontWeight: FontWeight.Bold,
      color: Colors.Orange,
    },
  });
