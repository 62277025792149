import EmergencyDepartment from './emergencyDepartment';
import ServiceData from './serviceData';
import Calculator from './shared/base';

export default class Breastfeeding extends Calculator {
  public serviceData: ServiceData;

  public emergencyDepartment: EmergencyDepartment;

  constructor({
    state,
    serviceData,
    emergencyDepartment,
    sliderComponentContinuedBreastfeedingLow,
    sliderComponentContinuedBreastfeedingHigh,
    sliderComponentContinuedExclusiveBreastfeedingLow,
    sliderComponentContinuedExclusiveBreastfeedingHigh,
    input1TotalNumberOfHsMothersWhoDiscussedANeed,
  }: {
    state: string;
    serviceData: ServiceData;
    emergencyDepartment: EmergencyDepartment;
    sliderComponentContinuedBreastfeedingLow: number;
    sliderComponentContinuedBreastfeedingHigh: number;
    sliderComponentContinuedExclusiveBreastfeedingLow: number;
    sliderComponentContinuedExclusiveBreastfeedingHigh: number;
    input1TotalNumberOfHsMothersWhoDiscussedANeed: number;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.emergencyDepartment = emergencyDepartment;
    this.sliderComponentContinuedBreastfeedingLow =
      sliderComponentContinuedBreastfeedingLow / 100;
    this.sliderComponentContinuedBreastfeedingHigh =
      sliderComponentContinuedBreastfeedingHigh / 100;
    this.sliderComponentContinuedExclusiveBreastfeedingLow =
      sliderComponentContinuedExclusiveBreastfeedingLow / 100;
    this.sliderComponentContinuedExclusiveBreastfeedingHigh =
      sliderComponentContinuedExclusiveBreastfeedingHigh / 100;
    this.input1TotalNumberOfHsMothersWhoDiscussedANeed = input1TotalNumberOfHsMothersWhoDiscussedANeed;
  }

  // NOTE: Breastfeeding Calculations Are Based On New Massachussets Spreadsheet

  /**
   * @name breastfeeding_tab_reduction_in_otitis_media_episodes_less_than_age_1_with_any_breastfeeding
   * @excel Excel Breastfeeding Tab B5
   * @constant 0.44
   */
  public readonly breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding = this
    .constants.breastfeeding
    .breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding;

  /**
   * @name breastfeeding_tab_reduction_in_otitis_media_episodes_less_than_age_1_with_exclusive_breastfeeding_for_three_months
   * @excel Excel Breastfeeding Tab B6
   * @constant 0.95
   */
  public readonly breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths = this
    .constants.breastfeeding
    .breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths;

  /**
   * @name breastfeeding_tab_rate_of_hospital_discharges_per_100000_related_to_infection_in_first_year_of_life
   * @excel Excel Breastfeeding Tab B9
   * @constant 443
   */
  public readonly breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife = this
    .constants.breastfeeding
    .breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife;

  /**
   * @name breastfeeding_tab_reduction_in_hospital_discharges_related_to_infection_in_first_year_of_life_with_any_increase_in_breastfeeding
   * @excel Excel Breastfeeding Tab B10
   * @constant 0.04
   */
  public readonly breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding = this
    .constants.breastfeeding
    .breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding;

  /**
   * @name breastfeeding_tab_reduction_in_hospital_discharges_related_to_infection_in_first_year_of_life_with_exclusive_breastfeeding
   * @excel Excel Breastfeeding Tab B11
   * @constant 0.12
   */
  public readonly breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding = this
    .constants.breastfeeding
    .breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding;

  /**
   * @name breastfeeding_tab_median_cost_of_hospitalization_for_infection_in_the_first_year_of_life
   * @excel Excel Breastfeeding Tab B12
   * @constant 16543
   */
  public readonly breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife = this
    .constants.breastfeeding
    .breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife;

  /**
   * @name breastfeeding_tab_medical_price_inflation_factor_2017_2019
   * @excel Excel Breastfeeding Tab B13
   * @constant 1.05
   */
  public readonly breastfeedingTabMedicalPriceInflationFactor20172019 = this
    .constants.breastfeeding
    .breastfeedingTabMedicalPriceInflationFactor20172019;

  /**
   * Missing references
   */
  public input1TotalNumberOfHsMothersWhoDiscussedANeed;

  /**
   * @name total_number_of_HS_mothers_who_discussed_a_need_for_breastfeeding_advice_assistance_with_HSS_in_past_year
   * @excel Excel C121
   * @reference input_1_total_number_of_hs_mothers_who_discussed_a_need (from app) (REQUIRED)
   */
  public totalNumberOfHSMothersWhoDiscussedANeedForBreastfeedingAdviceAssistanceWithHSSInPastYear = () => {
    return this.isFiniteNumber(
      this.input1TotalNumberOfHsMothersWhoDiscussedANeed
    );
  };

  /**
   * @name reduction_in_otitis_media_episodes_per_child_less_than_age_1_by_shifting_from_any_breastfeeding_to_exclusive_breastfeeding_for_3_months
   * @excel Excel C125
   * @formula breastfeeding_tab_reduction_in_otitis_media_episodes_less_than_age_1_with_exclusive_breastfeeding_for_three_months - breastfeeding_tab_reduction_in_otitis_media_episodes_less_than_age_1_with_any_breastfeeding
   */
  public reductionInOtitisMediaEpisodesPerChildLessThanAge1ByShiftingFromAnyBreastfeedingToExclusiveBreastfeedingFor3Months = () => {
    return this.isFiniteNumber(
      this
        .breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithExclusiveBreastfeedingForThreeMonths -
        this
          .breastfeedingTabReductionInOtitisMediaEpisodesLessThanAge1WithAnyBreastfeeding
    );
  };

  /**
   * (App)
   * @name component_slider_from_app
   */
  public sliderComponentContinuedBreastfeedingLow;

  /**
   * @name estimated_percentage_of_HS_mothers_who_discussed_need_for_breastfeeding_advice_assistance_and_continued_breastfeeding_due_to_HS_but_did_not_breastfeed_exclusively_low
   * @excel Excel C126
   * @reference slider_component_continued_breastfeeding_low (from app) (REQUIRED)
   */
  public estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedBreastfeedingDueToHSButDidNotBreastfeedExclusivelyLow = () => {
    return this.isFiniteNumber(this.sliderComponentContinuedBreastfeedingLow);
  };

  /**
   * (App)
   * @name slider_component_continued_breastfeeding_high
   */
  public sliderComponentContinuedBreastfeedingHigh;

  /**
   * @name estimated_percentage_of_HS_mothers_who_discussed_need_for_breastfeeding_advice_assistance_and_continued_breastfeeding_due_to_HS_but_did_not_breastfeed_exclusively_high
   * @excel Excel C127
   * @reference slider_component_continued_breastfeeding_high (from app) (REQUIRED)
   */
  public estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedBreastfeedingDueToHSButDidNotBreastfeedExclusivelyHigh = () => {
    return this.isFiniteNumber(this.sliderComponentContinuedBreastfeedingHigh);
  };

  /**
   * (App)
   * @name slider_component_continued_exclusive_breastfeeding_low
   */
  public sliderComponentContinuedExclusiveBreastfeedingLow;

  /**
   * @name estimated_percentage_of_HS_mothers_who_discussed_need_for_breastfeeding_advice_assistance_and_continued_exclusive_breastfeeding_due_to_HS_low
   * @excel Excel C128
   * @reference slider_component_continued_exclusive_breastfeeding_low (from app) (REQUIRED)
   */
  public estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedExclusiveBreastfeedingDueToHSLow = () => {
    return this.isFiniteNumber(
      this.sliderComponentContinuedExclusiveBreastfeedingLow
    );
  };

  /**
   * (App)
   * @name slider_component_continued_exclusive_breastfeeding_high
   */
  public sliderComponentContinuedExclusiveBreastfeedingHigh;

  /**
   * @name estimated_percentage_of_HS_mothers_who_discussed_need_for_breastfeeding_advice_assistance_and_continued_exclusive_breastfeeding_due_to_HS_high
   * @excel Excel C129
   * @reference slider_component_continued_exclusive_breastfeeding_high (from app) (REQUIRED)
   */
  public estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedExclusiveBreastfeedingDueToHSHigh = () => {
    return this.isFiniteNumber(
      this.sliderComponentContinuedExclusiveBreastfeedingHigh
    );
  };

  /**
   * @name estimated_number_of_breastfeeding_children_with_averted_otitis_media_and_medical_visit_due_to_HS_low
   * @excel Excel C130
   * @formula number_of_HS_mothers_who_discussed_a_need_for_breastfeeding_advice_assistance_with_HSS_in_past_year * reduction_in_otitis_media_episodes_per_child_less_than_age_1_by_shifting_from_any_breastfeeding_to_exclusive_breastfeeding_for_3_months * estimated_percentage_of_HS_mothers_who_discussed_need_for_breastfeeding_advice_assistance_and_continued_breastfeeding_due_to_HS_but_did_not_breastfeed_exclusively_low
   */
  public estimatedNumberOfBreastfeedingChildrenWithAvertedOtitisMediaAndMedicalVisitDueToHSLow = () => {
    return this.isFiniteNumber(
      this.totalNumberOfHSMothersWhoDiscussedANeedForBreastfeedingAdviceAssistanceWithHSSInPastYear() *
        this.reductionInOtitisMediaEpisodesPerChildLessThanAge1ByShiftingFromAnyBreastfeedingToExclusiveBreastfeedingFor3Months() *
        this.estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedBreastfeedingDueToHSButDidNotBreastfeedExclusivelyLow()
    );
  };

  /**
   * @name estimated_number_of_breastfeeding_children_with_averted_otitis_media_and_medical_visit_due_to_HS_high
   * @excel Excel C131
   * @formula number_of_HS_mothers_who_discussed_a_need_for_breastfeeding_advice_assistance_with_HSS_in_past_year * reduction_in_otitis_media_episodes_per_child_less_than_age_1_by_shifting_from_any_breastfeeding_to_exclusive_breastfeeding_for_3_months * estimated_percentage_of_HS_mothers_who_discussed_need_for_breastfeeding_advice_assistance_and_continued_breastfeeding_due_to_HS_but_did_not_breastfeed_exclusively_high
   */
  public estimatedNumberOfBreastfeedingChildrenWithAvertedOtitisMediaAndMedicalVisitDueToHSHigh = () => {
    return this.isFiniteNumber(
      this.totalNumberOfHSMothersWhoDiscussedANeedForBreastfeedingAdviceAssistanceWithHSSInPastYear() *
        this.reductionInOtitisMediaEpisodesPerChildLessThanAge1ByShiftingFromAnyBreastfeedingToExclusiveBreastfeedingFor3Months() *
        this.estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedBreastfeedingDueToHSButDidNotBreastfeedExclusivelyHigh()
    );
  };

  /**
   * (from Emergency Department Use)
   * savings_per_averted_ed_visit_that_is_not_replaced_with_an_office_visit
   * savings_per_averted_office_visit
   * savings_per_averted_fqhc_visit
   * @name savings_per_averted_office_FQHC_ED_visit
   * @excel Excel C132
   * @formula ( savings_per_averted_ed_visit_that_is_not_replaced_with_an_office_visit * 0.5 ) + ( AVERAGE(savings_per_averted_office_visit, savings_per_averted_fqhc_visit) * 0.5)
   */
  public savingsPerAvertedOfficeFQHCEDVisit = () => {
    return this.isFiniteNumber(
      this.emergencyDepartment.savingsPerAvertedEdVisitThatIsNotReplacedWithAnOfficeVisit() *
        0.5 +
        ((this.emergencyDepartment.savingsPerAvertedOfficeVisit() +
          this.emergencyDepartment.savingsPerAvertedFqhcVisit()) /
          2) *
          0.5
    );
  };

  /**
   * @name estimated_savings_due_to_averted_otitis_media_and_medical_visit_due_to_HS_low
   * @excel Excel C133
   * @formula estimated_number_of_breastfeeding_children_with_averted_otitis_media_and_medical_visit_due_to_HS_low * savings_per_averted_office_FQHC_ED_visit
   */
  public estimatedSavingsDueToAvertedOtitisMediaAndMedicalVisitDueToHSLow = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfBreastfeedingChildrenWithAvertedOtitisMediaAndMedicalVisitDueToHSLow() *
        this.savingsPerAvertedOfficeFQHCEDVisit()
    );
  };

  /**
   * @name estimated_savings_due_to_averted_otitis_media_and_medical_visit_due_to_HS_high
   * @excel Excel C134
   * @formula  estimated_number_of_breastfeeding_children_with_averted_otitis_media_and_medical_visit_due_to_HS_high * savings_per_averted_office_FQHC_ED_visit
   */
  public estimatedSavingsDueToAvertedOtitisMediaAndMedicalVisitDueToHSHigh = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfBreastfeedingChildrenWithAvertedOtitisMediaAndMedicalVisitDueToHSHigh() *
        this.savingsPerAvertedOfficeFQHCEDVisit()
    );
  };

  /**
   * @name proportion_of_HealthySteps_Medicaid_Tier_2_and_Tier_3_caseload_in_the_0_1_age_range
   * @excel Excel C135
   * @reference perc_children_under_age_one (from Overview Data Screen )
   */
  public proportionOfHealthyStepsMedicaidTier2AndTier3CaseloadInThe01AgeRange = () => {
    return this.isFiniteNumber(this.serviceData.percChildrenUnderAgeOne());
  };

  /**
   * cs_input_4 comes from Overview Data Screen
   * @name expected_number_of_hospitalizations_for_infection_in_the_01_age_range_absent_intervention
   * @excel Excel C136
   * @reference (cs_input_4 * proportion_of_HealthySteps_Medicaid_Tier_2_and_Tier_3_caseload_in_the_0_1_age_range * breastfeeding_tab_rate_of_hospital_discharges_per_100000_related_to_infection_in_first_year_of_life ) / 100000
   */
  public expectedNumberOfHospitalizationsForInfectionInThe01AgeRangeAbsentIntervention = () => {
    return this.isFiniteNumber(
      (this.serviceData.csInput4 *
        this.proportionOfHealthyStepsMedicaidTier2AndTier3CaseloadInThe01AgeRange() *
        this
          .breastfeedingTabRateOfHospitalDischargesPer100000RelatedToInfectionInFirstYearOfLife) /
        100000
    );
  };

  /**
   * @name expected_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_nonexclusive_breastfeeding_low
   * @excel Excel C137
   * @reference expected_number_of_hospitalizations_for_infection_in_the_01_age_range_absent_intervention * breastfeeding_tab_reduction_in_hospital_discharges_related_to_infection_in_first_year_of_life_with_any_increase_in_breastfeeding * estimated_percentage_of_HS_mothers_who_discussed_need_for_breastfeeding_advice_assistance_and_continued_breastfeeding_due_to_HS_but_did_not_breastfeed_exclusively_low
   */
  public expectedReductionInNumberOfHospitalizationsForInfectionInThe01AgeRangeBasedOnIncreasesInNonexclusiveBreastfeedingLow = () => {
    return this.isFiniteNumber(
      this.expectedNumberOfHospitalizationsForInfectionInThe01AgeRangeAbsentIntervention() *
        this
          .breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithAnyIncreaseInBreastfeeding *
        this.estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedBreastfeedingDueToHSButDidNotBreastfeedExclusivelyLow()
    );
  };

  /**
   * @name expected_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_nonexclusive_breastfeeding_high
   * @excel Excel C138
   * @reference expected_number_of_hospitalizations_for_infection_in_the_01_age_range_absent_intervention * breastfeeding_tab_reduction_in_hospital_discharges_related_to_infection_in_first_year_of_life_with_exclusive_breastfeeding * estimated_percentage_of_HS_mothers_who_discussed_need_for_breastfeeding_advice_assistance_and_continued_breastfeeding_due_to_HS_but_did_not_breastfeed_exclusively_high
   */
  public expectedReductionInNumberOfHospitalizationsForInfectionInThe01AgeRangeBasedOnIncreasesInNonexclusiveBreastfeedingHigh = () => {
    return this.isFiniteNumber(
      this.expectedNumberOfHospitalizationsForInfectionInThe01AgeRangeAbsentIntervention() *
        this
          .breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding *
        this.estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedBreastfeedingDueToHSButDidNotBreastfeedExclusivelyHigh()
    );
  };

  /**
   * @name expected_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_exclusive_breastfeeding_low
   * @excel Excel C139
   * @reference expected_number_of_hospitalizations_for_infection_in_the_01_age_range_absent_intervention * breastfeeding_tab_reduction_in_hospital_discharges_related_to_infection_in_first_year_of_life_with_exclusive_breastfeeding * estimated_percentage_of_HS_mothers_who_discussed_need_for_breastfeeding_advice_assistance_and_continued_exclusive_breastfeeding_due_to_HS_low
   */
  public expectedReductionInNumberOfHospitalizationsForInfectionInThe01AgeRangeBasedOnIncreasesInExclusiveBreastfeedingLow = () => {
    return this.isFiniteNumber(
      this.expectedNumberOfHospitalizationsForInfectionInThe01AgeRangeAbsentIntervention() *
        this
          .breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding *
        this.estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedExclusiveBreastfeedingDueToHSLow()
    );
  };

  /**
   * @name expected_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_exclusive_breastfeeding_high
   * @excel Excel C140
   * @reference expected_number_of_hospitalizations_for_infection_in_the_01_age_range_absent_intervention * breastfeeding_tab_reduction_in_hospital_discharges_related_to_infection_in_first_year_of_life_with_exclusive_breastfeeding * estimated_percentage_of_HS_mothers_who_discussed_need_for_breastfeeding_advice_assistance_and_continued_exclusive_breastfeeding_due_to_HS_high
   */
  public expectedReductionInNumberOfHospitalizationsForInfectionInThe01AgeRangeBasedOnIncreasesInExclusiveBreastfeedingHigh = () => {
    return this.isFiniteNumber(
      this.expectedNumberOfHospitalizationsForInfectionInThe01AgeRangeAbsentIntervention() *
        this
          .breastfeedingTabReductionInHospitalDischargesRelatedToInfectionInFirstYearOfLifeWithExclusiveBreastfeeding *
        this.estimatedPercentageOfHSMothersWhoDiscussedNeedForBreastfeedingAdviceAssistanceAndContinuedExclusiveBreastfeedingDueToHSHigh()
    );
  };

  /**
   * @name expected_savings_related_to_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_nonexclusive_breastfeeding_low
   * @excel Excel C141
   * @reference expected_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_nonexclusive_breastfeeding_low * breastfeeding_tab_median_cost_of_hospitalization_for_infection_in_the_first_year_of_life * breastfeeding_tab_medical_price_inflation_factor_2017_2019
   */
  public expectedSavingsRelatedToReductionInNumberOfHospitalizationsForInfectionInThe01AgeRangeBasedOnIncreasesInNonexclusiveBreastfeedingLow = () => {
    return this.isFiniteNumber(
      this.expectedReductionInNumberOfHospitalizationsForInfectionInThe01AgeRangeBasedOnIncreasesInNonexclusiveBreastfeedingLow() *
        this
          .breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife *
        this.breastfeedingTabMedicalPriceInflationFactor20172019
    );
  };

  /**
   * @name expected_savings_related_to_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_nonexclusive_breastfeeding_low
   * @excel Excel C142
   * @reference expected_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_nonexclusive_breastfeeding_high() * breastfeeding_tab_median_cost_of_hospitalization_for_infection_in_the_first_year_of_life * this.breastfeeding_tab_medical_price_inflation_factor_2017_2019
   */
  public expectedSavingsRelatedToReductionInNumberOfHospitalizationsForInfectionInThe01AgeRangeBasedOnIncreasesInNonexclusiveBreastfeedingHigh = () => {
    return this.isFiniteNumber(
      this.expectedReductionInNumberOfHospitalizationsForInfectionInThe01AgeRangeBasedOnIncreasesInNonexclusiveBreastfeedingHigh() *
        this
          .breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife *
        this.breastfeedingTabMedicalPriceInflationFactor20172019
    );
  };

  /**
   * @name expected_savings_related_to_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_exclusive_breastfeeding_low
   * @excel Excel C143
   * @reference expected_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_exclusive_breastfeeding_low * breastfeeding_tab_median_cost_of_hospitalization_for_infection_in_the_first_year_of_life * breastfeeding_tab_medical_price_inflation_factor_2017_2019
   */
  public expectedSavingsRelatedToReductionInNumberOfHospitalizationsForInfectionInThe01AgeRangeBasedOnIncreasesInExclusiveBreastfeedingLow = () => {
    return this.isFiniteNumber(
      this.expectedReductionInNumberOfHospitalizationsForInfectionInThe01AgeRangeBasedOnIncreasesInExclusiveBreastfeedingLow() *
        this
          .breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife *
        this.breastfeedingTabMedicalPriceInflationFactor20172019
    );
  };

  /**
   * @name expected_savings_related_to_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_exclusive_breastfeeding_high
   * @excel Excel C144
   * @reference expected_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_exclusive_breastfeeding_high * breastfeeding_tab_median_cost_of_hospitalization_for_infection_in_the_first_year_of_life * breastfeeding_tab_medical_price_inflation_factor_2017_2019
   */
  public expectedSavingsRelatedToReductionInNumberOfHospitalizationsForInfectionInThe01AgeRangeBasedOnIncreasesInExclusiveBreastfeedingHigh = () => {
    return this.isFiniteNumber(
      this.expectedReductionInNumberOfHospitalizationsForInfectionInThe01AgeRangeBasedOnIncreasesInExclusiveBreastfeedingHigh() *
        this
          .breastfeedingTabMedianCostOfHospitalizationForInfectionInTheFirstYearOfLife *
        this.breastfeedingTabMedicalPriceInflationFactor20172019
    );
  };

  /**
   * @name breastfeeding_estimated_savings_low
   * @excel Excel E119
   * @reference estimated_savings_due_to_averted_otitis_media_and_medical_visit_due_to_HS_low + expected_savings_related_to_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_nonexclusive_breastfeeding_low + expected_savings_related_to_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_exclusive_breastfeeding_low
   */
  public breastfeedingEstimatedSavingsLow = () => {
    return this.isFiniteNumber(
      this.estimatedSavingsDueToAvertedOtitisMediaAndMedicalVisitDueToHSLow() +
        this.expectedSavingsRelatedToReductionInNumberOfHospitalizationsForInfectionInThe01AgeRangeBasedOnIncreasesInNonexclusiveBreastfeedingLow() +
        this.expectedSavingsRelatedToReductionInNumberOfHospitalizationsForInfectionInThe01AgeRangeBasedOnIncreasesInExclusiveBreastfeedingLow()
    );
  };

  /**
   * @name breastfeeding_estimated_savings_high
   * @excel Excel F119
   * @reference estimated_savings_due_to_averted_otitis_media_and_medical_visit_due_to_HS_high + expected_savings_related_to_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_nonexclusive_breastfeeding_high + expected_savings_related_to_reduction_in_number_of_hospitalizations_for_infection_in_the_01_age_range_based_on_increases_in_exclusive_breastfeeding_high
   */
  public breastfeedingEstimatedSavingsHigh = () => {
    return this.isFiniteNumber(
      this.estimatedSavingsDueToAvertedOtitisMediaAndMedicalVisitDueToHSHigh() +
        this.expectedSavingsRelatedToReductionInNumberOfHospitalizationsForInfectionInThe01AgeRangeBasedOnIncreasesInNonexclusiveBreastfeedingHigh() +
        this.expectedSavingsRelatedToReductionInNumberOfHospitalizationsForInfectionInThe01AgeRangeBasedOnIncreasesInExclusiveBreastfeedingHigh()
    );
  };
}
