import { createStyles, StyleRules } from '@material-ui/core/styles';

export default (): StyleRules =>
  createStyles({
    spinner: {
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999,
    },
    spinnerInline: {
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
  });
