import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import * as Yup from 'yup';
import { FormikErrors, useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { FormValidations, Constants } from '../../../utils';
import { AppThunkDispatch } from '../../../interfaces/redux.interface';
import { authActions, selectIsLoading } from '../../../redux/authSlice';
import { CircularButton, InputText, Spinner } from '../../../components';
import { paths } from '../..';
import styles from './SignInForm.styles';

const awsSignInError = 'The email or password you entered is incorrect';

interface FormValues {
  email: string;
  password: string;
  aws: boolean;
}

const testid = 'signin';

const SignInForm: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const dispatch: AppThunkDispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);

  const initialValues: FormValues = {
    email: '',
    password: '',
    aws: false,
  };

  const onSubmit = (
    values: FormValues,
    { setErrors }: { setErrors: (erros: FormikErrors<FormValues>) => void }
  ) => {
    setErrors({ aws: '' });

    dispatch(
      authActions.signIn({
        username: values.email.trim().toLowerCase(),
        password: values.password,
      })
    ).catch(() => setErrors({ aws: awsSignInError }));
  };

  const validationSchema = Yup.object().shape({
    email: FormValidations.email,
    password: FormValidations.passwordRequired,
  });

  const form = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <Spinner show={isLoading} />
      <InputText
        id="email"
        name="email"
        onChange={form.handleChange}
        value={form.values.email}
        labelText="Email Address"
        placeholder="Eg: julia@healthysteps.org"
        errorMessage={form.errors.email}
        customClass={classes.input}
      />
      <InputText
        id="password"
        name="password"
        type={Constants.INPUT_TYPES.PASSWORD}
        onChange={form.handleChange}
        value={form.values.password}
        labelText="Password"
        placeholder="Enter your password"
        errorMessage={form.errors.password}
        customClass={classes.input}
      />
      {form.errors.aws && (
        <Typography
          classes={{ root: classes.error }}
          data-testid={`${testid}__error`}
        >
          {form.errors.aws}
        </Typography>
      )}
      <div className={classes.forgotLinkWrapper}>
        <Link
          className={classes.forgotLink}
          to={paths.forgotPassword}
          data-testid={`${testid}__forgot-password`}
        >
          Forgot your password?
        </Link>
      </div>
      <CircularButton
        variant="contained"
        type="submit"
        color="primary"
        customClass={classes.submitButton}
        data-testid={`${testid}__submit`}
      >
        Sign in
      </CircularButton>
      <Link to="?request-access=true">
        <CircularButton
          variant="contained"
          type="button"
          color="secondary"
          customClass={classes.requestAccessButton}
          data-testid={`${testid}__request-account`}
        >
          Request an account
        </CircularButton>
      </Link>
    </form>
  );
};

export default withStyles(styles)(SignInForm);
