import React, { FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import { withStyles, WithStyles as WithStylesType } from '@material-ui/core/styles';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { Dialog } from '../../../../components';
import { IUser } from '../../../../interfaces/users.interface';
import { useDispatch } from 'react-redux';
import { usersActions } from '../../../../redux/usersSlice';
import { Constants, StringFormatter } from '../../../../utils';
import styles from './UserRoleDialog.styles';

const { USER_ROLES } = Constants;
const { capitalize } = StringFormatter;

type Props = WithStylesType<typeof styles> & {
  user: IUser;
  open: boolean;
  onSubmit: (user: IUser, role: string) => void;
  onCancel: () => void;
};

const UserRoleDialog: FunctionComponent<Props> = ({
  user,
  open,
  onSubmit,
  onCancel,
  classes,
}) => {
  const dispatch = useDispatch();
  const currentRole = user.role;
  const [role, setRole] = useState<string>(currentRole);

  const onRoleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.value);
  }

  const onUserRoleSubmit = () => {
    dispatch(usersActions.updateUserRole(user.username, role));
    onSubmit(user, role);
  };

  useEffect(() => {
    setRole(user.role);
  }, [user]);

  return (
    <Dialog
      id='update-role-user-dialog'
      title='Pick a role'
      open={open}
      customClass={classes.noteDialog}
      onSubmitBtnClick={onUserRoleSubmit}
      onCancelBtnClick={onCancel}
      isSubmitBtnDisabled={role === currentRole}
    >
      <FormControl component="fieldset">
        <RadioGroup aria-label="role" name="role" value={role} onChange={onRoleChange}>
          {
            (Object.values(USER_ROLES).map((r) => (
              <FormControlLabel
                key={r}
                value={r}
                disabled={r === currentRole}
                label={capitalize(r)}
                control={<Radio />}
              />
            )))
          }
        </RadioGroup>
      </FormControl>
    </Dialog>
  );
};

export default withStyles(styles)(UserRoleDialog);