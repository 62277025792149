import { createStyles, StyleRules } from '@material-ui/core/styles';

export default (): StyleRules =>
  createStyles({
    container: {
      minHeight: '100vh',
      display: 'flex',
      padding: '0 18px',
    },
  });
