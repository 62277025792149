/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup';
import { InterventionsCommonForm } from '../interfaces/interventions.interface';
import Constants from './constants';

const getInterventionNumberOfChildsConfig = (fieldName: string, maxNumberOfChilds: number) => ({
  name: `${fieldName}_numberOfChildrenValidation`,
  message: 'Number cannot exceed total children ages 0-3 in Tiers 2 & 3 served entered on the General Information & Delivery Costs page.',
  test: (value: any) => value ? (+value <= maxNumberOfChilds) : true
});

export default {
  required: (fieldName: string) =>
    Yup.string().required(
      fieldName
        ? `${fieldName} field is required`
        : Constants.FORM_ERRORS.REQUIRED_FIELD_ERROR
    ),
  maxPercentageRequired: (fieldName: string) =>
    Yup.string().required(
      fieldName
        ? `${fieldName} field is required`
        : Constants.FORM_ERRORS.REQUIRED_FIELD_ERROR
    ).test(
      'Percentage cannot be greater than 100%',
      'Percentage cannot be greater than 100%',
      function test(value: any) {
        return +value <= 100;
      }
    ),
  reportNameValidation: (fieldName: string) => {
    return Yup.string().required(
      fieldName
        ? `${fieldName} field is required`
        : Constants.FORM_ERRORS.REQUIRED_FIELD_ERROR
    )
    .test(
      'Name can not be greater than 50 characters',
      'Name can not be greater than 50 characters',
      (value: any) => (value as string || '').trim().length <= 50
    )
    .matches(Constants.REGEX.reportNameField, {
      message: 'Please use a name that does not include characters like [<>,/?:@&=+$#]',
    });
  },
  password: Yup.string()
    .required('Password field is required')
    .matches(Constants.REGEX.Password, {
      excludeEmptyString: true,
      message: 'Password invalid',
    }),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), undefined], "Password doesn't match")
    .required('Confirm password is required'),
  passwordRequired: Yup.string().required('Password field is required'),
  email: Yup.string()
    .email('Enter a valid email address')
    .required('Email field is required'),
  date: Yup.date().min('1900-01-01').max('2100-01-01'),
  interventionNumberOfChilds: (fieldName: string, maxNumberOfChilds: number) => Yup.mixed()
    .test(getInterventionNumberOfChildsConfig(fieldName, maxNumberOfChilds)),
  intervention: (validations = {}) =>
    Yup.object({
      ...validations,
      [InterventionsCommonForm.LowEstimate]: Yup.string().required(
        Constants.FORM_ERRORS.REQUIRED_FIELD_ERROR
      ).test(
        'Percentage cannot be greater than 100%',
        'Percentage cannot be greater than 100%',
        function test(value: any) {
          return +value <= 100;
        }
      ),
      [InterventionsCommonForm.HighEstimate]: Yup.string()
        .required(Constants.FORM_ERRORS.REQUIRED_FIELD_ERROR)
        .test(
          'The high estimate value must be bigger than the low estimate',
          'The high estimate value must be bigger than the low estimate',
          function test(this: any, value: any) {
            if (!value) return false;
            if (
              +value > +(this.parent[InterventionsCommonForm.LowEstimate] || '')
            ) {
              return true;
            }

            return false;
          }
        ).test(
          'Percentage cannot be greater than 100%',
          'Percentage cannot be greater than 100%',
          function test(value: any) {
            return +value <= 100;
          }
        ),
    }
    ),
  termsOfService: Yup.boolean().oneOf([true], 'Terms acceptance required'),
  getInterventionNumberOfChildsConfig: getInterventionNumberOfChildsConfig,
};
