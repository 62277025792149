import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors } from '../../../../assets/styles';

export default (): StyleRules =>
  createStyles({
    container: {
      padding: '7px 36px',
    },
    switchWrapper: {
      padding: 18,
      borderBottom: `2px solid ${Colors.Gray5}`,
      '& > *': {
        display: 'inline-flex',
        verticalAlign: 'top',
      },
    },
    lastItem: {
      borderBottom: 0,
    },
  });
