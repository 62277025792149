import React, { FunctionComponent, useState } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import * as Yup from 'yup';
import { FormikErrors, useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormValidations } from '../../../utils';
import { CircularButton, InputText, Spinner, Checkbox } from '../../../components';
import cognitoService from '../../../services/cognito.service';
import styles from './RequestAccountForm.styles';
import cx from 'classnames';

interface FormValues {
  name_of_site: string;
  name: string;
  email: string;
  recaptcha: boolean;
  terms: boolean;
}

const testid = 'request-account';
const defaultPdfPath = './assets/resources/HealthySteps_ROI_Calculator_Terms_of_Service-8-10-2021.pdf';

const RequestAccountForm: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verified, setVerfied] = useState<string | null>(null);
  const [error, setError] = useState('');

  const initialValues: FormValues = {
    name_of_site: '',
    name: '',
    email: '',
    recaptcha: false,
    terms: false
  };

  const onSubmit = (
    values: FormValues,
    { setErrors }: { setErrors: (errors: FormikErrors<FormValues>) => void }
  ) => {
    setErrors({ recaptcha: '' });
    setError('');

    if (!verified) {
      setErrors({ recaptcha: 'reCAPTCHA Check required' });
    } else {
      setIsLoading(true);

      cognitoService
        .createUser({
          email: values.email.trim().toLowerCase(),
          name: values.name,
          name_of_site: values.name_of_site,
        }).then(_ => {
            setRequestSuccess(true);
            setIsLoading(false);
        }).catch(error => {
          setError(error.message);
          setIsLoading(false)
        });
    }
  };

  const validationSchema = Yup.object().shape({
    name_of_site: FormValidations.required('Name of site'),
    name: FormValidations.required('Your name'),
    email: FormValidations.email,
    terms: FormValidations.termsOfService
  });

  const form = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const onRecaptchaChange = (value: string | null) => {
    setVerfied(value);
  };

  return (
    <form onSubmit={form.handleSubmit}>
      <Spinner show={isLoading} />
      {error && (
        <span className={classes.recaptchaError}>
          {error}
        </span>
      )}
      {requestSuccess ? (
        <Typography className={classes.successMessage}>
          The HealthySteps National Office has received your request and a
          member of our staff will follow up with you soon. Thank you!
        </Typography>
      ) : (
        <>
          <InputText
            id="name_of_site"
            name="name_of_site"
            onChange={form.handleChange}
            value={form.values.name_of_site}
            labelText="Name of site"
            placeholder="Eg: HealthySteps Pediatrics"
            errorMessage={form.errors.name_of_site}
            customClass={classes.input}
          />
          <InputText
            id="name"
            name="name"
            onChange={form.handleChange}
            value={form.values.name}
            labelText="Your name"
            placeholder="Eg: Julia Smith"
            errorMessage={form.errors.name}
            customClass={classes.input}
          />
          <InputText
            id="email"
            name="email"
            onChange={form.handleChange}
            value={form.values.email}
            labelText="Email Address"
            placeholder="Eg: julia@healthysteps.org"
            errorMessage={form.errors.email}
            customClass={classes.input}
          />
          <Checkbox
            id="termsOfService"
            label="Terms of Service"
            name="terms"
            checked={form.values.terms}
            customClass={cx(classes.input, classes.termsOfServiceLinkInput)}
            errorMessage={form.errors.terms}
            onChange={form.handleChange}
            content={
              <div className={classes.termsOfServiceLink}>
                <span>I accept the </span>
                <a
                  href={defaultPdfPath}
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                >
                  HealthySteps ROI Calculator Terms of Service
                </a>
              </div>
            }
          />
          <div className={classes.recaptcha}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
              onChange={onRecaptchaChange}
            />
            {form.errors.recaptcha && (
              <span className={classes.recaptchaError}>
                {form.errors.recaptcha}
              </span>
            )}
          </div>
          <CircularButton
            variant="contained"
            type="submit"
            color="secondary"
            customClass={classes.submitButton}
            data-testid={`${testid}__submit`}
          >
            Request an account
          </CircularButton>
        </>
      )}
      <div className={classes.returnLinkWrapper}>
        <Link
          className={classes.forgotLink}
          to=""
          data-testid={`${testid}__return-to-login`}
        >
          Return to Login
        </Link>
      </div>
    </form>
  );
};

export default withStyles(styles)(RequestAccountForm);
