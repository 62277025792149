import Colors from './colors.styles';

const CardShadow1 = {
  border: `1px solid ${Colors.Gray1}`,
  borderRadius: 3,
  backgroundColor: Colors.White,
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.05)',
};

const CardShadow2 = {
  borderRadius: 4,
  boxShadow: '0 2px 2px 0 rgba(0,0,0,0.05)',
};

export default { CardShadow1, CardShadow2 };
