export enum ServiceDataForm {
  SiteLocation = 'site_location',
  SiteName = 'site_name',
  DateFrom = 'date_from',
  DateTo = 'date_to',
  CalculationsApplyTo = 'calculations_apply_to',
  NumberOfSitesCalculationsApplyTo = 'number_of_sites_calculations_apply_to',
  NumberOfSpecialistsDeliveringServices = 'delivery_costs.number_of_specialists_delivering_services',
  NumberOfSpecialistsDeliveringServicesNote = 'delivery_costs.number_of_specialists_delivering_services_note',
  SpecialistSalaryCost = 'delivery_costs.specialist_salary_cost',
  SpecialistSalaryCostNote = 'delivery_costs.specialist_salary_cost_note',
  DirectCost = 'delivery_costs.direct_cost',
  DirectCostNote = 'delivery_costs.direct_cost_note',
  OtherCost = 'delivery_costs.other_cost',
  OtherCostNote = 'delivery_costs.other_cost_note',
  IntermediaryCost = 'delivery_costs.intermediary_cost',
  IntermediaryCostNote = 'delivery_costs.intermediary_cost_note',
  SiteServiceCost = 'delivery_costs.site_service_cost',
  SiteServiceCostNote = 'delivery_costs.site_service_cost_note',
  Children0To3 = 'children_served.children_0_to_3',
  Children0To3Note = 'children_served.children_0_to_3_note',
  MedicaidChildren0To3 = 'children_served.medicaid_children_0_to_3',
  MedicaidChildren0To3Note = 'children_served.medicaid_children_0_to_3_note',
  Children0To3Tier2And3 = 'children_served.children_0_to_3_tier_2_and_3',
  Children0To3Tier2And3Note = 'children_served.children_0_to_3_tier_2_and_3_note',
  MedicaidChildren0To3Tier2And3 = 'children_served.medicaid_children_0_to_3_tier_2_and_3',
  MedicaidChildren0To3Tier2And3Note = 'children_served.medicaid_children_0_to_3_tier_2_and_3_note',
  MedicaidChildrenUnder1Tier2And3 = 'children_served.medicaid_children_under_1_tier_2_and_3',
  MedicaidChildrenUnder1Tier2And3Note = 'children_served.medicaid_children_under_1_tier_2_and_3_note',
  IsTestReport = 'is_test_report',
}

export interface IServiceData {
  dirty: boolean;
  [ServiceDataForm.CalculationsApplyTo]: string;
  [ServiceDataForm.SiteName]: string;
  [ServiceDataForm.SiteLocation]: string;
  [ServiceDataForm.DateFrom]: Date | null;
  [ServiceDataForm.DateTo]: Date | null;
  [ServiceDataForm.NumberOfSpecialistsDeliveringServices]: string;
  [ServiceDataForm.NumberOfSpecialistsDeliveringServicesNote]: string;
  [ServiceDataForm.SpecialistSalaryCost]: string;
  [ServiceDataForm.SpecialistSalaryCostNote]: string;
  [ServiceDataForm.DirectCost]: string;
  [ServiceDataForm.DirectCostNote]: string;
  [ServiceDataForm.OtherCost]: string;
  [ServiceDataForm.OtherCostNote]: string;
  [ServiceDataForm.IntermediaryCost]: string;
  [ServiceDataForm.IntermediaryCostNote]: string;
  [ServiceDataForm.SiteServiceCost]: string;
  [ServiceDataForm.SiteServiceCostNote]: string;
  [ServiceDataForm.Children0To3]: string;
  [ServiceDataForm.Children0To3Note]: string;
  [ServiceDataForm.MedicaidChildren0To3]: string;
  [ServiceDataForm.MedicaidChildren0To3Note]: string;
  [ServiceDataForm.Children0To3Tier2And3]: string;
  [ServiceDataForm.Children0To3Tier2And3Note]: string;
  [ServiceDataForm.MedicaidChildren0To3Tier2And3]: string;
  [ServiceDataForm.MedicaidChildren0To3Tier2And3Note]: string;
  [ServiceDataForm.MedicaidChildrenUnder1Tier2And3]: string;
  [ServiceDataForm.MedicaidChildrenUnder1Tier2And3Note]: string;
  [ServiceDataForm.NumberOfSitesCalculationsApplyTo]: string;
  [ServiceDataForm.IsTestReport]: boolean;
}

export const serviceDataState: IServiceData = {
  dirty: false,
  [ServiceDataForm.SiteLocation]: '',
  [ServiceDataForm.SiteName]: '',
  [ServiceDataForm.DateFrom]: null,
  [ServiceDataForm.DateTo]: null,
  [ServiceDataForm.CalculationsApplyTo]: '',
  [ServiceDataForm.NumberOfSpecialistsDeliveringServices]: '',
  [ServiceDataForm.NumberOfSpecialistsDeliveringServicesNote]: '',
  [ServiceDataForm.SpecialistSalaryCost]: '',
  [ServiceDataForm.SpecialistSalaryCostNote]: '',
  [ServiceDataForm.DirectCost]: '',
  [ServiceDataForm.DirectCostNote]: '',
  [ServiceDataForm.OtherCost]: '',
  [ServiceDataForm.OtherCostNote]: '',
  [ServiceDataForm.IntermediaryCost]: '',
  [ServiceDataForm.IntermediaryCostNote]: '',
  [ServiceDataForm.SiteServiceCost]: '',
  [ServiceDataForm.SiteServiceCostNote]: '',
  [ServiceDataForm.Children0To3]: '',
  [ServiceDataForm.Children0To3Note]: '',
  [ServiceDataForm.MedicaidChildren0To3]: '',
  [ServiceDataForm.MedicaidChildren0To3Note]: '',
  [ServiceDataForm.Children0To3Tier2And3]: '',
  [ServiceDataForm.Children0To3Tier2And3Note]: '',
  [ServiceDataForm.MedicaidChildren0To3Tier2And3]: '',
  [ServiceDataForm.MedicaidChildren0To3Tier2And3Note]: '',
  [ServiceDataForm.MedicaidChildrenUnder1Tier2And3]: '',
  [ServiceDataForm.MedicaidChildrenUnder1Tier2And3Note]: '',
  [ServiceDataForm.NumberOfSitesCalculationsApplyTo]: '',
  [ServiceDataForm.IsTestReport]: false
};
