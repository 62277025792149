import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors } from '../../../../assets/styles';

export default (): StyleRules => {
  return createStyles({
    root: {
      '& ul': {
        padding: 0,
      },
    },
    applyFilterBtn: {
      color: Colors.Green,
    },
  });
};
