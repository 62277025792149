import { createStyles, StyleRules } from '@material-ui/core/styles';
import { FontWeight, Colors } from '../../../../../assets/styles';

export default (): StyleRules => {
  return createStyles({
    fieldWrapper: {
      borderBottom: `1px solid ${Colors.Gray5}`,
      padding: '30px 0',
    },
    fieldContent: {
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'center',
    },
    fieldText: {
      color: Colors.Blue,
      fontWeight: FontWeight.Bold,
    },
    inputWrapper: {
      minWidth: 117,
      marginLeft: 35,
      textAlign: 'center',
    },
    input: {
      maxWidth: 117,
      display: 'block',
    },
    error: {
      fontWeight: FontWeight.Regular,
      fontSize: 14,
      marginTop: 5,
    },
    subLabel: {
      fontSize: 12,
      color: Colors.Gray6,
    },
  });
};
