import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { Colors, Cards } from '../../../assets/styles';

export default ({ typography }: Theme): StyleRules => {
  const { pxToRem } = typography;

  return createStyles({
    container: {
      padding: `${pxToRem(48)} ${pxToRem(140)}`,
    },
    title: {
      textAlign: 'center',
      marginBottom: 24,
    },
    sideNav: {
      backgroundColor: Colors.Gray8,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      ...Cards.CardShadow2,
      padding: `${pxToRem(35)} ${pxToRem(30)} !important`,
    },
    main: {
      backgroundColor: Colors.White,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      ...Cards.CardShadow2,
      padding: `${pxToRem(35)} ${pxToRem(40)} !important`,
    },
  });
};
