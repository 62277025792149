import usersService from './users.service';

const GetUsersPageLimit = 60;

const createUser = (
  {
    email,
    name,
    name_of_site,
  }: {
    email: string;
    name: string;
    name_of_site: string;
  }
) => {
  return usersService.createUser(
    {
      Username: email,
      MessageAction: 'SUPPRESS',
      UserAttributes: [
        {
          Name: 'name',
          Value: name,
        },
        {
          Name: 'email',
          Value: email,
        },
        {
          Name: 'custom:name_of_site',
          Value: name_of_site,
        },
        {
          Name: 'custom:role',
          Value: 'user',
        },
        {
          Name: 'custom:approved',
          Value: '0',
        },
        {
          Name: 'custom:send_email',
          Value: 'true',
        },
      ],
    }
  );
};

const getUsers = (
  {
    pageSize,
    paginationToken,
    searchText,
    userAction,
    usersLoaded = 0,
  }: {
    pageSize?: number;
    paginationToken?: string;
    searchText?: string;
    userAction?: boolean;
    usersLoaded?: number;
  }
) => {
  const searchBy = `name ^= '${searchText}'`;

  const params: any = {
    Filter: searchText && searchBy,
  };

  if (userAction) {
    if (usersLoaded <= GetUsersPageLimit) {
      params.Limit = usersLoaded;
    } else {
      params.Limit = GetUsersPageLimit;
    }
  } else {
    params.Limit = pageSize;
  }

  if (paginationToken) {
    params.PaginationToken = paginationToken;
  }

  return usersService.getUsers(params);
};

const resendWelcomeEmail = (
  username: string
) => {
  return usersService.resendWelcomeEmail(username);
};

const approveUser = (
  username: string) => {
  return usersService.adminUpdateUserAttributes(
    {
      Username: username,
      UserAttributes: [
        {
          Name: 'custom:approved',
          Value: '1',
        },
      ],
    }
  );
};

const disableUser = (
  username: string) => {
  return usersService.adminDisableUser(
    {
      Username: username,
    }
  );
};

const enableUser = (
  username: string
) => {
  return usersService.adminEnableUser(
    {
      Username: username,
    }
  );
};

const changeUserEmail = (
  currentEmail: string,
  newEmail: string) => {
  return usersService.adminUpdateUserAttributes(
    {
      UserAttributes: [
        {
          Name: 'email',
          Value: newEmail,
        },
        {
          Name: 'email_verified',
          Value: 'false',
        }
      ],
      Username: currentEmail,
    },
  );
};

const toggleUserSendEmail = (
  username: string,
  value: string
) => {
  return usersService.adminUpdateUserAttributes(
    {
      Username: username,
      UserAttributes: [
        {
          Name: 'custom:send_email',
          Value: value,
        },
      ],
    },
  );
};

export default {
  createUser,
  getUsers,
  resendWelcomeEmail,
  approveUser,
  disableUser,
  enableUser,
  changeUserEmail,
  toggleUserSendEmail,
};
