import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, IRootState } from '../../interfaces/redux.interface';
import { IUsersState } from '../../interfaces/users.interface';
import { snackbarActions } from '../snackbarSlice';
import cognitoService from '../../services/cognito.service';
import usersService from '../../services/users.service';
import { ISort } from '../../interfaces/sort.interface';
import { IFilter } from '../../interfaces/filter.interface';

export const selectUsers = (state: IRootState) => state.users.users;
export const selectCurrentUser = (state: IRootState) =>
  state.users.selectedUser;
export const selectIsLoading = (state: IRootState) => state.users.isLoading;
export const selectIsEmailEditDialogOpen = (state: IRootState) =>
  state.users.isEmailEditDialogOpen;
export const selectIsResendEmailDialogOpen = (state: IRootState) =>
  state.users.isResendEmailDialogOpen;
export const selectUsersFilter = (state: IRootState) =>
  state.users.filter;
export const selectUsersSort = (state: IRootState) =>
  state.users.sort;

const initialState: IUsersState = {
  isLoading: false,
  users: [],
  pageSize: 10,
  selectedUser: null,
  isEmailEditDialogOpen: false,
  isResendEmailDialogOpen: false,
  sort: undefined,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setUsersResponse: (state, action) => {
      state.isLoading = false;
      state.users = action.payload.users;
    },
    resetState: (state) => {
      state.users = [];
      state.filter = undefined;
      state.sort = undefined;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    setIsEmailEditDialogOpen: (state, action) => {
      state.isEmailEditDialogOpen = action.payload;
    },
    setIsResendEmailDialogOpen: (state, action) => {
      state.isResendEmailDialogOpen = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});

const {
  setLoading,
  resetState,
  setUsersResponse,
  setSelectedUser,
  setIsEmailEditDialogOpen,
  setIsResendEmailDialogOpen,
  setSort,
  setFilter,
} = usersSlice.actions;


const getUsers = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true));
  try {
    const { users: { filter, sort } } = getState();
    const users = await usersService.getUsers({ filter, sort });
    dispatch(setUsersResponse({ users }));
  } catch (error: any) {
    dispatch(snackbarActions.error(error.message));
  } finally {
    dispatch(setLoading(false));
  }
}

const approveUser = (email: string): AppThunk => (dispatch) => {
  dispatch(setLoading(true));

  cognitoService
    .approveUser(email).then(_ => {
      cognitoService
        .resendWelcomeEmail(email).then(_ => dispatch(getUsers()));
    });
};

const enableUser = (username: string): AppThunk => (dispatch) => {
  dispatch(setLoading(true));

  cognitoService.enableUser(username).then(_ => {
    dispatch(getUsers());
  });
};

const disableUser = (username: string): AppThunk => (dispatch) => {
  dispatch(setLoading(true));

  cognitoService.disableUser(username).then(_ => {
    dispatch(getUsers());
  });
};

const deleteUser = (username: string): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await usersService.deleteUser(username);
    dispatch(getUsers());
  } catch (ex: any) {
    dispatch(snackbarActions.error(ex.message));
  } finally {
    dispatch(setLoading(false));
  }
};

const updateUserRole = (username: string, role: string): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await usersService.updateUserRole(username, role);
    dispatch(getUsers());
  } catch (ex: any) {
    dispatch(snackbarActions.error(ex.message));
  } finally {
    dispatch(setLoading(false));
  }
};

const resendWelcomeEmail = (email: string): AppThunk => (dispatch) => {
  dispatch(setLoading(true));

  cognitoService
    .resendWelcomeEmail(email).then(_ => {
      dispatch(getUsers());
      dispatch(snackbarActions.success('Welcome email succesfully sent'));
    })
    .catch(e => {
      dispatch(getUsers());
      dispatch(snackbarActions.error(e.message));
    });
};

const changeUserEmail = (
  currentEmail: string,
  newEmail: string
): AppThunk<Promise<object | string>> => (dispatch) => {
  dispatch(setLoading(true));

  return cognitoService
    .changeUserEmail(currentEmail, newEmail.trim().toLowerCase())
    .then((res) => {
      dispatch(getUsers());
      dispatch(snackbarActions.success('Email succesfully changed'));
      return res;
    })
    .catch((error) => {
      dispatch(setLoading(false));
      throw new Error(error);
    });
};

const toggleUserSendEmail = (
  username: string,
  value: string
): AppThunk<Promise<object | string>> => (dispatch) => {
  dispatch(setLoading(true));

  return cognitoService
    .toggleUserSendEmail(username, value)
    .then((res) => {
      dispatch(getUsers());
      dispatch(snackbarActions.success('Succesfully updated'));
      return res;
    })
    .catch((error) => {
      dispatch(setLoading(false));
      throw new Error(error);
    });
};


const exportUsersList = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true));
  try {
    const { users: { filter, sort } } = getState();
    const res = await usersService.userListDownloadRequest({ filter, sort });
    const csvBlob = await res.blob();
    saveAs(csvBlob, 'ROI_UsersList.csv');
  } catch (error: any) {
    dispatch(snackbarActions.error(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

const updateSort = (sort?: ISort): AppThunk => async (dispatch) => {
  try {
    dispatch(setSort(sort));
    dispatch(getUsers());
  } catch (ex: any) {
    dispatch(snackbarActions.error(ex.message));
  }
};

const updateFilter = (filter?: IFilter): AppThunk => async (dispatch) => {
  try {
    dispatch(setFilter(filter));
    dispatch(getUsers());
  } catch (ex: any) {
    dispatch(snackbarActions.error(ex.message));
  }
};

export const usersReducer = usersSlice.reducer;
export const usersActions = {
  getUsers,
  approveUser,
  enableUser,
  disableUser,
  deleteUser,
  updateUserRole,
  resetState,
  resendWelcomeEmail,
  changeUserEmail,
  setSelectedUser,
  setIsEmailEditDialogOpen,
  setIsResendEmailDialogOpen,
  exportUsersList,
  updateSort,
  updateFilter,
  toggleUserSendEmail,
};
