import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import bg from '../../assets/images/signin-background.jpg';

export default (theme: Theme): StyleRules =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '100vh',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage: `url(${bg})`,
      padding: '0 15px',
    },
    content: {
      margin: 'auto',
    },
    footer: {
      width: '100%',
      maxWidth: 689,
      padding: '53px 0',
      margin: '0 auto',
    },
  });
