import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors, FontWeight } from '../../../assets/styles';

export default (): StyleRules =>
  createStyles({
    selectWrapper: {
      border: `solid 1px ${Colors.Gray2}`,
      backgroundColor: Colors.White,
      borderRadius: '4px !important',
      height: 27,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 17,
    },
    selectIcon: {
      marginRight: 10,
    },
    label: {
      fontSize: 16,
      color: Colors.Blue1,
      fontWeight: FontWeight.Bold,
    },
    labelError: {
      color: Colors.Red,
    },
    placeholder: {
      color: Colors.Gray,
    },
    inputError: {
      borderColor: Colors.Red,
    },
    error: {
      fontWeight: FontWeight.Regular,
      fontSize: 14,
    },
    text: {
      marginTop: 20,
    },
  });
