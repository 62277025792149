import apiService from './api.service';

const savingsIdeaEmail = (params = {}) =>
  apiService.authSendEmail('contactus/savingidea/post', {
    method: 'POST',
    mode: 'no-cors',
    body: params
  });

export default {
  savingsIdeaEmail,
};