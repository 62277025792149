import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors } from '../../../../assets/styles';

export default (): StyleRules => {
  return createStyles({
    root: {
      '& ul': {
        padding: 0,
      },
    },
    input: {
      width: 320,
    },
    linearProgress: {
      marginTop: 10,
    },
    usersList: {
      height: 200,
      border: '1px solid',
      marginTop: 10,
      overflow: 'auto',
    },
    applyFilterBtn: {
      color: Colors.Green,
    },
  });
};
