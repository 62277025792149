import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IRootState } from '../../interfaces/redux.interface';
import { IContentState } from '../../interfaces/content.interface';
import contentService from '../../services/content.service';

export const selectIsCalculatorDashboardLoading = (state: IRootState) =>
  state.content.calculatorDashboard.isLoading;
export const selectCalculatorDashboardContent = (state: IRootState) =>
  state.content.calculatorDashboard.content;

const fetchCalculatorDashboardContent = createAsyncThunk(
  'content/fetchCalculatorDashboardContent',
  () => {
    return contentService.getCalculatorDashboardContent();
  }
);

const initialState: IContentState = {
  calculatorDashboard: {
    isLoading: false,
    content: null,
  },
};

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCalculatorDashboardContent.pending, (state) => {
      state.calculatorDashboard.isLoading = true;
    });
    builder.addCase(
      fetchCalculatorDashboardContent.fulfilled,
      (state, action) => {
        state.calculatorDashboard.isLoading = false;
        state.calculatorDashboard.content = action.payload;
      }
    );
  },
});

export const contentReducer = contentSlice.reducer;
export const contentActions = {
  fetchCalculatorDashboardContent,
};
