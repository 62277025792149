import { createStyles, StyleRules } from '@material-ui/core/styles';

export default (): StyleRules =>
  createStyles({
    container: {
      display: 'flex',
    },
    content: {
      width: '100%',
    },
    main: {
      /** Header height: 71px */
      maxHeight: 'calc(100vh - 71px)',
      overflowY: 'scroll',
    },
    mainWithFooter: {
      /** Footer height: 102px */
      maxHeight: 'calc(100vh - 71px - 102px)',
    },
  });
