import ServiceData from './serviceData';
import Calculator from './shared/base';

export default class SmokingCessation extends Calculator {
  public serviceData: ServiceData;

  constructor({
    state,
    serviceData,
    sliderSmokerLowEstimate,
    sliderSmokerHighEstimate,
    firstInput,
  }: {
    state: string;
    serviceData: ServiceData;
    sliderSmokerLowEstimate: number;
    sliderSmokerHighEstimate: number;
    firstInput: number;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.firstInput = firstInput;
    this.sliderSmokerLowEstimate = sliderSmokerLowEstimate / 100;
    this.sliderSmokerHighEstimate = sliderSmokerHighEstimate / 100;
  }

  /**
   * @name smoking_tab_combined_child_and_adults_savings_over_3_years
   * @excel Excel Smoking Tab B12
   * @constant 1434
   */
  public readonly smokingTabCombinedChildAndAdultsSavingsOver3Years = this
    .constants.smokingCessation
    .smokingTabCombinedChildAndAdultsSavingsOver3Years;

  /**
   * @name smoking_tab_medicaid_price_inflaction_factor
   * @excel Excel Smoking Tab B13
   * @constant 1.17
   */
  public readonly smokingTabMedicaidPriceInflactionFactor = this.constants
    .smokingCessation.smokingTabMedicaidPriceInflactionFactor;

  /**
   * (App)
   * @name first_input
   */
  public firstInput;

  /**
   * @name number_of_HS_mothers_who_discussed_a_need_for_smoking_tobacco_cessation_advice
   * @excel Excel C147
   * @reference input_percentage_of_HS_mothers_who_discussed_a_smoking_tobacco_addiction * cs_input_4 (from Service Data Screen)
   */
  public numberOfHSMothersWhoDiscussedANeedForSmokingTobaccoCessationAdvice = () => {
    return this.isFiniteNumber(this.firstInput);
  };

  /**
   * (App) Estimated percentage of smokers treated due to HealthySteps - Low Estimate
   * @name slider_smoker_low_estimate
   * @excel Excel C151
   */
  public sliderSmokerLowEstimate;

  /**
   * (App) Estimated percentage of smokers treated due to HealthySteps - High Estimate
   * @name slider_smoker_high_estimate
   * @excel Excel C152
   */
  public sliderSmokerHighEstimate;

  /**
   * @name estimated_number_of_parents_who_discussed_smoking_took_up_treatment_and_quit_due_to_HS_low
   * @excel Excel C153
   * @formula number_of_HS_mothers_who_discussed_a_need_for_smoking_tobacco_cessation_advice * slider_smoker_low_estimate
   */
  public estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow = () => {
    return this.isFiniteNumber(
      this.numberOfHSMothersWhoDiscussedANeedForSmokingTobaccoCessationAdvice() *
        this.sliderSmokerLowEstimate
    );
  };

  /**
   * @name estimated_number_of_parents_who_discussed_smoking_took_up_treatment_and_quit_due_to_HS_high
   * @excel Excel C154
   * @formula number_of_HS_mothers_who_discussed_a_need_for_smoking_tobacco_cessation_advice * slider_smoker_high_estimate
   */
  public estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh = () => {
    return this.isFiniteNumber(
      this.numberOfHSMothersWhoDiscussedANeedForSmokingTobaccoCessationAdvice() *
        this.sliderSmokerHighEstimate
    );
  };

  /**
   * @name combined_child_and_adult_medical_cost_savings_over_3_years_per_individual_who_quits
   * @excel Excel C155
   * @formula number_of_HS_mothers_who_discussed_a_need_for_smoking_tobacco_cessation_advice * slider_smoker_high_estimate
   */
  public combinedChildAndAdultMedicalCostSavingsOver3YearsPerIndividualWhoQuits = () => {
    return this.isFiniteNumber(
      this.smokingTabCombinedChildAndAdultsSavingsOver3Years *
        this.smokingTabMedicaidPriceInflactionFactor
    );
  };

  /**
   * @name smoking_cessation_estimated_savings_low
   * @excel Excel E145
   * @formula estimated_number_of_parents_who_discussed_smoking_took_up_treatment_and_quit_due_to_HS_low * combined_child_and_adult_medical_cost_savings_over_3_years_per_individual_who_quits
   */
  public smokingCessationEstimatedSavingsLow = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSLow() *
        this.combinedChildAndAdultMedicalCostSavingsOver3YearsPerIndividualWhoQuits()
    );
  };

  /**
   * @name smoking_cessation_estimated_savings_high
   * @excel Excel F145
   * @formula estimated_number_of_parents_who_discussed_smoking_took_up_treatment_and_quit_due_to_HS_high * combined_child_and_adult_medical_cost_savings_over_3_years_per_individual_who_quits
   */
  public smokingCessationEstimatedSavingsHigh = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfParentsWhoDiscussedSmokingTookUpTreatmentAndQuitDueToHSHigh() *
        this.combinedChildAndAdultMedicalCostSavingsOver3YearsPerIndividualWhoQuits()
    );
  };
}
