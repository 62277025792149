/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-explicit-any */

interface IIndex {
  [key: string]: boolean;
}

interface IStringIndex {
  [key: string]: any;
}

export enum InterventionsChecklistForm {
  OralHealth = 'oralHealth',
  EmergencyDepartmentUse = 'emergencyDepartmentUse',
  Immunizations = 'immunizations',
  Asthma = 'asthma',
  PostpartumMaternalDepression = 'postpartumMaternalDepression',
  HealthyBirthSpacing = 'healthyBirthSpacing',
  IntimatePartnerViolence = 'intimidatePartnerViolence',
  Breastfeeding = 'breastfeeding',
  SmokingCessation = 'smokeCessation',
}

export interface IInterventionsChecklist extends IIndex {
  [InterventionsChecklistForm.OralHealth]: boolean;
  [InterventionsChecklistForm.EmergencyDepartmentUse]: boolean;
  [InterventionsChecklistForm.Immunizations]: boolean;
  [InterventionsChecklistForm.Asthma]: boolean;
  [InterventionsChecklistForm.PostpartumMaternalDepression]: boolean;
  [InterventionsChecklistForm.HealthyBirthSpacing]: boolean;
  [InterventionsChecklistForm.IntimatePartnerViolence]: boolean;
  [InterventionsChecklistForm.Breastfeeding]: boolean;
  [InterventionsChecklistForm.SmokingCessation]: boolean;
}

export const interventionsChecklistState: IInterventionsChecklist = {
  [InterventionsChecklistForm.OralHealth]: false,
  [InterventionsChecklistForm.EmergencyDepartmentUse]: false,
  [InterventionsChecklistForm.Immunizations]: false,
  [InterventionsChecklistForm.Asthma]: false,
  [InterventionsChecklistForm.PostpartumMaternalDepression]: false,
  [InterventionsChecklistForm.HealthyBirthSpacing]: false,
  [InterventionsChecklistForm.IntimatePartnerViolence]: false,
  [InterventionsChecklistForm.Breastfeeding]: false,
  [InterventionsChecklistForm.SmokingCessation]: false,
};

export enum InterventionsCommonForm {
  LowEstimate = 'low_estimate',
  HighEstimate = 'high_estimate',
  EstimatesNote = 'estimates_note',
  LowEstimateExclusive = 'low_estimate_exclusive',
  HighEstimateExclusive = 'high_estimate_exclusive',
  EstimatesExclusiveNote = 'estimates_exclusive_note',
  SavingsLow = 'savings_low',
  SavingsHigh = 'savings_high',
}

export interface IInterventionsCommon {
  [InterventionsCommonForm.LowEstimate]: string;
  [InterventionsCommonForm.HighEstimate]: string;
  [InterventionsCommonForm.EstimatesNote]: string;
  [InterventionsCommonForm.SavingsLow]: string;
  [InterventionsCommonForm.SavingsHigh]: string;
}

export interface IInterventionsCommonExclusive {
  [InterventionsCommonForm.LowEstimateExclusive]: string;
  [InterventionsCommonForm.HighEstimateExclusive]: string;
  [InterventionsCommonForm.EstimatesExclusiveNote]: string;
}

export enum OralHealthForm {
  NeedForOralHealth = 'need_for_oral_health',
  NeedForOralHealthNote = 'need_for_oral_health_note',
}

export interface IOralHealth extends IInterventionsCommon, IStringIndex {
  [OralHealthForm.NeedForOralHealth]: string;
  [OralHealthForm.NeedForOralHealthNote]: string;
  isValid: boolean;
}

export const oralHealthState: IOralHealth = {
  [OralHealthForm.NeedForOralHealth]: '',
  [OralHealthForm.NeedForOralHealthNote]: '',
  [InterventionsCommonForm.LowEstimate]: '',
  [InterventionsCommonForm.HighEstimate]: '',
  [InterventionsCommonForm.EstimatesNote]: '',
  [InterventionsCommonForm.SavingsLow]: '',
  [InterventionsCommonForm.SavingsHigh]: '',
  isValid: false,
};

export enum EmergencyDepartmentUseForm {
  ContactedByPhone = 'contacted_by_phone',
  ContactedByPhoneNote = 'contacted_by_phone_note',
  TreatedAtHome = 'treated_at_home',
  TreatedAtHomeNote = 'treated_at_home_note',
  ClinicVisit = 'clinic_visit',
  ClinicVisitNote = 'clinic_visit_note',
}

// export interface IEmergencyDepartmentUse
//   extends IInterventionsCommon,
export interface IEmergencyDepartmentUse extends IStringIndex {
  [EmergencyDepartmentUseForm.ContactedByPhone]: string;
  [EmergencyDepartmentUseForm.ContactedByPhoneNote]: string;
  [EmergencyDepartmentUseForm.TreatedAtHome]: string;
  [EmergencyDepartmentUseForm.TreatedAtHomeNote]: string;
  [EmergencyDepartmentUseForm.ClinicVisit]: string;
  [EmergencyDepartmentUseForm.ClinicVisitNote]: string;
  isValid: boolean;
}

export const emergencyDepartmentUseState: IEmergencyDepartmentUse = {
  [EmergencyDepartmentUseForm.ContactedByPhone]: '',
  [EmergencyDepartmentUseForm.ContactedByPhoneNote]: '',
  [EmergencyDepartmentUseForm.TreatedAtHome]: '',
  [EmergencyDepartmentUseForm.TreatedAtHomeNote]: '',
  [EmergencyDepartmentUseForm.ClinicVisit]: '',
  [EmergencyDepartmentUseForm.ClinicVisitNote]: '',
  // [InterventionsCommonForm.LowEstimate]: '',
  // [InterventionsCommonForm.HighEstimate]: '',
  [InterventionsCommonForm.SavingsLow]: '',
  [InterventionsCommonForm.SavingsHigh]: '',
  isValid: false,
};

export enum ImmunizationsForm {
  NeedForFluVaccinations = 'need_for_flu_vaccinations',
  NeedForFluVaccinationsNote = 'need_for_flu_vaccinations_note',
  NumberOfEncourageVisits = 'number_of_encourage_visits',
  NumberOfEncourageVisitsNote = 'number_of_encourage_visits_note',
}

export interface IImmunizations extends IInterventionsCommon, IStringIndex {
  [ImmunizationsForm.NeedForFluVaccinations]: string;
  [ImmunizationsForm.NeedForFluVaccinationsNote]: string;
  [ImmunizationsForm.NumberOfEncourageVisits]: string;
  [ImmunizationsForm.NumberOfEncourageVisitsNote]: string;
  isValid: boolean;
}

export const immunizationsState: IImmunizations = {
  [ImmunizationsForm.NeedForFluVaccinations]: '',
  [ImmunizationsForm.NeedForFluVaccinationsNote]: '',
  [ImmunizationsForm.NumberOfEncourageVisits]: '',
  [ImmunizationsForm.NumberOfEncourageVisitsNote]: '',
  [InterventionsCommonForm.LowEstimate]: '',
  [InterventionsCommonForm.HighEstimate]: '',
  [InterventionsCommonForm.EstimatesNote]: '',
  [InterventionsCommonForm.SavingsLow]: '',
  [InterventionsCommonForm.SavingsHigh]: '',
  isValid: false,
};

export enum AsthmaForm {
  NumberHaveAsthma = 'number_have_asthma',
  NumberHaveAsthmaNote = 'number_have_asthma_note',
}

export interface IAsthma extends IInterventionsCommon, IStringIndex {
  [AsthmaForm.NumberHaveAsthma]: string;
  [AsthmaForm.NumberHaveAsthmaNote]: string;
  isValid: boolean;
}

export const asthmaState: IAsthma = {
  [AsthmaForm.NumberHaveAsthma]: '',
  [AsthmaForm.NumberHaveAsthmaNote]: '',
  [InterventionsCommonForm.LowEstimate]: '',
  [InterventionsCommonForm.HighEstimate]: '',
  [InterventionsCommonForm.EstimatesNote]: '',
  [InterventionsCommonForm.SavingsLow]: '',
  [InterventionsCommonForm.SavingsHigh]: '',
  isValid: false,
};

export enum PostpartumMaternalDepressionForm {
  DiscussedProblem = 'discussed_problem',
  DiscussedProblemNote = 'discussed_problem_note',
  ReceivedCounseling = 'received_counseling',
  ReceivedCounselingNote = 'received_counseling_note',
  ReferredForServices = 'referred_for_services',
  ReferredForServicesNote = 'referred_for_services_note',
}

export interface IPostpartumMaternalDepression
  extends IInterventionsCommon,
    IStringIndex {
  [PostpartumMaternalDepressionForm.DiscussedProblem]: string;
  [PostpartumMaternalDepressionForm.DiscussedProblemNote]: string;
  [PostpartumMaternalDepressionForm.ReceivedCounseling]: string;
  [PostpartumMaternalDepressionForm.ReceivedCounselingNote]: string;
  [PostpartumMaternalDepressionForm.ReferredForServices]: string;
  [PostpartumMaternalDepressionForm.ReferredForServicesNote]: string;
  isValid: boolean;
}

export const postpartumMaternalDepressionState: IPostpartumMaternalDepression = {
  [PostpartumMaternalDepressionForm.DiscussedProblem]: '',
  [PostpartumMaternalDepressionForm.DiscussedProblemNote]: '',
  [PostpartumMaternalDepressionForm.ReceivedCounseling]: '',
  [PostpartumMaternalDepressionForm.ReceivedCounselingNote]: '',
  [PostpartumMaternalDepressionForm.ReferredForServices]: '',
  [PostpartumMaternalDepressionForm.ReferredForServicesNote]: '',
  [InterventionsCommonForm.LowEstimate]: '',
  [InterventionsCommonForm.HighEstimate]: '',
  [InterventionsCommonForm.EstimatesNote]: '',
  [InterventionsCommonForm.SavingsLow]: '',
  [InterventionsCommonForm.SavingsHigh]: '',
  isValid: false,
};

export enum HealthyBirthSpacingForm {
  NeedForContraception = 'need_for_contraception',
  NeedForContraceptionNote = 'need_for_contraception_note',
  FollowedUpOnReferals = 'followed_up_on_referals',
  FollowedUpOnReferalsNote = 'followed_up_on_referals_note',
}

export interface IHealthyBirthSpacing
  extends IInterventionsCommon,
    IStringIndex {
  [HealthyBirthSpacingForm.NeedForContraception]: string;
  [HealthyBirthSpacingForm.NeedForContraceptionNote]: string;
  [HealthyBirthSpacingForm.FollowedUpOnReferals]: string;
  [HealthyBirthSpacingForm.FollowedUpOnReferalsNote]: string;
  isValid: boolean;
}

export const healthyBirthSpacingState: IHealthyBirthSpacing = {
  [HealthyBirthSpacingForm.NeedForContraception]: '',
  [HealthyBirthSpacingForm.NeedForContraceptionNote]: '',
  [HealthyBirthSpacingForm.FollowedUpOnReferals]: '',
  [HealthyBirthSpacingForm.FollowedUpOnReferalsNote]: '',
  [InterventionsCommonForm.LowEstimate]: '',
  [InterventionsCommonForm.HighEstimate]: '',
  [InterventionsCommonForm.EstimatesNote]: '',
  [InterventionsCommonForm.SavingsLow]: '',
  [InterventionsCommonForm.SavingsHigh]: '',
  isValid: false,
};

export enum IntimatePartnerViolenceForm {
  DiscussedIPV = 'discussed_ipv',
  DiscussedIPVNote = 'discussed_ipv_note',
  ReceivedCounseling = 'received_counseling',
  ReceivedCounselingNote = 'received_counseling_note',
  ReferredForServices = 'referred_for_services',
  ReferredForServicesNote = 'referred_for_services_note',
}

export interface IIntimatePartnerViolence
  extends IInterventionsCommon,
    IStringIndex {
  [IntimatePartnerViolenceForm.DiscussedIPV]: string;
  [IntimatePartnerViolenceForm.DiscussedIPVNote]: string;
  [IntimatePartnerViolenceForm.ReceivedCounseling]: string;
  [IntimatePartnerViolenceForm.ReceivedCounselingNote]: string;
  [IntimatePartnerViolenceForm.ReferredForServices]: string;
  [IntimatePartnerViolenceForm.ReferredForServicesNote]: string;
  isValid: boolean;
}

export const intimatePartnerViolenceState: IIntimatePartnerViolence = {
  [IntimatePartnerViolenceForm.DiscussedIPV]: '',
  [IntimatePartnerViolenceForm.DiscussedIPVNote]: '',
  [IntimatePartnerViolenceForm.ReceivedCounseling]: '',
  [IntimatePartnerViolenceForm.ReceivedCounselingNote]: '',
  [IntimatePartnerViolenceForm.ReferredForServices]: '',
  [IntimatePartnerViolenceForm.ReferredForServicesNote]: '',
  [InterventionsCommonForm.LowEstimate]: '',
  [InterventionsCommonForm.HighEstimate]: '',
  [InterventionsCommonForm.EstimatesNote]: '',
  [InterventionsCommonForm.SavingsLow]: '',
  [InterventionsCommonForm.SavingsHigh]: '',
  isValid: false,
};

export enum BreastfeedingForm {
  DiscussedNeedForBreastfeeding = 'discussed_need_for_breastfeeding',
  DiscussedNeedForBreastfeedingNote = 'discussed_need_for_breastfeeding_note',
  ReceivedCounseling = 'received_counseling',
  ReceivedCounselingNote = 'received_counseling_note',
  ReferredForServices = 'referred_for_services',
  ReferredForServicesNote = 'referred_for_services_note',
}

export interface IBreastfeeding
  extends IInterventionsCommon,
    IInterventionsCommonExclusive,
    IStringIndex {
  [BreastfeedingForm.DiscussedNeedForBreastfeeding]: string;
  [BreastfeedingForm.DiscussedNeedForBreastfeedingNote]: string;
  [BreastfeedingForm.ReceivedCounseling]: string;
  [BreastfeedingForm.ReceivedCounselingNote]: string;
  [BreastfeedingForm.ReferredForServices]: string;
  [BreastfeedingForm.ReferredForServicesNote]: string;
  isValid: boolean;
}

export const breastfeedingState: IBreastfeeding = {
  [BreastfeedingForm.DiscussedNeedForBreastfeeding]: '',
  [BreastfeedingForm.DiscussedNeedForBreastfeedingNote]: '',
  [BreastfeedingForm.ReceivedCounseling]: '',
  [BreastfeedingForm.ReceivedCounselingNote]: '',
  [BreastfeedingForm.ReferredForServices]: '',
  [BreastfeedingForm.ReferredForServicesNote]: '',
  [InterventionsCommonForm.LowEstimate]: '',
  [InterventionsCommonForm.HighEstimate]: '',
  [InterventionsCommonForm.EstimatesNote]: '',
  [InterventionsCommonForm.LowEstimateExclusive]: '',
  [InterventionsCommonForm.HighEstimateExclusive]: '',
  [InterventionsCommonForm.EstimatesExclusiveNote]: '',
  [InterventionsCommonForm.SavingsLow]: '',
  [InterventionsCommonForm.SavingsHigh]: '',
  isValid: false,
};

export enum SmokingCessationForm {
  DiscussedProblem = 'discussed_problem',
  DiscussedProblemNote = 'discussed_problem_note',
}

export interface ISmokingCessation extends IInterventionsCommon, IStringIndex {
  [SmokingCessationForm.DiscussedProblem]: string;
  [SmokingCessationForm.DiscussedProblemNote]: string;
  isValid: boolean;
}

export const smokingCessationState: ISmokingCessation = {
  [SmokingCessationForm.DiscussedProblem]: '',
  [SmokingCessationForm.DiscussedProblemNote]: '',
  [InterventionsCommonForm.LowEstimate]: '',
  [InterventionsCommonForm.HighEstimate]: '',
  [InterventionsCommonForm.EstimatesNote]: '',
  [InterventionsCommonForm.SavingsLow]: '',
  [InterventionsCommonForm.SavingsHigh]: '',
  isValid: false,
};
