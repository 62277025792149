import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../assets/styles';

export default ({ typography }: Theme): StyleRules => {
  const { pxToRem } = typography;

  return createStyles({
    footer: {
      backgroundColor: Colors.White,
      borderTop: `1px solid ${Colors.Gray3}`,
      padding: `${pxToRem(28)} ${pxToRem(85)}`,
      textAlign: 'end',
      position: 'fixed',
      width: 'calc(100% - 74px)',
      bottom: 0,
    },
    button: {
      maxWidth: 120,
      marginLeft: 10,
    },
    reportButton: {
      maxWidth: 247,
      marginLeft: 10,
    },
    secondaryButton: {
      textTransform: 'none',
    },
    calculationsNameInput: {
      width: 500,
    },
  });
};
