import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors } from '../../../assets/styles';

export default (): StyleRules => {
  return createStyles({
    groupHeader: {
      color: Colors.Blue,
      textAlign: 'center',
      marginBottom: '20px'
    }
  });
};
