import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import UsersTable from './UsersTable/UsersTable';
import EmailDialogs from './EmailDialogs/EmailDialogs';
import styles from './UsersDashboard.styles';

const UsersDashboard: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const testid = 'users-table';

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography
          variant="h1"
          classes={{ root: classes.title }}
          data-testid={`${testid}__title`}
        >
          HealthySteps ROI Calculator Users
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <UsersTable />
      </Grid>
      <EmailDialogs />
    </Grid>
  );
};

export default withStyles(styles)(UsersDashboard);
