import { createStyles, StyleRules } from '@material-ui/core/styles';
import { FontWeight, Colors } from '../../../../../assets/styles';

export default (): StyleRules => {
  return createStyles({
    noteContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    noteWrapper: {
      backgroundColor: Colors.Gray7,
      borderRadius: 4,
      padding: '15px 22px',
      width: '100%',
      marginTop: 20,
      marginBottom: 5,
    },
    note: {
      fontSize: 13,
    },
    noteActions: {
      textAlign: 'right',
      marginTop: 5,
    },
    noteAction: {
      fontSize: 13,
      fontWeight: FontWeight.Regular,
      color: Colors.Blue1,
      textTransform: 'none',
      padding: '3px 6px',
      '&:after': {
        content: '""',
        position: 'absolute',
        backgroundColor: Colors.Blue1,
        width: 1,
        height: 12,
        right: 0,
      },
      '&:last-of-type': {
        '&:after': {
          width: 0
        }
      },
    },
  });
};
