/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent, useState } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FormikProps } from 'formik';
import {
  selectReportName,
  calculatorActions,
  selectIsLoading,
  selectTouched
} from '../../../redux/calculatorSlice';
import { selectUser } from '../../../redux/authSlice';
import { CircularButton, Dialog, ReportNameDialog } from '../../../components';
import { paths, history } from '../..';
import { Constants, StringFormatter } from '../../../utils';
import styles from './Footer.styles';

interface IProps extends WithStylesType<typeof styles> {
  currentForm: FormikProps<any>;
}

const Footer: FunctionComponent<IProps> = ({
  classes,
  currentForm
}) => {
  const location = useLocation();
  const reportName = useSelector(selectReportName);
  const isLoading = useSelector(selectIsLoading);
  const touched = useSelector(selectTouched);
  const dispatch = useDispatch();
  const [isTitleDialogOpen, setIsTitleDialogOpen] = useState(false);
  const [isConfirmReviewDialogOpen, setConfirmReviewDialogOpen] = useState(false);
  const [isSubmitReview, setIsSubmitReview] = useState(false);
  const selectedUser = useSelector(selectUser);
  const selectedUserName = String(selectedUser?.name);
  const selectedUserEmail = String(selectedUser?.email);
  const selectedUserNameOfSite = String(selectedUser?.['custom:name_of_site']);

  const pathOptions = {
    [StringFormatter.replaceWithReportName(
      reportName,
      paths.calculatorInterventionsChecklist
    )]: {
      backTo: StringFormatter.replaceWithReportName(
        reportName,
        paths.calculatorServiceData
      ),
    },
    [StringFormatter.replaceWithReportName(
      reportName,
      paths.calculatorInterventions
    )]: {
      backTo: StringFormatter.replaceWithReportName(
        reportName,
        paths.calculatorInterventionsChecklist
      ),
    },
    [StringFormatter.replaceWithReportName(
      reportName,
      paths.calculatorReport
    )]: {
      backTo: StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsSmokingCessation
      ),
    },
  };

  const onFinishReportClick = () => {
    setIsSubmitReview(true);
    if (reportName) {
      dispatch(calculatorActions.saveReport({ update: true, status: Constants.REPORT_STATUS.SUBMITTED }));
      dispatch(calculatorActions.newReportNotification(selectedUserName, selectedUserNameOfSite, selectedUserEmail));
    } else {
      setConfirmReviewDialogOpen(false);
      setIsTitleDialogOpen(true);
    }
  };

  const onSaveReport = (reportName: string) => { 
    dispatch(
      calculatorActions.saveReport({
        reportName: reportName,
        update: true,
        ...(isSubmitReview && { status: Constants.REPORT_STATUS.SUBMITTED }),
      }));
    if (isSubmitReview) {
      dispatch(calculatorActions.newReportNotification(selectedUserName, selectedUserNameOfSite, selectedUserEmail));
    }
    setIsTitleDialogOpen(false);
  };

  const onDialogConfirm = (reportName: string) => {
    currentForm.validateForm().then((currentFormErrors) => {
      if (Object.keys(currentFormErrors).length === 0) {
        currentForm.submitForm();
        onSaveReport(reportName);
      }
    });
  };

  const saveAndNext = () => {
    currentForm.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        if (!reportName) setIsTitleDialogOpen(true);
        else {
          currentForm.submitForm();
          onSaveReport(reportName);
        }
      }
    });
  };

  const onSaveAndNextClick = (event: React.FormEvent) => {
    if (touched) {
      event.preventDefault();
      saveAndNext();
    }
  };

  if (isLoading) return null;

  return (
    <footer className={classes.footer}>
      <ReportNameDialog
        isOpen={isTitleDialogOpen}
        onClose={() => setIsTitleDialogOpen(false)}
        inputCustomClass={classes.calculationsNameInput}
        onSave={onDialogConfirm}
      />
      <Dialog
        id="report__confirm-submit-report"
        title="Are you sure you want to submit this calculation for review?"
        open={isConfirmReviewDialogOpen}
        onClose={() => {
          setConfirmReviewDialogOpen(false);
        }}
        onSubmitBtnClick={onFinishReportClick}
        submitBtnText="Submit for Review"
        closeOnSubmit={false}
      >
        <p>
          Once submitted you will not be able to edit this calculation while it is being reviewed. 
          The HealthySteps National Office will reach out with next steps.
        </p>
      </Dialog>
      {pathOptions[location.pathname] && (
        <CircularButton
          type="button"
          color="primary"
          customClass={`${classes.button} ${classes.secondaryButton}`}
          onClick={() => {
            history.push(pathOptions[location.pathname].backTo);
          }}
        >
          Previous
        </CircularButton>
      )}
      {location.pathname ===
      StringFormatter.replaceWithReportName(
        reportName,
        paths.calculatorReport
      ) ? (
        <CircularButton
          type="button"
          color="primary"
          variant="contained"
          customClass={classes.reportButton}
          onClick={() => setConfirmReviewDialogOpen(true)}
        >
          Submit for Review
        </CircularButton>
      ) : (
        <CircularButton
          type="submit"
          color="primary"
          variant="contained"
          customClass={classes.button}
          onClick={onSaveAndNextClick}
        >
          Save & Next
        </CircularButton>
      )}
    </footer>
  );
};

export default withStyles(styles)(Footer);
