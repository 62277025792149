import React, { FunctionComponent, useState } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import cx from 'classnames';
import {
  FormControl,
  Input,
  FormHelperText,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { Constants } from '../../../utils';
import styles from './InputText.styles';
import useOnMount from '../../../hooks/useOnMount';

interface IProps extends WithStylesType<typeof styles> {
  id: string;
  type?: string;
  disabled?: boolean;
  name: string;
  value?: string;
  labelText?: string;
  placeholder?: string;
  customClass?: string;
  errorMessage?: string;
  autoFocus?: boolean;
  hideErrorMessage?: boolean;
  hideMaxLengthMessage?: boolean;
  hideInputContent?: boolean;
  autoComplete?: string;
  onChange?: (
    e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  endAdornment?: string | JSX.Element;
  onEndAdornmentClick?: () => void;
  onNumberChange?: (value: string) => void;
  onEnterPress?: () => void;
  maxLength?: number;
  customAction?: string | JSX.Element;
}

const NumberFormatCustom = (props: {}) => (
  <NumberFormat
    {...props}
    thousandSeparator
    allowNegative={false}
    isAllowed={(values) => {
      const { formattedValue, floatValue } = values;

      if (formattedValue === '' || floatValue === 0) {
        return true;
      }

      if (floatValue) {
        return floatValue <= 99999999.99;
      }

      return false;
    }}
  />
);

const InputText: FunctionComponent<IProps> = ({
  id,
  type = 'text',
  disabled,
  name,
  value,
  labelText,
  placeholder,
  customClass = '',
  classes,
  errorMessage,
  onChange,
  autoFocus,
  hideErrorMessage,
  hideInputContent = false,
  endAdornment,
  onEndAdornmentClick,
  onNumberChange,
  onEnterPress,
  maxLength,
  hideMaxLengthMessage,
  customAction,
  autoComplete,
}) => {
  const inputRef = React.useRef<HTMLInputElement>();
  const [showPassword, setShowPassword] = useState(false);
  const testid = `${id}-input`;

  useOnMount(() => {
    if (autoFocus && inputRef) {
      if (inputRef.current !== undefined) {
        inputRef.current.selectionStart =
          value?.length || inputRef.current.selectionStart;
      }
    }
  });

  const endAdornmentEl = () => {
    if (!endAdornment) return null;

    if (onEndAdornmentClick) {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={() => {
              if (onEndAdornmentClick) onEndAdornmentClick();
            }}
          >
            {endAdornment}
          </IconButton>
        </InputAdornment>
      );
    }

    return <InputAdornment position="end">{endAdornment}</InputAdornment>;
  };

  const numberInputProps = {
    onValueChange: (values: NumberFormatValues) =>
      onNumberChange && onNumberChange(values.value),
    decimalScale: type === Constants.INPUT_TYPES.CURRENCY ? 2 : 0,
    autocomplete: 'off',
  };

  const inputOptions = {
    [Constants.INPUT_TYPES.PASSWORD]: {
      props: {
        type: showPassword ? 'text' : 'password',
      },
      action: (
        <button
          type="button"
          className={classes.passwordToggle}
          onClick={() => setShowPassword(!showPassword)}
          data-testid={`${testid}__toggle-password-visibility`}
        >
          {showPassword ? 'Hide password' : 'Show password'}
        </button>
      ),
    },
    [Constants.INPUT_TYPES.CURRENCY]: {
      props: {
        placeholder: '0',
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        inputComponent: NumberFormatCustom,
      },
      className: classes.startAdornment,
      inputProps: numberInputProps,
    },
    [Constants.INPUT_TYPES.NUMBER]: {
      props: {
        placeholder: '0',
        inputComponent: NumberFormatCustom,
      },
      inputProps: numberInputProps,
    },
    [Constants.INPUT_TYPES.NUMBER_WITH_ADORNMENT]: {
      props: {
        placeholder: '0',
        inputComponent: NumberFormatCustom,
        endAdornment: <InputAdornment position="end">#</InputAdornment>,
      },
      className: classes.endAdornment,
      inputProps: numberInputProps,
    },
    [Constants.INPUT_TYPES.PERCENTAGE]: {
      props: {
        placeholder: '0',
        inputComponent: NumberFormatCustom,
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      },
      className: classes.endAdornment,
      inputProps: numberInputProps,
    },
    [Constants.INPUT_TYPES.TEXTAREA]: {
      props: {
        multiline: true,
        rows: 7,
      },
      className: classes.textareaInput,
    },
    [Constants.INPUT_TYPES.TEXT]: {
      props: {
        type: 'text',
      },
    },
  };

  return (
    <FormControl fullWidth className={cx({ [customClass]: customClass } )}>
      {!hideInputContent && (
        <>
          <div className={classes.header}>
            <label
              className={cx(classes.label, {
                [classes.labelError]: errorMessage,
              })}
              htmlFor={id}
              data-testid={`${testid}__label`}
            >
              {labelText}
            </label>
            {inputOptions[type].action}
            {customAction}
          </div>
          <Input
            inputRef={inputRef}
            disableUnderline
            id={id}
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            name={name}
            onChange={onChange}
            onKeyPress={(e: React.KeyboardEvent) => {
              if (onEnterPress && e.key === 'Enter') {
                e.preventDefault();
                onEnterPress();
              }
            }}
            classes={{
              root: cx(classes.inputWrapper, {
                [inputOptions[type].className || '']: inputOptions[type].className,
                [classes.inputWrapperNoLabel]: !labelText,
                [classes.inputError]: errorMessage,
              }),
              input: classes.input,
            }}
            autoFocus={autoFocus}
            endAdornment={endAdornmentEl()}
            inputProps={{
              'data-testid': `${testid}__input`,
              maxLength,
              autocomplete: autoComplete,
              ...inputOptions[type].inputProps,
            }}
            {...inputOptions[type].props}
          />
        </>
      )}
      {(maxLength && !hideMaxLengthMessage) && (
        <span className={classes.maxLength}>
          {`${value?.length} / ${maxLength}`}
        </span>
      )}
      {!hideErrorMessage && errorMessage && (
        <FormHelperText
          classes={{ root: classes.error }}
          error
          data-testid={`${testid}__error-message`}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default withStyles(styles)(InputText);
