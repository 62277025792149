const html2pdf = require('html2pdf.js');

const download = (id: string, fileName: string) => {
    const element = document.getElementById(id);
    if (element) {
      const opt = {
        margin:       1,
        filename:     `Report - ${fileName}.pdf`,
        image:        {
          type: 'jpeg',
          quality: 1
        },
        html2canvas:  {
          scale: 3,
        },
        jsPDF:        {
          unit: 'pt',
          format: 'b4',
          orientation: 'portrait'
        },
      };
      html2pdf().set(opt).from(element).save();
    }
};

export default {
  download,
};
