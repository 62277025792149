import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../../assets/styles';

export default ({ typography }: Theme): StyleRules => {
  const { pxToRem } = typography;
  return createStyles({
    container: {
      position: 'relative',
      width: '100%',
    },
    table: {
      width: '100%',
      backgroundColor: Colors.White,
      border: `1px solid ${Colors.Gray1}`,
      marginBottom: 24,
    },
    reportName: {
      color: Colors.Blue1,
      display: 'block',
    },
    specialistName: {
      color: Colors.Blue1,
      display: 'block',
    },
    specialistEmail: {
      fontSize: 13,
    },
    basicCellText: {
      fontSize: 13,
      width: '100%',
    },
    status: {
      borderRadius: 14.5,
      backgroundColor: Colors.Green3,
      width: 123,
      padding: '5px 0',
    },
    pendingStatus: {
      backgroundColor: Colors.Orange,
      '& $statusText': {
        color: Colors.White,
      },
    },
    submittedStatus: {
      backgroundColor: Colors.Red,
      '& $statusText': {
        color: Colors.White,
      },
      marginRight: '15.9%'
    },
    finishedStatus: {
      backgroundColor: Colors.Green3,
      '& $statusText': {
        color: Colors.White,
      },
    },
    statusText: {
      fontSize: 13,
      color: Colors.White,
      textAlign: 'center',
    },
    optionsButton: {
      color: Colors.Blue1,
    },
    reportWrapper: {
      height: 0,
      overflow: 'hidden',
    },
    filterButton: {
      marginLeft: pxToRem(-15),
      marginRight: pxToRem(5)
    },
    activeFilter: {
      color: Colors.Blue1
    },
    controlsContainer: {
      display: 'flex',
      justifyContent: 'end',
    },
    exportButton: {
      padding: 0,
      height: '38px',
      width: '270px',
      border: `1px solid ${Colors.Gray2}`,
      alignContent: 'end',
      backgroundColor: Colors.White,
      color: Colors.Gray10,
      marginBottom: 20,
      '&:hover': {
        color: Colors.Gray4,
      }
    },
    filterContent: {
      width: '400px',
      '& > div': {
        width: '100%',
        padding: '0 16px',
      },
    },
    select: {
      '& > div': {
        marginTop: 0,
      },
    },
    radioOption: {
      marginBottom: '16px',
    },
    radio: {
      color: Colors.Green3
    }
  });
};
