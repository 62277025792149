import dayjs from 'dayjs';

const isValid = (date: Date | null) => {
  if (!date) return false;
  return date instanceof Date && !isNaN(date.getTime());
};

const formatDate = (date: Date | null, format: string = 'MM/DD/YYYY') => dayjs(date?.toString()).format(format);

export default {
  isValid,
  formatDate,
};
