/* eslint-disable @typescript-eslint/indent */
import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { FormikProps } from 'formik';
import { Typography } from '@material-ui/core';
import InterventionItem from '../InterventionItem/InterventionItem';
import styles from './InterventionBody.styles';

interface IProps extends WithStylesType<typeof styles> {
  description?: string | React.ReactElement;
  fields?: {
    text: string;
    id: string;
    percentage?: boolean;
    showError?: boolean;
    showSlider?: boolean;
    note?: string;
  }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormikProps<any>;
  onNote: (fieldName: string, noteAction: 'add' | 'edit' | 'delete') => void;
}

const InterventionBody: FunctionComponent<IProps> = ({
  classes,
  description,
  fields,
  form,
  onNote
}) => {

  return (
    <div>
      {description && (
        <Typography className={classes.description}>{description}</Typography>
      )}
      {fields?.map((field) => (
        <InterventionItem
          key={field.id}
          field={field}
          form={form}
          onNote={onNote}
        />
      ))}
    </div>
  );
};

export default withStyles(styles)(InterventionBody);
