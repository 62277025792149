import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors, FontWeight } from '../../../assets/styles';

export default (): StyleRules =>
  createStyles({
    button: {
      textTransform: 'none',
      fontWeight: FontWeight.Bold,
      fontSize: 15,
      color: Colors.Gray,
    },
    avatarInitials: {
      backgroundColor: Colors.Green,
      fontSize: 13,
      fontWeight: FontWeight.Regular,
      width: 44,
      height: 44,
      marginRight: 16,
    },
    icon: {
      height: 'auto',
      width: 10,
      marginLeft: 13,
    },
  });
