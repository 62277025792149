const { REGION, USER_POOL_ID, USER_POOL_WEB_CLIENT_ID } = JSON.parse(
  process.env.REACT_APP_AMPLIFY_CONFIG || ''
);

export default {
  Auth: {
    mandatorySignIn: true,
    region: REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
  },
};
