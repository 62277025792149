import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import {
  FormGroup,
  FormControlLabel,
  Switch as MuiSwitch,
} from '@material-ui/core';
import cx from 'classnames';
import styles from './Switch.styles';

interface IProps extends WithStylesType<typeof styles> {
  id: string;
  checked: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  labelPlacement?: 'bottom' | 'top' | 'end' | 'start' | undefined;
  customLabelClasses?: string;
}

const Switch: FunctionComponent<IProps> = ({
  classes,
  id,
  checked,
  onChange,
  label,
  labelPlacement,
  customLabelClasses
}) => {
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <MuiSwitch
            id={id}
            name={id}
            data-testid={`${id}__switch`}
            checked={checked}
            onChange={onChange}
            color="primary"
          />
        }
        label={label}
        labelPlacement={labelPlacement}
        classes={{
          root: classes.labelRoot,
          label: cx(customLabelClasses, {
            [classes.label]: label,
            [classes.uncheckedLabel]: label && !checked,
          }),
        }}
      />
    </FormGroup>
  );
};

export default withStyles(styles)(Switch);
