import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import styles from './ReviewsDashboard.styles';
import ReviewsTable from './ReviewsTable/ReviewsTable';

const ReviewsDashboard: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const testid = 'users-table';

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography
          variant="h1"
          classes={{ root: classes.title }}
          data-testid={`${testid}__title`}
        >
          HealthySteps ROI Calculations for Review
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ReviewsTable />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ReviewsDashboard);