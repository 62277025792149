import { IResourcesGroup } from '../../../interfaces/resourcesGroup.interface';

const resources: IResourcesGroup[]  = [
    {
      header: 'Before Your ROI Calculation',
      resources: [
        {
          label: 'FAQs',
          path: '../../assets/resources/FAQs.pdf'
        },
        {
          label: 'Sample ROI Calculator Report',
          path: '../../assets/resources/Sample HealthySteps ROI Calculator Report.pdf'
        },
        {
          label: 'Optional HealthySteps ROI Data Collection Tool',
          path: '../../assets/resources/Optional HealthySteps ROI Data Collection Tool.xlsx',
          directDownload: true
        },
        {
          label: 'Babies Don’t Go to the Doctor By Themselves',
          path: '../../assets/resources/Babies Dont Go to the Doctor By Themselves.pdf'
        },
        {
          label: 'Early Childhood Behavioral Health Integration Activities and HealthySteps',
          path: '../../assets/resources/Early Childhood Behavioral Health Integration Activities and HealthyStepsSustaining Practice Averting Costs.pdf'
        },
      ]
    },
    {
      header: 'After Your ROI Calculation',
      resources: [
        {
          label: 'HealthySteps Business Case Template',
          path: '../../assets/resources/HealthySteps Business Case Template.pptx',
          directDownload: true
        },
        {
          label: 'Checklist Before Meeting with Medicaid',
          path: '../../assets/resources/Checklist Before Meeting with Medicaid .pdf'
        },
        {
          label: 'Qs to Anticipate from Medicaid',
          path: '../../assets/resources/Qs to Anticipate from Medicaid.pdf'
        },
      ]
    }
  ];

  export default resources;