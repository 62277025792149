import Calculator from './shared/base';

export default class ServiceData extends Calculator {
  constructor({
    state,
    dcInput1,
    dcInput2,
    dcInput3,
    dcInput4,
    csInput1,
    csInput2,
    csInput3,
    csInput4,
    csInput5,
  }: {
    state: string;
    dcInput1: number;
    dcInput2: number;
    dcInput3: number;
    dcInput4: number;
    csInput1: number;
    csInput2: number;
    csInput3: number;
    csInput4: number;
    csInput5: number;
  }) {
    super({ state });
    this.dcInput1 = dcInput1;
    this.dcInput2 = dcInput2;
    this.dcInput3 = dcInput3;
    this.dcInput4 = dcInput4;
    this.csInput1 = csInput1;
    this.csInput2 = csInput2;
    this.csInput3 = csInput3;
    this.csInput4 = csInput4;
    this.csInput5 = csInput5;
  }

  /**
   * First 4 inputs in delivery cost section
   * @names dc_input_1, dc_input_2, dc_input_3, dc_input_4
   * @excel Excel F3, Excel F4
   */
  public dcInput1;

  public dcInput2;

  public dcInput3;

  public dcInput4;

  /**
   * Total site service delivery cost during the 12-month date range
   * @name dc_input_5
   * @excel Excel F5 = Excel F4 + Excel F
   * @formula dc_input_5 = (dc_input_1 + dc_input_2 + dc_input_3+ dc_input_4) * medicaid_perc_of_caseload
   */
  public dcInput5 = () => {
    return this.isFiniteNumber(
      this.dcInput1 + this.dcInput2 + this.dcInput3 + this.dcInput4
    );
  };

  /**
   * Total children ages 0-3 served (panel size) during the 12-month date range
   * @name cs_input_1
   * @excel Excel D2
   */
  public csInput1;

  /**
   * cs_input_2 = Total children with Medicaid ages 0-3 served (panel size) within the 12-month date range
   * @excel Excel C2
   */
  public csInput2;

  /**
   * @name medicaid_perc_of_caseload
   * @excel Excel C4
   * @formula cs_input_2 / cs_input_1
   */
  public medicaidPercOfCaseload = () => {
    return this.isFiniteNumber(this.csInput2 / this.csInput1);
  };

  /**
   * @name perc_children_under_age_one
   * @excel Not In Excel But Require For Other Interventions
   * @formula cs_input_5 / cs_input_4
   */
  public percChildrenUnderAgeOne = () => {
    return this.isFiniteNumber(this.csInput5 / this.csInput4);
  };

  /**
   * Total children ages 0-3 in Tiers 2 & 3 served by the HS Specialist(s) within the 12-month date range
   * @name cs_input_3
   * @excel Excel C3
   */
  public csInput3;

  /**
   * Total Medicaid children 0-3 in Tiers 2 & 3 served by the HS Specialist(s) within past 12 months (it should be lower than cs_input_3)
   * @name cs_input_4
   * @excel Excel C5
   * @formula cs_input_3 * medicaid_perc_of_caseload
   */
  public csInput4;

  /**
   * Total Medicaid children, under age 1, in Tiers 2 & 3 served by the HS Specialist(s) within past 12 months (it should be lower than cs_input_4)
   * @name cs_input_5
   * @excel Not In Excel (but it requires user input)
   */
  public csInput5;
}
