import React, { FunctionComponent, useState } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableHead,
  TableCell as TC,
  Button,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import ReviewsTableBody from './ReviewTableBody/ReviewsTableBody';
import useOnMount from '../../../../hooks/useOnMount';
import { InputText, Spinner } from '../../../../components';
import {
  calculatorActions,
  selectReportsList,
  selectHasMoreToLoad,
  selectIsLoading
} from '../../../../redux/calculatorSlice';
import Report from '../../../Calculator/CalculatorReport/Report/Report';
import styles, { tableCellStyles } from './ReviewsTable.styles';
import { Constants, StringFormatter } from '../../../../utils';

export const TableCell = withStyles(tableCellStyles)(TC);

const ReviewsTable: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const testid = 'reviews-table';
  const isLoading = useSelector(selectIsLoading);
  const reports = useSelector(selectReportsList);
  const hasMoreData = useSelector(selectHasMoreToLoad);
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();

  const headerOptions = [
    {
      title: 'Name of Report',
      testid: `${testid}__name-of-report-header-cell`,
    },
    {
      title: 'Submitted / Completed',
      testid: `${testid}__submitted-header-cell`,
    },
    {
      title: 'Specialist',
      testid: `${testid}__specialist-cell`,
    },
    {
      title: 'Name of Site',
      testid: `${testid}__name-of-site-cell`,
    },
    {
      title: 'Status',
      testid: `${testid}__status-cell`,
    },
    {
      title: '',
      testid: `${testid}__null-cell`,
    },
  ];

  useOnMount(() => {
    dispatch(calculatorActions.clearReports());
    dispatch(calculatorActions.getReviewReports());
  });
  
  return (
    <>
      <div className={classes.container}>
        <Spinner show={isLoading} inline />
        <form className={classes.searchForm}>
          <InputText
            id="search"
            name="search"
            placeholder="Search by name"
            customClass={classes.searchInput}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.currentTarget.value);
            }}
            endAdornment={<SearchIcon />}
          />
        </form>
        <Table className={classes.table} data-testid={`${testid}__table`}>
          <TableHead data-testid={`${testid}__table-header`}>
            <TableRow>
              {headerOptions.map((option) => (
                <TableCell key={option.title} data-testid={option.testid}>
                  {option.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <ReviewsTableBody reports={reports} />
        </Table>
        {reports?.map((report, idx) => (
          <div key={idx} className={classes.reportWrapper}>
            <Report
              id={report.name}
              report={report.summary.report}
              interventionsChecklist={report.interventionsChecklist}
              isFinished={report.status === Constants.REPORT_STATUS.FINISHED}
              lastDateUpdated={report.updatedISO}
              titleText={report.siteName || StringFormatter.getStateName(report.state)}
            />
          </div>
        ))}
        {!isLoading && !reports.length && (
        <Typography
          className={classes.noResults}
          data-testid={`${testid}__no-results-found`}
        >
          We're sorry, no results found.
        </Typography>
        )}
        {hasMoreData && (
          <Button
            fullWidth
            className={classes.moreBtn}
            onClick={() => dispatch(calculatorActions.getReviewReports())}
            data-testid={`${testid}__load-more`}
          >
            Load more
          </Button>
        )}
      </div>
    </>
  );
};

export default withStyles(styles)(ReviewsTable);