import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import cx from 'classnames';
import history from '../../routes/history';
import useOnMount from '../../hooks/useOnMount';
import MainNavigation from '../MainNavigation/MainNavigation';
import Header from '../Header/Header';
import styles from './PageLayout.styles';

interface IProps extends WithStylesType<typeof styles> {
  footer?: boolean;
}

const PageLayout: FunctionComponent<IProps> = ({
  classes,
  children,
  footer,
}) => {
  const id = 'page-layout';

  useOnMount(() => {
    const unlisten = history.listen(() => {
      const container = document.getElementById(id);

      if (container) {
        container.scrollTop = 0;
      }
    });
    return () => {
      unlisten();
    };
  });

  return (
    <div className={classes.container}>
      <MainNavigation />
      <div className={classes.content}>
        <Header />
        <div
          id={id}
          className={cx(classes.main, {
            [classes.mainWithFooter]: footer,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(PageLayout);
