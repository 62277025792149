import { createSlice } from '@reduxjs/toolkit';
import { SnackbarOptions } from '../../interfaces/snackbar.interface';
import { AppThunk, IRootState } from '../../interfaces/redux.interface';

export const selectSnackbar = (state: IRootState) => state.snackbar;

const initialState = {
  severity: '',
  message: '',
  open: false,
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbar: (state, action) => {
      state.open = true;
      state.severity = action.payload.severity;
      state.message = action.payload.message;
    },
    clearSnackbar: (state) => initialState,
  },
});

const { setSnackbar, clearSnackbar } = snackbarSlice.actions;

const success = (message: string): AppThunk => (dispatch) =>
  dispatch(setSnackbar({ severity: SnackbarOptions.Success, message }));

const warning = (message: string): AppThunk => (dispatch) =>
  dispatch(setSnackbar({ severity: SnackbarOptions.Warning, message }));

const info = (message: string): AppThunk => (dispatch) =>
  dispatch(setSnackbar({ severity: SnackbarOptions.Info, message }));

const error = (message: string): AppThunk => (dispatch) =>
  dispatch(setSnackbar({ severity: SnackbarOptions.Error, message }));

const normal = (message: string): AppThunk => (dispatch) =>
  dispatch(setSnackbar({ severity: '', message }));

export const snackbarReducer = snackbarSlice.reducer;
export const snackbarActions = {
  success,
  warning,
  info,
  error,
  normal,
  clearSnackbar,
};
