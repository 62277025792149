import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { Typography, FormHelperText } from '@material-ui/core';
import { InputText, Slider } from '../../../../../components';
import InterventionItemNote from '../InterventionItemNote/InterventionItemNote';
import styles from './InterventionItem.styles';
import { Constants } from '../../../../../utils';
import { selectServiceData } from '../../../../../redux/calculatorSlice';
import { ImmunizationsForm } from '../../../../../interfaces/interventions.interface';

type Props = WithStylesType<typeof styles> & {
  key: React.Key | null | undefined;
  field: {
    text: string;
    id: string;
    percentage?: boolean;
    showError?: boolean;
    showSlider?: boolean;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormikProps<any>;
  onNote: (fieldName: string, noteAction: 'add' | 'edit' | 'delete') => void;
};

const InterventionItem: FunctionComponent<Props> = ({
  field,
  form,
  classes,
  onNote,
}) => {
  const noteFieldId = `${field.id}_note`;
  const note = form.values[noteFieldId];
  const serviceData = useSelector(selectServiceData);
  const totalChildrenServed = +serviceData['children_served.children_0_to_3_tier_2_and_3'];
  const showPercentageLabel = (!field.percentage &&
    field.id !== ImmunizationsForm.NumberOfEncourageVisits &&
    form.values[field.id]);

  const getPercentage = (val: Number) =>
    Math.trunc((+val / totalChildrenServed) * 100);

  return (
    <div className={classes.fieldWrapper}>
      <div className={classes.fieldContent}>
        <div>
          <Typography className={classes.fieldText}>
            {field.text}
          </Typography>
          {(field.percentage && field.showSlider) &&
            <Slider
              id={`${field.id}__slider`}
              label=""
              value={form.values[field.id]}
              hideInput={true}
              onSliderChange={(sliderValue) => {
                form.setFieldValue(
                  field.id,
                  sliderValue
                );
              }}
            />}
        </div>
        <div className={classes.inputWrapper}>
          <InputText
            id={field.id}
            name={field.id}
            value={form.values[field.id]}
            onNumberChange={(value) => {
              form.setFieldValue(field.id, value);
            }}
            type={
              field.percentage
                ? Constants.INPUT_TYPES.PERCENTAGE
                : Constants.INPUT_TYPES.NUMBER_WITH_ADORNMENT
            }
            customClass={classes.input}
            hideErrorMessage
            errorMessage={
              typeof form.errors[field.id] === 'string'
                ? (form.errors[field.id] as string)
                : ''
            }
          />
          {showPercentageLabel &&
            <span className={classes.subLabel}>
              {`(${getPercentage(form.values[field.id])}% of Tier 2 & 3)`}
            </span>}
        </div>
      </div>
      <InterventionItemNote note={note} noteName={noteFieldId} onNote={onNote} />
      {field.showError &&
        form.errors[field.id] &&
        form.errors[field.id] !==
        Constants.FORM_ERRORS.REQUIRED_FIELD_ERROR && (
          <FormHelperText classes={{ root: classes.error }} error>
            {form.errors[field.id]}
          </FormHelperText>
        )}
    </div>
  );
};

export default withStyles(styles)(InterventionItem);