import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { FontWeight } from '../../assets/styles';

export default makeStyles(
  ({ typography }: Theme): StyleRules => {
    const { pxToRem } = typography;

    return {
      container: {
        boxShadow: 'none',
        borderRadius: '22.5px',
        height: pxToRem(45),
        fontSize: pxToRem(14),
        fontWeight: FontWeight.Bold,
        width: '100%',
      },
    };
  }
);
