import React, { FunctionComponent } from 'react';
import { Typography, Button } from '@material-ui/core';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import styles from './InterventionItemNote.styles';
import { Constants } from '../../../../../utils';

const { DIALOG_MODES } = Constants;

type Props = WithStylesType<typeof styles> & {
  note?: string;
  noteName: string;
  onNote: (fieldName: string, noteAction: 'add' | 'edit' | 'delete') => void;
};

const InterventionItemNote: FunctionComponent<Props> = ({
  note,
  noteName,
  onNote,
  classes,
}) => {
  return (
    <>
      {
        note ?
          <div className={classes.noteContainer}>
            <div className={classes.noteWrapper}>
              <Typography className={classes.note}>
                {`Note: ${note}`}
              </Typography>
            </div>
            <div className={classes.noteActions}>
              <Button
                className={classes.noteAction}
                onClick={() => onNote(noteName, DIALOG_MODES.DELETE)}
                data-testid={`${noteName}__delete`}
              >
                Delete note
              </Button>
              <Button
                className={classes.noteAction}
                onClick={() => onNote(noteName, DIALOG_MODES.EDIT)}
                data-testid={`${noteName}__edit`}
              >
                Edit note
              </Button>
            </div>
          </div>
          :
          <div className={classes.noteActions}>
            <Button
              className={classes.noteAction}
              onClick={() => onNote(noteName, DIALOG_MODES.ADD)}
              data-testid={`${noteName}__add`}
            >
              Add note
            </Button>
          </div>
      }
    </>
  );
};

export default withStyles(styles)(InterventionItemNote);