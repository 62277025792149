import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import CalculationsComponent from './CalculationsComponent/CalculationsComponent'
import styles from './CalculationsDashboard.styles';

const CalculationsDashboard: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const testid = 'admin-reports-dashboard';
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography
          variant="h1"
          classes={{ root: classes.title }}
          data-testid={`${testid}__title`}
        >
          HealthySteps ROI Calculations
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CalculationsComponent />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(CalculationsDashboard);