import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ResourcesGroup from './ResourcesGroup';
import styles from './ResourcesDashboard.styles';
import resourcesGroups from './resources';

type props = WithStylesType<typeof styles>;

const ResourcesDashboard: FunctionComponent<props> = ({ classes }) => {
  return (
    <Grid container spacing={2} className={classes.root}>
      {
        resourcesGroups && resourcesGroups.length && resourcesGroups.map((resourcesGroup, index) =>
          <Grid 
            item
            xs={12}
            sm={6}
            key={`${resourcesGroup.header}_${index}`}
            className={classes.resourceGroup}>
            {
              <ResourcesGroup resourcesGroup={resourcesGroup} />
            }
          </Grid>
        )
      }
    </Grid>
  );
};

export default withStyles(styles)(ResourcesDashboard);

