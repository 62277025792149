import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { FontWeight, Colors, Breakpoints } from '../../../assets/styles';

export default ({ typography, breakpoints }: Theme): StyleRules => {
  const { pxToRem } = typography;

  return createStyles({
    container: {
      maxWidth: 450,
      margin: 'auto',
      textAlign: 'center',
      [breakpoints.up(Breakpoints.SM)]: {
        paddingBottom: 100,
      },
    },
    hsLogo: {
      margin: '0 auto',
      display: 'block',
      width: '100%',
      height: pxToRem(150),
    },
    input: {
      paddingTop: pxToRem(34),
      '& label': {
        letterSpacing: 1.38,
        textTransform: 'uppercase',
        fontSize: 12,
      },
    },
    title: {
      fontSize: pxToRem(36),
      paddingTop: pxToRem(26),
      [breakpoints.down(Breakpoints.SM)]: {
        fontSize: 26,
      },
    },
    subtitle: {
      paddingTop: pxToRem(26),
      fontSize: pxToRem(18),
      fontWeight: FontWeight.Light,
      color: Colors.Gray4,
    },
    submitButton: {
      width: '100%',
      maxWidth: 300,
      display: 'block',
      margin: `${pxToRem(41)} auto 0`,
    },
    rulesList: {
      display: 'flex',
      flexWrap: 'wrap',
      textAlign: 'left',
    },
    rulesItem: {
      width: '50%',
      lineHeight: '22px',
      fontSize: 14,
      color: Colors.Gray4,
      fontWeight: FontWeight.Light,
      paddingLeft: 20,
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        backgroundColor: Colors.Blue1,
        width: 3,
        height: 3,
        borderRadius: '50%',
        top: '50%',
        left: 6,
        transform: 'translateY(-50%)',
      },
      [breakpoints.down(Breakpoints.XS)]: {
        fontSize: 12,
      },
    },
    error: {
      fontSize: pxToRem(15),
      fontWeight: FontWeight.Regular,
      color: Colors.Red,
      paddingTop: pxToRem(20),
    },
  });
};
