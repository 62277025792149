import { createSlice } from '@reduxjs/toolkit';
import { IRootState } from '../../interfaces/redux.interface';
import { IDashboardState } from '../../interfaces/dashboard.interface';

export const selectIsDashboardLoading = (state: IRootState) =>
  state.dashboard.isLoading;

const initialState: IDashboardState = {
  isLoading: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const dashboardReducer = dashboardSlice.reducer;
export const dashboardActions = {
  ...dashboardSlice.actions,
};
