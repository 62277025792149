import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { selectIsDashboardLoading } from '../../redux/dashboardSlice';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Copyright, PageLayout, Spinner } from '../../components';
import styles from './Dashboard.styles';

const Dashboard: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
  children,
}) => {
  const isLoading = useSelector(selectIsDashboardLoading);
  return (
    <>
      <Spinner show={isLoading} />
      <PageLayout>
        <div className={classes.container}>
          {children}
          <Copyright color="blue">
            Copyright 2020 ZERO TO THREE. All rights reserved.
          </Copyright>
        </div>
      </PageLayout>
    </>
  );
};

export default withStyles(styles)(Dashboard);
