/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { Auth as AwsAuth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { IUserCredentials } from '../interfaces/auth.interface';
import usersService from './users.service';

const cognitoUser$ = new BehaviorSubject<CognitoUser | null>(null);

const setCognitoUser = (state: CognitoUser) => {
  cognitoUser$.next(state);
};

const getCognitoUser = () => cognitoUser$.getValue();

const useCognitoUserState = () => {
  const [value, setState] = useState<CognitoUser | null>(
    cognitoUser$.getValue()
  );
  useEffect(() => {
    const sub = cognitoUser$.subscribe((s) => setState(s));
    return () => sub.unsubscribe();
  });
  return [value, setCognitoUser];
};

const signIn = ({ username, password }: IUserCredentials) =>
  AwsAuth.signIn(username, password);

const signOut = () => AwsAuth.signOut();

const forgotPassword = (username: string) =>
  AwsAuth.forgotPassword(username.trim().toLowerCase());

const forgotPasswordSubmit = (
  username: string,
  code: string,
  password: string
) =>
  AwsAuth.forgotPasswordSubmit(username.trim().toLowerCase(), code, password);

const verifyEmail = (user: CognitoUser | null, code: string) =>
  AwsAuth.verifyUserAttributeSubmit(user, 'email', code);

const resendCode = (user: CognitoUser | null) => AwsAuth.verifyUserAttribute(user, 'email');

const currentAuthenticatedUser = () => AwsAuth.currentAuthenticatedUser();

const completeNewPassword = (user: CognitoUser | null, password: string) =>
  AwsAuth.completeNewPassword(user, password).then((currentUser) => {
    return usersService.adminUpdateUserAttributes(
      {
        UserAttributes: [
          {
            Name: 'email_verified',
            Value: 'true',
          },
        ],
        Username: currentUser.challengeParam.userAttributes.email
      }
    );
  });

export default {
  signIn,
  signOut,
  currentAuthenticatedUser,
  completeNewPassword,
  setCognitoUser,
  getCognitoUser,
  useCognitoUserState,
  forgotPassword,
  forgotPasswordSubmit,
  verifyEmail,
  resendCode,
};
