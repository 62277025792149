/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent, Fragment } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Typography, Grid, Divider } from '@material-ui/core';
import { StringFormatter, Constants } from '../../../../utils';
import { ISummaryReport } from '../../../../interfaces/calculator.interface';
import hsLogo from '../../../../assets/icons/hs-logo-color.svg';
import styles from './ReportHeader.styles';

interface IProps extends WithStylesType<typeof styles> {
  report: ISummaryReport;
}

interface IInfoLine {
  label: string;
  value: number | string | null;
  type?: string;
}

const ReportHeader: FunctionComponent<IProps> = ({ classes, report }) => {
  const headerInfo = [
    { 
      section: 'Patient Population',
      lines: [
        {
          label: 'Children 0-3',
          value: report.totalChildren0to3,
          type: Constants.STRING_TYPES.NUMBER_WITH_COMMAS,
        },
        {
          label: 'Children with Medicaid 0-3',
          value: report.totalChildrenMedicaid0to3,
          type: Constants.STRING_TYPES.NUMBER_WITH_COMMAS,
        },
        {
          label: 'Percent of Panel Size with Medicaid',
          value: report.medicaidPercentageOfTotals,
          type: Constants.STRING_TYPES.PENCENTAGE,
        },
      ],
    },
    {
      section: 'Annual HealthySteps Costs',
      lines: [
        {
          label: 'Total Cost',
          value: report.totalAnnualProgramCost,
          type: Constants.STRING_TYPES.MONEY,
        },
        {
          label: 'Medicaid Cost',
          value: report.totalAnnualMedicaidProgramCost,
          type: Constants.STRING_TYPES.MONEY,
        },
        {
          label: 'Cost per Child with Medicaid',
          value: report.annualCostPerChild,
          type: Constants.STRING_TYPES.MONEY,
        },
      ],
    },
    {
      section: 'Annual Medicaid Savings',
      lines: [
        {
          label: 'Total Savings',
          value: report.totalSavingsAvg,
          type: Constants.STRING_TYPES.MONEY,
        },
        {
          label: 'Savings per Child with Medicaid',
          value: report.costSavingsPerMedicaidFamily,
          type: Constants.STRING_TYPES.MONEY,
        },
      ],
    },
    {
      section: 'Annual Medicaid Return on Investment',
      lines: [
        {
          label: 'Overall Medicaid ROI',
          value: report.annualReturnOnInvestmentPerc,
          type: Constants.STRING_TYPES.PENCENTAGE,
        },
      ],
    },
  ];

  const getHeaderInfo = () => {
    const buildLines = (lines: IInfoLine[]) => {
      return lines.map((line: IInfoLine, index: number) => {
        let val: string | number = Constants.STRING_TYPES.EMPTY;
  
        const setFormat = (type: string, value: number | string) => {
          switch (type) {
            case Constants.STRING_TYPES.MONEY: {
              return StringFormatter.money(value.toString());
            }
            case Constants.STRING_TYPES.PENCENTAGE: {
              return StringFormatter.percentage(value.toString(), 0, 0);
            }
            case Constants.STRING_TYPES.NUMBER_WITH_COMMAS: {
              return StringFormatter.numberWithCommas(value.toString());
            }
            default: {
              return value;
            }
          }
        };

        if (line.value) {
          val = line.type ? setFormat(line.type, line.value) : line.value;
        }

        return (
          <Fragment key={index}>
            <Grid item xs={9}>{line.label}</Grid>
            <Grid item xs={3} className={classes.alignRight}>{val}</Grid>
          </Fragment>
        );
      });
    };

    return headerInfo.map(({ section, lines }, index) => (
      <Fragment key={index}>
        <Typography variant="h2" className={classes.contentSubTitle}>
          {section}
        </Typography>
        <Grid container className={classes.calculatedItems}>
          {buildLines(lines)}
        </Grid>
      </Fragment>
    ));
  };

  const getSavingsPerDollar = () => 
    StringFormatter.money((report.anEstimatedDollarsInSavings + 1).toString());

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} className={classes.leftPadding}>
        <Typography variant="h2" className={classes.contentTitle}>
          HealthySteps Cost Savings and Medicaid Return on Investment (ROI)
        </Typography>
        <Grid container className={classes.leftHalf}>
          {getHeaderInfo()}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.rightHalf}>
          <img className={classes.hsLogo} src={hsLogo} alt="" />
          <div className={classes.headerBanner}>
            <Divider variant="middle" className={classes.divider} />
            {`For every $ 1 invested in HealthySteps, an estimated ${getSavingsPerDollar()} in savings is realized by the state Medicaid agency annually.`}
            <Divider variant="middle" className={classes.divider} />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ReportHeader);
