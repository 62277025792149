import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { List } from '@material-ui/core';
import styles from './ResourcesGroup.styles';
import ResourceItem from './ResourceItem';
import { IResourcesGroup } from '../../../interfaces/resourcesGroup.interface';

type props = WithStylesType<typeof styles> & {
  resourcesGroup: IResourcesGroup;
};

const ResourcesGroup: FunctionComponent<props> = ({ classes, resourcesGroup }) => {
  const { header, resources } = resourcesGroup;
  return (
    <>
      <div className={classes.groupHeader}>{header}</div>
      <List>
        {
          resources.map((resource, index) =>
            <ResourceItem key={`${resource.label}_${index}`} resource={resource} />
          )
        }
      </List>
    </>
  );
};

export default withStyles(styles)(ResourcesGroup);