import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Colors, FontWeight } from '../../../../assets/styles';

const amount: CSSProperties = {
  width: '25%',
  color: Colors.Gray,
  fontSize: 14,
  textAlign: 'center',
};

const title: CSSProperties = {
  color: Colors.Blue,
  fontSize: 16,
  fontWeight: FontWeight.Light,
};

export default (): StyleRules => ({
  table: {
    marginBottom: 80,
    marginTop: 30,
  },
  columnTitle: {
    color: Colors.Gray,
    fontWeight: FontWeight.Light,
    fontSize: 14,
    textAlign: 'center',
    padding: '12px',
  },
  sectionTitle: {
    ...title,
    padding: '12px',
  },
  subsectionTitle: {
    ...title,
    backgroundColor: 'rgba(204,204,204,0.1)',
    fontSize: 14,
    paddingLeft: 76,
    padding: '12px',
  },
  sectionAmount: {
    ...amount,
    padding: '12px',
  },
  subsectionAmount: {
    ...amount,
    backgroundColor: 'rgba(204,204,204,0.1)',
    padding: '12px',
  },
});

export const tableCellStyles = ({
  typography,
  breakpoints,
}: Theme): StyleRules => {
  return createStyles({
    root: {
      border: `1px solid ${Colors.Gray5}`,
    },
  });
};
