import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { Colors, FontWeight } from '../../../../assets/styles';

export default ({ typography }: Theme): StyleRules => {
  const { pxToRem } = typography;

  return createStyles({
    container: {},
    title: {
      fontSize: 13,
      color: Colors.Gray,
      fontWeight: FontWeight.Bold,
      textTransform: 'uppercase',
      marginTop: pxToRem(50),
    },
    noReports: {
      width: '100%',
      maxWidth: 488,
      margin: `${pxToRem(75)} auto`,
      textAlign: 'center',
    },
    noReportsTitle: {
      fontSize: 22,
      color: Colors.Gray6,
      fontWeight: FontWeight.Bold,
      marginBottom: 16,
    },
    noReportsDescription: {
      fontSize: 13,
      color: Colors.Gray6,
      fontWeight: FontWeight.Regular,
    },
    reportsList: {
      paddingTop: '23px',
    },
    loadMoreProgress: {
      margin: '15px 0',
    },
    moreBtn: {
      fontSize: 14,
      fontWeight: FontWeight.Bold,
      color: Colors.Blue1,
      marginBottom: 15,
    },
    spaceBottom: {
      marginBottom: 30,
    },
  });
};
