import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { FontWeight, Colors } from '../../../assets/styles';

export default ({ typography }: Theme): StyleRules => {
  const { pxToRem } = typography;

  return createStyles({
    input: {
      paddingTop: pxToRem(34),
      '& label': {
        fontSize: 12,
        letterSpacing: 1.38,
        textTransform: 'uppercase',
      },
    },
    submitButton: {
      maxWidth: 300,
      display: 'block',
      margin: `${pxToRem(41)} auto 0`,
    },
    requestAccessButton: {
      maxWidth: 300,
      display: 'block',
      margin: `${pxToRem(15)} auto 0`,
    },
    forgotLinkWrapper: {
      textAlign: 'right',
      paddingTop: pxToRem(14),
    },
    forgotLink: {
      fontSize: pxToRem(15),
    },
    error: {
      fontSize: pxToRem(15),
      fontWeight: FontWeight.Regular,
      color: Colors.Red,
      paddingTop: pxToRem(20),
    },
  });
};
