import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { FontWeight, Colors } from '../../../../assets/styles';

export default ({ typography, breakpoints }: Theme): StyleRules => {
  const { pxToRem } = typography;

  return createStyles({
    container: {
      position: 'relative',
      width: '100%',
    },
    tableContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '16px 34px',
      border: `1px solid ${Colors.Gray1}`,
      borderRadius: 2,
      backgroundColor: Colors.White,
      boxShadow: '0 2px 2px 0 rgba(0,0,0,0.05)',
      marginBottom: 15,
    },
    text: {
      textAlign: 'center',
      fontWeight: 'bold',
      marginBottom: '60px'
    },
    textLink: {
      fontSize: 20,
      fontWeight: 'bold'
    },
    table: {
      width: '100%',
      backgroundColor: Colors.White,
      border: `1px solid ${Colors.Gray1}`,
      marginBottom: 24,
    },
    searchForm: {
      marginBottom: 20,
    },
    searchInput: {
      maxWidth: 340,
    },
    moreBtn: {
      fontSize: 14,
      fontWeight: FontWeight.Bold,
      color: Colors.Blue1,
      marginBottom: 30,
    },
    noResults: {
      textAlign: 'center',
      fontSize: pxToRem(22),
      paddingTop: pxToRem(130),
    },
    reportWrapper: {
      height: 0,
      overflow: 'hidden',
    },
  });
};

export const tableCellStyles = ({
  typography,
  breakpoints,
}: Theme): StyleRules => {
  return createStyles({
    root: {
      paddingLeft: 24,
    },
    head: {
      fontWeight: FontWeight.Bold,
      color: Colors.Gray,
      fontSize: 15,
      paddingTop: 8,
      paddingBottom: 8,
    },
    body: {
      color: Colors.Gray,
      fontSize: 15,
    },
  });
};