export default Object.freeze({
  White: '#ffffff',
  Black: ' #000000',
  Gray: '#666666',
  Gray1: '#e3eaf2',
  Gray2: '#cccccc',
  Gray3: '#EAEDF3',
  Gray4: '#333333',
  Gray5: '#F2F2F2',
  Gray6: '#999999',
  Gray7: '#F9F9F9',
  Gray8: 'rgba(204,204,204,0.1)',
  Gray9: '#F1F1F1',
  Gray10: '#777777',
  Blue: '#143f65',
  Blue1: '#008DCD',
  Blue2: '#0260A0',
  Red: '#F44336',
  Green: '#73C8C3',
  Green1: '#2F8B74',
  Green2: '#238F7A',
  Green3: '#5CD7B4',
  Orange: '#FFBE41',
  Orange1: 'rgba(255,190,65,0.25)',
  Orange2: '#f15a4b',
  Orange3: 'rgba(255,190,65,0.5)',
  Yellow: '#FFBE41',
});
