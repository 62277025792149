import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import RPForm from './RPForm/RPForm';
import styles from './ResetPassword.styles';

const ResetPassword: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  return (
    <div className={classes.container}>
      <RPForm />
    </div>
  );
};

export default withStyles(styles)(ResetPassword);
