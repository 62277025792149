import React, { FunctionComponent, useEffect } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import cx from 'classnames';
import { Typography, Button, LinearProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ReportItem from './ReportItem/ReportItem';
import useOnMount from '../../../../hooks/useOnMount';
import { Tooltip } from '../../../../components';
import {
  calculatorActions,
  selectIsLoading,
  selectReportsList,
  selectHasMoreToLoad,
} from '../../../../redux/calculatorSlice';
import { ISummaryReport } from '../../../../interfaces/calculator.interface';
import { IInterventionsChecklist } from '../../../../interfaces/interventions.interface';
import styles from './ReportsList.styles';

const testId = 'dashboard-section';

const ReportsList: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const testid = 'reports';
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const reports = useSelector(selectReportsList);
  const hasMoreData = useSelector(selectHasMoreToLoad);

  useEffect(() => () => {
    dispatch(calculatorActions.clearReports());
  }, [dispatch]);

  useOnMount(() => {
    dispatch(calculatorActions.clearReports());
    dispatch(calculatorActions.getReports());
  });

  const description = 
    <>
      Here you may continue editing an <i>in progress</i> calculation and download a <i>finished</i> calculation report. If your report is <i>under review</i>, 
      the HealthySteps National Office will reach out soon with next steps to finish the calculation.
    </>;

  return (
    <div className={classes.container}>
      <Typography
        variant="h2"
        className={classes.title}
        data-testid={`${testid}__title`}
      >
        Your Reports
        <Tooltip
          id={`${testId}__dashboard-section`}
          title={description}
          placement="right"
        >
          {}
        </Tooltip>
      </Typography>
      <div className={cx(!hasMoreData ? classes.spaceBottom : '')}>
        {reports && reports.length ? (
          <div className={classes.reportsList}>
            {reports.map(
              (report: {
                summary: {
                  totalSavingsHigh: string;
                  totalSavingsLow: string;
                  report: ISummaryReport;
                };
                interventionsChecklist: IInterventionsChecklist;
                updated: number;
                name: string;
                status: string;
                updatedISO: string;
                siteName: string;
                state: string;
              }) => (
                <ReportItem key={report.name} report={report} />
              )
            )}
          </div>
        ) : !isLoading && (
          <div className={classes.noReports}>
            <Typography
              variant="h3"
              className={classes.noReportsTitle}
              data-testid={`${testid}__no-reports-title`}
            >
              You do not have any
              <br /> ROI Calculation Reports to view.
            </Typography>
            <Typography
              className={classes.noReportsDescription}
              data-testid={`${testid}__no-reports-description`}
            >
              Please click "START THE CALCULATOR" above to generate ROI
              savings and corresponding ROI Calculation Reports.
            </Typography>
          </div>
        )}
      </div>
      {isLoading && <LinearProgress className={classes.loadMoreProgress} />}
      {hasMoreData && (
        <Button
          fullWidth
          className={classes.moreBtn}
          onClick={() => dispatch(calculatorActions.getReports())}
          data-testid={`${testid}__load-more`}
        >
          Load more
        </Button>
      )}
    </div>
  );
};

export default withStyles(styles)(ReportsList);
