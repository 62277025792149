/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FunctionComponent, useState, useLayoutEffect } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, FormikErrors } from 'formik';
import { CircularButton, InputText, Spinner } from '../../components';
import { HSLogoColorIcon } from '../../assets/icons';
import { AppThunkDispatch } from '../../interfaces/redux.interface';
import { FormValidations } from '../../utils';
import { authActions, selectIsLoading } from '../../redux/authSlice';
import { paths } from '..';
import authService from '../../services/auth.service';
import styles from './VerifyEmail.styles';

const awsVerifyEmailError = 'Invalid code provided, please request a code again.';

interface FormValues {
  code: string;
  aws: boolean;
}

const VerifyEmail: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const dispatch: AppThunkDispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const history = useHistory();
  const [cognitoUser] = authService.useCognitoUserState();

  useLayoutEffect(() => {
    if (!cognitoUser) {
      history.push(paths.calculatorDashboard);
    }
    return () => {
      if (!cognitoUser) {
        dispatch(authActions.signOut());
      }
    };
    // eslint-disable-next-line
  }, [cognitoUser, history]);

  const initialValues: FormValues = {
    code: '',
    aws: false,
  };

  const onSubmit = (
    values: FormValues,
    { setErrors }: { setErrors: (errors: FormikErrors<FormValues>) => void },
  ) => {
    setErrors({ aws: '' });
    setSubmitted(true);

    dispatch(authActions.verifyEmail(values.code))
      .then(() => {
        history.push(paths.calculatorDashboard);
      })
      . catch(() => {
        setErrors({ aws: awsVerifyEmailError });
        setSubmitted(false);
      });
  };

  const validationSchema = Yup.object().shape({
    code: FormValidations.required('Verification Code'),
  });

  const form = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <>
      <div className={classes.root}>
        <div className={classes.containerColumn}>
          <div className={classes.main}>
            <HSLogoColorIcon className={classes.zeroLogo} />
            <h1 className={classes.title}>Verify Your Email</h1>
            <p className={classes.note}>Enter your verification code</p>
            <form
              className={classes.form}
              onSubmit={form.handleSubmit}
            >
              <InputText
                id="code"
                name="code"
                onChange={form.handleChange}
                value={form.values.code}
                labelText="Verification Code"
                placeholder="Enter Verification Code"
                errorMessage={form.errors.code || form.errors.aws}
                customClass={classes.inputMail}
              />
              <div className={classes.formBottom}>
                <CircularButton
                  color="primary"
                  type="submit"
                  variant="contained"
                  customClass={classes.submitButtonStyles}
                  disabled={submitted}
                >
                  Verify EMAIL
                </CircularButton>
              </div>
            </form>
            <div className={classes.resendCode}>
              <span
                className={classes.option}
                onClick={() => dispatch(authActions.resendCode())}
              >
                Send the code again
              </span>
            </div>
            <div className={classes.loginLinkContainer}>
              <Link
                to={paths.signIn}
                className={classes.loginLink}
                onClick={() => dispatch(authActions.signOut())}
              >
                Return To Login
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Spinner show={isLoading} />
    </>
  );
};

export default withStyles(styles)(VerifyEmail);
