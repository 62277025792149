import React, { FunctionComponent, useState, MouseEvent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import cx from 'classnames';
import {
  Table,
  TableRow,
  TableHead,
  TableCell as TC,
  TableSortLabel,
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';
import { GetApp, FilterList, MoreVert } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, Dialog, Menu } from '../../../../components';
import {
  selectUsers,
  usersActions,
  selectIsLoading,
  selectUsersFilter,
  selectUsersSort,
} from '../../../../redux/usersSlice';
import TableBody from './TableBody/TableBody';
import FilterMenu from '../../../../components/FilterMenu/FilterMenu';
import useOnMount from '../../../../hooks/useOnMount';
import styles, { tableCellStyles } from './UsersTable.styles';
import { IUser } from '../../../../interfaces/users.interface';
import { ITableHeaderOption } from '../../../../interfaces/tableHeaderOption.interface';
import UserRoleDialog from '../UserRoleDialog/UserRoleDialog';

export const TableCell = withStyles(tableCellStyles)(TC);

const UsersTable: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const testid = 'users-table';
  const users = useSelector(selectUsers);
  const usersFilter = useSelector(selectUsersFilter);
  const usersSort = useSelector(selectUsersSort);
  const isLoading = useSelector(selectIsLoading);
  const [userToDelete, setUserToDelete] = useState<IUser | undefined>(undefined);
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = useState(false);
  const [userToUpdateRole, setUserToUpdateRole] = useState<IUser | undefined>(undefined);
  const [isUpdateUserRoleDialogOpen, setIsUpdateUserRoleDialogOpen] = useState(false);
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState<Element | null>(null);
  const [selectedFilterOption, setSelectedFilterOption] = useState<ITableHeaderOption | null>(null);
  const [headersMoreOptionsMenuAnchoEl, setHeadersMoreOptionsMenuAnchoEl] = useState<Element | null>(null);
  const dispatch = useDispatch();

  const headerOptions: ITableHeaderOption[] = [
    {
      title: 'Specialist',
      testid: `${testid}__specialist-header-cell`,
      filter: {
        name: 'name',
      },
      sorting: true,
    },
    {
      title: 'Name of Site',
      testid: `${testid}__name-of-site-header-cell`,
      filter: {
        name: 'site',
      },
      sorting: true,
    },
    {
      title: 'Role',
      testid: `${testid}__role-header-cell`,
      filter: {
        name: 'role',
      },
      sorting: true,
    },
    {
      title: 'Status',
      testid: `${testid}__status-header-cell`,
      filter: {
        name: 'accountStatus',
      },
      sorting: true,
    },
    {
      title: 'Do Not Email',
      testid: `${testid}__not-email-header-cell`,
      filter: null,
      sorting: false,
    },
  ];

  const headersMenuOptions = [
    {
      text: 'Reset filtering and sorting',
      action: () => {
        if (usersFilter || usersSort) {
          dispatch(usersActions.resetState());
          dispatch(usersActions.getUsers());
        }
      },
      testid: ''
    }
  ];

  useOnMount(() => {
    dispatch(usersActions.getUsers());
    return () => {
      dispatch(usersActions.resetState());
    };
  });

  const onDeleteUser = (user: IUser) => {
    setUserToDelete(user);
    setIsDeleteUserDialogOpen(true);
  };

  const onDeleteUserConfirm = () => {
    setIsDeleteUserDialogOpen(false);
    userToDelete && dispatch(usersActions.deleteUser(userToDelete.username));
  };

  const onDeleteUserCancel = () => {
    setIsDeleteUserDialogOpen(false);
  };

  const onUserChangeRole = (user: IUser) => {
    setUserToUpdateRole(user);
    setIsUpdateUserRoleDialogOpen(true);
  };

  const onUpdateUserRoleSubmit = () => {
    setIsUpdateUserRoleDialogOpen(false);
  };

  const onUpdateUserRoleCancel = () => {
    setIsUpdateUserRoleDialogOpen(false);
  };

  const onExportUserList = () => {
    dispatch(usersActions.exportUsersList());
  };

  const onRequestSort = (propertyName: any) => {
    const isAsc = usersSort?.orderBy === propertyName && usersSort?.order === 'asc';
    dispatch(usersActions.updateSort({ orderBy: propertyName, order: isAsc ? 'desc' : 'asc' }));
  };

  const onFilterButtonClick = (event: MouseEvent, selectedFilterOption: ITableHeaderOption) => {
    setSelectedFilterOption(selectedFilterOption);
    setFilterMenuAnchorEl(event.currentTarget);
  };

  const onFilterMenuClose = () => {
    setFilterMenuAnchorEl(null);
  };

  const onApplyFilter = (filterName: string, filterValue: string | null) => {
    if (!filterValue) {
      onClearFilter(filterName);
      return;
    }
    onFilterMenuClose();
    dispatch(usersActions.updateFilter({ property: filterName, value: filterValue }));
  };

  const onClearFilter = (filterName: string) => {
    if (usersFilter && usersFilter.property === filterName) {
      dispatch(usersActions.updateFilter(undefined));
    }
    onFilterMenuClose();
  }

  const onHeadersMoreOptionsClick = (e: MouseEvent) => {
    setHeadersMoreOptionsMenuAnchoEl(e.currentTarget);
  };

  const onToggleSendEmail = (user: IUser, value: boolean) => {
    dispatch(usersActions.toggleUserSendEmail(user.username, value.toString()));
  };

  return (
    <div className={classes.container}>
      <Spinner show={isLoading} inline />
      <div className={classes.controlsContainer}>
        <Button onClick={onExportUserList} className={classes.exportButton}>
          <GetApp />Export
        </Button>
      </div>
      <Table className={classes.table} data-testid={`${testid}__table`}>
        <TableHead data-testid={`${testid}__table-header`}>
          <FilterMenu
            anchorEl={filterMenuAnchorEl}
            filterName={selectedFilterOption?.filter?.name || ''}
            currentFilterValue={usersFilter && selectedFilterOption?.filter?.name === usersFilter.property ? usersFilter.value.toString() : null}
            onClose={onFilterMenuClose}
            onApplyFilter={onApplyFilter}
            onClearFilter={onClearFilter}
            label={`Filter by ${selectedFilterOption?.title}`} />
          <TableRow>
            {headerOptions.map((option) => (
              <TableCell
                key={option.filter?.name}
                data-testid={option.testid}
                sortDirection={usersSort && usersSort.orderBy === option.filter?.name ? usersSort.order : false}
              >
                {option.filter &&
                  <IconButton
                    className={cx(classes.filterButton, {
                      [classes.activeFilter]:
                        option.filter?.name === usersFilter?.property
                    })}
                    onClick={(event) => onFilterButtonClick(event, option)}
                    size='small'
                  >
                    <FilterList />
                  </IconButton>}
                {option.sorting ?
                  <TableSortLabel
                    active={usersSort?.orderBy === option.filter?.name}
                    direction={usersSort?.orderBy === option.filter?.name ? usersSort?.order : 'asc'}
                    onClick={() => onRequestSort(option.filter?.name)}
                  >
                    {option.title}
                  </TableSortLabel>
                  : option.title}
              </TableCell>
            ))}
            <TableCell>
              <IconButton
                onClick={onHeadersMoreOptionsClick}
                className={classes.headersMenuOptionsButton}
                data-testid={'users-more-options-btn'}
              >
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={headersMoreOptionsMenuAnchoEl}
                setAnchorEl={setHeadersMoreOptionsMenuAnchoEl}
                options={headersMenuOptions}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          users={users}
          onUserChangeRole={onUserChangeRole}
          onDeleteUser={onDeleteUser}
          onToggleNotSendEmail={onToggleSendEmail}
        />
      </Table>
      {!isLoading && !users.length && (
        <Typography
          className={classes.noResults}
          data-testid={`${testid}__no-results-found`}
        >
          We're sorry, no results found.
        </Typography>
      )}
      {userToUpdateRole &&
        <UserRoleDialog
          user={userToUpdateRole}
          open={isUpdateUserRoleDialogOpen}
          onSubmit={onUpdateUserRoleSubmit}
          onCancel={onUpdateUserRoleCancel} />
      }
      <Dialog
        id='delete-user-dialog'
        title={`Are you sure you want to delete ${userToDelete?.name}`}
        open={isDeleteUserDialogOpen}
        onSubmitBtnClick={onDeleteUserConfirm}
        onCancelBtnClick={onDeleteUserCancel}
        submitBtnText='Delete'
      />
    </div>
  );
};

export default withStyles(styles)(UsersTable);