import { createStyles, StyleRules } from '@material-ui/core/styles';
import { FontWeight, Colors } from '../../../../../assets/styles';

export default (): StyleRules => {
  return createStyles({
    container: {},
    description: {
      fontWeight: FontWeight.Bold,
      color: Colors.Blue,
      margin: '30px 0',
    },
    savings: {
      fontWeight: FontWeight.Bold,
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 10,
    },
    calculationsTitle: {
      fontSize: 12,
      fontWeight: FontWeight.Bold,
      textTransform: 'uppercase',
      marginBottom: 30,
      marginTop: 10,
    },
    sliderContainer: {
      borderBottom: `1px solid ${Colors.Gray5}`,
      marginBottom: 30,
    },
  });
};
