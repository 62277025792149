import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Typography, Avatar } from '@material-ui/core';
import { LightbulbIcon } from '../../../../assets/icons';
import styles from './HelpfulTips.styles';

interface IProps extends WithStylesType<typeof styles> {
  title?: string;
  tips?: [];
  testid: string;
}

const HelpfulTips: FunctionComponent<IProps> = ({
  classes,
  title,
  tips = [],
  testid,
}) => {
  return (
    <div className={classes.container}>
      <Typography
        variant="h2"
        className={classes.title}
        data-testid={`${testid}__helpful-tips__title`}
      >
        <Avatar className={classes.avatar}>
          <LightbulbIcon />
        </Avatar>
        {title}
      </Typography>
      {tips.map((tip, idx) => (
        <Typography
          key={tip}
          className={classes.paragraph}
          data-testid={`${testid}__helpful-tips__tip-${idx + 1}`}
        >
          {tip}
        </Typography>
      ))}
      <Typography
        key="tooltip-explain_tip"
        className={classes.paragraph}
        data-testid={`${testid}__helpful-tips__tip-tooltip-explain_tip`}
      >
        Hover over the Helpful Tips icon <Avatar className={classes.avatarSmall}><LightbulbIcon /></Avatar> throughout the calculator for additional tips.
      </Typography>
    </div>
  );
};

export default withStyles(styles)(HelpfulTips);
