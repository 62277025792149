import ServiceData from './serviceData';
import Calculator from './shared/base';


export default class PostpartumMaternalDepression extends Calculator {
  public serviceData: ServiceData;

  constructor({
    state,
    serviceData,
    lowEstimatedSlider,
    highEstimatedSlider,
  }: {
    state: string;
    serviceData: ServiceData;
    lowEstimatedSlider: number;
    highEstimatedSlider: number;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.lowEstimatedSlider = lowEstimatedSlider / 100;
    this.highEstimatedSlider = highEstimatedSlider / 100;
  }

  /**
   * @name maternal_depression_tab_savings_per_mother_child_with_prenatal_depression_per_year
   * @excel Excel Maternal Depression Tab B3
   * @constant 1805
   */
  public readonly maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear = this
    .constants.postpartumMaternalDepression
    .maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear;

  /**
   * @name maternal_depression_tab_percentage_reporting_persistent_depressive_symtopms
   * @excel Excel Maternal Depression Tab B4
   * @constant 12% (0.12)
   */
  public readonly maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms = this
    .constants.postpartumMaternalDepression
    .maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms;

  /**
   * (App) Of the parents/caregivers seen at your site over the past year, approximately how many discussed a problem with postpartum depression with a HS Specialist ??
   * @name percentage_of_HS_children_under_age_1
   * @excel Excel C77
   * @formula perc_children_under_age_one (from Service Data Screen)
   */
  public readonly percentageOfHSChildrenUnderAge1 = () => {
    return this.isFiniteNumber(this.serviceData.percChildrenUnderAgeOne());
  };

  /**
   * @name number_of_HS_Medicaid_children_under_age_1
   * @excel Excel C78
   * @formula percentage_of_HS_children_under_age_1 * cs_input_4 (from Service Data Screen)
   */
  public numberOfHSMedicaidChildrenUnderAge1 = () => {
    return this.isFiniteNumber(
      this.percentageOfHSChildrenUnderAge1() * this.serviceData.csInput4
    );
  };

  /**
   * @name percentage_of_low_income_mothers_who_have_persistent_depressive_symptoms
   * @excel Excel C79
   * @formula maternal_depression_tab_percentage_reporting_persistent_depressive_symtopms
   */
  public percentageOfLowIncomeMothersWhoHavePersistentDepressiveSymptoms = () => {
    return this.isFiniteNumber(
      this.maternalDepressionTabPercentageReportingPersistentDepressiveSymtopms
    );
  };

  /**
   * @name estimated_number_of_HS_Medicaid_children_under_age_1_whose_mothers_have_persistent_depression
   * @excel Excel C80
   * @formula number_of_HS_Medicaid_children_under_age_1 * percentage_of_low_income_mothers_who_have_persistent_depressive_symptoms
   */
  public estimatedNumberOfHSMedicaidChildrenUnderAge1WhoseMothersHavePersistentDepression = () => {
    return this.isFiniteNumber(
      this.numberOfHSMedicaidChildrenUnderAge1() *
        this.percentageOfLowIncomeMothersWhoHavePersistentDepressiveSymptoms()
    );
  };

  /**
   * @name low_estimated_slider
   * @excel Excel C81
   */
  public lowEstimatedSlider;

  /**
   * @name high_estimated_slider
   * @excel Excel C82
   */
  public highEstimatedSlider;

  /**
   * @name estimated_number_of_mothers_with_lower_health_care_use_due_to_HS_low
   * @excel Excel C83
   * @formula estimated_number_of_HS_Medicaid_children_under_age_1_whose_mothers_have_persistent_depression * low_estimated_slider
   */
  public estimatedNumberOfMothersWithLowerHealthCareUseDueToHSLow = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfHSMedicaidChildrenUnderAge1WhoseMothersHavePersistentDepression() *
        this.lowEstimatedSlider
    );
  };

  /**
   * @name estimated_number_of_mothers_with_lower_health_care_use_due_to_HS_high
   * @excel Excel C84
   * @formula estimated_number_of_HS_Medicaid_children_under_age_1_whose_mothers_have_persistent_depression * high_estimated_slider
   */
  public estimatedNumberOfMothersWithLowerHealthCareUseDueToHSHigh = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfHSMedicaidChildrenUnderAge1WhoseMothersHavePersistentDepression() *
        this.highEstimatedSlider
    );
  };

  /**
   * @name maternal_depression_tab_medicaid_price_inflation_factor
   * @constant 1.053897069
   */
  public readonly maternalDepressionTabMedicaidPriceInflationFactor = this.constants.postpartumMaternalDepression
    .maternalDepressionTabMedicaidPriceInflationFactor;

  /**
   * @name savings_per_mother_child_dyad_affected_by_postpartum_depression
   * @excel Excel C85
   * @formula maternal_depression_tab_savings_per_mother_child_with_prenatal_depression_per_year
   */
  public savingsPerMotherChildDyadAffectedByPostpartumDepression = () => {
    return this.isFiniteNumber(
      this
        .maternalDepressionTabSavingsPerMotherChildWithPrenatalDepressionPerYear
        *
        this.maternalDepressionTabMedicaidPriceInflationFactor
    );
  };

  /**
   * @name maternal_depression_estimated_savings_low
   * @excel Excel E70
   * @formula estimated_number_of_mothers_with_lower_health_care_use_due_to_HS_low * savings_per_mother_child_dyad_affected_by_postpartum_depression
   */
  public readonly maternalDepressionEstimatedSavingsLow = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfMothersWithLowerHealthCareUseDueToHSLow() *
        this.savingsPerMotherChildDyadAffectedByPostpartumDepression()
    );
  };

  /**
   * @name maternal_depression_estimated_savings_high
   * @excel Excel E70
   * @formula estimated_number_of_mothers_with_lower_health_care_use_due_to_HS_high * savings_per_mother_child_dyad_affected_by_postpartum_depression
   */
  public readonly maternalDepressionEstimatedSavingsHigh = () => {
    return this.isFiniteNumber(
      this.estimatedNumberOfMothersWithLowerHealthCareUseDueToHSHigh() *
        this.savingsPerMotherChildDyadAffectedByPostpartumDepression()
    );
  };
}
