import React, { FunctionComponent, useState } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import cx from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectReportName, selectTouched, calculatorActions } from '../../../../../redux/calculatorSlice';
import { StringFormatter } from '../../../../../utils';
import { AppThunkDispatch } from '../../../../../interfaces/redux.interface';
import { CircularButton, Dialog } from '../../../../../components';
import { paths } from '../../../..';
import styles from './InterventionContainer.styles';

interface IProps extends WithStylesType<typeof styles> {
  header?: React.ReactElement;
  body?: React.ReactElement;
  footer?: React.ReactElement;
  disabled: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const InterventionContainer: FunctionComponent<IProps> = ({
  classes,
  header,
  body,
  footer,
  disabled,
  onSubmit,
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch: AppThunkDispatch = useDispatch();
  const [isSaveExitDialogOpen, setIsSaveExitDialogOpen] = useState(false);
  const reportName = useSelector(selectReportName);
  const touched = useSelector(selectTouched);

  const pathOptions = {
    [StringFormatter.replaceWithReportName(
      reportName,
      paths.interventionsEmergencyDepartmentUse
    )]: {
      backTo: StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsOralHealth
      ),
    },
    [StringFormatter.replaceWithReportName(
      reportName,
      paths.interventionsImmunizations
    )]: {
      backTo: StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsEmergencyDepartmentUse
      ),
    },
    [StringFormatter.replaceWithReportName(
      reportName,
      paths.interventionsAsthma
    )]: {
      backTo: StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsImmunizations
      ),
    },
    [StringFormatter.replaceWithReportName(
      reportName,
      paths.interventionsPostpartumMaternalDepression
    )]: {
      backTo: StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsAsthma
      ),
    },
    [StringFormatter.replaceWithReportName(
      reportName,
      paths.interventionsHealthyBirthSpacing
    )]: {
      backTo: StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsPostpartumMaternalDepression
      ),
    },
    [StringFormatter.replaceWithReportName(
      reportName,
      paths.interventionsIntimatePartnerViolence
    )]: {
      backTo: StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsHealthyBirthSpacing
      ),
    },
    [StringFormatter.replaceWithReportName(
      reportName,
      paths.interventionsBreastfeeding
    )]: {
      backTo: StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsIntimatePartnerViolence
      ),
    },
    [StringFormatter.replaceWithReportName(
      reportName,
      paths.interventionsSmokingCessation
    )]: {
      backTo: StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsBreastfeeding
      ),
    },
  };

  const onExitCalculator = () => {
    if (touched) {
      setIsSaveExitDialogOpen(true);
    } else {
      history.push(paths.calculatorDashboard);
    }
  };

  const onSaveAndExit = () => {
    setIsSaveExitDialogOpen(false);
    dispatch(calculatorActions.saveReport({ update: true }))
      .then(() =>
        history.push(paths.calculatorDashboard)
      );
  };

  return (
    <>
      <form className={classes.container} onSubmit={onSubmit}>
        {header}
        <div className={cx({ [classes.disabled]: disabled })}>
          {body}
          {footer}
        </div>
        {disabled && (
          <div className={classes.toggleOffTextWrapper}>
            <Typography className={classes.toggleOffText}>
              This intervention is not provided at your site.<br/>Skip this step or toggle it on.
            </Typography>
          </div>
        )}
        <div className={classes.buttonWrapper}>
          <IconButton className={classes.homeBtn} onClick={onExitCalculator}>
            <ExitToApp />
            Exit
          </IconButton>
          {location.pathname !==
            StringFormatter.replaceWithReportName(
              reportName,
              paths.interventionsOralHealth
            ) && (
            <CircularButton
              type="button"
              color="primary"
              customClass={`${classes.button} ${classes.secondaryButton}`}
              onClick={() => {
                history.push(pathOptions[location.pathname].backTo);
              }}
            >
              Previous
            </CircularButton>
          )}
          <CircularButton
            type="submit"
            color="primary"
            variant="contained"
            customClass={classes.button}
          >
            {location.pathname !==
              StringFormatter.replaceWithReportName(
                reportName,
                paths.interventionsSmokingCessation
              ) &&
              disabled &&
              'Skip Step'}
            {location.pathname !==
              StringFormatter.replaceWithReportName(
                reportName,
                paths.interventionsSmokingCessation
              ) &&
              !disabled &&
              'Save & Next'}
            {location.pathname ===
              StringFormatter.replaceWithReportName(
                reportName,
                paths.interventionsSmokingCessation
              ) && 'Save & View Report'}
          </CircularButton>
        </div>
      </form>
      <Dialog
        id="save-and-exit_dialog.testid"
        title="Are you sure you want to leave this page?"
        open={isSaveExitDialogOpen}
        onClose={() => {
          setIsSaveExitDialogOpen(false);
        }}
        onSubmitBtnClick={onSaveAndExit}
        submitBtnText="Save & Exit"
        cancelBtnText="Exit without saving"
        onCancelBtnClick={() => history.push(paths.calculatorDashboard)}
      >
        <Typography className={classes.saveAndExitTitle}>
          Any unsaved changes will be lost
        </Typography>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(InterventionContainer);
