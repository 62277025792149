import { createStyles, StyleRules } from '@material-ui/core/styles';
import { FontWeight, Colors } from '../../../../assets/styles';

export default (): StyleRules => {
  return createStyles({
    section: {
      marginBottom: 50,
    },
    sectionTitle: {
      textTransform: 'uppercase',
      fontWeight: FontWeight.Bold,
      fontSize: 13,
      color: Colors.Gray,
      marginBottom: 30,
    },

    sectionItemLink: {
      display: 'flex',
      alignItems: 'center'
    },
    sectionItemSwitch: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: 30
    },
    sectionItemDisabled: {
      opacity: 0.44,
      '& $sectionItemStatus': {
        border: `1px solid ${Colors.Gray6}`,
        backgroundColor: 'transparent',
      },
    },
    sectionItem: {
      fontSize: 14,
      fontWeight: FontWeight.Regular,
      color: Colors.Gray4,
      marginLeft: 10,
    },
    sectionItemActive: {
      '& > p': {
        color: Colors.Blue1,
      },
      '& > span': {
        backgroundColor: Colors.Blue1,
        '& > p': {
          color: Colors.White,
        },
      },
    },
    sectionItemStatus: {
      minHeight: 24,
      minWidth: 24,
      backgroundColor: Colors.Gray2,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    isValidIcon: {
      minHeight: 24,
      minWidth: 24,
    },
    isErrorIcon: {
      minHeight: 28,
      minWidth: 28,
      color: Colors.Red,
    },
    sectionItemStatusContent: {
      fontSize: 12,
      color: Colors.Gray,
    },
    sectionItemStatusContentDisabled: {
      borderBottom: `1px solid ${Colors.Gray6}`,
      width: 10,
    },
  });
};
