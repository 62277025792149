import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { Colors, FontWeight } from '../../../assets/styles';

export default ({ typography }: Theme): StyleRules => {
  const { pxToRem } = typography;

  return createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    label: {
      color: Colors.Blue,
      fontWeight: FontWeight.Bold,
    },
    labelError: {
      color: Colors.Red,
    },
    inputWrapper: {
      marginTop: '12px !important',
      border: `solid 1px ${Colors.Gray2}`,
      backgroundColor: Colors.White,
      borderRadius: 4,
      height: 40,
    },
    inputWrapperNoLabel: {
      marginTop: '0 !important',
    },
    startAdornment: {
      paddingLeft: 17,
      '& $input': {
        paddingLeft: 0,
      },
    },
    endAdornment: {
      paddingRight: 17,
      '& $input': {
        paddingRight: 0,
      },
    },
    textareaInput: {
      height: '100%',
      '& $input': {
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
    inputError: {
      borderColor: Colors.Red,
    },
    input: {
      padding: '0 17px',
      height: '100%',
      '&::placeholder': {
        color: Colors.Gray,
      },
    },
    passwordToggle: {
      fontSize: pxToRem(15),
      fontWeight: FontWeight.Light,
      color: Colors.Blue1,
    },
    error: {
      fontWeight: FontWeight.Regular,
      fontSize: 14,
    },
    maxLength: {
      marginTop: 10,
    }
  });
};
