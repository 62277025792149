import React, { FunctionComponent, MouseEvent, useState } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import MoreVert from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router-dom';
import { Dialog, Menu, ReportNameDialog } from '../../../../../components';
import { Constants, StringFormatter, Pdf } from '../../../../../utils';
import { paths } from '../../../..';
import Report from '../../../../Calculator/CalculatorReport/Report/Report';
import { calculatorActions } from '../../../../../redux/calculatorSlice';
import { ISummaryReport } from '../../../../../interfaces/calculator.interface';
import { IInterventionsChecklist } from '../../../../../interfaces/interventions.interface';
import styles from './ReportItem.styles';

interface IProps extends WithStylesType<typeof styles> {
  report: {
    summary: {
      totalSavingsHigh: string;
      totalSavingsLow: string;
      report: ISummaryReport;
    };
    interventionsChecklist: IInterventionsChecklist;
    updated: number;
    name: string;
    status: string;
    updatedISO: string;
    siteName: string;
    state: string;
  };
}

const ReportsList: FunctionComponent<IProps> = ({ classes, report }) => {
  const testid = `report-${report.name}`;
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isCopyNameDialogOpen, setIsCopyNameDialogOpen] = useState(false);

  const onMenuOpen = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const editReport = () => {
    if (report.status === Constants.REPORT_STATUS.FINISHED) {
      history.push(
        paths.calculatorReport.replace(Constants.REPORT.URL_PARAM, report.name)
      );
    } else {
      history.push(
        paths.calculatorServiceData.replace(
          Constants.REPORT.URL_PARAM,
          report.name
        )
      );
    }
  };

  const copyReport = () => {
    setIsCopyNameDialogOpen(true);
  };

  const options = [
    {
      text: 'Edit',
      action: () => {
        editReport();
      },
      hidden: report.status === Constants.REPORT_STATUS.FINISHED,
      testid: `${testid}__edit`,
    },
    {
      text: 'Copy',
      action: () => {
        copyReport();
      },
      hidden: report.status === Constants.REPORT_STATUS.SUBMITTED,
      testid: `${testid}__copy`,
    },
    {
      text: 'Delete',
      action: () => setIsDialogOpen(true),
      hidden: report.status === Constants.REPORT_STATUS.FINISHED,
      testid: `${testid}__delete`,
    },
    {
      text: 'Download',
      action: () => {
        Pdf.download(report.name, report.name);
      },
      hidden: report.status === Constants.REPORT_STATUS.PENDING,
      testid: `${testid}__download`,
    },
  ];

  const onDialogConfirm = () => {
    dispatch(calculatorActions.deleteReport(report.name));
  };

  const onCopyReportDialogConfirm = (reportName: string) => {
    setIsCopyNameDialogOpen(false);
    dispatch(calculatorActions.copyReport(report.name, reportName));
  };

  const { status, updatedISO: lastDateUpdated, siteName, state } = report;
  const disableNavigation = (report.status === Constants.REPORT_STATUS.SUBMITTED || report.status === Constants.REPORT_STATUS.FINISHED);

  return (
    <>
      <div className={classes.reportWrapper}>
        <Report
          id={report.name}
          report={report.summary.report}
          isFinished={status === Constants.REPORT_STATUS.FINISHED}
          lastDateUpdated={lastDateUpdated}
          interventionsChecklist={report.interventionsChecklist}
          titleText={siteName || StringFormatter.getStateName(state)}
        />
      </div>
      <div className={classes.container}>
        <div className={classes.nameWrapper}>
          <Typography
            className={cx(classes.name, {
              [classes.transitionDisabled]: disableNavigation
            })}
            variant="h2"
            data-testid={`${testid}__name`}
            onClick={() =>  !disableNavigation && editReport()}
          >
            {report.name}
          </Typography>
          {report.status === Constants.REPORT_STATUS.SUBMITTED && (
            <>
              <Typography
                className={classes.summary}
                data-testid={`${testid}__summary`}
              >
                {`Total Savings (High): ${StringFormatter.money(
                  report.summary.totalSavingsHigh
                )}`}
              </Typography>
              <Typography
                className={classes.summary}
                data-testid={`${testid}__summary`}
              >
                {`Total Savings (Low): ${StringFormatter.money(
                  report.summary.totalSavingsLow
                )}`}
              </Typography>
            </>
          )}
        </div>
        <Typography className={classes.date} data-testid={`${testid}__date`}>
          {`${Constants.REPORT_STATUSES[report.status]?.dateText} ${dayjs(
            report.updated
          ).format('MM/DD/YYYY | h:mm a')}`}
        </Typography>
        <div
          className={cx(classes.status, {
            [classes.submittedStatus]:report.status === Constants.REPORT_STATUS.SUBMITTED,
            [classes.finishedStatus]:report.status === Constants.REPORT_STATUS.FINISHED,
            [classes.pendingStatus]:report.status === Constants.REPORT_STATUS.PENDING,
          })}
        >
          <Typography
            className={classes.statusText}
            data-testid={`${testid}__status`}
          >
            {Constants.REPORT_STATUSES[report.status].status}
          </Typography>
        </div>
        <>
          {report.status !== Constants.REPORT_STATUS.SUBMITTED &&
          <IconButton
            onClick={onMenuOpen}
            className={classes.button}
            data-testid={`${testid}_more-options-btn`}
          >
            <MoreVert />
          </IconButton>}
          <Menu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            options={options}
          />
        </> 
      </div>
      <Dialog
        id={`${testid}__delete`}
        title="Delete report"
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSubmitBtnClick={onDialogConfirm}
        submitBtnText="Yes, delete"
      >
        Are you sure you want to delete this report?
      </Dialog>
      <ReportNameDialog
        isOpen={isCopyNameDialogOpen}
        onClose={() => setIsCopyNameDialogOpen(false)}
        inputCustomClass={classes.calculationsNameInput}
        onSave={onCopyReportDialogConfirm}
      />
    </>
  );
};

export default withStyles(styles)(ReportsList);
