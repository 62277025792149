import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import cx from 'classnames';
import { FormikProps } from 'formik';
import { IInterventionsChecklist } from '../../../../interfaces/interventions.interface';
import { Switch, Tooltip } from '../../../../components';
import ServiceSectionLayout from '../../ServiceSectionLayout/ServiceSectionLayout';
import styles from './InterventionsChecklist.styles';

interface IProps extends WithStylesType<typeof styles> {
  testid: string;
  title: string | React.ReactElement;
  form: FormikProps<IInterventionsChecklist>;
  options: {
    title: string;
    formId: string;
    tooltip?: string | React.ReactElement;
  }[];
}

const Interventions: FunctionComponent<IProps> = ({
  classes,
  testid,
  title,
  form,
  options,
}) => {
  return (
    <ServiceSectionLayout
      testId={testid}
      customClass={classes.container}
      title={title}
    >
      {options.map((intervention, idx) => (
        <div
          key={intervention.formId}
          className={cx(classes.switchWrapper, {
            [classes.lastItem]: idx === options.length - 1,
          })}
        >
          <Switch
            id={intervention.formId}
            checked={form.values[intervention.formId]}
            onChange={form.handleChange}
            label={intervention.title}
          />
          {intervention.tooltip &&
          <Tooltip
            id={`${intervention.formId}__switch-tooltip`}
            title={intervention.tooltip}
            placement="right"
          >
            {}
          </Tooltip>}
        </div>
      ))}
    </ServiceSectionLayout>
  );
};

export default withStyles(styles)(Interventions);
