/* eslint-disable consistent-return */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
  selectInterventionsChecklistValue,
  calculatorActions,
  selectInterventionsValue,
  selectServiceDataForm,
  selectReportName,
  selectState,
  selectTouched,
} from '../../../../redux/calculatorSlice';
import {
  InterventionsChecklistForm,
  IOralHealth,
  OralHealthForm,
  InterventionsCommonForm,
} from '../../../../interfaces/interventions.interface';
import { CalculatorEnum } from '../../../../interfaces/calculator.interface';
import {
  InterventionHeader,
  InterventionBody,
  InterventionFooter,
  InterventionContainer,
  InterventionFieldNoteDialog
} from '../Intervention';
import { FormValidations, StringFormatter } from '../../../../utils';
import { OralHealth as OralHealthClass } from '../../../../calculations';
import useDebounceEffect from '../../../../hooks/useDebounceEffect';
import { paths } from '../../..';
import { Constants } from '../../../../utils';

const { DIALOG_MODES } = Constants;

const OralHealth: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const switchValue = useSelector(
    selectInterventionsChecklistValue(InterventionsChecklistForm.OralHealth)
  );
  const oralHealthData = useSelector(
    selectInterventionsValue(CalculatorEnum.OralHealth)
  );

  const reportName = useSelector(selectReportName);

  const serviceData = useSelector(selectServiceDataForm);
  const selectedState = useSelector(selectState);
  const touched = useSelector(selectTouched);

  const [reinitialize, setReinitialize] = useState(true);
  const [noteName, setNoteName] = useState<string>('');
  const [noteAction, setNoteAction] = useState<'add' | 'edit' | 'delete'>(DIALOG_MODES.ADD);
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);

  const fields = [
    {
      text: `Number of caregivers who discussed a need for oral health services for their child(ren) with a HS Specialist and/or were referred for services
      `,
      id: OralHealthForm.NeedForOralHealth,
      showError: true,
    },
  ];

  const onSubmit = (values: IOralHealth) => {
    if (touched) {
      dispatch(
        calculatorActions.saveReport({
          reportName,
          update: true,
        }));
    }

    history.push(
      StringFormatter.replaceWithReportName(
        reportName,
        paths.interventionsAsthma
      )
    );
  };

  useEffect(() => {
    if (switchValue) {
      const timeout = setTimeout(() => {
        setReinitialize(false);
      }, 250);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [switchValue]);

  const form = useFormik({
    initialValues: oralHealthData,
    onSubmit,
    validationSchema: switchValue && FormValidations.intervention({
      [OralHealthForm.NeedForOralHealth]:
        FormValidations.interventionNumberOfChilds(OralHealthForm.NeedForOralHealth, serviceData.csInput3),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: reinitialize || !switchValue,
  });

  useEffect(() => {
    if (!switchValue) {
      form.resetForm();
      dispatch(
        calculatorActions.clearInterventionData(CalculatorEnum.OralHealth)
      );
    }
  }, [switchValue]);

  useDebounceEffect(() => {
    const hasBeenEdited = Object.keys(form.values).some(
      (key) => !!form.values[key]
    );
    if (!form.dirty && !hasBeenEdited) return;
    /** Calculations happen */
    const oralHealth = new OralHealthClass({
      state: selectedState,
      serviceData,
      lowEstimatedSlider: +form.values[InterventionsCommonForm.LowEstimate],
      highEstimatedSlider: +form.values[InterventionsCommonForm.HighEstimate],
    });

    const savingsLow = oralHealth.dentalEstimatedSavingLow();
    const savingsHigh = oralHealth.dentalEstimatedSavingHigh();

    if (
      +form.values[InterventionsCommonForm.SavingsLow] !== savingsLow ||
      +form.values[InterventionsCommonForm.SavingsHigh] !== savingsHigh
    ) {
      form.setValues({
        ...form.values,
        [InterventionsCommonForm.SavingsLow]: savingsLow.toString(),
        [InterventionsCommonForm.SavingsHigh]: savingsHigh.toString(),
      });
    }

    form.validateForm().then((errors) => {
      const isValid = !Object.keys(errors).length;

      if (form.dirty) {
        dispatch(
          calculatorActions.setInterventionData({
            intervention: CalculatorEnum.OralHealth,
            value: { ...form.values, isValid: !!isValid },
          })
        );
        dispatch(calculatorActions.setSummaryData());
      }
    });
  }, [form.values]);

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      calculatorActions.toggleInterventionsChecklistValue({
        key: InterventionsChecklistForm.OralHealth,
        value: e.target.checked,
      })
    );
  };

  const onNote = (noteName: string, noteAction: 'add' | 'edit' | 'delete') => {
    setNoteName(noteName);
    setNoteAction(noteAction);
    setIsNoteDialogOpen(true);
  };

  const onDialogNoteClose = () => {
    setIsNoteDialogOpen(false);
  };

  return (
    <>
      <InterventionContainer
        disabled={!switchValue}
        onSubmit={form.handleSubmit}
        header={
          <InterventionHeader
            title="Oral Health"
            checked={switchValue}
            onChange={onSwitchChange}
          />
        }
        body={
          <InterventionBody
            description={
              <>
                Conversations with HealthySteps sites indicate that HS Specialists sometimes advise caregivers about the importance and benefits of early dental visits, provide education to caregivers on wiping the teeth down after feeding, and make referrals to oral health services. The following questions ask about interactions with caregivers in these situations during the 12-month date range.
              </>
            }
            form={form}
            fields={fields}
            onNote={onNote}
          />
        }
        footer={
          <InterventionFooter
            form={form}
            savingsText="Oral health"
            description="What percentage of children had a first dental visit by age 3 due to HealthySteps Specialist(s) support?"
            onNote={onNote}
          />
        }
      />
      <InterventionFieldNoteDialog
        id={`${noteName}__${noteAction}-dialog`}
        name={noteName}
        value={form.values[noteName]}
        mode={noteAction}
        open={isNoteDialogOpen}
        onClose={onDialogNoteClose}
        form={form} />
    </>
  );
};

export default OralHealth;
