
import { IBreakdownReport, breakdownReportState } from '../../../../interfaces/calculator.interface';
import { IInterventionsChecklist, InterventionsChecklistForm } from '../../../../interfaces/interventions.interface';
import { StringFormatter, Constants } from '../../../../utils';

export const getReportBreakdown = (
  report: IBreakdownReport = breakdownReportState,
  interventionsChecklist: IInterventionsChecklist
) => {

  const formatMoney = (value: string) =>
    (value && value !== Constants.STRING_TYPES.EMPTY) ?
      StringFormatter.money(value) : Constants.STRING_TYPES.EMPTY;

  const formatPerc = (value: string) => 
    (value && value !== Constants.STRING_TYPES.EMPTY) ?
      StringFormatter.percentage(value) : Constants.STRING_TYPES.EMPTY;

  return [
    {
      title: 'HealthySteps Overview Data',
      subCategories: [
        {
          title: 'General Information',
          visible: true,
          lines: [
            {
              description: 'HealthySteps Site/System Name',
              values: [report.siteName],
              displayDefault: false,
            },
            {
              description: 'Select Site State Location',
              values: [report.siteLocation],
              displayDefault: false,
            },
            {
              description: 'Enter Date Range',
              values: [report.dateFrom, report.dateTo],
              displayDefault: false,
            },
            {
              description: 'Select if data for ROI calculations will apply to:',
              values: [report.calculationsApplyTo],
              displayDefault: false,
            },
            {
              description: 'Number of sites ROI calculation will apply to:',
              values: [report.numberOfSitesCalculationsApplyTo],
              displayDefault: false,
            }
          ]
        },
        {
          title: 'HealthySteps Service Delivery Costs',
          visible: true,
          lines: [
            {
              description: 'Total of HS Specialists delivering services',
              values: [report.numberOfSpecialistsDeliveringServices],
              displayDefault: false,
              note: report.numberOfSpecialistsDeliveringServicesNote,
            },
            {
              description: 'HS Specialist(s) salary and benefits (should include fringe)',
              values: [formatMoney(report.specialistSalaryCost)],
              displayDefault: false,
              note: report.specialistSalaryCostNote,
            },
            {
              description: 'Other direct costs including professional services, printing, publications, supplies, communication, travel, and meetings. Do not include the costs of the initial HealthySteps Institute or professional development stipends',
              values: [formatMoney(report.directCost)],
              displayDefault: false,
              note: report.directCostNote
            },
            {
              description: 'Other (for example: food for parent groups, mileage, annual cell phone cost, etc.)',
              values: [formatMoney(report.otherCost)],
              displayDefault: false,
              note: report.otherCostNote,
            },
            {
              description: 'Intermediary costs (if applicable)',
              values: [formatMoney(report.intermediaryCost)],
              displayDefault: false,
              note: report.intermediaryCostNote,
            },
            {
              description: 'Total site service delivery cost during the 12-month date range',
              values: [formatMoney(report.siteServiceCost)],
              displayDefault: false,
              note: report.siteServiceCostNote
            },
          ]
        },
        {
          title: 'Children Served',
          visible: true,
          lines: [
            {
              description: 'Total children ages 0-3 served (panel size) during the 12-month date range',
              values: [report.children0To3],
              displayDefault: false,
              note: report.children0To3Note,
            },
            {
              description: 'Total children with Medicaid ages 0-3 served (panel size) within the 12-month date range',
              values: [report.medicaidChildren0To3],
              displayDefault: false,
              note: report.medicaidChildren0To3Note,
            },
            {
              description: 'Total children ages 0-3 in Tiers 2 & 3 served by the HS Specialist(s) within the 12-month date range',
              values: [report.children0To3Tier2And3],
              displayDefault: false,
              note: report.children0To3Tier2And3Note,
            },
            {
              description: 'Total children with Medicaid ages 0-3 in Tiers 2 & 3 served by the HS Specialist(s) within the 12-month date range',
              values: [report.medicaidChildren0To3Tier2And3],
              displayDefault: false,
              note: report.medicaidChildren0To3Tier2And3Note,
            },
            {
              description: 'Total children with Medicaid under age 1, in Tiers 2 & 3 served by the HS Specialist(s) within the 12-month date range',
              values: [report.medicaidChildrenUnder1Tier2And3],
              displayDefault: false,
              note: report.medicaidChildrenUnder1Tier2And3Note,
            },
          ]
        },
      ],
    },
    {
      title: 'Interventions for Children',
      resultsHeaders: ['# or %', 'Low Estimate', 'High Estimate'],
      subCategories: [
        {
          title: 'Oral Health',
          visible: interventionsChecklist[InterventionsChecklistForm.OralHealth],
          lines: [
            {
              description: 'Number of caregivers who discussed a need for oral health services for their child(ren) with a HS Specialist and/or were referred for services',
              values: [report.oralHealth_needForOralHealth, '', ''],
              displayDefault: true,
              note: report.oralHealth_needForOralHealthNote,
            },
            {
              description: 'What percentage of children had a first dental visit by age 3 due to HealthySteps Specialist(s) support?',
              values: ['', formatPerc(report.oralHealth_lowEstimate), formatPerc(report.oralHealth_highEstimate)],
              displayDefault: true,
              note: report.oralHealth_estimatesNote,
            }
          ]
        },
        {
          title: 'Asthma',
          visible: interventionsChecklist.asthma,
          lines: [
            {
              description: 'Number of Tier 2 and 3 children who had asthma or were wheezing and at risk for asthma',
              values: [report.asthma_numberHaveAsthma, '', ''],
              displayDefault: true,
              note: report.asthma_numberHaveAsthmaNote,
            },
            {
              description: 'Percentage of children with asthma or related symptoms who avoid emergency department visits due to HealthySteps',
              values: ['', formatPerc(report.asthma_lowEstimate), formatPerc(report.asthma_highEstimate)],
              displayDefault: true,
              note: report.asthma_estimatesNote,
            }
          ],
        },
        {
          title: 'Appropriate Use of Care',
          visible: interventionsChecklist.emergencyDepartmentUse,
          lines: [
            {
              description: 'Number of caregivers who contacted HS Specialist(s) seeking advice about whether to bring a sick child in for a visit',
              values: [report.emergencyDepartment_contactedByPhone, '', ''],
              displayDefault: true,
              note: report.emergencyDepartment_contactedByPhoneNote,
            },
            {
              description: 'Percentage of caregivers seeking advice about a sick child who ended up treating the child at home for a minor illness but would have brought the child in for a visit (for example, at a doctor’s office, urgent care, or hospital emergency department) if they had not talked with a HS Specialist',
              values: [formatPerc(report.emergencyDepartment_treatedAtHome), '', ''],
              displayDefault: true,
              note: report.emergencyDepartment_treatedAtHomeNote,
            },
            {
              description: 'Percentage of caregivers seeking advice about a sick child who ended up bringing the child in for a clinic or other office-based visit (including urgent care) but would have brought the child in for a hospital emergency department visit if they had not talked with a HS Specialist',
              values: [formatPerc(report.emergencyDepartment_clinicVisit), '', ''],
              displayDefault: true,
              note: report.emergencyDepartment_clinicVisitNote,
            }
          ],
        },
        {
          title: 'Flu Vaccine',
          visible: interventionsChecklist.immunizations,
          lines: [
            {
              description: 'Number of caregivers who discussed a need for flu vaccines for their children with a HS Specialist and/or were assisted in pursuing the flu vaccine',
              values: [report.immunizations_needForFluVaccinations, '', ''],
              displayDefault: true,
              note: report.immunizations_needForFluVaccinationsNote,
            },
            {
              description: 'Number of well-child visits that occurred due to HS Specialist(s) contacting families to triage barriers and/or encourage attendance',
              values: [report.immunizations_numberOfEncourageVisits, '', ''],
              displayDefault: true,
              note: report.immunizations_numberOfEncourageVisitsNote,
            },
            {
              description: 'Percentage of children who were unvaccinated and received the flu vaccine due to HealthySteps involvement',
              values: ['', formatPerc(report.immunizations_lowEstimate), formatPerc(report.immunizations_highEstimate)],
              displayDefault: true,
              note: report.immunizations_estimatesNote,
            }
          ] 
        },
      ],
    },
    {
      title: 'Interventions for Caregivers',
      subCategories: [
        {
          title: 'Postpartum Maternal Depression',
          visible: interventionsChecklist.postpartumMaternalDepression,
          lines: [
            {
              description: 'Number of caregivers who discussed postpartum maternal depression with a HS Specialist (either in-person or telephonically)',
              values: [report.postpartumMaternalDepression_discussedProblem, '', ''],
              displayDefault: true,
              note: report.postpartumMaternalDepression_discussedProblemNote,
            },
            {
              description: 'Number of mothers who received counseling or related services for postpartum maternal depression from a HS Specialist',
              values: [report.postpartumMaternalDepression_receivedCounseling, '', ''],
              displayDefault: true,
              note: report.postpartumMaternalDepression_receivedCounselingNote,
            },
            {
              description: 'Number of mothers that the HS Specialist(s) referred for counseling or other services for postpartum maternal depression',
              values: [report.postpartumMaternalDepression_referredForServices, '', ''],
              displayDefault: true,
              note: report.postpartumMaternalDepression_referredForServicesNote,
            },
            {
              description: 'Percentage reduction in number of mothers with persistent depression due to HealthySteps',
              values: ['', formatPerc(report.postpartumMaternalDepression_lowEstimate), formatPerc(report.postpartumMaternalDepression_highEstimate)],
              displayDefault: true,
              note: report.postpartumMaternalDepression_estimatesNote,
            }
          ]
        },
        {
          title: 'Healthy Birth Spacing',
          visible: interventionsChecklist.healthyBirthSpacing,
          lines: [
            {
              description: 'Number of mothers who discussed a need for contraception or other family planning services with a HS Specialist and/or were referred for services (e.g., postpartum visit, family planning, contraception, etc.',
              values: [report.healthyBirthSpacing_needForContraception, '', ''],
              displayDefault: true,
              note: report.healthyBirthSpacing_needForContraceptionNote,
            },
            {
              description: 'Percentage of mothers who followed up on referrals for services (e.g., postpartum visit, family planning, contraception, etc.)',
              values: [formatPerc(report.healthyBirthSpacing_followedUpOnReferals), '', ''],
              displayDefault: true,
              note: report.healthyBirthSpacing_followedUpOnReferalsNote,
            },
            
            {
              description: 'Estimated percentage of unintended pregnancies/births averted due to HealthySteps',
              values: ['', formatPerc(report.healthyBirthSpacing_lowEstimate), formatPerc(report.healthyBirthSpacing_highEstimate)],
              displayDefault: true,
              note: report.healthyBirthSpacing_estimatesNote
            },
          ]
        },
        {
          title: 'Intimate Partner Violence',
          visible: interventionsChecklist.intimidatePartnerViolence,
          lines: [
            {
              description: 'Number of caregivers who discussed a concern related to IPV with a HS Specialist',
              values: [report.intimatePartnerViolence_discussedProblem, '', ''],
              displayDefault: true,
              note: report.intimatePartnerViolence_discussedProblemNote,
            },
            {
              description: 'Number of caregivers who received counseling or related services from a HS Specialist',
              values: [report.intimatePartnerViolence_receivedCounseling, '', ''],
              displayDefault: true,
              note: report.intimatePartnerViolence_receivedCounselingNote,
            },
            {
              description: 'Number of caregivers who were referred for IPV related services by a HS Specialist',
              values: [report.intimatePartnerViolence_referredForServices, '', ''],
              displayDefault: true,
              note: report.intimatePartnerViolence_referredForServicesNote,
            },
            {
              description: 'Percentage of severe IPV cases with improvement due to HealthySteps',
              values: ['', formatPerc(report.intimatePartnerViolence_lowEstimate), formatPerc(report.intimatePartnerViolence_highEstimate)],
              displayDefault: true,
              note: report.intimatePartnerViolence_estimatesNote,
            },
          ],
        },
        {
          title: 'Breastfeeding',
          visible: interventionsChecklist.breastfeeding,
          lines: [
            {
              description: 'Number of caregivers who discussed a need for breastfeeding advice/assistance with a HS Specialist',
              values: [report.breastfeeding_discussedProblem, '', ''],
              displayDefault: true,
              note: report.breastfeeding_discussedProblemNote,
            },
            {
              description: 'Number of caregivers who discussed a need for breastfeeding advice/assistance and received counseling or related services from a HS Specialist',
              values: [report.breastfeeding_receivedCounseling, '', ''],
              displayDefault: true,
              note: report.breastfeeding_receivedCounselingNote,
            },
            {
              description: 'Number of caregivers who discussed a need for breastfeeding advice/assistance with a HS Specialist and were referred for services',
              values: [report.breastfeeding_referredForServices, '', ''],
              displayDefault: true,
              note: report.breastfeeding_referredForServicesNote,
            },
            {
              description: 'Percentage of caregivers who continued breastfeeding due to HealthySteps',
              values: ['', formatPerc(report.breastfeeding_lowEstimate), formatPerc(report.breastfeeding_highEstimate)],
              displayDefault: true,
              note: report.breastfeeding_estimatesNote,
            },
            {
              description: 'Percentage of caregivers who continued exclusive breastfeeding due to HealthySteps',
              values: ['', formatPerc(report.breastfeeding_lowEstimateExclusive), formatPerc(report.breastfeeding_highEstimateExclusive)],
              displayDefault: true,
              note: report.breastfeeding_estimatesExclusiveNote,
            },
          ],
        },
        {
          title: 'Smoking Cessation',
          visible: interventionsChecklist.smokeCessation,
          lines: [
            {
              description: 'Number of caregivers who discussed a problem with smoking or any other tobacco product use with a HS Specialist and/or were referred for services',
              values: [report.smokeCessation_discussedProblem, '', ''],
              displayDefault: true,
              note: report.smokeCessation_discussedProblemNote,
            },
            {
              description: 'Percentage of caregivers who smoke or use tobacco products, and quit due to HealthySteps',
              values: ['', formatPerc(report.smokeCessation_lowEstimate), formatPerc(report.smokeCessation_highEstimate)],
              displayDefault: true,
              note: report.smokeCessation_estimatesNote,
            },
          ]
        },
      ]
    }
  ];
};

export default {
  getReportBreakdown
};