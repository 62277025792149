import React, { FunctionComponent, useState } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import {
  IconButton,
  Menu,
  Card,
  CardContent,
  CardActions,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import styles from './ReportStatusFilterMenu.styles';

type Props = WithStylesType<typeof styles> & {
  anchorEl: Element | null;
  filterName: string;
  currentFilterValues: string[] | undefined;
  onClose: () => void;
  onApplyFilter: (filterName: string, filterValues: string[] | undefined) => void;
};

const ReportStatusFilterMenu: FunctionComponent<Props> = ({
  anchorEl,
  filterName,
  currentFilterValues,
  onClose,
  onApplyFilter,
  classes,
}) => {

  const [filterValues, setFilterValues] = useState<string[] | undefined>(currentFilterValues);

  const onSubmit = (e: React.FormEvent) => {
    onApplyFilter(filterName, filterValues);
    e.preventDefault();
  };

  const onFilterChange = (e: any) => {
    const statusName = e.target.name;
    const statusSelected = e.target.checked;
    let _filterValues: string[] = [...(filterValues || [])];
    if (statusSelected) {
      // Add the value if does not exists
      if (_filterValues.indexOf(statusName) === -1) {
        _filterValues.push(statusName);
      }
    } else {
      // "Remove" the value if does exists (remove by filtering)
      _filterValues = _filterValues.filter(v => v !== statusName);
      
    }
    if(!_filterValues.length) {
      _filterValues= [...(filterValues || [])];
    }
    setFilterValues(_filterValues);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      className={classes.root}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
      <Card>
        <CardContent>
          <form noValidate autoComplete="off" onSubmit={onSubmit}>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  label='In progress'
                  control={
                    <Checkbox checked={(filterValues || []).indexOf('pending') > -1} onChange={onFilterChange} name='pending' />
                  }
                />
                <FormControlLabel
                  label='Finished'
                  control={
                    <Checkbox checked={(filterValues || []).indexOf('finished') > -1} onChange={onFilterChange} name='finished' />
                  }
                />
              </FormGroup>
            </FormControl>
          </form>
        </CardContent>
        <CardActions>
          <IconButton
            size='small'
            className={classes.applyFilterBtn}
            onClick={() => onApplyFilter(filterName, filterValues)}>
            <Check />
          </IconButton>
        </CardActions>
      </Card>
    </Menu>
  );
};

export default withStyles(styles)(ReportStatusFilterMenu);