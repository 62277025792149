import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { FontWeight, Colors } from '../../../assets/styles';

export default ({ typography }: Theme): StyleRules => {
  const { pxToRem } = typography;

  return createStyles({
    container: {},
    input: {
      paddingTop: pxToRem(15),
      '& label': {
        fontSize: 12,
        letterSpacing: 1.38,
        textTransform: 'uppercase',
      },
    },
    returnLinkWrapper: {
      marginTop: pxToRem(30),
      textAlign: 'center',
    },
    submitButton: {
      maxWidth: 300,
      display: 'block',
      margin: `${pxToRem(30)} auto 0`,
    },
    successMessage: {
      paddingTop: pxToRem(20),
      fontSize: pxToRem(20),
      fontWeight: FontWeight.Bold,
      color: Colors.Blue,
      textAlign: 'center',
    },
    recaptcha: {
      paddingTop: pxToRem(15),
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    recaptchaError: {
      paddingTop: 5,
      color: Colors.Red,
    },
    termsOfServiceLinkInput: {
      '& label': {
        marginRight: 0
      }
    },
    termsOfServiceLink: {
      textTransform: 'none',
      fontSize: 13,
      letterSpacing: 1,
      '& span': {
        color: Colors.Black,
        fontWeight: '600'
      },
      '& a': {
        textDecoration: 'underline',
        color: Colors.Blue1,
        fontSize: '13px'
      }
    },
  });
};
