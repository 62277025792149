import { makeStyles, StyleRules } from '@material-ui/core/styles';
import { Colors } from '../../assets/styles';

export default makeStyles(
  (): StyleRules => {
    return {
      tooltip: {
        fontSize: 14,
        maxWidth: 465,
      },
      avatar: {
        backgroundColor: Colors.Orange1,
        display: 'inline-flex',
        marginRight: 11,
        transform: 'scale(.5)',
        cursor: 'pointer',
        verticalAlign: 'sub',
        '& > svg': {
          transform: 'scale(1.25)',
        },
        '&:hover': {
          backgroundColor: Colors.Orange3,
        }
      },
    };
  }
);
