/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import styles from './Information.styles';
import CircularButton from '../../../../components/CircularButton/CircularButton';
import { Constants } from '../../../../utils';
import { paths } from '../../..';

interface IProps extends WithStylesType<typeof styles> {
  title?: string;
  description?: string;
  testid: string;
}

const LINK_TEXT = {
  RESOURCES: 'resources',
};

const Information: FunctionComponent<IProps> = ({
  classes,
  title,
  description = '',
  testid,
}) => {
  const history = useHistory();

  const navigateToLink = (event: React.SyntheticEvent | React.MouseEvent) => {
    event.preventDefault();
    if (event.target instanceof HTMLAnchorElement){
      const reference = event.target.href;
      const linkTo = reference.substr(reference.lastIndexOf('/') + 1);
      if (linkTo === LINK_TEXT.RESOURCES) {
        history.push(paths.resourcesDashboard);
      }
    }
  };

  return (
    <div className={classes.container}>
      <Typography
        variant="h2"
        className={classes.title}
        data-testid={`${testid}__information__title`}
      >
        {title}
      </Typography>
      <div onClick={navigateToLink}>
        <Typography
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: description }}
          data-testid={`${testid}__information__description`}
        />
      </div>
      <Link
        to={paths.calculatorServiceData.replace(
          Constants.REPORT.URL_PARAM,
          Constants.REPORT.NEW
        )}
      >
        <CircularButton
          type="button"
          color="primary"
          variant="contained"
          customClass={classes.button}
          data-testid={`${testid}__information__button`}
        >
          Start the Calculator
        </CircularButton>
      </Link>
    </div>
  );
};

export default withStyles(styles)(Information);
