import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { Colors, FontWeight } from '../../assets/styles';

export default ({ typography }: Theme): StyleRules => {
  const { pxToRem } = typography;

  const commonBtn = {
    width: 'auto',
    minWidth: 100,
    height: 32,
  };

  return createStyles({
    container: {
      padding: `${pxToRem(40)} ${pxToRem(32)}`,
    },
    title: {
      marginBottom: pxToRem(32),
    },
    content: {
      marginBottom: pxToRem(32),
      color: Colors.Blue,
      fontWeight: FontWeight.Regular,
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    cancelBtn: {
      ...commonBtn,
      marginRight: 10,
      textTransform: 'none',
    },
    acceptBtn: {
      ...commonBtn,
    },
  });
};
