import { saveAs } from 'file-saver';
import path from 'path';

const resourceDownloadRequest = async (url: string, saveName?: string, customConfig?: {}) => {
  const config = {
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Accept': '*/*'
    },
    ...customConfig
  };

  const res = await fetch(url, config);
  const blob = await res.blob();
  saveAs(blob, saveName || path.basename(url))
}

export default resourceDownloadRequest;
