import { StyleRules } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Colors, FontWeight } from '../../../../assets/styles';

const notesBtn: CSSProperties = {
  fontSize: 13,
  fontWeight: FontWeight.Regular,
  color: Colors.Blue1,
  textTransform: 'none',
  padding: '3px 6px',
};

const titleLeftDot = {
  content: '""',
  backgroundColor: Colors.Blue,
  minWidth: 5,
  height: 5,
  marginRight: 20,
  marginTop: 6,
  borderRadius: '50%',
};

export default (): StyleRules =>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 0',
    borderBottom: `2px solid ${Colors.Gray5}`,
  },
  hasChild: {
    borderBottom: 0,
    paddingBottom: 0,
  },
  childDepth1: {
    paddingLeft: 30,
    '& $titleWrapper:before': {
      ...titleLeftDot,
    },
  },
  childDepth2: {
    paddingLeft: 60,
    '& $titleWrapper:before': {
      ...titleLeftDot,
    },
  },
  lastItem: {
    borderBottom: 0,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleWrapper: {
    display: 'flex',
    maxWidth: '50%',
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  readOnlyInputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& div': {
      border: 'none',
    },
    '& input, & p': {
      fontSize: '18px',
      color: Colors.Black,
    },
  },
  input: {
    maxWidth: 182,
  },
  readOnlyInput: {
    maxWidth: 182,
    border: 'none',
  },
  noteContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  noteWrapper: {
    backgroundColor: Colors.Gray7,
    borderRadius: 4,
    padding: '15px 22px',
    width: '100%',
    marginTop: 20,
    marginBottom: 5,
  },
  note: {
    fontSize: 13,
  },
  subWrapper: {
    marginTop: 5,
    display: 'flex',
    width: '100%',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  subLabel: {
    color: Colors.Gray6,
    fontSize: 13,
    fontWeight: FontWeight.Regular,
    padding: '3px 6px',
  },
  subContentPush: {
    marginRight: 'auto'
  },
  addNoteBtn: {
    ...notesBtn
  },
  deleteNoteBtn: {
    ...notesBtn,
    '&:after': {
      content: '""',
      position: 'absolute',
      backgroundColor: Colors.Blue1,
      width: 1,
      height: 12,
      right: 0,
    },
  },
  editNoteBtn: {
    ...notesBtn,
  },
  dialog: {
    width: 600,
  },
  description: {
    paddingBottom: 10,
  },
  errorMessage: {
    color: Colors.Red,
    textAlign: 'right',
    fontSize: 14,
  },
  extraDetails: {
    color: Colors.Gray6,
    fontSize: 13,
    paddingLeft: '20px',
  }
});
