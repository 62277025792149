import { createStyles, StyleRules } from '@material-ui/core/styles';

export default (): StyleRules => {
  return createStyles({
    noteDialog: {
      width: 600,
    },
    noteDialogInput: {
      paddingBottom: 10,
    },
  });
};
