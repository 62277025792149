import React, { useState } from 'react';
import { Switch, Router } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppThunkDispatch } from '../../interfaces/redux.interface';
import { authActions } from '../../redux/authSlice';
import { IRoute } from '../../interfaces/router.interface';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import PublicRoute from './PublicRoute/PublicRoute';
import useOnMount from '../../hooks/useOnMount';
import history from '../history';
import routes from '../routes';

const AppRouter = () => {
  const dispatch: AppThunkDispatch = useDispatch();
  const [authRequested, setAuthRequested] = useState(false);

  useOnMount(() => {
    dispatch(authActions.currentAuthenticatedUser()).then(() => {
      setAuthRequested(true);
    });
  });

  const buildRoute = (route: IRoute) => {
    if (route.public) {
      return <PublicRoute key={route.path} {...route} />;
    }

    return <PrivateRoute key={route.path} {...route} />;
  };

  const buildRoutes = (routesArray: IRoute[]) =>
    routesArray.map((route) => {
      if (route.childRoutes) {
        route.childRoutesElements = buildRoutes(route.childRoutes);
      }

      return buildRoute(route);
    });

  if (!authRequested) return null;

  return (
    <Router history={history}>
      <Switch>{buildRoutes(routes)}</Switch>
    </Router>
  );
};

export default AppRouter;
