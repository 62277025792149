import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';

export default ({ typography }: Theme): StyleRules => {
  const { pxToRem } = typography;

  return createStyles({
    container: {
      padding: `${pxToRem(48)} ${pxToRem(140)}`,
    },
    title: {
      textAlign: 'center',
    },
  });
};
