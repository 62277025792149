import apiService from './api.service';
import { IFilter } from '../interfaces/filter.interface';
import { ISort } from '../interfaces/sort.interface';

const adminUpdateUserAttributes = (params: any) =>
apiService.authRequest(`user/attributes`, {
  method: 'PUT',
  body: JSON.stringify({
    ...params,
  }),
})

const adminDisableUser = (params: any) =>
apiService.authRequest(`user/disable`, {
  method: 'PUT',
  body: JSON.stringify({
    ...params,
  }),
})

const adminEnableUser = (params: any) =>
apiService.authRequest(`user/enable`, {
  method: 'PUT',
  body: JSON.stringify({
    ...params,
  }),
})

const deleteUser = (userName: string) =>
  apiService.authRequest(`user/${userName}`, {
    method: 'DELETE',
  });

const updateUserRole = (userName: string, role: string) =>
  apiService.authRequest(`user/${userName}/role`, {
    method: 'PUT',
    body: JSON.stringify({
      role,
    }),
  });

const resendWelcomeEmail = (userName: string) =>
  apiService.nonAuthRequest(`user/welcome/mail`, {
    method: 'PUT',
    body: JSON.stringify({
      userName,
    }),
  });

const createUser = (payload: any) =>
  apiService.nonAuthRequest(`user`, {
    method: 'POST',
    body: JSON.stringify({
      ...payload,
    }),
  });

const userListDownloadRequest = ({ filter, sort }: { filter?: IFilter, sort?: ISort }) => {
  const parameters = [];
  if (filter) {
    parameters.push({ name: 'filter', value: encodeURIComponent(JSON.stringify(filter)) });
  }
  if (sort) {
    parameters.push({ name: 'sort', value: encodeURIComponent(JSON.stringify(sort)) });
  }
  let queryString = `users/export${parameters.length ? `?${parameters.map(p => `${p.name}=${p.value}`).join('&')}`: ''}`;
  return apiService.authRequest(queryString, {  responseType: 'text'  }, true);
};

const getUsers = ({ filter, sort }: { filter?: IFilter, sort?: ISort }) => {
  const parameters = [];
  if (filter) {
    parameters.push({ name: 'filter', value: encodeURIComponent(JSON.stringify(filter)) });
  }
  if (sort) {
    parameters.push({ name: 'sort', value: encodeURIComponent(JSON.stringify(sort)) });
  }
  let queryString = `users${parameters.length ? `?${parameters.map(p => `${p.name}=${p.value}`).join('&')}`: ''}`;
  return apiService.authRequest(queryString);
};

export default {
  deleteUser,
  updateUserRole,
  userListDownloadRequest,
  getUsers,
  resendWelcomeEmail,
  createUser,
  adminUpdateUserAttributes,
  adminDisableUser,
  adminEnableUser
};
