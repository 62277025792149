import { createStyles, StyleRules } from '@material-ui/core/styles';
import { Colors } from '../../assets/styles';

export default (): StyleRules =>
  createStyles({
    menu: {
      '& li:not(:last-child)': {
        borderBottom: `1px solid ${Colors.Gray1}`,
      },
    },
    menuItem: {
      height: 58,
      '&:hover': {
        color: Colors.Blue1,
      },
    },
  });
