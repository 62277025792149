import { createStyles, StyleRules, Theme } from '@material-ui/core/styles';
import { Colors } from '../../../assets/styles';

export default (theme: Theme): StyleRules =>
  createStyles({
    container: {
      margin: '25px 0px 50px 0px',
    },
    text: {
      textAlign: 'center',
      fontWeight: 'bold'
    },
    textLink: {
      fontSize: 20,
      fontWeight: 'bold'
    },
    contactEmail: {
      background: Colors.White,
      marginTop: 30,
      padding: 16,
      paddingBottom: 25,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'end',
      padding: '16px 0',
    },
    button: {
      maxWidth: 240,
    },
    textArea: {
      width: '100%',
      minHeight: '200px',
      padding: 8,
      resize: 'none',
    },
  });
