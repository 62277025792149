import React, { FunctionComponent } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import cx from 'classnames';
import { Switch } from '../../../../../components';
import styles from './InterventionHeader.styles';

interface IProps extends WithStylesType<typeof styles> {
  title: string;
  checked: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InterventionHeader: FunctionComponent<IProps> = ({
  classes,
  title,
  onChange,
  checked,
}) => {
  return (
    <div>
      <div className={classes.titleWrapper}>
        <Typography
          variant="h2"
          className={cx({ [classes.titleUnchecked]: !checked })}
        >
          {title}
        </Typography>
        <Switch
          id="id"
          checked={checked}
          onChange={onChange}
          labelPlacement='start'
          customLabelClasses={classes.toggleLabel}
          label={!checked ? 'You can edit this intervention by turning toggle on' : ''}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(InterventionHeader);
