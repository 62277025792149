import React, { FunctionComponent, useState } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import * as Yup from 'yup';
import { FormikErrors, useFormik } from 'formik';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { authActions, selectIsLoading } from '../../../redux/authSlice';
import { paths } from '../..';
import { FormValidations } from '../../../utils';
import { CircularButton, InputText, Spinner } from '../../../components';
import { HSLogoColorIcon } from '../../../assets/icons';
import { AppThunkDispatch } from '../../../interfaces/redux.interface';
import styles from './RPForm.styles';

interface FormValues {
  code: string;
  password: string;
  confirmation: string;
  email: string;
  error: string;
}

const testid = 'new-password';

const RPForm: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const isLoading = useSelector(selectIsLoading);
  const dispatch: AppThunkDispatch = useDispatch();
  const initialValues: FormValues = {
    code: '',
    password: '',
    confirmation: '',
    email: '',
    error: '',
  };

  const onSubmit = (
    values: FormValues,
    { setErrors }: { setErrors: (erros: FormikErrors<FormValues>) => void }
  ) => {
    dispatch(
      authActions.forgotPasswordSubmit(
        values.email,
        values.code,
        values.password
      )
    )
      .then(() => setSubmitted(true))
      .catch(() =>
        setErrors({ error: 'There was an error with the provided data' })
      );
  };

  const validationSchema = Yup.object().shape({
    code: FormValidations.required('Validation Code'),
    email: FormValidations.email,
    password: FormValidations.password,
    confirmation: FormValidations.passwordConfirmation,
  });

  const form = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div className={classes.container}>
      <Spinner show={isLoading} />
      <HSLogoColorIcon className={classes.hsLogo} />
      {submitted ? (
        <>
          <Typography
            variant="h1"
            classes={{ root: classes.title }}
            data-testid={`${testid}__title`}
          >
            Success! Your password was reset!
          </Typography>
          <Link to={paths.signIn}>
            <CircularButton
              variant="contained"
              type="button"
              color="primary"
              customClass={classes.submitButton}
              data-testid={`${testid}__submit`}
            >
              Return To Login
            </CircularButton>
          </Link>
        </>
      ) : (
        <>
          <Typography
            variant="h1"
            classes={{ root: classes.title }}
            data-testid={`${testid}__title`}
          >
            Reset Your New Password
          </Typography>
          <Typography
            classes={{ root: classes.subtitle }}
            data-testid={`${testid}__subtitle`}
          >
            Enter your validation code and change your password
          </Typography>
          <form onSubmit={form.handleSubmit}>
            <InputText
              id="code"
              name="code"
              onChange={form.handleChange}
              value={form.values.code}
              labelText="Validation Code"
              placeholder="Enter Validation Code"
              errorMessage={form.errors.code}
              customClass={classes.input}
            />
            <InputText
              labelText="Email Address"
              id="forgot-password-form-email-input"
              name="email"
              onChange={form.handleChange}
              value={form.values.email}
              errorMessage={form.errors.email}
              placeholder="Eg: julia@healthysteps.org"
              customClass={classes.input}
            />
            <InputText
              id="password"
              name="password"
              type="password"
              onChange={form.handleChange}
              value={form.values.password}
              labelText="New Password"
              placeholder="Enter new password"
              errorMessage={form.errors.password}
              customClass={classes.input}
              autoComplete="new-password"
            />
            <ul
              className={classes.rulesList}
              data-testid={`${testid}__rules-list`}
            >
              <li className={classes.rulesItem}>One lowercase character</li>
              <li className={classes.rulesItem}>One special character</li>
              <li className={classes.rulesItem}>One uppercase character</li>
              <li className={classes.rulesItem}>8 characters minimum</li>
              <li className={classes.rulesItem}>One number</li>
              <li className={classes.rulesItem}>50 characters</li>
            </ul>
            <InputText
              id="confirmation"
              name="confirmation"
              type="password"
              onChange={form.handleChange}
              value={form.values.confirmation}
              labelText="Confirm Password"
              placeholder="Confirm new password"
              errorMessage={form.errors.confirmation}
              customClass={classes.input}
            />
            {form.errors.error && (
              <Typography classes={{ root: classes.error }}>
                {form.errors.error}
              </Typography>
            )}
            <CircularButton
              variant="contained"
              type="submit"
              color="primary"
              customClass={classes.submitButton}
              data-testid={`${testid}__submit`}
            >
              Set new password
            </CircularButton>
          </form>
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(RPForm);
