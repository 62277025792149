import React, { FunctionComponent, useLayoutEffect } from 'react';
import {
  withStyles,
  WithStyles as WithStylesType,
} from '@material-ui/core/styles';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  authActions,
  selectUser,
  selectIsLoading,
} from '../../../redux/authSlice';
import { paths } from '../..';
import { FormValidations } from '../../../utils';
import { CircularButton, InputText, Spinner } from '../../../components';
import { HSLogoColorIcon } from '../../../assets/icons';
import styles from './NPCForm.styles';
import authService from '../../../services/auth.service';

interface FormValues {
  password: string;
  confirmation: string;
}

const testid = 'new-password';

const NPCForm: FunctionComponent<WithStylesType<typeof styles>> = ({
  classes,
}) => {
  const [cognitoUser] = authService.useCognitoUserState();
  const user = useSelector(selectUser);
  const isLoading = useSelector(selectIsLoading);
  const history = useHistory();
  const dispatch = useDispatch();
  const initialValues: FormValues = {
    password: '',
    confirmation: '',
  };

  useLayoutEffect(() => {
    if (!cognitoUser) {
      history.push(paths.calculatorDashboard);
    }
  }, [cognitoUser, history]);

  const onSubmit = (values: FormValues) => {
    dispatch(
      authActions.newPasswordChallenge(user?.email || '', values.password)
    );
  };

  const validationSchema = Yup.object().shape({
    password: FormValidations.password,
    confirmation: FormValidations.passwordConfirmation,
  });

  const form = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div className={classes.container}>
      <Spinner show={isLoading} />
      <HSLogoColorIcon className={classes.hsLogo} />
      <Typography
        variant="h1"
        classes={{ root: classes.title }}
        data-testid={`${testid}__title`}
      >
        Set Your New Password
      </Typography>
      <Typography
        classes={{ root: classes.subtitle }}
        data-testid={`${testid}__subtitle`}
      >
        Verify and activate your user account
      </Typography>
      <form onSubmit={form.handleSubmit}>
        <InputText
          id="password"
          name="password"
          type="password"
          onChange={form.handleChange}
          value={form.values.password}
          labelText="New Password"
          placeholder="Enter new password"
          errorMessage={form.errors.password}
          customClass={classes.input}
        />
        <ul className={classes.rulesList} data-testid={`${testid}__rules-list`}>
          <li className={classes.rulesItem}>One lowercase character</li>
          <li className={classes.rulesItem}>One special character</li>
          <li className={classes.rulesItem}>One uppercase character</li>
          <li className={classes.rulesItem}>8 characters minimum</li>
          <li className={classes.rulesItem}>One number</li>
          <li className={classes.rulesItem}>50 characters</li>
        </ul>
        <InputText
          id="confirmation"
          name="confirmation"
          type="password"
          onChange={form.handleChange}
          value={form.values.confirmation}
          labelText="Confirm Password"
          placeholder="Confirm new password"
          errorMessage={form.errors.confirmation}
          customClass={classes.input}
        />
        <CircularButton
          variant="contained"
          type="submit"
          color="primary"
          customClass={classes.submitButton}
          data-testid={`${testid}__submit`}
        >
          Set new password
        </CircularButton>
      </form>
    </div>
  );
};

export default withStyles(styles)(NPCForm);
