/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Snackbar as MuiSnackbar, IconButton } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { selectSnackbar, snackbarActions } from '../../redux/snackbarSlice';

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Snackbar = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector(selectSnackbar);

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(snackbarActions.clearSnackbar());
  };

  return (
    <MuiSnackbar
      open={snackbar.open}
      autoHideDuration={5000}
      onClose={handleClose}
      message={!snackbar.severity && snackbar.message}
      action={
        !snackbar.severity && (
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        )
      }
    >
      {snackbar.severity ? (
        <Alert onClose={handleClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      ) : undefined}
    </MuiSnackbar>
  );
};

export default Snackbar;
