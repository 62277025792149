import React from 'react';
import {
  withStyles,
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Amplify } from 'aws-amplify';
import AppRouter from './routes/AppRouter/AppRouter';
import awsConfig from './aws-config';
import store from './redux/store';
import { Colors, FontWeight } from './assets/styles';
import { Snackbar } from './components';
import styles from './App.styles';

Amplify.configure(awsConfig);

const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      action: {
        disabled: Colors.Gray2,
      },
      primary: {
        main: Colors.Blue1,
        dark: Colors.Blue,
      },
      secondary: {
        main: Colors.Orange2,
      },
    },
    typography: {
      fontFamily: ['Museo Sans', 'sans-serif'].join(','),
      fontWeightLight: FontWeight.Light,
      fontWeightRegular: FontWeight.Regular,
      fontWeightBold: FontWeight.Bold,
      h1: {
        fontSize: 28,
        color: Colors.Blue,
        fontWeight: FontWeight.Bold,
      },
      h2: {
        fontSize: 20,
        color: Colors.Blue1,
        fontWeight: FontWeight.Bold,
      },
      body1: {
        color: Colors.Gray,
        fontWeight: FontWeight.Regular,
      },
      body2: {
        fontSize: 16,
        color: Colors.Blue,
        fontWeight: FontWeight.Bold,
      },
    },
  })
);

const App = () => (
  <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <Snackbar />
        <AppRouter />
      </Provider>
    </ThemeProvider>
  </>
);

export default withStyles(styles)(App);
