import Asthma from './asthma';
import Breastfeeding from './breastfeeding';
import EmergencyDepartment from './emergencyDepartment';
import HealthBirthSpacing from './healthBirthSpacing';
import Immunizations from './immunizations';
import IntimatePartner from './intimatePartnerViolence';
import OralHealth from './oralHealth';
import PostpartumMaternalDepression from './postpartumMaternalDepression';
import ServiceData from './serviceData';
import SmokingCessation from './smokingCessation';
import Calculator from './shared/base';
import { IInterventionsChecklist, InterventionsChecklistForm } from '../interfaces/interventions.interface';

export default class Main extends Calculator {
  public serviceData: ServiceData;

  public emergencyDepartment: EmergencyDepartment;

  public asthma: Asthma;

  public breastfeeding: Breastfeeding;

  public healthBirthSpacing: HealthBirthSpacing;

  public immunizations: Immunizations;

  public intimatePartner: IntimatePartner;

  public oralHealth: OralHealth;

  public postpartumMaternalDepression: PostpartumMaternalDepression;

  public smokingCessation: SmokingCessation;

  public interventionsChecklist: IInterventionsChecklist;

  constructor({
    state,
    serviceData,
    emergencyDepartment,
    asthma,
    breastfeeding,
    healthBirthSpacing,
    immunizations,
    intimatePartner,
    oralHealth,
    postpartumMaternalDepression,
    smokingCessation,
    interventionsChecklist,
  }: {
    state: string;
    serviceData: ServiceData;
    emergencyDepartment: EmergencyDepartment;
    asthma: Asthma;
    breastfeeding: Breastfeeding;
    healthBirthSpacing: HealthBirthSpacing;
    immunizations: Immunizations;
    intimatePartner: IntimatePartner;
    oralHealth: OralHealth;
    postpartumMaternalDepression: PostpartumMaternalDepression;
    smokingCessation: SmokingCessation;
    interventionsChecklist: IInterventionsChecklist;
  }) {
    super({ state });
    this.serviceData = serviceData;
    this.emergencyDepartment = emergencyDepartment;
    this.asthma = asthma;
    this.breastfeeding = breastfeeding;
    this.healthBirthSpacing = healthBirthSpacing;
    this.immunizations = immunizations;
    this.intimatePartner = intimatePartner;
    this.oralHealth = oralHealth;
    this.postpartumMaternalDepression = postpartumMaternalDepression;
    this.smokingCessation = smokingCessation;
    this.interventionsChecklist = interventionsChecklist;
  }

  /**
   * @name children_low_savings
   * @excel Excel E11
   * @formula dental_low_savings + emergency_deparment_use_low_savings + inmunizations_low_savings + asthma_low_savings
   */
  public childrenLowSavings = () => {
    return (
      (this.interventionsChecklist[InterventionsChecklistForm.OralHealth] ? this.oralHealth.dentalEstimatedSavingLow() : 0) +
      (this.interventionsChecklist.emergencyDepartmentUse ? this.emergencyDepartment.emergencyDepartmentEstimatedSavingLow() : 0) +
      (this.interventionsChecklist.immunizations ? this.immunizations.immunizationsEstimatedSavingsLow() : 0) +
      (this.interventionsChecklist.asthma ? this.asthma.asthmaEstimatedSavingsLow() : 0)
    );
  };

  /**
   * @name children_high_savings
   * @excel Excel F11
   * @formula dental_high_savings + emergency_deparment_use_high_savings + inmunizations_high_savings + asthma_high_savings
   */
  public childrenHighSavings = () => {
    return (
      (this.interventionsChecklist[InterventionsChecklistForm.OralHealth] ? this.oralHealth.dentalEstimatedSavingHigh() : 0) +
      (this.interventionsChecklist.emergencyDepartmentUse ? this.emergencyDepartment.emergencyDepartmentEstimatedSavingHigh() : 0) +
      (this.interventionsChecklist.immunizations ? this.immunizations.immunizationsEstimatedSavingsHigh() : 0) +
      (this.interventionsChecklist.asthma ? this.asthma.asthmaEstimatedSavingsHigh() : 0)
    );
  };

  /**
   * @name parent_low_savings
   * @excel Excel E68
   * @formula postpartum_maternal_depression_low_savings + breastfeeding_low_savings + smoking_cessation_low_savings + intimate_partner_violence_low_savings + healthy_birth_spacing_low_savings
   */
  public parentLowSavings = () => {
    return (
      (this.interventionsChecklist.postpartumMaternalDepression ? this.postpartumMaternalDepression.maternalDepressionEstimatedSavingsLow() : 0) +
      (this.interventionsChecklist.breastfeeding ? this.breastfeeding.breastfeedingEstimatedSavingsLow() : 0) +
      (this.interventionsChecklist.smokeCessation ? this.smokingCessation.smokingCessationEstimatedSavingsLow() : 0) +
      (this.interventionsChecklist.intimidatePartnerViolence ? this.intimatePartner.intimatePartnerViolenceEstimatedSavingsLow() : 0) +
      (this.interventionsChecklist.healthyBirthSpacing ? this.healthBirthSpacing.healthBirthSpacingEstimatedSavingsLow() : 0)
    );
  };

  /**
   * @name parent_high_savings
   * @excel Excel F68
   * @formula postpartum_maternal_depression_high_savings + breastfeeding_high_savings + smoking_cessation_high_savings + intimate_partner_violence_high_savings + healthy_birth_spacing_high_savings
   */
  public parentHighSavings = () => {
    return (
      (this.interventionsChecklist.postpartumMaternalDepression ? this.postpartumMaternalDepression.maternalDepressionEstimatedSavingsHigh() : 0) +
      (this.interventionsChecklist.breastfeeding ? this.breastfeeding.breastfeedingEstimatedSavingsHigh() : 0) +
      (this.interventionsChecklist.smokeCessation ? this.smokingCessation.smokingCessationEstimatedSavingsHigh() : 0) +
      (this.interventionsChecklist.intimidatePartnerViolence ? this.intimatePartner.intimatePartnerViolenceEstimatedSavingsHigh() : 0) +
      (this.interventionsChecklist.healthyBirthSpacing ? this.healthBirthSpacing.healthBirthSpacingEstimatedSavingsHigh() : 0)
    );
  };

  /**
   * @name global_estimate_savings_low
   * @excel Excel E9
   * @formula children_low_savings + parent_low_savings
   */
  public globalEstimateSavingsLow = () => {
    return this.childrenLowSavings() + this.parentLowSavings();
  };

  /**
   * @name global_estimate_savings_high
   * @excel Excel F9
   * @formula children_high_savings + parent_high_savings
   */
  public globalEstimateSavingsHigh = () => {
    return this.childrenHighSavings() + this.parentHighSavings();
  };

  /**
   * @name total_savings_avg
   * @formula (global_estimate_savings_low + global_estimate_savings_high) / 2
   */
  public totalSavingsAvg = () => {
    return (
      (this.globalEstimateSavingsLow() + this.globalEstimateSavingsHigh()) / 2
    );
  };

  /**
   * FINAL REPORT
   * NOTES: SOME VALUES COMES FROM Overview Data Screen
   */

  /**
   * @name total_annual_medicaid_program_cost
   * @formula dc_input_5 * medicaid_perc_of_caseload
   */
  public totalAnnualMedicaidProgramCost = () => {
    return (
      this.serviceData.dcInput5() * this.serviceData.medicaidPercOfCaseload()
    );
  };

  /**
   * @name annual_cost_per_child
   * @formula total_annual_program_cost / cs_input_1
   */
  public annualCostPerChild = () => {
    return this.isFiniteNumber(
      this.serviceData.dcInput5() / this.serviceData.csInput1
    );
  };

  /**
   * @name annual_cost_per_medicaid_child
   * @formula total_annual_medicaid_program_cost / cs_input_2
   */
  public annualCostPerMedicaidChild = () => {
    return this.isFiniteNumber(
      this.totalAnnualMedicaidProgramCost() / this.serviceData.csInput2
    );
  };

  /**
   * @name cost_savings_per_medicaid_family
   * @formula total_savings_avg / cs_input_2
   */
  public costSavingsPerMedicaidFamily = () => {
    return this.isFiniteNumber(
      this.totalSavingsAvg() / this.serviceData.csInput2
    );
  };

  /**
   * @name annual_return_on_investment
   * @formula total_savings_avg - medicaid_anual_cost
   */
  public annualReturnOnInvestment = () => {
    return this.totalSavingsAvg() - this.totalAnnualMedicaidProgramCost();
  };

  /**
   * @name annual_medicaid_return_on_investment
   * @formula total_savings_avg * medicaid_perc_of_caseload
   */
  public annualMedicaidReturnOnInvestment = () => {
    return this.totalSavingsAvg() * this.serviceData.medicaidPercOfCaseload();
  };

  /**
   * @name national_office_demostrated_annualized_savings_to_medicaid_up_to_dollars_per_family
   * @formula global_estimate_savings_high / cs_input_2
   */
  public nationalOfficeDemostratedAnnualizedSavingsToMedicaidUpToDollarsPerFamily = () => {
    return this.isFiniteNumber(
      this.globalEstimateSavingsHigh() / this.serviceData.csInput2
    );
  };

  /**
   * percentage needs to be 75%, 168% (NOT 0.75, or 1.68)
   * @name annual_return_on_investment_perc
   * @formula ( total_savings_avg - total_annual_medicaid_program_cost) / total_annual_medicaid_program_cost
   */
  public annualReturnOnInvestmentPerc = () => {
    return this.isFiniteNumber(
      ((this.totalSavingsAvg() - this.totalAnnualMedicaidProgramCost()) /
        this.totalAnnualMedicaidProgramCost()) *
        100
    );
  };

  /**
   * assuming annual_return_on_investment_perc is 168% this calculation is
   * @name an_estimated_dollars_in_savings
   * @formula annual_return_on_investment_perc * 0.01
   */
  public anEstimatedDollarsInSavings = () => {
    return this.isFiniteNumber(this.annualReturnOnInvestmentPerc() * 0.01);
  };

  /**
   * @name medicaid_percentage_of_totals
   * @formula dc_input_5 * medicaid_perc_of_caseload
   */
  public medicaidPercentageOfTotals = () => {
    return (this.serviceData.csInput2 * 100) / this.serviceData.csInput1;
  };

  /**
   * @name return_per_medicaid_child
   * @formula costSavingsPerMedicaidFamily - annualCostPerChild
   */
  public returnPerMedicaidChild = () => {
    return this.costSavingsPerMedicaidFamily() - this.annualCostPerChild();
  };
}

  
